import $ from "jquery";
import Vue from "vue";
import Data from "../application/Data";
import DataUtils from "./DataUtils";
import FiltersUtils from "./FiltersUtils";
import Utils from "./Utils";

export default class URLManager {
    public static data:any;
    public static previousHash:string;
    public static hash:string;
    public static params:string;
    private static hashChangeDelay:number = 200;

    public static paused:boolean = false;
    public static status:boolean = false;


    public static init(){
        //OLD VG MODE, TO REMOVE WHEN DONE
        if (Data.data.platform.useOldVirtualGlass) {
            if (window.location.href.toLowerCase().indexOf("?mode=virtualglass") != -1 || window.location.href.toLowerCase().indexOf("?platform=virtualglass") != -1){
                Data.data.oldVGMode = true;
                Data.data.VGMode = true;
                Data.data.platform.useVirtualGlass = true;
                Data.data.platform.useOldVirtualGlass = true;
                Data.data.platform.useURL = false;

                if (Data.data.platform.id == "MYVERALLIA") {
                    Data.data.config.views.general = Data.data.config.views.generalVG;
                    Data.data.config.views.myproducts = Data.data.config.views.myproductsVG;
                }
            }
        }

        this.data = { app: "catalog", country: Data.data.country, language: Data.data.language };



        if (!Data.data.platform.useURL) return;

        let hash: string = window.location.hash;

        if (!hash) return;
        hash = hash.replace("#/", "");

        if (!hash ||hash == "") return;

        //APP
        if (Data.data.platform.id == "INTERNAL" || Data.data.platform.id == "MYVERALLIA"){
            if(Data.data.platform.useVirtualGlass){
                if (hash == "vg") this.data.app = "virtualglass";
            }
        }

        //PUBLIC COUNTRY AND LANGUAGE
        if (Data.data.platform.id == "PUBLIC") {
            let tab: any = hash.split("/");
            let params: any = tab[0].split("-");
            if (params.length == 2) {
                if (Data.data.config.countries.indexOf(params[0]) != -1) {
                    this.data.country = params[0];
                    this.data.urlLanguage = params[1];
                    this.data.language = Data.data.config.countriesLanguages[this.data.country][0];
                }
            }
        }
    }
    public static startDetectURLChange(){
        if (!Data.data.platform.useURL) return;

        this.status = true;

        $(window).on("hashchange", this.setDataFromURL.bind(this));
    }
    public static stopDetectURLChange() {
        if (!Data.data.platform.useURL) return;

        this.status = false;

        $(window).off("hashchange");
    }
    private static formatStringToUrl(value: string) {
        if (!value) return value;

        value = value.toLowerCase();
        return value.replace(/[^a-zA-Z0-9]/g, '');
    }
    public static setHash(hash: string, replace:boolean=false) {
        //window.history.pushState(null, null, "#/" + hash);
        //
        if (!replace) window.location.hash = "#/" + hash;
        //if (!replace) window.location.hash = "#/" + hash;
        //if (!replace) window.history.pushState(null, null, "#/" + hash);
        else window.history.replaceState(null, null, "#/" + hash);

        this.previousHash = hash;
    }
    private static openHome() {
        if (Data.data.product) $(window).trigger("CLOSE_PRODUCT");

        if (Data.data.section == "home") return;

        console.log("openHome");

        Data.data.section = "home";
        Data.data.view = Data.data.platform.view;
        Data.data.userPath = [];
    }
    private static openView(view: string) {
        if (Data.data.product) $(window).trigger("CLOSE_PRODUCT");

        if (view == Data.data.view) return;

        console.log("openView", view);

        Vue.set(Data.data, "view", view);
        Vue.set(Data.data, "section", "view");

        //Data.data.product = null;
        FiltersUtils.setFilters();
    }
    private static openProduct(product: any) {
        //Data.data.section = "product";
        $(window).trigger("OPEN_PRODUCT", product);
    }
    private static resume() {
        this.paused = false;
    }







    public static setDataFromURL() {
        if (!Data.data.platform.useURL || this.paused == true) return;

        this.paused = true;
        setTimeout(this.resume.bind(this), this.hashChangeDelay);

        this.hash = window.location.hash;

        if (!this.hash || this.hash == ""){
            this.openHome();
        }

        this.hash = this.hash.replace("#/", "");
        let tab: any = this.hash.split("?");
        this.hash = tab[0];
        this.params = tab[1];

        if (this.hash == ""){
            if (Data.data.platform.id == "MYVERALLIA") {
                Data.data.products = Data.productsJson;
                Data.data.baseProducts = Data.baseProductsJson;
                this.openView("general");
            }else{
                this.openHome();
            }

            return;
        }

        tab = this.hash.split("/");
        if (tab.length == 0) {
            if (Data.data.platform.id == "MYVERALLIA") {
                Data.data.products = Data.productsJson;
                Data.data.baseProducts = Data.baseProductsJson;
                this.openView("general");
            }else{
                this.openHome();
            }

            return;
        }


        if (Data.data.app == "catalog") {
            if (Data.data.platform.id == "INTERNAL" || Data.data.platform.id == "MYVERALLIA" || Data.data.platform.id == "SELECTIVELINE" || Data.data.platform.id == "PUBLIC") {
                let view: string = tab[0];
                let bp: string = tab[1];

                if (Data.data.platform.id == "SELECTIVELINE"){
                    view = "slproducts";
                } else if (Data.data.platform.id == "PUBLIC"){
                    view = "publicproducts";
                }

                //if (Data.data.platform.id == "SELECTIVELINE")

                if (view && view.charAt(0) == "8") {

                } else {
                    if (!Data.data.config.views[Data.data.platform.view]) return;
                    if (Data.data.config.views[Data.data.platform.view].views.indexOf(view) == -1) return;

                    if(Data.data.platform.id == "MYVERALLIA"){
                        if (view == "myproducts") {
                            Data.data.products = Data.myProductsJson;
                            Data.data.baseProducts = Data.myProductsBaseProductsJson;
                        } else {
                            Data.data.products = Data.productsJson;
                            Data.data.baseProducts = Data.baseProductsJson;
                        }
                    }

                    this.openView(view);

                    if (bp && bp.charAt(0) == "8"){
                        let str:string;
                        let found:boolean = false;
                        for(str in Data.data.products){
                            let bpIndex: number = Data.data.products[str].baseProducts.indexOf(bp);
                            if (bpIndex != -1){
                                Data.data.products[str].bpIndex = bpIndex;

                                this.openProduct(Data.data.products[str]);
                                found = true;

                                break;
                            }
                        }

                        if (!found && Data.data.product) Vue.set(Data.data, "product", null);
                    }
                }
            }

            //FILTERS
            if (Data.data.platform.catalogFiltersInURL == true && Data.data.view && Data.data.config.views[Data.data.view].filters && this.params) {
                let p:any = Utils.copy(this.params);
                this.setFiltersFromURLParams(p);
                //setTimeout(this.setFiltersFromURLParams.bind(this, p), 100);
            }
        }

        this.setURLFromData(true);
    }
    private static setFiltersFromURLParams(URLparams:string){
        let filters: any = FiltersUtils.createFilters(Data.data.view);

        if (URLparams) {
            let URLParams: any = URLparams.split("&");
            let params: any = {};
            let a: number = URLParams.length;
            for (let i = 0; i < a; i++) {
                let tab: any = URLParams[i].split("=");
                params[tab[0]] = tab[1];
            }

            let str: string;
            for (str in params) {
                let paramData: any = params[str];
                let filterConfig: any = Data.data.config.filters[str];

                if (filterConfig && params) {
                    if (Data.data.config.views[Data.data.view].filters.indexOf(str) != -1) {

                        //VALUE
                        if (filterConfig.type == "value") {
                            if (paramData == "true") filters[str] = true;

                            //MULTIPLE VALUES
                        } else if (filterConfig.type == "multipleValues") {
                            let a: number = Data.data.filters[str].data.length;
                            paramData = paramData.split(",");

                            for (let i = 0; i < a; i++) {
                                let value: string = this.formatStringToUrl(Data.data.filters[str].data[i].toLowerCase());
                                if (paramData.indexOf(value) != -1) filters[str].selected.push(Data.data.filters[str].data[i]);
                            }

                            //ARRAY
                        } else if (filterConfig.type == "array") {
                            let a: number = Data.data.filters[str].length;
                            paramData = paramData.split(",");

                            for (let i = 0; i < a; i++) {
                                if (paramData.indexOf(filters[str][i].id) != -1) filters[str][i].selected = true;
                            }

                            //TREE VALUES
                        } else if (filterConfig.type == "treeValues") {
                            paramData = paramData.split("-");
                            let paramsList: any = {};

                            let a: number = paramData.length;
                            for (let i = 0; i < a; i++) {
                                let tab: any = paramData[i].split("_");
                                paramsList[tab[0]] = [];
                                if (tab[1]) paramsList[tab[0]] = tab[1].split(",");
                            }

                            //console.log(paramsList);

                            a = Data.data.filters[str].length;
                            for (let i = 0; i < a; i++) {
                                if (paramsList[filters[str][i].id]) {
                                    filters[str][i].selected = true;

                                    let b: number = filters[str][i].sub.length;
                                    for (let j = 0; j < b; j++) {
                                        if (paramsList[filters[str][i].id].indexOf(filters[str][i].sub[j].id) != -1) {
                                            filters[str][i].sub[j].selected = true;
                                        }
                                    }
                                }
                            }

                            //NUMBER RANGE
                        } else if (filterConfig.type == "numberRange") {
                            let a: number = Data.data.filters[str].length;
                            paramData = paramData.split("-");

                            if (Number(paramData[0]) < Number(paramData[1]) && Number(paramData[0]) >= filters[str].min && Number(paramData[1]) <= filters[str].max) {
                                filters[str].range[0] = Number(paramData[0]);
                                filters[str].range[1] = Number(paramData[1]);
                            }

                            //DATES RANGE
                        } else if (filterConfig.type == "datesRange") {
                            let a: number = Data.data.filters[str].length;
                            paramData = paramData.split("-");

                            let startIndex: number = filters[str].dates.indexOf(paramData[0]);
                            let endIndex: number = filters[str].dates.indexOf(paramData[1]);

                            if (startIndex != -1 && endIndex != -1 && startIndex <= endIndex && startIndex >= 0 && endIndex <= filters[str].dates.length - 1) {
                                filters[str].startDate = paramData[0];
                                filters[str].endDate = paramData[1];
                            }
                        }
                    }
                }
            }
        }

        if (JSON.stringify(Data.data.filters) != JSON.stringify(filters)) {
            Data.data.savedFilters[Data.data.platform.id + "-" + Data.data.view] = filters;
            Vue.set(Data.data, "filters", filters);

            $(window).trigger("UPDATE_FILTERS");
        }
    }
    public static setURLFromData(force:boolean=false, replaceURL:boolean=false){
        if (force == false){
            if (!Data.data.platform.useURL) return;

            if (this.paused == true) return;
        }

        this.paused = true;
        setTimeout(this.resume.bind(this), this.hashChangeDelay);

        let hash:string = "";
        let params:string = "";

        if (Data.data.app == "catalog") {
            if (Data.data.platform.id == "INTERNAL") {
                if (Data.data.view) hash += this.formatStringToUrl(Data.data.view);
                if (Data.data.product) hash += "/" + Data.data.product.baseProducts[Data.data.product.bpIndex] + "/" + this.formatStringToUrl(Data.data.product.name);

            }else if (Data.data.platform.id == "MYVERALLIA") {
                if (Data.data.view && Data.data.section != "home") hash += this.formatStringToUrl(Data.data.view);
                if (Data.data.product) hash += "/" + Data.data.product.baseProducts[Data.data.product.bpIndex] + "/" + this.formatStringToUrl(Data.data.product.name);

            } else if (Data.data.platform.id == "PUBLIC") {
                if (!Data.data.product) hash += Data.data.country + "-" + Data.data.language;
                else hash += Data.data.country + "-" + Data.data.language + "/" + Data.data.product.baseProducts[Data.data.product.bpIndex] + "/" + this.formatStringToUrl(Data.data.product.name);
            } else if (Data.data.platform.id == "SELECTIVELINE") {
                if (!Data.data.product) hash += Data.data.language;
                else hash += Data.data.language + "/" + Data.data.product.baseProducts[Data.data.product.bpIndex] + "/" + this.formatStringToUrl(Data.data.product.name);
            }

            //FILTERS
            if (Data.data.platform.catalogFiltersInURL == true && Data.data.section == "view" && Data.data.view && Data.data.config.views[Data.data.view].filters){
                let a: number = Data.data.config.views[Data.data.view].filters.length;
                for(let i=0; i<a; i++){
                    let f: string = Data.data.config.views[Data.data.view].filters[i];
                    let filterConfig: any = Data.data.config.filters[f];

                    if (!filterConfig || !Data.data.filters || !Data.data.filters[f]) continue;


                    //VALUE
                    if (filterConfig.type == "value"){
                        if (Data.data.filters[f] == true) params += "&" + f + "=true";

                    //MULTIPLE VALUES
                    } else if (filterConfig.type == "multipleValues") {
                        let multipleValues:string = "&" + f + "=";

                        let b: number = Data.data.filters[f].selected.length
                        if (b == 0) continue;

                        for(let j=0; j<b; j++){
                            multipleValues += this.formatStringToUrl(Data.data.filters[f].selected[j].toLowerCase()) + ",";
                        }

                        params += multipleValues.slice(0, -1);

                    //ARRAY
                    }else if (filterConfig.type == "array") {
                        let arrayValues: string = "&" + f + "=";

                        let c: number = 0;
                        let b: number = Data.data.filters[f].length;
                        for (let j = 0; j < b; j++) {
                            if (Data.data.filters[f][j].selected == true) {
                                arrayValues += Data.data.filters[f][j].id + ",";
                                c++;
                            }
                        }

                        if (c != 0 && c != b) params += arrayValues.slice(0, -1);

                    //TREE VALUES
                    } else if (filterConfig.type == "treeValues") {
                        let treeValues: string = "&" + f + "=";

                        let c: number = 0;
                        let b: number = Data.data.filters[f].length;
                        for (let j = 0; j < b; j++) {
                            if (Data.data.filters[f][j].selected == true) {
                                if(c == 0) treeValues += Data.data.filters[f][j].id;
                                else treeValues += "-" + Data.data.filters[f][j].id;
                                c ++;

                                let subtreeValues:string = "_";

                                let d: number = Data.data.filters[f][j].sub.length;
                                for (let k = 0; k < d; k++) {
                                    if (Data.data.filters[f][j].sub[k].selected == true) {
                                        subtreeValues += Data.data.filters[f][j].sub[k].id + ",";
                                    }
                                }

                                if (subtreeValues != "_") treeValues += subtreeValues.slice(0, -1);
                            }
                        }

                        if (c != 0) params += treeValues;

                    //NUMBER RANGE
                    } else if (filterConfig.type == "numberRange") {
                        let numberValues: string = "&" + f + "=";

                        if (Data.data.filters[f].range[0] > Data.data.filters[f].min || Data.data.filters[f].range[1] < Data.data.filters[f].max){
                            params += numberValues + Data.data.filters[f].range[0] + "-" + Data.data.filters[f].range[1];
                        }

                    //DATES RANGE
                    } else if (filterConfig.type == "datesRange") {
                        let datesValues: string = "&" + f + "=";

                        if (Data.data.filters[f].startDate != Data.data.filters[f].dates[0] || Data.data.filters[f].endDate != Data.data.filters[f].dates[Data.data.filters[f].dates.length - 1]) {
                            params += datesValues + Data.data.filters[f].startDate + "-" + Data.data.filters[f].endDate;
                        }
                    }
                }

                if (params != "") params = "?" + params.substring(1);
            }
        } else if (Data.data.app == "virtualglass"){
            if (Data.data.platform.id == "INTERNAL" || Data.data.platform.id == "MYVERALLIA") {
                hash = "vg";
            }
        }

        //console.log(this.previousHash + " : " + hash);

        if (this.previousHash != hash + params) this.setHash(hash + params, replaceURL);
    }

}