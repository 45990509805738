
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import VGObject from "@/ts/player/modules/VGObject";

    @Component({
        components: {
            vuescroll
        }
    })
    export default class Colors extends Generic {
        private countries:any[] = [];

        private mounted() {
            for(let str in Data.data.vg.colors){
                var color = Data.data.vg.colors[str];

                let a:number = color.countries.length;
                for(let i=0; i<a; i++){
                    if(this.countries.indexOf(color.countries[i]) == -1) this.countries.push(color.countries[i]);
                }

            }
        }
        private destroyed(){

        }
        private close(){
            this.$emit("close");
        }
        private selectColor(color:any){
            Data.data.vg.itemData.colorID = color;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setColor(color);

            this.saveScreenshot();
        }
        private saveScreenshot(){
            //$(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);
        }
        private getColorName(color:any){
            if(color.translations[Data.data.language]) return color.translations[Data.data.language];
            else if(color.translations.en_us) return color.translations.en_us;

            return color.id;
        }
        private getProductColors(){
            let colors:any = {};

            let meta: any = this.getMetaFromBaseProduct(Data.data.vg.itemData.baseProduct);

            for (let j = 0; j < meta.baseProducts.length; j++){
                let baseProduct: any = Data.data.baseProducts[meta.baseProducts[j]];

                if (!baseProduct) continue;

                for (let str in Data.data.vg.colors) {
                    let color: any = Data.data.vg.colors[str];

                    if (color.sapColor == baseProduct.sapColor) {
                        for (let i = 0; i < baseProduct.countries.length; i++) {
                            let bpCountry: string = baseProduct.countries[i];

                            if (color.countries.indexOf(bpCountry) != -1) {
                                colors[str] = color;
                            }
                        }
                    }
                }
            }

            console.log(colors);

            return colors;
        }
    }
