
import Generic from "../Generic.vue";
import SpecsData from "./product/SpecsData.vue";
import PLSSpecsData from "./product/PLSSpecsData.vue";
import BuyFinishedProducts from "./product/BuyFinishedProducts.vue";
import FinishedProducts from "./product/FinishedProducts.vue";
import FinishedProductsStocks from "./product/FinishedProductsStocks.vue";
import Utils from "../../ts/utils/Utils";
import UserPath from "./UserPath.vue";
import $ from "jquery";

import {
    Component,
} from 'vue-property-decorator';
import Data from "@/ts/application/Data";
import URLManager from "@/ts/utils/URLManager";
import vuescroll from 'vuescroll';

@Component({
    components: {
        SpecsData,
        PLSSpecsData,
        BuyFinishedProducts,
        FinishedProducts,
        FinishedProductsStocks,
        vuescroll
    }
})

export default class ProductPLS extends Generic {
    private currentImage: number = 0;
    private plsTooltip: boolean = false;
    private scrollPosition: number = 0;



    private mounted() {
        for (let i = 0; i < Data.data.product.baseProducts.length; i++) {
            let bp: any = Data.data.baseProducts[Data.data.product.baseProducts[i]];

            if (!bp.pls) continue;

            Data.data.product.bpIndex = i;

            break;
        }

        Data.data.productSection = Data.data.productSections[0];
        $(window).on("resize", this.resize.bind(this))
        //console.log(Data.data.product);

        //$(window).trigger("CALL_GG");
    }
    private destroyed() {
        $(window).off("resize", this.resize.bind(this))
        //Data.data.product = null;
    }
    private resize() {
        this.areActionsButtonFixed();
    }
    private handleScroll(e: any) {
        if (!Data.data.products) return;

        this.scrollPosition = e.scrollTop;
    }
    private isOnDev(){
        if(window.location.href.indexOf('appsdev.corp') != -1) return true;

        return false;
    }
    private areActionsButtonFixed() {
        let offset: any = $("#ProductActions").offset();
        if (offset && offset.top <= -50 && !Data.data.isMobile) return true;

        return false;
    }
    private isCloseButtonFixed() {
        if (this.scrollPosition >= 70 && !Data.data.isMobile) return true;
        else if (Data.data.isMobile) return true;

        return false;
    }
    private closeProduct() {
        $(window).trigger("CLOSE_PRODUCT");
    }
    private openProduct(bp: string, index: number) {
        this.$set(Data.data.product, "bpIndex", index);
        this.currentImage = 0;
        this.plsTooltip = false;

        $(window).trigger("OPEN_PRODUCT", Data.data.product);
    }
    private imageChange() {
        this.plsTooltip = false;
        $(window).trigger("UPDATE_PRODUCT", this.currentImage);
    }
    private clickOutsideTooltip() {
        //if(this.plsTooltip) this.plsTooltip = false;
    }
    private isSectionVisible(section: string) {
        if (Data.data.productSection == "buy") {
            if (section == "buy") return true;

            return false;
        }

        if (section == "buy") return false;


        if (Data.data.isMobile) {
            return true;
        }

        if (section == "specs") {
            if (!Data.data.productSection || Data.data.productSection == "specs") return true;
            else return false;
        } else if (section == "commercialData") {
            if (Data.data.productSection == "commercialData") return true;
            else return false;
        } else if (section == "finishedProducts") {
            if (Data.data.productSection == "finishedProducts") return true;
            else return false;
        } else if (section && "stocks") {
            if (Data.data.productSection == "stocks") return true;
            else return false;
        }

        return false;
    }
    private downloadGlassDrawing() {
        window.open(Data.data.product.glassDrawingLink, "_blank");
    }
    private downloadDilatationCurves() {
        window.open(Data.data.product.dilatationCurvesLink, "_blank");
    }
    private downloadZoomPicture() {
        window.open(Data.data.platform.productsImages + 'zoom/' + Data.data.product.baseProducts[Data.data.product.bpIndex] + '.jpg', "_blank");
    }
    private toggleComparator() {
        if (!Data.data.comparator[Data.data.product.glassDrawing]) {
            this.$set(Data.data.comparator, Data.data.product.glassDrawing, { baseProduct: Data.data.product.baseProducts[Data.data.product.bpIndex], index: Object.keys(Data.data.comparator).length });
        } else {
            this.$delete(Data.data.comparator, Data.data.product.glassDrawing);
        }
    }
    private openInVirtualGlass() {
        if (this.maintenanceMode()) {
            return;
        }

        if (Data.data.platform.useOldVirtualGlass) {
            $(window).trigger("OPEN_OLD_VIRTUALGLASS", Data.data.product);
            return;
        }

        if (Data.data.app == "catalog") $(window).trigger("CREATE_PROJECT_FROM_ITEM_SELECTED_FROM_CATALOG", Utils.copy(Data.data.product));
        else $(window).trigger("ITEM_SELECTED_FROM_CATALOG", Utils.copy(Data.data.product));

        $(window).trigger("CLOSE_PRODUCT");
    }
    private openContact() {
        if (Data.data.platform.id == "MYVERALLIA" || Data.data.platform.id == "PUBLIC") {
            try {
                let label: string = Data.data.product.name;
                let bp: string = Data.data.product.baseProducts[Data.data.product.bpIndex];

                //@ts-ignore
                window.contactForm(bp, null, label);
            } catch (e: any) {
                console.log("Could not open contact", e);
            }
        } else if (Data.data.platform.id == "SELECTIVELINE") {
            let url: string = Data.data.platform.contactUrl.replace("{{COUNTRY}}", Data.data.language);

            //@ts-ignore
            window.open(url, "_blank");
        }
    }
    private editPLS(){
        if(this.isOnDev()) return;

        let bp: any = Data.data.baseProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]];
        let pls: any = Data.data.plsProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]][this.currentImage];
        window.open(Data.data.platform.plsEditUrl + pls._id, "_blank");
    }
}
