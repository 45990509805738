import Vue from 'vue';
import Vuetify from 'vuetify';
import vuescroll from 'vuescroll';
import Snotify, { SnotifyPosition } from 'vue-snotify';

import Data from "./application/Data";
import App from '../components/App.vue';
import Loader from './application/Loader';
import DataUtils from './utils/DataUtils';
import Player from './player/Player';
import $ from 'jquery';
import SSO from './application/SSO';
import URLManager from './utils/URLManager';
import VGDataLoader from './application/VGDataLoader';
import { decomposeDataUri } from '@pixi/utils';
import Notifications from './application/Notifications';



export default class Main{
    private fileLoader:Loader;
    private sso:SSO;
    private forcePlatform:string = null;

    constructor(){
        if (window.location.host.indexOf("localhost") == -1 && window.location.protocol == "http:") {
            window.location.href = "https://" + location.host + location.pathname + window.location.hash.replace("/#", "#");
            return;
        }

        if (window.location.host.indexOf("appsdev.corp") != -1 || window.location.host.indexOf("appsqual.corp") != -1 || window.location.host.indexOf("apps.corp") != -1) {
            this.sso = new SSO(this.setUserData.bind(this));
            return;
        }

        new Notifications();
        Data.init();
        this.loadPlatform();
    }
    private setUserData(userData: any) {
        this.sso = null;

        if (!userData) return;

        Data.init();
        if (userData){
            Data.data.user = userData;
            Data.data.userID = userData.id;
            Data.data.country = userData.country;
            Data.data.company = userData.country;
        }
        this.loadPlatform();
    }
    private loadPlatform(){
        if (window.location.host.indexOf("force.com") != -1 || window.location.host.indexOf("site.com") != -1 || window.location.host.indexOf("verallia.com") != -1) {
            //@ts-ignore
            Data.data.config.assetsPath = window.catalogAssetsPath;
        }

        this.fileLoader = new Loader(this.initPlatform.bind(this), this.filesError.bind(this));
        this.fileLoader.add({ id: "environments", url: Data.data.config.assetsPath + "json/environments.json", method: "GET" });
        this.fileLoader.start();
    }
    private initPlatform(){
        $(window).on("APP_DISPOSE", this.dispose.bind(this));
        $(window).on("APP_READY", this.appReady.bind(this));

        Data.environments = this.fileLoader.get("environments");
        Data.data.mode = "prod";

        if (window.location.host.indexOf("localhost") != -1) {
            Data.data.platform = Data.environments.LOCALHOST.TEST;
            //Data.data.platform = Data.environments.LOCALHOST.TESTMYVERALLIA;
            //Data.data.platform = Data.environments.LOCALHOST.PUBLIC;
            //Data.data.platform = Data.environments.LOCALHOST.SELECTIVELINE;
            Data.data.mode = "dev";
            Data.data.vg.env = "localhost";
        } else if (window.location.host.indexOf("appsdev.corp.inpkg.net") != -1) {
            Data.data.platform = Data.environments.APPSDEV.INTERNAL;
            Data.data.vg.env = "internal_dev";
        } else if (window.location.host.indexOf("appsqual.corp.inpkg.net") != -1) {
            Data.data.platform = Data.environments.APPSQUAL.INTERNAL;
            Data.data.vg.env = "internal_qual";
        } else if (window.location.host.indexOf("apps.corp.inpkg.net") != -1) {
            Data.data.platform = Data.environments.APPSPROD.INTERNAL;
            Data.data.vg.env = "internal_prod";
        } else if (window.location.host.indexOf("force.com") != -1 || window.location.host.indexOf("qual.verallia.com") != -1) {
            //@ts-ignore
            if (window.MYVERALLIA_ENV) Data.data.platform = Data.environments.MYVERALLIA_QUAL.MYVERALLIA;
            else Data.data.platform = Data.environments.MYVERALLIA_QUAL.PUBLIC;
            Data.data.vg.env = "myverallia_qual";
        } else if (window.location.host.indexOf("verallia.com") != -1) {
            //@ts-ignore
            if (window.MYVERALLIA_ENV) Data.data.platform = Data.environments.MYVERALLIA_PROD.MYVERALLIA;
            else Data.data.platform = Data.environments.MYVERALLIA_PROD.PUBLIC;
            Data.data.vg.env = "myverallia_prod";
        } else if (window.location.host.indexOf("collection.selective-line.com") != -1) {
            Data.data.platform = Data.environments.SELECTIVELINE.SELECTIVELINE;
        }

        if (Data.forcePlatform){
            Data.data.platform = Data.forcePlatform;
            Data.data.platform.enableCatalogDSwitch = true;
        }

        Data.forcePlatform = null;

        URLManager.init();
        Data.data.app = URLManager.data.app;

        // if (window.location.host.indexOf("apps.corp") != -1) {
        //     let users: any = ["B1147402", "S6677133"];
        //     if (users.indexOf(Data.data.user.id) != -1) {
        //         Data.data.platform.useVirtualGlass = true;
        //         Data.data.platform.useOldVirtualGlass = false;
        //     }
        // }


        if(Data.data.platform.id == "PUBLIC"){
            Data.data.country = URLManager.data.country;
            Data.data.language = URLManager.data.language;
            Data.data.config.languages = Data.data.config.countriesLanguages[Data.data.country];
            if (Data.data.config.languages.indexOf("en_us") == -1) Data.data.config.languages.push("en_us");

            if (Data.data.config.countriesLanguages[Data.data.country].indexOf(URLManager.data.urlLanguage) != -1) Data.data.language = URLManager.data.urlLanguage;

        } if (Data.data.platform.id == "MYVERALLIA") {
            Data.data.config.views.virtualglass = Data.data.config.views.generalVG;

            try{
                console.log("getting data from MYVerallia:");
                //@ts-ignore
                Data.data.MyVeralliaUserID = window.mv_user_contactid;
                if (Data.data.mode == "prod"){
                    Data.data.userID = Data.data.user.id = Data.data.MyVeralliaUserID;
                    //@ts-ignore
                    Data.data.config.VGAssetsPath = window.playerAssetsPath;
                }

                // if (window.location.host.indexOf("verallia.com") != -1) {
                //     let users: any = ["0037S0000041X9qQAE", "003D000001quwmnIAA", "0032o00002TxiobAAB"];
                //     if (users.indexOf(Data.data.user.id) != -1) {
                //         Data.data.platform.useVirtualGlass = true;
                //         Data.data.platform.useOldVirtualGlass = false;
                //     }
                // }

                //@ts-ignore
                Data.data.salesforceToken = window.sfdc_ws_token;
                //@ts-ignore
                let language:string = window.ct_myverallia_selected_Language.toLowerCase();
                //console.log("language detected " + language);
                if (language == "fr") Data.data.language = "fr_fr";
                else if (language == "de") Data.data.language = "de_de";
                else if (language == "es") Data.data.language = "es_es";
                else if (language == "pt") Data.data.language = "pt_pt";
                else if (language == "it") Data.data.language = "it_it";
                else if (language == "ua" || language == "uk") Data.data.language = "ua_ua";
                else if (language == "en") Data.data.language = "en_us";
                else Data.data.language = language;

                //console.log("language updated " + language);


                //@ts-ignore
                Data.data.user.email = window.mv_user_email;
                //@ts-ignore
                Data.data.user.country = Data.data.user.company = window.ct_myverallia_selected_country.toLowerCase();
                //@ts-ignore
                Data.data.MyVeralliaToken = window.sfdc_ws_token;

                if (Data.data.config.countriesLanguages[Data.data.user.country].indexOf(Data.data.language) == -1) Data.data.language = "en_us";


                //@ts-ignore
                if (window.mv_can_access_virtualglass == "true") Data.data.MYVeralliaCanAccessVG = true;
                //@ts-ignore
                else if (window.mv_can_access_virtualglass == "false") Data.data.MYVeralliaCanAccessVG = false;
                //@ts-ignore
                else Data.data.MYVeralliaCanAccessVG = window.mv_can_access_virtualglass;

                //@ts-ignore
                if (window.mv_can_order == "true") Data.data.MyVeralliaUserCanOrder = true;
                //@ts-ignore
                else if (window.mv_can_order == "false") Data.data.MyVeralliaUserCanOrder = false;
                //@ts-ignore
                else Data.data.MyVeralliaUserCanOrder = window.mv_can_order;

                //@ts-ignore
                if (window.mv_can_access_hd_picture == "true") Data.data.MYVeralliaUserCanAccessHDPictures = "true";
                //@ts-ignore
                else if (window.mv_can_access_hd_picture == "false") Data.data.MYVeralliaUserCanAccessHDPictures = "false";
                //@ts-ignore
                else Data.data.MYVeralliaUserCanAccessHDPictures = window.mv_can_access_hd_picture;


                //@ts-ignore
                Data.data.country = window.ct_myverallia_selected_country.toLowerCase();
                //@ts-ignore
                Data.data.mvCountries = JSON.parse(ct_myverallia_countries).co;

                console.log("Data from MYVerallia OK");
            }catch(e){
                console.log("Error parsing MYVerallia Data");
                console.log(e);
                //Data.data.mvCountries = JSON.parse('{"co":[{"co":"FR","na":"France"}, {"co":"DE","na":"Allemagne"}]}').co;
            }

            //console.log("language = " + Data.data.language);

            if (!Data.data.country) Data.data.country = Data.data.config.defaultCountry;
            if (!Data.data.language) Data.data.language = Data.data.config.defaultLanguage;

            //console.log("set language = " + Data.data.language);

            Data.data.config.languages = Data.data.config.countriesLanguages[Data.data.country];
            if (Data.data.config.languages.indexOf("en_us") == -1) Data.data.config.languages.push("en_us");
            if (Data.data.config.languages.indexOf(Data.data.language) == -1) Data.data.config.languages.push(Data.data.language);

            //console.log("set language2 = " + Data.data.language);
            //console.log(Data.data.config.languages);
        } if (Data.data.platform.id == "SELECTIVELINE") {
            Data.data.language = URLManager.data.language;
            Data.data.config.languages = Data.data.config.countriesLanguages.fr;
            Data.data.config.languages.push("en_us");

            if (Data.originLanguage && Data.data.config.languages.indexOf(Data.originLanguage) == -1){
                Data.data.language = Data.originLanguage;
                Data.data.config.languages.push(Data.originLanguage);
            }
        }else if (Data.data.platform.id == "INTERNAL") {
            try {
                let l: string = localStorage.getItem("catalogLanguage");
                if (Data.data.config.languages.indexOf(l) != -1) {
                    Data.data.language = l;
                    Data.data.loc = Data.data.locs[l];

                    if (l == "ua_ua" || l == "ru") $("#app").css("font-family", "Roboto");
                    else $("#app").css("font-family", "DMSans");
                }
            } catch (e: any) {

            }
        }

        //@ts-ignore
        window.defaultBottlePictureURL = Data.data.config.assetsPath + "images/defaultBottle.jpg";
        Data.data.headers = { user: Data.data.user.id, app: "virtualglass", env: Data.data.vg.env };

        this.loadFiles();
    }
    private loadFiles(){
        let t: Main = this;

        this.fileLoader = new Loader(this.loadCatalogData.bind(this), this.filesError.bind(this));

        Data.data.config.languages.filter((l:string) => {
            t.fileLoader.add({ id: "loc" + l, url: Data.data.platform.locsUrl.replace("{{LANG}}", l), method: "GET" });
        })

        this.fileLoader.start();
    }
    private filesError(error: any) {
        $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorLoadingSystemFiles);
    }
    private loadCatalogData(){
        let t: Main = this;
        Data.data.config.languages.filter((l: string) => {
            Data.data.locs[l] = t.fileLoader.get("loc" + l);
        })

        Data.data.loc = Data.data.locs[Data.data.language];


        this.fileLoader = new Loader(this.filesComplete.bind(this), this.filesError.bind(this));

        if(!Data.data.oldDataMode){
            if (Data.data.platform.id == "MYVERALLIA" && Data.data.mode == "prod"){
                //No data loading needed, using variables in catalog page
            } else if (Data.data.platform.id == "PUBLIC" && Data.data.mode == "prod"){
                this.fileLoader.add({ id: "catalogProducts", url: Data.data.platform.catalogData.products.replace("{{COUNTRY}}", Data.data.country), method: "GET" });
                this.fileLoader.add({ id: "catalogBaseProducts", url: Data.data.platform.catalogData.baseProducts.replace("{{COUNTRY}}", Data.data.country), method: "GET" });
                this.fileLoader.add({ id: "catalogMappings", url: Data.data.platform.catalogData.mappings.replace("{{COUNTRY}}", Data.data.country), method: "GET" });
            }else{
                this.fileLoader.add({ id: "catalogProducts", url: Data.data.platform.catalogData.products, method: "GET" });
                this.fileLoader.add({ id: "catalogBaseProducts", url: Data.data.platform.catalogData.baseProducts, method: "GET" });
                this.fileLoader.add({ id: "catalogMappings", url: Data.data.platform.catalogData.mappings, method: "GET" });
            }



            if (Data.data.platform.id == "INTERNAL") {
                this.fileLoader.add({ id: "catalogPLS", url: Data.data.platform.catalogData.pls, method: "GET" });
            }else if(Data.data.platform.id == "MYVERALLIA"){
                //this.fileLoader.add({ id: "catalogPLS", url: Data.data.config.assetsPath + Data.data.platform.catalogData.pls, method: "GET" });

                if (Data.data.platform.id == "MYVERALLIA" && Data.data.mode == "prod") {
                    //No data loading needed, using variables in catalog page
                    this.fileLoader.add({ id: "catalogPLS", url: Data.data.platform.catalogData.pls, method: "GET" });
                }else{
                    this.fileLoader.add({ id: "catalogMyProducts", url: Data.data.platform.catalogData.myProducts, method: "GET" });
                    this.fileLoader.add({ id: "catalogMyProductsBaseProducts", url: Data.data.platform.catalogData.myProductsBaseProducts, method: "GET" });
                    this.fileLoader.add({ id: "catalogPLS", url: Data.data.config.assetsPath + Data.data.platform.catalogData.pls, method: "GET" });
                }
            } else if (Data.data.platform.id == "SELECTIVELINE") {
                //this.fileLoader.add({ id: "productsLines", url: Data.data.platform.catalogData.productsLines, method: "GET" });
            }
        }else{
            // this.fileLoader.add({ id: "catalogData", url: Data.data.platform.catalogData.oldData, method: "GET" });

            // if (Data.data.platform.id == "INTERNAL") {
            //     this.fileLoader.add({ id: "catalogPLS", url: Data.data.platform.catalogData.pls, method: "GET" });
            //     this.fileLoader.add({ id: "productsLines", url: Data.data.platform.catalogData.productsLines, method: "GET" });
            // }
        }

        this.fileLoader.start();
    }
    private filesComplete(){
        if (!Data.data.oldDataMode) {
            if (Data.data.platform.id == "MYVERALLIA" && Data.data.mode == "prod") {
                //@ts-ignore
                Data.productsJson = window.catalogMetaProducts;
                //@ts-ignore
                Data.baseProductsJson = window.catalogBaseProducts;
                //@ts-ignore
                Data.myProductsJson = window.catalogMyMetaProducts;
                //@ts-ignore
                Data.myProductsBaseProductsJson = window.catalogMyBaseProducts;
                //@ts-ignore
                Data.marketsJson = window.catalogMappings.market;
                //@ts-ignore
                Data.colorsJson = window.catalogMappings.color;
                //@ts-ignore
                Data.finishJson = window.catalogMappings.finfamily;
                //@ts-ignore
                Data.packageJson = window.catalogMappings.package;
                //@ts-ignore
                Data.palletJson = window.catalogMappings.pallet;
                //@ts-ignore
                Data.shapeJson = window.catalogMappings.shape;
            }else{
                Data.productsJson = this.fileLoader.get("catalogProducts");
                Data.baseProductsJson = this.fileLoader.get("catalogBaseProducts");
                Data.marketsJson = this.fileLoader.get("catalogMappings").market;
                Data.colorsJson = this.fileLoader.get("catalogMappings").color;
                Data.finishJson = this.fileLoader.get("catalogMappings").finfamily;
                Data.packageJson = this.fileLoader.get("catalogMappings").package;
                Data.palletJson = this.fileLoader.get("catalogMappings").pallet;
                Data.shapeJson = this.fileLoader.get("catalogMappings").shape;

                if (Data.data.platform.id == "MYVERALLIA") {
                    Data.myProductsJson = this.fileLoader.get("catalogMyProducts");
                    Data.myProductsBaseProductsJson = this.fileLoader.get("catalogMyProductsBaseProducts");
                }
            }


            DataUtils.parseData();

            if (Data.data.platform.id == "INTERNAL") {
                Data.plsJson = this.fileLoader.get("catalogPLS");
                DataUtils.parsePLS();

            } else if (Data.data.platform.id == "MYVERALLIA" || Data.data.platform.id == "PUBLIC") {
                Data.plsJson = this.fileLoader.get("catalogPLS");
                DataUtils.parsePLS();

            }else if (Data.data.platform.id == "SELECTIVELINE") {
                //Data.data.productsLines = this.fileLoader.get("productsLines");
            }

            DataUtils.parseFilters();
        }else{
            // Data.catalogJson = this.fileLoader.get("catalogData");
            // DataUtils.parseOldData();

            // if (Data.data.platform.id == "INTERNAL") {
            //     Data.data.productsLines = this.fileLoader.get("productsLines");
            //     DataUtils.parseProductsLines();

            //     Data.plsJson = this.fileLoader.get("catalogPLS");
            //     DataUtils.parsePLS();
            // }
        }

        //this.initKeyBindings();
        this.initVue();

        if (Data.data.platform.useVirtualGlass == true){
            if (!Data.data.platform.useOldVirtualGlass){
                this.loadServerData();
            }else{
                this.getVGUserData();
            }
        }else{
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("APP_READY");

            URLManager.startDetectURLChange();
        }
    }
    private loadServerData() {
        this.fileLoader = new Loader(this.serverDataComplete.bind(this));
        this.fileLoader.add({ id: "serverData", url: Data.data.platform.digitalEngineFileURL + "virtualglass/server/data.json", method: "GET", headers: Data.data.headers });
        this.fileLoader.start();
    }
    private serverDataComplete() {
        let data: any = this.fileLoader.get("serverData");


        //LOGIN AS FOR ADMIN USERS
        if (Data.data.adminUsers.indexOf(Data.data.userID) != -1 && Data.data.platform.id == "INTERNAL") {
            let tab: any = window.location.href.split("?");
            let params: string = tab[1];

            if (params) {
                let URLParams: any = params.split("&");
                let param: any = {};
                let a: number = URLParams.length;
                for (let i = 0; i < a; i++) {
                    let tab: any = URLParams[i].split("=");
                    param[tab[0]] = tab[1];
                }

                if (param.loginas && param.loginas != "") {
                    Data.data.userID = Data.data.user.id = Data.data.headers.user = param.loginas;
                }
            }
        }

        // if (data) {
        //     Data.data.vg.serverData = data;
        //     if (typeof data == "string") Data.data.vg.serverData = JSON.stringify(data);
        // }

        this.fileLoader = new Loader(this.getVGUserData.bind(this), this.VGuserNotFound.bind(this));
        this.fileLoader.add({ id: "loginUser", url: Data.data.platform.digitalEngineVGURL + "token/" + Data.data.user.id, method: "GET", headers: Data.data.headers });
        this.fileLoader.start();
    }
    private getVGUserData() {
        if (!Data.data.platform.useOldVirtualGlass){
            Data.data.token = this.fileLoader.get("loginUser").token;
            Data.data.headers.token = Data.data.token;

            this.fileLoader = new Loader(this.VGuserDataComplete.bind(this), this.VGuserDataError.bind(this));
            this.fileLoader.add({ id: "VGModelsList", url: Data.data.platform.digitalEngineVGURL + "models", method: "GET", headers: Data.data.headers });
        }else{
            this.fileLoader = new Loader(this.VGuserDataComplete.bind(this), this.VGuserDataError.bind(this));
            this.fileLoader.add({ id: "VGModelsList", url: Data.data.platform.oldVirtualGlassModels, method: "GET" });
        }

        this.fileLoader.start();
    }
    private VGuserDataError(){
        console.log("Error getting VG user data");

    }
    private VGuserNotFound() {
        let userData: any = { email: Data.data.user.email, country: Data.data.user.country, company: Data.data.user.company, data: Data.data.vg.VGUserDataModel };
        if (!userData.country) userData.country = "fr";
        if (!userData.company) userData.company = "verallia";

        this.fileLoader = new Loader(this.VGUserCreationComplete.bind(this), this.VGUserCreationError.bind(this));
        this.fileLoader.add({ id: "VGUserCreation", url: Data.data.platform.digitalEngineVGURL + "create-user/" + Data.data.user.id + "/" + Data.data.vg.env, method: "POST", contentType: "application/json", data: JSON.stringify(userData) });
        this.fileLoader.start();
    }
    private VGUserCreationError(){
        console.log("Could not create VG user");
    }
    private VGUserCreationComplete(){
        let result:any = this.fileLoader.get("VGUserCreation");

        if (!result){
            this.VGUserCreationError();
            return;
        }

        if (!Data.data.platform.useOldVirtualGlass) {
            Data.data.token = result.token;
            Data.data.headers = { user: Data.data.user.id, token: Data.data.token, app: "virtualglass", env: Data.data.vg.env };

            this.fileLoader = new Loader(this.VGuserDataComplete.bind(this), this.VGuserDataError.bind(this));
            this.fileLoader.add({ id: "VGModelsList", url: Data.data.platform.digitalEngineVGURL + "models", method: "GET", headers: Data.data.headers });
        } else {
            this.fileLoader = new Loader(this.VGuserDataComplete.bind(this), this.VGuserDataError.bind(this));
            this.fileLoader.add({ id: "VGModelsList", url: Data.data.platform.oldVirtualGlassModels, method: "GET" });
        }

        this.fileLoader.start();
    }
    private VGuserDataComplete() {
        Data.data.vg.modelsList = this.fileLoader.get("VGModelsList");
        DataUtils.parseVirtualGlassModels();

        if (Data.data.app == "virtualglass"){
            new VGDataLoader(this.VGDataComplete.bind(this), this.VGDataError.bind(this));
            return;
        }

        this.VGDataComplete();
    }
    private VGDataComplete(){
        $(window).trigger("HIDE_OVERLAY");
        $(window).trigger("HIDE_LOADING");
        $(window).trigger("APP_READY");

        URLManager.startDetectURLChange();
    }
    private VGDataError() {
        $(window).trigger("HIDE_OVERLAY");
        $(window).trigger("HIDE_LOADING");
        $(window).trigger("APP_READY");

        URLManager.startDetectURLChange();
    }






    private dispose(){
        $(window).off("APP_DISPOSE");
        $(window).off("APP_READY");

        //console.log("app destroyed");

        URLManager.setHash("", true);

        $("#app").empty().attr("class", null);
        new Main();
    }
    private appReady(){
        Data.data.section = "home";

        setTimeout(this.hideLoading.bind(this), 2000);

        if(Data.data.platform.useOldVirtualGlass){
            let postData:any = JSON.stringify({ type: "verallia", data: { message: "catalogReady" } });
            window.parent.postMessage(postData, "*");


            let canAccessVG:boolean = true;

            try {
                //@ts-ignore
                if (window.mv_can_access_virtualglass == false || window.mv_can_access_virtualglass == "false") canAccessVG = false;
                //@ts-ignore
                else if (window.mv_can_access_virtualglass == true || window.mv_can_access_virtualglass == "true") canAccessVG = true;
            } catch (e:any) {
                canAccessVG = false;
            }

            try {
                console.log("calling catalog loaded callback for VG");

                //@ts-ignore
                console.log("SF contact id : " + window.mv_user_contactid);
                //@ts-ignore
                window.catalogLoaded(window.sfdc_ws_token, canAccessVG, window.mv_user_contactid);
                //@ts-ignore
                window.setCatalogCountry(Data.data.country);
            }catch(e:any){

            }
        }
    }
    private hideLoading(){
        $(".loader").fadeOut(1000);
        $("#LoadingScreen").fadeOut(1000);
        //$("#loader").fadeOut(500);

        setTimeout(this.removeLoader.bind(this), 1000);

        return;


        //ONLY FOR TRANSLATION
        this.fileLoader = new Loader(this.locsLoaded.bind(this));

        this.fileLoader.add({ id: "trad_de", url: "de.json", method: "GET" });
        this.fileLoader.add({ id: "trad_es", url: "es.json", method: "GET" });
        this.fileLoader.add({ id: "trad_pt", url: "pt.json", method: "GET" });
        this.fileLoader.add({ id: "trad_it", url: "it.json", method: "GET" });
        this.fileLoader.add({ id: "trad_ru", url: "ru.json", method: "GET" });
        this.fileLoader.add({ id: "trad_pl", url: "pl.json", method: "GET" });
        this.fileLoader.add({ id: "trad_uk", url: "uk.json", method: "GET" });

        this.fileLoader.start();
    }
    private locsLoaded(){
        let data:any = Data.data.locs.en_us;
        let tab_en:any = [];
        let json:any = {};

        let str: string;
        for(str in data){
            let o:any = {};
            tab_en.push(o);

            o.key = str;
            o.value = data[str];

            if (data[str].indexOf("-----") != - 1) o.comment = true;
        }

        console.log(tab_en.length + " keys");


        let languages:any = ["de", "it", "es", "pt", "ru", "uk", "pl"];
        for(let i=0; i<languages.length; i++){
            let l: string = languages[i];
            let ldata: any = this.fileLoader.get("trad_" + l);
            json[l] = {};
            let j: number = 0;

            let str: string;
            for (str in ldata) {
                if (!tab_en[j].comment) json[l][tab_en[j].key] = ldata[str];
                else json[l][tab_en[j].key] = "--------------------";

                j ++;
            }

            console.log(j + " keys in " + l);
        }

        //@ts-ignore
        window.langs = json;
    }




    private removeLoader(){
        $("#LoadingScreen").remove();
    }
    private initVue(){
        Vue.use(Vuetify);
        Vue.use(vuescroll);
        Vue.use(Snotify, {
            toast: {
                position: SnotifyPosition.rightTop,
                timeout: 8000,
                showProgressBar:true
            }
        });

        const params: any = {
            icons: {
                iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
            },
            theme: {
                themes: {
                    light: {
                        green: "#00a8a7"
                    },
                },
            }
        };



        Vue.config.productionTip = false;
        new Vue({

            vuetify: new Vuetify(params),
            render: h => h(App),
        }).$mount('#app')
    }
    private initKeyBindings(){
        document.onkeydown = this.checkKeys.bind(this);
    }
    private checkKeys(e:any){
        //console.log(e);
        if (e.altKey && e.which == 89) {
            //alert("Alt + Y shortcut combination was pressed");
        } else if (e.altKey && e.key == "m") {
            Data.data.menu = !Data.data.menu;
        } else if (e.altKey && e.key == "s") {
            Data.data.search = !Data.data.search;
        }
    }
    private formatVGData(data:any) {
        let formattedData:any = {};

        data.filter((d:any) => {
            formattedData[d.id] = d;
        })

        return formattedData;
    }
    private getLabelsByType(labels: any, type:string){
        let data:any = {};

        labels.filter((label:any) => {
            if (label.type == type) data[label.id] = label;
        })

        return data;
    }
}