import Utils from "../utils/Utils";
import Labels from './Labels';
import Model from './Model';
import Content from './Content';
import Capsule from './Capsule';
import Data from "../application/Data";
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import BabylonManager from "../managers/BabylonManager";
import gsap from "gsap";
import $ from "jquery";



export default class VGAccessoryObject {
    public mdata: any;
    public babylon: BabylonManager;
    public onComplete: any;
    public onError: any;
    public uid: string;
    public model3D: any;
    public updateModelsWithSamePID: boolean = true;
    public loader: any;
    public model: any;
    public container: any;
    public meshes: any = [];
    public mesh: any = {};



    constructor(data: any, babylon: BabylonManager, onComplete: any, onError: any) {
        this.mdata = data;
        this.babylon = babylon;
        this.onComplete = onComplete;
        this.onError = onError;


        this.uid = Utils.generateUID();

        this.container = new BABYLON.TransformNode(this.uid, this.babylon.scene);
        this.container.rotation.y = Utils.degreesToRadians(90);
        this.container.scaling.x = this.container.scaling.y = this.container.scaling.z = babylon.objectsScale;
    }
    public init() {
        this.loadModel();
    }
    public destroy() {

    }
    public loadModel() {
        if (Data.models[this.mdata.glassDrawing] && !this.mdata.forceLoading) {
            this.model3D = Data.models[this.mdata.glassDrawing];
            this.initModel();
        } else {
            BABYLON.SceneLoader.LoadAssetContainer(this.mdata.path, this.mdata.filename, this.babylon.scene, this.modelLoaded.bind(this));
        }
    }
    public modelLoaded(model: any) {
        //console.log("model loaded");
        //console.log(model);
        this.model3D = Data.models[this.mdata.glassDrawing] = model;

        var t = this;
        this.model3D.meshes.filter((child: any) => {
            child.visibility = 0;
        })

        this.initModel();
    }
    public initModel() {
        //model.setEnabled(false);
        //console.log(this.model3D);

        this.initElements();


        if (this.onComplete) this.onComplete();
    }
    public initElements() {
        //console.log("ixixi: ", this.mdata);
        //this.externalMesh = this.model.scene.children[0].children[0];
        var t = this;
        this.model3D.meshes.filter((child: any) => {
            child.parent = null;

            //console.log(child.name);

            t.meshes.push(child.name);
            t.mesh[child.name] = child.clone(this.uid + "_" + child.name);
            t.mesh[child.name].parent = t.container;

            //t.mesh[child.name].material = new BABYLON.StandardMaterial("capsule", this.babylon.scene);
        })
    }


    public show() {
        this.enableMeshes();
        this.container.position.y = 0;
    }
    public hide() {
        this.disableMeshes();
        this.container.position.y = 20000;
    }
    public enableMeshes(){
        let str:string;
        for(str in this.mesh){
            let mesh:any = this.mesh[str];

            mesh.visibility = 1;
        }
    }
    public disableMeshes() {
        let str: string;
        for (str in this.mesh) {
            let mesh: any = this.mesh[str];

            mesh.visibility = 0;
        }
    }


    public setRotation(rotation: number) {
        this.container.rotation.y = Utils.degreesToRadians(rotation);
    }
    public assignMaterial(mesh:string, material:string){
        if (material) this.mesh[mesh].material = this.babylon.materials.capsules[material];
        else this.mesh[mesh].material = null;
    }
}