
    import Generic from "../Generic.vue";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';
    import Vue from "vue";


    import {
        Component,
    } from 'vue-property-decorator';

    import Data from "@/ts/application/Data";
    import FiltersUtils from "@/ts/utils/FiltersUtils";
    import URLManager from "@/ts/utils/URLManager";
    import DataUtils from "@/ts/utils/DataUtils";
    import Utils from "@/ts/player/utils/Utils";
    import { InputHTMLAttributes } from "vue/types/jsx";



    @Component({
        components: {
            vuescroll
        }
    })
    export default class Menu extends Generic {
        private oldWidth:number = 0;
        private panels:any = [0, 1, 2];
        private temporary:boolean = false;

        private dialog: boolean = false;
        private num: number = 0;
        private range:any = {};
        private filterRangeStatus:any = {};
        private filtersReady:boolean = false;


        private mounted(){
            $(window).on("UPDATE_FILTERS", this.updateFilters.bind(this));
            $(window).on("resize", this.resize.bind(this));
            this.resize();

            let str: string;
            for (str in Data.data.config.filters) {
                if (Data.data.config.filters[str].ranges){
                    this.filterRangeStatus[str + "left"] = false;
                    this.filterRangeStatus[str + "right"] = false;
                }

                if (Data.data.config.filters[str].type == "numberRange") {
                    this.filterRangeStatus[str + "update"] = false;
                }
            }

            this.filtersReady = true;
        }
        private destroyed(){
            $(window).off("UPDATE_FILTERS", this.updateFilters.bind(this));
            $(window).off("resize", this.resize.bind(this));
            this.resize();
        }
        private resize(){
            //@ts-ignore
            if(Data.data.windowWidth < Data.data.config.tabletBreakPoint){
                if (!this.temporary) Data.data.filtersStatus = false;
                this.temporary = true;
                return
            }

            this.temporary = false;
            Data.data.filtersStatus = true;
            this.oldWidth = Data.data.windowWidth;
        }
        private toggleMenu(){
            Data.data.filtersStatus = !Data.data.filtersStatus;
        }
        private updateFilters(filter:string=null){
            //Data.data.catalogSearch = null; // put back if the filters need to clear the search field every time the catalog is updated
            this.updateTreeValues();

            for (let str in Data.data.config.filters) {
                if (Data.data.config.filters[str].type == "numberRange") {
                    this.$set(Data.data.filters[str].range, 0, Number(Data.data.filters[str].range[0]));
                    this.$set(Data.data.filters[str].range, 1, Number(Data.data.filters[str].range[1]));

                    if(Data.data.filters[str].range[0] < Data.data.filters[str].min) this.$set(Data.data.filters[str].range, 0, Data.data.filters[str].min);
                    else if(Data.data.filters[str].range[0] > Data.data.filters[str].max) this.$set(Data.data.filters[str].range, 0, Data.data.filters[str].max);

                    if (Data.data.filters[str].range[1] < Data.data.filters[str].min) this.$set(Data.data.filters[str].range, 1, Data.data.filters[str].min);
                    else if (Data.data.filters[str].range[1] > Data.data.filters[str].max) this.$set(Data.data.filters[str].range, 1, Data.data.filters[str].max);

                    if (Data.data.filters[str].range[0] > Data.data.filters[str].range[1]) this.$set(Data.data.filters[str].range, 0, Data.data.filters[str].range[1]);

                    this.$set(this.filterRangeStatus, str + "update", false);
                }
            }

            // console.log(this.filterRangeStatus);
            this.$forceUpdate();

            // for (let str in Data.data.config.filters) {
            //     if (Data.data.config.filters[str].type == "numberRange") {
            //         let minput:HTMLInputElement = document.getElementById("filterrangemin-" + str);
            //         if(minput){
            //             minput.innerHTML = String(Data.data.filters[str].range[0]);
            //             console.log(minput);
            //         }
            //     }
            // }

            URLManager.setURLFromData(false, true);

            //$(window).trigger("CALL_GG");
            $(window).trigger("UPDATE_VIEW", filter);
        }
        private resetFilters(){
            Data.data.catalogSearch = null;
            Data.data.filters = Data.data.savedFilters[Data.data.platform.id + "-" + Data.data.view] = FiltersUtils.createFilters(Data.data.view);
            this.updateFilters();
        }
        private collapseFilters(){
            this.panels = [];
        }
        private selectAllCountries(filter:string){
            let a:number = Data.data.filters[filter].length;
            for(let i=0; i<a; i++){
                Data.data.filters[filter][i].selected = true;
            }

            this.num ++;
            this.updateFilters(filter);
        }
        private unselectAllCountries(filter:string){
            let a:number = Data.data.filters[filter].length;
            for(let i=0; i<a; i++){
                Data.data.filters[filter][i].selected = false;
            }

            this.num ++;
            this.updateFilters(filter);
        }
        private checkCountriesSelected(filter:string){
            let b:number = 0;
            let a:number = Data.data.filters[filter].length;
            for(let i=0; i<a; i++){
                if(Data.data.filters[filter][i].selected == true) b ++;
            }

            if(a == b) return false;

            return true;
        }
        private toggleArrayFilterValue(target:any, filter:string=null){
            target.selected = !target.selected;

            this.updateFilters(filter);
        }
        private updateTreeParent(tree:any, filter:string=null){
            tree.selected = true;
            this.updateFilters(filter);
        }
        private updateTreeChildren(tree:any, filter:string=null){
            if(tree.selected == false){
                for(let i=0; i<tree.sub.length; i++){
                    tree.sub[i].selected = false;
                }
            }

            this.updateFilters(filter);
        }
        private updateTreeValues(){
            let str:string;
            for(str in Data.data.filters){
                let filterConfig:any = Data.data.config.filters[str];

                if(filterConfig.type == "treeValues"){
                    let filterData:any = Data.data.filters[str];

                    for(let i=0; i<filterData.length; i++){
                        //if()
                    }
                }
            }
        }
        private checkDatesRange(filterData:any){
            let startIndex:number = filterData.dates.indexOf(filterData.startDate);
            let endIndex:number = filterData.dates.indexOf(filterData.endDate);

            if(startIndex > endIndex) filterData.endDate = filterData.startDate;
            else if(endIndex < startIndex) filterData.startDate = filterData.endDate;

            this.updateFilters();
        }
        private getEnabledTreeLength(tab:any){
            let num:number = 0;

            let a:number = tab.length;
            for(let i=0; i<a; i++){
                if(tab[i].enabled == true) num ++;
            }

            return num;
        }




        private getNumberRanges(filter:any){
            this.range[filter] = [];
            let tempRanges:any = [];

            let ranges:any = Data.data.config.filters[filter].ranges;
            for(let i=0; i<ranges.length; i++){
                let values:any = [];
                let range:any = {};

                if(i == 0){
                    values = [Data.data.filters[filter].min, ranges[i]];
                }else if(i == ranges.length - 1){
                    values = [ranges[i], 100000000];
                }else{
                    values = [ranges[i - 1], ranges[i]];
                }

                range = { active:false, values:values };

                if(this.between(values[0], Data.data.filters[filter].min, Data.data.filters[filter].max) == true || this.between(values[1], Data.data.filters[filter].min, Data.data.filters[filter].max) == true){
                    tempRanges.push(range);
                }
            }

            if(tempRanges.length > 1){
                tempRanges[0].values[0] = Data.data.filters[filter].min;
                tempRanges[tempRanges.length - 1].values[1] = Data.data.filters[filter].max;
            }

            for(let i=0; i<tempRanges.length; i++){
                if(tempRanges[i].values[0] != tempRanges[i].values[1]){
                    this.range[filter].push(tempRanges[i]);

                    if(i == 0){
                        if(this.between(tempRanges[i].values[0], Data.data.filters[filter].range[0], Data.data.filters[filter].range[1], true) == true) tempRanges[i].active = true;
                    }else{
                        if(this.between(tempRanges[i].values[0], Data.data.filters[filter].range[0], Data.data.filters[filter].range[1]) == true
                        && tempRanges[i].values[0] != Data.data.filters[filter].range[1]) tempRanges[i].active = true;
                    }

                    if(this.between(tempRanges[i].values[1], Data.data.filters[filter].range[0], Data.data.filters[filter].range[1]) == true
                    && tempRanges[i].values[1] != Data.data.filters[filter].range[0]) tempRanges[i].active = true;

                    if(!tempRanges[i].active){
                        if(this.between(Data.data.filters[filter].range[0], tempRanges[i].values[0], tempRanges[i].values[1]) == true
                        && this.between(Data.data.filters[filter].range[1], tempRanges[i].values[0], tempRanges[i].values[1]) == true) tempRanges[i].active = true;
                    }
                }
            }

            return this.range[filter];
        }
        private between(value:number, first:number, last:number, takFirstValue:boolean=false) {
            let lower:number = Math.min(first,last) , upper = Math.max(first,last);
            //return value >= lower && value < upper;
            if(takFirstValue == true) return value >= first && value <= last;

            return value > first && value <= last;
        }
        private toggleNumberRange(filter:string, index:number){
            let min:number = -1;
            let max:number = -1;

            let activeTabs:any = [];

            for(let i=0; i<this.range[filter].length; i++){
                activeTabs.push(this.range[filter][i].active);
            }

            activeTabs[index] = !activeTabs[index];

            let passed:number = 0;
            let allActive:boolean = true;
            for(let i=0; i<activeTabs.length; i++){
                if(activeTabs[i]){
                    passed ++;

                    if(min == -1) min = this.range[filter][i].values[0];

                    max = this.range[filter][i].values[1];
                }else{
                    allActive = false;
                    if(passed != 0 && i >= index){
                        break;
                    }
                }
            }

            if(min == max){
                min = Data.data.filters[filter].min;
                max = Data.data.filters[filter].max;
            }

            Vue.set(Data.data.filters[filter], "range", [min, max]);

            this.updateFilters(filter);
        }
        private getPanelInfo(filter:string){
            let filterConfig:any = Data.data.config.filters[filter];
            let filterData:any = Data.data.filters[filter];

            if(!filterConfig) return;

            if(filterConfig.type == "treeValues"){
                let colors:any = [];
                let number:number = 0;
                for(let i=0; i<filterData.length -1; i ++){
                    if(filterData[i].selected){
                        let found:boolean = false;
                        for(let j=0; j<filterData[i].sub.length -1; j ++){
                            if(filterData[i].sub[j].selected){
                                found = true;
                                number ++;
                                if(filter == "colors") colors.push(filterData[i].sub[j]);
                            }
                        }

                        if(!found){
                            number ++;
                            if(filter == "colors") colors.push(filterData[i]);
                        }
                    }
                }

                if(filter != "colors") return null;
                else return colors;
            }else if(filterConfig.type == "array"){
                let number:number = 0;
                for(let i=0; i<filterData.length -1; i ++){
                    if(filterData[i].selected){
                        number ++;
                    }
                }

                return number;
            }else if(filterConfig.type == "multipleValues"){
                return filterData.selected.length;
            }else if(filterConfig.type == "value"){
                if(filterData) return this.loc("enabled");
                else return this.loc("disabled");
            }else if(filterConfig.type == "numberRange"){
                return filterData.range[0] + " " + this.loc("product-" + filter + "Units") + " - " + filterData.range[1] + " " + this.loc("product-" + filter + "Units");
            }
        }

        private openLeftList(filter:string){
            console.log("openLeftList", filter);
        }

        private openRightlist(filter: string) {
            console.log("openRightlist", filter);
        }
        private setRangeValueLeft(filter: string, value: number, index:number=-1) {
            if(Number(Data.data.filters[filter].range[1]) < value || Number(Data.data.filters[filter].range[1]) == value){
                if(index == Data.data.config.filters[filter].ranges.length -1) Data.data.filters[filter].range[1] = Data.data.filters[filter].max;
                else Data.data.filters[filter].range[1] = Data.data.config.filters[filter].ranges[index + 1];
            }

            Data.data.filters[filter].range[0] = value;
            this.updateFilters();
        }
        private setRangeValueRight(filter:string, value:number, index: number=-1){
            if (Number(Data.data.filters[filter].range[0]) > value || Number(Data.data.filters[filter].range[0]) == value) {
                if (index == 0) Data.data.filters[filter].range[0] = Data.data.filters[filter].min;
                else Data.data.filters[filter].range[0] = Data.data.config.filters[filter].ranges[index - 1];
            }

            Data.data.filters[filter].range[1] = value;
            this.updateFilters();
        }
        private toggleRangeFilterStatus(filter:string, dir:string, forceClose:boolean=false){
            if(!forceClose) this.$set(this.filterRangeStatus, filter + dir, true);
            else this.$set(this.filterRangeStatus, filter + dir, false);

            this.$forceUpdate();
        }
        private toggleRangeFilterUpdateStatus(filter:string){
            this.$set(this.filterRangeStatus, filter + "update", true);

            // this.$set(Data.data.filters[filter].range, 0, Number(Data.data.filters[filter].range[0]));
            // this.$set(Data.data.filters[filter].range, 1, Number(Data.data.filters[filter].range[1]));

            this.$forceUpdate();
        }
    }
