
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import Home from "./Home.vue";
    import Project from "./Project.vue";
    import Renders from "./Renders.vue";
    import Disclaimer from "./Disclaimer.vue";
import Maintenance from "./Maintenance.vue";
    import Burger from "./burger/Burger.vue";
    import $ from "jquery";


    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import FiltersUtils from "@/ts/utils/FiltersUtils";
    import Loader from "@/ts/application/Loader";

    @Component({
        components: {
            Home,
            Burger,
            Project,
            Disclaimer,
            Maintenance,
            Renders
        }
    })
    export default class VirtualGlass extends Generic {
        private fileScreenShotLoader:Loader;

        mounted(){
            //init catalog view to virtualgass
            Data.data.VGMode = true;
            Data.data.view = "virtualglass";

            if (this.maintenanceMode()) Data.data.section = "maintenance";
            else if(!Data.data.vg.userData.data.disclaimer) Data.data.section = "disclaimer";
            //FiltersUtils.setFilters();

            $(window).on("SAVE_ITEM_SCREENSHOT", this.saveItemScreenshot.bind(this));
            $(window).on("DELETE_ITEM_SCREENSHOT", this.deleteItemScreenshot.bind(this));
            $(window).on("CAPSULE_DETECTED", this.capsuleDetected.bind(this));
        }
        destroyed(){
            Data.data.VGMode = false;
            $(window).off("SAVE_ITEM_SCREENSHOT");
            $(window).off("DELETE_ITEM_SCREENSHOT");
            $(window).off("CAPSULE_DETECTED");
        }
        capsuleDetected(e:any, data:any){
            //console.log("capsuleDetected", data);

            if(!Data.data.vg.capsules[data.glassDrawing]) Data.data.vg.capsules[data.glassDrawing] = [];
            if(Data.data.vg.capsules[data.glassDrawing].indexOf(data.capsule) == -1 ) Data.data.vg.capsules[data.glassDrawing].push(data.capsule);

            //if(Data.data.vg.itemData && !Data.data.vg.itemData.capsuleType) Data.data.vg.itemData.capsuleType = data.capsule;
        }
        saveItemScreenshot(e:any, id:string){
            window.PLAYER.takeScreenShot(700, this.saveScreenShot.bind(this), id);
        }
        deleteItemScreenshot(e:any, id:string){
            let fileScreenShotLoader:Loader = new Loader(this.screenShotSaved.bind(this), this.screenShotError.bind(this));

            fileScreenShotLoader.add({ id: "deletePNG", url: Data.data.platform.digitalEngineURL + "virtualglass/delete-item-screenshot/" + id, method:"DELETE", headers: Data.data.headers });
            fileScreenShotLoader.start();
        }
        private saveScreenShot(id:string, base64:string){
            let canvas:any = document.createElement("canvas");
            canvas.width = "2000";
            canvas.height = "2000";
            let ctx:any = canvas.getContext("2d");
            let t:any = this;

            let image:any = new Image();
            image.onload = function() {
                let pr: number = 400 / image.height;
                ctx.drawImage(image, 0, 0, image.width * pr, image.height * pr);

                //canvas.width = image.width;
                //canvas.height = image.height;

                //window.document.body.appendChild(canvas);
                //window.document.body.appendChild(image);

                let newCanvas:any = Utils.removeTransparentPixelsFromBase64(canvas);
                t.saveScreenShotImage(id, newCanvas.toDataURL());
            };
            image.crossOrigin = "Anonymous";
            image.src = base64;
        }
        private saveScreenShotImage(id:string, base64:string){
            let file:any = Utils.createFile(Utils.convertDataURIToBinary(base64), id + ".png");

            let fileScreenShotLoader:Loader = new Loader(this.screenShotSaved.bind(this), this.screenShotError.bind(this));

            let formData:FormData = new FormData();
            formData.append("file", file, id + ".png");

            fileScreenShotLoader.add({ id: "uploadPNG", url: Data.data.platform.digitalEngineURL + "virtualglass/save-item-screenshot/" + id, method:"POST", contentType:false, data:formData, headers: Data.data.headers });
            fileScreenShotLoader.start();
        }
        private screenShotSaved(){
            //console.log("screenShotSaved");
        }
        private screenShotError(){
            //console.log("screenShotError");
        }
        private isDisclaimerAccepted(){
            if(Data.data.vg.userData.data.disclaimer) return true;

            return false;
        }
    }
