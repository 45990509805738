import Data from "../application/Data";
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import BabylonManager from "../managers/BabylonManager";



export default class VGCapsule {
    public mdata: any;
    public babylon: BabylonManager;
    public onComplete: any;
    public onError: any;



    constructor(data: any, babylon: BabylonManager, onComplete: any, onError: any) {
        this.mdata = data;
        this.babylon = babylon;
        this.onComplete = onComplete;
        this.onError = onError;
    }
    public init() {
        this.loadModel();
    }
    public destroy() {

    }
    public loadModel() {
        if (!Data.models[this.mdata.glassDrawing] && !this.mdata.forceLoading) {
            BABYLON.SceneLoader.LoadAssetContainer(this.mdata.path, this.mdata.filename, this.babylon.scene, this.modelLoaded.bind(this));
        }else{
            if (this.onComplete) this.onComplete();
        }
    }
    public modelLoaded(model: any) {
        Data.models[this.mdata.glassDrawing] = model;

        if (this.onComplete) this.onComplete();
    }
    public show() {

    }
    public hide() {

    }
    public enableMeshes() {

    }
    public disableMeshes() {

    }
}