
    import Generic from "../../Generic.vue";
    import Utils from "../../../ts/utils/Utils";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";

    @Component({
        components: {

        }
    })

    export default class FinishedProductsStocks extends Generic {
        private fps:any = null;
        private headers:any = [];
        private countries:any = ["all"];
        private countriesList:any = [];
        private results:any = null;

        private mounted() {
            this.headers = [
                { text: 'Country', value: 'Country', width:1 },
                { text: 'Code usine', value: 'PlantCode', width:1 },
                { text: 'Usine', value: 'PlantText', width:3 },
                { text: 'quantity', value: 'quantity', width:1 },
                { text: 'Blocked Stock', value: 'BlockedStock', width:1 },
                { text: 'Stck In Transit Used', value: 'StckInTransitUsed', width:1 },
                { text: 'Prod Date From', value: 'ProdDateFrom', width:2 },
                { text: 'Prod Remain Qty', value: 'ProdRemainQty', width:2 }
            ]

            this.fps = this.getFinishedProducts();
        }
        private destroyed(){

        }
        private getCountries(){
            let countries:any = [];
            let str:string;
            for(str in Data.data.finishedProductsStocks[Data.data.product.baseProducts[Data.data.product.bpIndex]]){
                let fp:any = Data.data.finishedProductsStocks[Data.data.product.baseProducts[Data.data.product.bpIndex]][str];

                for(let i=0; i<fp.length; i++){
                    if(fp[i].Country && countries.indexOf(fp[i].Country) == -1) countries.push(fp[i].Country);
                }
            }

            this.countriesList = countries;

            return countries;
        }
        private selectCountry(country:string){
            let index:number = this.countries.indexOf(country);

            if(index != -1){
                this.countries.splice(index, 1);

                if(this.countries.length == 0){
                    this.countries = ["all"];
                }
            }else{
                if(country != "all"){
                    this.countries.push(country);
                    let allIndex:number = this.countries.indexOf("all");
                    if(allIndex != -1) this.countries.splice(allIndex, 1);

                    if(this.countries.length == this.countriesList.length && this.countries.indexOf("all") == -1){
                        this.countries = ["all"];
                    }
                }else{
                    this.countries = ["all"];
                }
            }

            this.fps = this.getFinishedProducts();
        }
        private getFinishedProducts(){
           let fp:any = Data.data.finishedProductsStocks[Data.data.product.baseProducts[Data.data.product.bpIndex]];
            let result = {};

            let str:string;
            for(str in fp){
                for(let i=0; i<fp[str].length; i++){

                    let passed:boolean = false;
                    if(this.countries.indexOf("all") != -1) passed = true;
                    else if(this.countries.indexOf(fp[str][i].Country) != -1) passed = true;

                    if(!passed) continue;

                    if(!result[str]) result[str] = [];

                    result[str].push(fp[str][i]);
                }
            }

            this.results = result;

            return result;
        }
        private getTotalStock(fp:string=null){
            if(!this.results) return 0;

            let total:number = 0;

            let str:string;
            for(str in this.results){
                let passed:boolean = false;

                if(!fp) passed = true;
                else if(fp == str) passed = true;

                if(!passed) continue;

                for(let i=0; i<this.results[str].length; i++){
                    total += this.results[str][i].quantity;
                }
            }

            return this.formatNumbers(total);
        }
        private formatNumbers(num:number){
            return String(num).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
        private formatStocksDate(value:string){
            if(!value || value == "") return "";

            let diff:number = 2592000000;  //30 days in ms

            let current_date:Date = new Date();
            let stock_date:Date = new Date(value);
            let stock_date_ms:number = stock_date.getTime();
            let current_date_ms:number = current_date.getTime();

            let day:any = stock_date.getDate();
            let month:any = stock_date.getMonth() + 1;
            let year:any = stock_date.getFullYear();

            if(day < 10) day = "0" + day;
            if(month < 10) month = "0" + month;

            if(stock_date_ms < current_date_ms + 2592000000) return day + "/" + month + "/" + year;
            else if(stock_date_ms > current_date_ms + 2592000000) return month + "/" + year;
            else return day + "/" + month + "/" + year;
        }
    }
