
    import Generic from "../../Generic.vue";
    import Data from "@/ts/application/Data";
    import Home from "./Home.vue";
    import Project from "./Project.vue";
    import $ from 'jquery';

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";

    @Component({
        components: {
            Home,
            Project
        }
    })
    export default class Burger extends Generic {
        private width:any = 400;
        private breakPoint:number = 840;
        private visible:boolean = true;
        private temporary:boolean = true;
        private bottom:boolean = true;

        private mounted() {
            window.addEventListener("resize", this.resize.bind(this));
            this.resize();
        }
        private destroyed(){
            window.removeEventListener("resize", this.resize.bind(this));
        }
        private resize(){
            //@ts-ignore
            if($(window).width() <= 840){
                this.width = "100%";
                return
            }

            this.width = 400;
        }
    }
