import Utils from "../utils/Utils";
import Labels  from './Labels';
import Model  from './Model';
import Content  from './Content';
import Capsule  from './Capsule';
import Data from "../application/Data";
import * as BABYLON from "babylonjs";
import 'babylonjs-loaders';
import BabylonManager from "../managers/BabylonManager";
import gsap from "gsap";
import $ from "jquery";
import { config } from "vue/types/umd";



export default class VGObject{
    public mdata:any;
    public babylon: BabylonManager;
    public onComplete:any;
    public onError:any;
    public uid:string;
    public modelData:any;
    public labels: Labels;
    public content: Content;
    public capsule: Capsule;
    public model3D: any;
    public updateModelsWithSamePID: boolean = true;
    public loader: any;
    public model: any;
    public cudeTexture: any;

    public modelMesh: any;
    public decorMesh: any;
    public capsulesMesh: any;
    public contentMesh: any;
    public internalContentMesh: any;
    public container: any;

    public hasModelMesh: boolean = false;
    public hasDecorMesh: boolean = false;
    public hasCapsuleMesh: boolean = false;
    public hasInternalContentMesh: boolean = false;
    public hasExternalContentMesh: boolean = false;
    public meshes: any; //not used, just to avoid type declaration
    public capsulesIndex: number = 0;
    public box: any;




    constructor(data:any, babylon:BabylonManager, onComplete:any, onError:any){
        this.mdata = data;
        this.babylon = babylon;
        this.onComplete = onComplete;
        this.onError = onError;


        this.uid = Utils.generateUID();
        this.modelData = { labelBottomPosition:10, labelHeight:110, labelMaxHeight:null, backLabelMaxHeight:null, diameter:0, height:0, circumference:0 };


        this.container = new BABYLON.TransformNode(this.uid, this.babylon.scene);
        //this.container.rotation.x = Utils.degreesToRadians(180);
        this.container.scaling.x = this.container.scaling.y = this.container.scaling.z = this.babylon.objectsScale;
    }
    public init(){
        this.loadModel();
    }
    public destroy(){
        $(window).off("SCENE_CHANGE", this.sceneChange.bind(this));
        //$(window).off("ENABLE_MODELS", this.enableModels.bind(this));
    }
    public sceneChange(){
        if (this.model) this.model.sceneChange();
        if (this.capsule) this.capsule.sceneChange();
        if (this.labels) this.labels.sceneChange();
    }
    public enableModels(){
        if (this.model) this.model.enable();
        if (this.capsule) this.capsule.enable();
        if (this.labels) this.labels.enable();
        if (this.content) this.content.enable();
    }
    public disableModels(){
        if (this.model) this.model.disable();
        if (this.capsule) this.capsule.disable();
        if (this.labels) this.labels.disable();
        if (this.content) this.content.disable();
    }
    public loadModel(){
        if(Data.models[this.mdata.glassDrawing] && !this.mdata.forceLoading){
            this.model3D = Data.models[this.mdata.glassDrawing];
            this.initModel();
        }else{
            BABYLON.SceneLoader.LoadAssetContainer(this.mdata.path, this.mdata.filename, this.babylon.scene, this.modelLoaded.bind(this), null, this.modelError.bind(this));
        }
    }
    public modelLoaded(model:any){
        //console.log("model loaded");
        //console.log(model);
        this.model3D = Data.models[this.mdata.glassDrawing] = model;

        var t = this;
        this.model3D.meshes.filter((child:any) => {
            ///child.y = 20000;
            //child.visibility = 0;
            if (child.name.toLowerCase().indexOf("capsule_") != -1) {
                $(window).trigger("CAPSULE_DETECTED", { glassDrawing: t.mdata.glassDrawing, capsule: child.name });
            }
        })

        this.initModel();
    }
    private modelError(){
        delete this.babylon.models[this.mdata.id];
        if(this.onError) this.onError();
    }
    public initModel(){
        //model.setEnabled(false);
        //console.log(this.model3D);
        this.initElements();

        if (this.model) this.model.init();
        if (this.content) this.content.init();
        if (this.capsule) this.capsule.init();
        if (this.labels) this.labels.init();

        //console.log(this.modelData);

        if (!this.mdata.disableCompatibleCapsules){
            this.loadCompatibleCapsules();

            return;
        }

        $(window).on("SCENE_CHANGE", this.sceneChange.bind(this));
        $(window).on("ENABLE_MODELS", this.enableModels.bind(this));
        if(this.onComplete) this.onComplete();
    }
    private loadCompatibleCapsules(){
        if (Data.config.models.catalog[this.mdata.glassDrawing] && Data.config.models.catalog[this.mdata.glassDrawing].capsules){
            //console.log(Data.config.models.catalog[this.mdata.glassDrawing].capsules);

            this.loadNextCapsule();
        }else{
            $(window).on("SCENE_CHANGE", this.sceneChange.bind(this));
            $(window).on("ENABLE_MODELS", this.enableModels.bind(this));
            if (this.onComplete) this.onComplete();
        }
        //this.babylon.loadCapsule();
    }
    private loadNextCapsule(){
        let capsule: string = Data.config.models.catalog[this.mdata.glassDrawing].capsules[this.capsulesIndex];
        let capsuleData: any = Data.config.models.capsules[capsule];

        if (!capsuleData){
            this.capsuleComplete();
            return;
        }

        let data:any = {};
        data.glassDrawing = capsule;
        data.path = Data.config.dataFilesPath + "virtualglass/assets/models/" + capsule + "/";
        data.filename = capsuleData.id + ".gltf";

        this.babylon.loadCapsule(data, this.capsuleComplete.bind(this), this.capsuleComplete.bind(this));
    }
    private capsuleComplete(){
        if (this.capsulesIndex == Data.config.models.catalog[this.mdata.glassDrawing].capsules.length - 1){
            $(window).on("SCENE_CHANGE", this.sceneChange.bind(this));
            $(window).on("ENABLE_MODELS", this.enableModels.bind(this));
            if (this.onComplete) this.onComplete();

            return;
        }

        this.capsulesIndex ++;
        this.loadNextCapsule();
    }
    public initElements(){
        //console.log("ixixi: ", this.mdata);
        //this.externalMesh = this.model.scene.children[0].children[0];
        var t = this;
        this.model3D.meshes.filter((child:any) => {
            // console.log(child.name);
            child.parent = null;
            if (child.name.toLowerCase() == "model"){
                t.modelMesh = child.clone(this.mdata.glassDrawing + "_MODEL");
                t.hasModelMesh = true;
            }else if (child.name.toLowerCase().indexOf("capsule_") != -1){
                if (!t.capsulesMesh) t.capsulesMesh = {};
                t.capsulesMesh[child.name] = child.clone(this.mdata.glassDrawing + "_CAPSULE");
                t.hasCapsuleMesh = true;
            }else if (child.name.toLowerCase() == "internal_content"){
                t.internalContentMesh = child.clone(this.mdata.glassDrawing + "_CONTENT_ID");
                t.hasInternalContentMesh = true;
            } else if (child.name.toLowerCase() == "external_content") {
                t.contentMesh = child.clone(this.mdata.glassDrawing + "_CONTENT_OUT");
                t.hasExternalContentMesh = true;
            } else if (child.name.toLowerCase() == "decor") {
                t.decorMesh = child.clone(this.mdata.glassDrawing + "_DECOR");
                t.hasDecorMesh = true;
            }
        })

        if (!this.hasCapsuleMesh && Data.config.models.catalog[this.mdata.glassDrawing] && Data.config.models.catalog[this.mdata.glassDrawing].capsules) {
            this.hasCapsuleMesh = true;
        }
        // console.log(this.modelMesh);
        // console.log(this.internalContentMesh);
        // console.log(this.contentMesh);
        // console.log(this.capsuleMesh);
        // console.log(this.decorMesh);

        if (t.hasModelMesh) this.model = new Model(this);
        if (t.hasExternalContentMesh) this.content = new Content(this);
        if (t.hasCapsuleMesh) this.capsule = new Capsule(this);
        if (t.hasDecorMesh) this.labels = new Labels(this);
    }


    public show(){
        this.enableModels();
        this.container.position.y = 0;
    }
    public hide(){
        if (this.model) this.model.mesh.showBoundingBox = false;
        this.disableModels();
        this.container.position.y = 20000;
    }
    public setPosition(x:number, y:number, z:number){
        this.container.position.x = x;
        this.container.position.y = y;
        this.container.position.z = z;
    }
    public moveTo(x: number, y: number, z: number, duration: number){
        if(!duration) duration = 1;

        gsap.to(this.container.position, { duration:duration, x:x, y:y, z:z });
    }
    public fadeIn(duration:number){
        /*if(!duration) duration = 0.5;

        gsap.to(this.container.position, { duration: duration * 2, y:0, ease:Power4.easeOut });
        gsap.to(this.model.externalFront, { duration:duration, visibility:1 });
        gsap.to(this.model.internalFront, { duration:duration, visibility:1 });
        if (this.content.isActive == true) gsap.to(this.content.front, { duration: duration, visibility:1 });
        if (this.capsule.isActive == true) gsap.to(this.capsule.front, { duration:duration, visibility:1 });
        if(this.labels.isActive == true){
            gsap.to(this.labels.front, { duration:duration, visibility:1 });
            gsap.to(this.labels.back, { duration:duration, visibility:1 });
        }*/
    }
    public fadeOut(duration:number){
        /*if(!duration) duration = 0.5;

        gsap.to(this.container.position, duration * 2, { y:2000, ease:Power2.easeIn });
        gsap.to(this.model.externalFront, duration, { visibility:0 });
        gsap.to(this.model.internalFront, duration, { visibility:0 });
        if (this.content.isActive == true) gsap.to(this.content.front, { duration:duration, visibility:0 });
        if (this.capsule.isActive == true) gsap.to(this.capsule.front, { duration:duration, visibility:0 });
        if(this.labels.isActive == true){
            gsap.to(this.labels.front, { duration:duration, visibility:0 });
            gsap.to(this.labels.back, { duration:duration, visibility:0 });
        }*/
    }
    public getProductData(){
        return this.modelData;
    }
    public setRotation(rotation:number){
        this.container.rotation.y = Utils.degreesToRadians(rotation);
    }
    public setColor(color:string, data:any=null){
        if (!this.model) return;

        let type: string = typeof color;

        if (data) Data.config.colors[color] = data;
        let colorData: any = Data.config.colors[color];

        if(!colorData){
            if (color) console.log("The color " + color + " is not declared into config");
            return;
        }else{
            this.model.color = color;
            this.model.generateTexture();
        }



        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.model.color = color;
            model.model.generateTexture();
        })
    }


    public showExternalContent(){
        if (!this.content) return;

        this.content.showExternalContent();
    }
    public hideExternalContent() {
        if (!this.content) return;

        this.content.hideExternalContent();
    }
    public showContents(){
        if (!this.content) return;

        this.content.showContents();
    }
    public hideContents(){
        if (!this.content) return;

        this.content.hideContents();
    }
    public setContent(content: string) {
        let contentData: any = Data.config.contents.bottle[content];
        if (!contentData) contentData = Data.config.contents.jar[content];

        if (!contentData){
            console.log(content + " content not found in config.");
            return;
        }

        //console.log(contentData);

        if (contentData.secondaryType == "liquid") this.setLiquidContent(content);
        else if (contentData.secondaryType == "texture" || contentData.secondaryType == "physics") this.setSolidContent(content);
        //else if (contentData.secondaryType == "texture") this.setSolidContent(contentData);
    }
    public setLiquidContent(content:any){
        if (!this.content) return;

        let contentData: any = Data.config.contents.bottle[content];
        if (!contentData) contentData = Data.config.contents.jar[content];

        //console.log("setLiquidContent");

        this.content.content = content;
        this.model.generateTexture();

        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            //model.content.content = content;
            //model.model.generateTexture();
        })
    }
    public setSolidContent(content: any){
        if (!this.content) return;

        let contentData: any = Data.config.contents.bottle[content];
        if (!contentData) contentData = Data.config.contents.jar[content];

        //console.log("setSolidContent");

        this.content.content = content;
        this.content.setTexture();

        if (this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            //model.content.content = content;
            //model.model.setTexture();
        })
    }
    public removeContent(){
        if (!this.content) return;

        //@ts-ignore
        this.content.content = null;
        this.model.generateTexture();


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.content.content = null;
            model.model.generateTexture();
        })
    }
    public setCapsule(capsule: string, texturePath: any, shininess: number, type:string=null){
        //console.log("setCapsule " + capsule + " : " + texturePath + " : " + shininess + " : " + type);
        if (!this.capsule) return;

        var capsuleData = Data.config.labels.capsuleBottle[capsule];
        if (!capsuleData) capsuleData = Data.config.labels.capsuleJar[capsule];

        if(!capsuleData){
            console.log("The capsule " + capsule + " is not declared into config");
        }else{
            this.capsule.display(texturePath + capsuleData.texture, shininess, type);

            if(this.updateModelsWithSamePID == false) return;

            var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
            models.filter(model => {
                model.capsule.display(texturePath + capsuleData.texture, shininess, type);
            })
        }
    }
    public setCapsuleShininess(shininess:number){
        if (!this.capsule) return;

        this.capsule.setShininess(shininess);


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.capsule.setShininess(shininess);
        })
    }
    public removeCapsule(){
        if (!this.capsule) return;

        this.capsule.hide();

        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.capsule.hide();
        })
    }
    public setLabelImage(target: any, url: string, position: any, height: number, onComplete: any=null, onError: any=null){
        if (!this.labels) return;

        this.labels.setLabelImage(target, url, position, height, onComplete, onError);


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.labels.setLabelImage(target, url, position, height);
        })
    }
    public setLabelPosition(target: any, position: any){
        if (!this.labels) return;

        this.labels.setLabelPosition(target, position);


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.labels.setLabelPosition(target, position);
        })
    }
    public setLabelHeight(target: any, height:number){
        if (!this.labels) return;

        this.labels.setLabelHeight(target, height);


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.labels.setLabelHeight(target, height);
        })
    }
    public getLabelHeight(key:string){
        if (!this.labels) return;

        return this.labels.getLabelHeight(key);
    }
    public getLabelPosition(key: string){
        if (!this.labels) return;

        return this.labels.getLabelPosition(key);
    }
    public removeLabel(target:any){
        if (!this.labels) return;

        this.labels.removeLabel(target);


        if(this.updateModelsWithSamePID == false) return;

        var models = this.babylon.application.player.getModelWithSamePID(this.mdata.itemID, this.mdata.id);
        models.filter(model => {
            model.labels.removeLabel(target);
        })
    }
    public designFromData(productData:any){
        this.updateModelsWithSamePID = false;

        //this.setRotation(productData.rotation.x);
        this.setColor(productData.colorID);

        if(productData.contentID){
            let contentData: any = Data.config.contents.bottle[productData.contentID];
            if (contentData){
                this.setLiquidContent(productData.contentID);
            }else{
                contentData = Data.config.contents.jar[productData.contentID];
                this.setSolidContent(productData.contentID);
            }
        }

        if(productData.capsuleID){
            let capData: any = this.babylon.application.player.config.labels.capsuleBottle[productData.capsuleID];
            if (!capData) capData = this.babylon.application.player.config.labels.capsuleJar[productData.capsuleID];

            this.setCapsule(productData.capsuleID, this.babylon.application.player.config.dataFilesPath + "virtualglass/assets/labels/" + capData.type + "/" + capData.user + "/textures/", productData.capsuleShininess, productData.capsuleType);
            this.setCapsuleShininess(productData.capsuleShininess);
        }

        var t = this;

        let labelTarget:string = "label";
        designLabel("label");

        function designLabel(target:string){
            labelTarget = target;
            if(productData[target + "ID"]){

                var image = t.babylon.application.player.config.labels[target][productData[target + "ID"]];
                if(image){
                    //t.setLabelImage(target, t.babylon.application.player.config.assetsPath + "images/labels/" + target + "/" + image.image, productData[target + "Position"], productData[target + "Height"], labelComplete, labelError);
                    t.setLabelImage(target, t.babylon.application.player.config.dataFilesPath + "virtualglass/assets/labels/" + target + "/" + image.user + "/" + image.image, productData[target + "Position"], productData[target + "Height"], labelComplete, labelError);
                }
            }else{
                labelComplete();
            }
        }
        function labelComplete(){
            if(labelTarget == "label") designLabel("backLabel");
            else if(labelTarget == "backLabel") designLabel("decor");
            else if(labelTarget == "decor") t.updateModelsWithSamePID = true;
        }
        function labelError(){
            labelComplete();
        }
    }
    public displayPositionHelper(){
        this.babylon.application.positionPlane.parent = this.container;
        this.babylon.application.positionPlane.visibility = 1;

        if (this.model) this.model.mesh.showBoundingBox = true;
    }
    public hidePositionHelper(){
        this.babylon.application.positionPlane.visibility = 0;
        this.babylon.application.positionPlane.parent = null;

        if (this.model) this.model.mesh.showBoundingBox = false;
    }
}