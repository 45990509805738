var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',{class:{ Catalog:_vm.data.app == 'catalog',
VirtualGlass:_vm.data.app == 'virtualglass',
Internal:_vm.data.platform.id == 'INTERNAL',
Public:_vm.data.platform.id == 'PUBLIC',
MyVerallia:_vm.data.platform.id == 'MYVERALLIA',
SelectiveLine:_vm.data.platform.id == 'SELECTIVELINE',
Mobile:_vm.data.windowWidth < _vm.data.config.mobileBreakPoint }},[_c('Dialog'),_c('Overlay'),(_vm.isHeaderVisible())?_c('Header'):_vm._e(),(_vm.data.platform.useVirtualGlass)?_c('VGManager'):_vm._e(),_c('div',{attrs:{"id":"VGPlayerContainer"}}),_c('v-main',{style:({ height:(_vm.data.windowHeight - 60) + 'px' })},[(_vm.data.app == 'catalog')?_c('Catalog'):_vm._e(),(_vm.data.app == 'virtualglass' && _vm.data.vg.dataReady)?_c('VirtualGlass'):_vm._e()],1),_c('div',{attrs:{"id":"LoadingScreen"}},[_c('div',{attrs:{"id":"loader"}},[(_vm.data.platform.id != 'SELECTIVELINE')?_c('img',{attrs:{"src":_vm.data.config.assetsPath + 'images/logo.png'}}):_c('img',{attrs:{"src":_vm.data.config.assetsPath + 'images/logoSelectiveLine.png'}}),_c('div',{staticClass:"loader"})])]),(_vm.statusGG)?_c('v-dialog',{attrs:{"scrollable":"","max-width":"800px","overlay-opacity":"0.8","persistent":""},model:{value:(_vm.statusGG),callback:function ($$v) {_vm.statusGG=$$v},expression:"statusGG"}},[_c('v-card',{attrs:{"id":"GGDIV"}},[_c('v-card-text',{staticClass:"mt-5 DialogContent",staticStyle:{"max-height":"800px"}},[_c('h2',[_vm._v(" "+_vm._s(_vm.ggtimer)+" ")]),_c('h1',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.ggtext)}})]),_c('v-img',{attrs:{"src":_vm.data.config.assetsPath + 'images/gg.png',"cover":""}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }