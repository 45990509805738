
    import Generic from "../Generic.vue";
    import Home from "./Home.vue";
    import ProductsView from "./ProductsView.vue";
    import Products from "./Products.vue";
    import Product from "./Product.vue";
    import ProductPLS from "./ProductPLS.vue";
    import Footer from "./Footer.vue";
    import Menu from "./Menu.vue";


    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";
    import Utils from "@/ts/player/utils/Utils";
    import URLManager from "@/ts/utils/URLManager";

    @Component({
        components: {
            Home,
            ProductsView,
            Products,
            Product,
            ProductPLS,
            Footer,
            Menu
        },
        watch:{
            "data.section":function(oldValue, newValue){
                this.sectionChange();
            },
            "data.view":function(oldValue, newValue){
                this.sectionChange();
            }
        }
    })
    export default class Catalog extends Generic {
        private mounted(){
            //URLManager.setDataFromURL();
        }
        private destroyed(){

        }
        private sectionChange(){
            //URLManager.setURLFromData();
        }
        private productChange(){

        }
    }
