
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';


    import {
        Component, Vue,
    } from 'vue-property-decorator';
    import ProductsView from "@/components/catalog/ProductsView.vue";
import VGObject from "@/ts/player/modules/VGObject";
import Loader from "@/ts/application/Loader";
import Utils from "@/ts/utils/Utils";
import DataUtils from "@/ts/utils/DataUtils";

    @Component({
        components: {
            vuescroll,
            ProductsView
        }
    })
    export default class ProductsSettings extends Generic {
        private fileLoader:Loader;
        private tempIndex:number;
        private tempID:string;
        private tempItem:any;
        private tempItemData:any;
        private itemsDialog:boolean = false;
        private swapID:string = null;
        private importSections:any = ['catalog', 'library'];
        private itemSelection:number = 0;
        private sharedProjects:any;
        private itemsPerPage:number = 50;
        private currentPage:number = 1;
        private tempCacheID:string = null;
        private itemsRemoval:any = null;
        private projectsRemoval:any = null;


        private mounted() {
            this.tempCacheID = Utils.generateUID();

            $(window).on("ITEM_SELECTED_FROM_CATALOG", this.itemSelectedFromCatalog.bind(this));
            $(window).on("ADD_ITEM", this.openItems.bind(this));
        }
        private destroyed(){
            $(window).off("ITEM_SELECTED_FROM_CATALOG");
            $(window).off("ADD_ITEMS");
        }
        private handleScroll(e:any){
            if(Data.data.vg.items.length == 0) return;
            console.log("handleScroll");

            let position:number = e.scrollTop;
            let areaHeight:number = $("#ItemsSelector #ItemsScroller .__view").height();
            let itemsHeight:number = $("#ItemsSelector #ItemsScroller").height();

            let areaOffset:any = [areaHeight - 50, areaHeight + 100];
            let offset:number = Math.round(itemsHeight - position);


            if(offset > areaOffset[0] && offset < areaOffset[1]){
                this.currentPage ++;
                console.log(this.currentPage);
            }
        }
        private openItems(e:any, mode:string){
            this.addItem();
            if(mode == "library") this.itemSelection = 1;
        }
        private addItem(){
            this.swapID = null;
            this.itemsDialog = true;
            this.itemSelection = 0;
        }

        private getItemIndex(){
            for(let i=0; i<Data.data.vg.project.items.length; i++){
                if(Data.data.vg.item.itemID == Data.data.vg.project.items[i].itemID){
                    return i;
                }
            }
        }
        private moveTo(direction:string){
            let index:number = this.getItemIndex();
            let secondIndex:number = index - 1;
            if(direction == "right") secondIndex = index + 1;

            let item1:any = Data.data.vg.project.items[index];
            let item2:any = Data.data.vg.project.items[secondIndex];

            item1.itemIndex = secondIndex;
            item2.itemIndex = index;

            Vue.set(Data.data.vg.project.items, index, item2);
            Vue.set(Data.data.vg.project.items, secondIndex, item1);

            // $(window).trigger("SHOW_OVERLAY");
            // $(window).trigger("SHOW_LOADING");

            // this.fileLoader = new Loader(this.itemsSwapComplete.bind(this), this.itemsSwapError.bind(this));
            // this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + Data.data.userID + "/project/" + Data.data.vg.project.id + "/update-item-settings/" + item1.id, method:"PUT", contentType:"application/json", data:JSON.stringify(item1), headers: Data.data.headers });
            // this.fileLoader.add({ id: "request2", url: Data.data.platform.digitalEngineVGURL + Data.data.userID + "/project/" + Data.data.vg.project.id + "/update-item-settings/" + item2.id, method:"PUT", contentType:"application/json", data:JSON.stringify(item2), headers: Data.data.headers });
            // this.fileLoader.start();
        }
        private itemsSwapComplete(){
            let response1:any = this.fileLoader.getResponse("request1");
            let response2:any = this.fileLoader.getResponse("request2");

            if(response1.status != 200 || response2.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
        }
        private itemsSwapError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }


        private editItem(index:number){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.tempIndex = index;
            this.tempID = Data.data.vg.item.itemID;

            $(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);

            setTimeout(this.openModel.bind(this), 1000);
        }
        private openModel(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.tempCacheID = Utils.generateUID();

            let item:any = Data.data.vg.project.items[this.tempIndex];
            let id:string = item.id;

            Data.data.vg.item = this.getVGItemFromCurrentProject(id);
            Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
            Data.data.vg.catalogItem = this.getVGItemCatalog(Data.data.vg.itemData.glassDrawing);

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            window.PLAYER.hideAllModels();
            window.PLAYER.focusOnModel(Data.data.vg.item.id);
            model.setRotation(Data.data.vg.item.rotation.x);

            // let pictures:any = $(".ItemScreenshot");
            // let a:number = Data.data.vg.project.items.length;
            // for(let i=0; i<a; i++){
            //     let item:any = Data.data.vg.project.items[i];

            //     //if(item.itemID == this.tempID) $($(".ItemScreenshot")[i]).find(".v-image__image").css("background-image", "url(" + Data.data.platform.digitalEngineFileURL + "virtualglass/" + Data.data.userID + "/screenshots/" + item.itemID + ".png?" + Math.random() + ")");
            // }

            model.show();
        }
        private removeItem(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.deleteItemComplete.bind(this), this.deleteItemError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/delete-item-settings/" + Data.data.vg.item.id, method:"DELETE", contentType:"application/json", headers: Data.data.headers });
            this.fileLoader.start();
        }
        private deleteItemError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private deleteItemComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");


            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            for(let i=0; i<Data.data.vg.project.items.length; i++){
                if(Data.data.vg.project.items[i].id == Data.data.vg.item.id){
                    Data.data.vg.project.items.splice(i, 1);
                }
            }

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.hide();

            if(Data.data.vg.project.items.length > 0){
                let item:any = Data.data.vg.project.items[0];

                Data.data.vg.item = this.getVGItemFromCurrentProject(item.id);
                Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
                Data.data.vg.catalogItem = this.getVGItemCatalog(Data.data.vg.itemData.glassDrawing);

                let model:VGObject = window.PLAYER.getModel(item.id);
                if(model){
                    window.PLAYER.focusOnModel(Data.data.vg.item.id);
                    model.show();
                }

                return;
            }

            Data.data.vg.item = null;
            Data.data.vg.itemData = null;
            Data.data.vg.catalogItem = null;
        }
        private changeImportSection(){
            if(this.itemSelection == 2){
                $(window).trigger("SHOW_OVERLAY");
                $(window).trigger("SHOW_LOADING");

                //console.log(this.itemSelection);

                this.fileLoader = new Loader(this.sharedProjectsComplete.bind(this), this.sharedProjectsError.bind(this));
                this.fileLoader.add({ id: "sharedProjects", url: Data.data.platform.digitalEngineVGURL + "shared-projects/" + Data.data.user.id, method:"GET", headers: Data.data.headers });
                this.fileLoader.start();
            }
        }
        private sharedProjectsError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
        }
        private sharedProjectsComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            let response:any = this.fileLoader.getResponse("sharedProjects");

            if(response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            this.sharedProjects = this.fileLoader.get("sharedProjects");
        }




        private itemSelectedFromCatalog(e:any, p:any){
            //console.log("itemSelectedFromCatalog", p);

            if(Data.data.vg.item) $(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);

            this.itemsDialog = false;

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            //console.log("p.colorIndex " + p.colorIndex);

            this.tempItemData = Utils.copy(Data.data.vg.VGItemDataModel);
            this.tempItemData.id = Utils.generateUID();
            this.tempItemData.glassDrawing = p.glassDrawingVG;
            this.tempItemData.baseProduct = p.baseProducts[p.colorIndex];
            this.tempItemData.name = p.name;
            this.tempItemData.colorID = DataUtils.getVGColorFromProduct(p, this.tempItemData.baseProduct);

            this.fileLoader = new Loader(this.saveItemComplete.bind(this), this.saveItemError.bind(this));
            if(!this.swapID){
                this.tempItem = Utils.copy(Data.data.vg.VGItemModel);
                this.tempItem.id = Utils.generateUID();
                this.tempItem.itemID = this.tempItemData.id;
                this.tempItem.type = "catalog";
                this.tempItem.index = this.tempItem.itemIndex = Data.data.vg.project.items.length;

                this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/add-item-settings/" + this.tempItem.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempItem), headers: Data.data.headers });
            }else{
                this.tempItem = Utils.copy(Data.data.vg.item);
                this.tempItem.id = Utils.generateUID();
                this.tempItem.itemID = this.tempItemData.id;
                this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/update-item-settings/" + this.swapID, method:"PUT", contentType:"application/json", data:JSON.stringify(this.tempItem), headers: Data.data.headers });
            }

            this.fileLoader.add({ id: "request2", url: Data.data.platform.digitalEngineVGURL + "add-item-catalog/" + Data.data.userID + "/" + this.tempItemData.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempItemData), headers: Data.data.headers });

            this.fileLoader.start();
        }
        private saveItemError(){
            this.tempCacheID = Utils.generateUID();

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveItemComplete(){
            this.tempCacheID = Utils.generateUID();

            let file1:any = this.fileLoader.get("request1");
            let response1:any = this.fileLoader.getResponse("request1");
            let file2:any = this.fileLoader.get("request2");
            let response2:any = this.fileLoader.getResponse("request2");


            if(!file1 || response1.status != 200 || !file2 || response2.status != 200){
                $(window).trigger("HIDE_OVERLAY");
                $(window).trigger("HIDE_LOADING");
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }


            if(!this.swapID){
                Data.data.vg.project.items.push(this.tempItem);
                Data.data.vg.item = this.getVGItemFromCurrentProject(this.tempItem.id);
            }else{
                Data.data.vg.item.itemID = this.tempItem.itemID;
                let a:number = Data.data.vg.project.items.length;
                for(let i=0; i<a; i++){
                    if(Data.data.vg.project.items[i].id == this.swapID){
                        Data.data.vg.project.items[i].id = this.tempItem.id;
                        Data.data.vg.project.items[i].itemID = this.tempItem.itemID;
                        break;
                    }
                }
                this.swapID = null;
            }

            Data.data.vg.items.push(this.tempItemData);
            Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
            Data.data.vg.catalogItem = this.getVGItemCatalog(Data.data.vg.itemData.glassDrawing);

            //this.tempPosition = { x:Data.data.vg.item.position.x, z:Data.data.vg.item.position.z };

            // if(window.PLAYER.isModeloaded("77141") == false){
            //     let data: any = Utils.copy(Data.data.config.dialogs.vgModelLoading);
            //     $(window).trigger("DIALOG_OPEN", data);
            // }

            $(window).on("MODELS_LOADED", this.modelReady.bind(this));
            $(window).trigger("LOAD_MODELS");

            //let modelName:string = Data.data.vg.models.catalog[Data.data.vg.catalogItem.glassDrawing].id;
            //window.PLAYER.loadModel({ id:this.tempItem.id, itemID:this.tempItemData.id, glassDrawing:Data.data.vg.catalogItem.glassDrawing, path:Data.data.platform.digitalEngineFileURL + "virtualglass/assets/models/" + this.tempItemData.glassDrawing + "/", filename:modelName + ".gltf" }, this.modelReady.bind(this), this.modelError.bind(this));
        }



        private selectItemFromLibrary(item:any){
            console.log("selectItemFromLibrary", item);

            if(Data.data.vg.item) $(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);

            this.itemsDialog = false;

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");



            this.fileLoader = new Loader(this.saveItemLibComplete.bind(this), this.saveItemLibError.bind(this));
            if(!this.swapID){
                this.tempItem = Utils.copy(Data.data.vg.VGItemModel);
                this.tempItem.id = Utils.generateUID();
                this.tempItem.itemID = item.id;
                this.tempItem.type = "catalog";
                this.tempItem.index = this.tempItem.itemIndex = Data.data.vg.project.items.length;

                this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/add-item-settings/" + this.tempItem.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempItem), headers: Data.data.headers });
            }else{
                this.tempItem = Utils.copy(Data.data.vg.item);
                this.tempItem.id = Utils.generateUID();
                this.tempItem.itemID = item.id;
                this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/update-item-settings/" + this.swapID, method:"PUT", contentType:"application/json", data:JSON.stringify(this.tempItem), headers: Data.data.headers });
            }

            this.fileLoader.start();
        }
        private saveItemLibError(){
            this.tempCacheID = Utils.generateUID();

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveItemLibComplete(){
            this.tempCacheID = Utils.generateUID();

            let file1:any = this.fileLoader.get("request1");
            let response1:any = this.fileLoader.getResponse("request1");


            if(!file1 || response1.status != 200){
                $(window).trigger("HIDE_OVERLAY");
                $(window).trigger("HIDE_LOADING");
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }


            if(!this.swapID){
                Data.data.vg.project.items.push(this.tempItem);
                Data.data.vg.item = this.getVGItemFromCurrentProject(this.tempItem.id);
            }else{
                Data.data.vg.item.itemID = this.tempItem.itemID;
                let a:number = Data.data.vg.project.items.length;
                for(let i=0; i<a; i++){
                    if(Data.data.vg.project.items[i].id == this.swapID){
                        Data.data.vg.project.items[i].id = this.tempItem.id;
                        Data.data.vg.project.items[i].itemID = this.tempItem.itemID;
                        break;
                    }
                }
                this.swapID = null;
            }

            Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
            Data.data.vg.catalogItem = this.getVGItemCatalog(Data.data.vg.itemData.glassDrawing);

            $(window).on("MODELS_LOADED", this.modelReady.bind(this));
            $(window).trigger("LOAD_MODELS");
        }


        private modelReady(){
            $(window).off("MODELS_LOADED");

            // console.log("MODELS_LOADED");
            // console.log(Data.data.vg.item);
            // console.log(Data.data.vg.itemData);

            window.PLAYER.hideAllModels();
            window.PLAYER.focusOnModel(Data.data.vg.item.id);
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.show();
            model.designFromData(Data.data.vg.itemData);

            this.itemsDialog = false;
        }
        private modelError(){
            $(window).trigger("HIDE_OVERLAY");
        }



        private deleteItem(item:any){
            this.tempItem = item;

            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                let pitem:any =  Data.data.vg.project.items[i];

                if(pitem.itemID == item.id){
                    $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgDeleteItemUsed);

                    return;
                }
            }

            let data:any = Utils.copy(Data.data.config.dialogs.vgConfirmDeleteItem);
            data.buttons.yes.callback = this.deleteItemConfirm.bind(this);

            $(window).trigger("DIALOG_OPEN", data);
        }
        private deleteItemConfirm(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            let items:any = [];
            let projects:any = [];
            for(let i=0; i<Data.data.vg.projects.length; i++){
                let project:any = Data.data.vg.projects[i];

                for(let j=0; j<project.items.length; j++){
                    if(this.tempItem.id == project.items[j].itemID){
                        if(projects.indexOf(project.id) == -1){
                            projects.push(project.id);
                        }

                        items.push({ projectID:project.id, item:project.items[j].id });
                    }
                }
            }

            this.fileLoader = new Loader(this.deleteItemCatalogComplete.bind(this), this.deleteItemCatalogError.bind(this));
            this.fileLoader.add({ id: "deleteItem", url: Data.data.platform.digitalEngineVGURL + "delete-item-catalog/" + Data.data.user.id + "/" + this.tempItem.id, method:"DELETE", contentType:"application/json", headers: Data.data.headers });

            if(projects.length > 0){

            }

            this.fileLoader.start();
        }
        private deleteItemCatalogError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private deleteItemCatalogComplete(){
            let file1:any = this.fileLoader.get("deleteItem");
            let response1:any = this.fileLoader.getResponse("deleteItem");

            if(!file1 || response1.status != 200){
                this.deleteItemCatalogError();
                return;
            }

            $(window).trigger("DELETE_ITEM_SCREENSHOT", this.tempItem.id);

            for(let i=0; i<Data.data.vg.items.length; i++){
                if(Data.data.vg.items[i].id == this.tempItem.id){
                    this.$delete(Data.data.vg.items, i);

                    break;
                }
            }


            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
        }
    }
