
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import FiltersUtils from "@/ts/utils/FiltersUtils";
    import Loader from "@/ts/application/Loader";
    import RenderCheck from "@/ts/application/RenderCheck";

    @Component({
        components: {
            vuescroll
        }
    })
    export default class Renders extends Generic {
        private status:boolean = false;
        private renderDialog:boolean = false;
        private fileLoader:Loader;
        private rendersCheck:any = {};
        private timer:any;
        private tempRender:any = null;
        private qualities:any = ["preview", "sd", "hd", "4k"];
        private project:any = null;
        private tempItem:any;
        private tempBP:any;

        mounted(){
            $(window).on("OPEN_RENDERS", this.openRenders.bind(this));
            $(window).on("NEW_RENDER", this.newRender.bind(this));
            $(window).on("ADD_RENDER", this.addRender.bind(this));
            $(window).on("APP_READY", this.checkRenders.bind(this));

            this.checkRenders();

            // let options:any = {
            //     html:"your render is in progress <img src='http://localhost:8000/files/virtualglass/bran/PROJECTX/RENDERX.png' />"
            // };

            // //@ts-ignore
            // this.$snotify.success("", options);
            // //@ts-ignore
            // this.$snotify.info("", options);
            // //@ts-ignore
            // this.$snotify.error("", options);
        }
        destroyed(){
            $(window).off("OPEN_RENDERS");
            $(window).off("NEW_RENDER");
            $(window).off("ADD_RENDER");
            $(window).off("APP_READY");
        }
        private newRender(){
            this.tempRender = Utils.copy(Data.data.vg.VGRenderModel);
            this.tempRender.id = Utils.generateUID();
            this.tempRender.projectID = Data.data.vg.project.id;
            this.tempRender.userID = Data.data.user.id;
            this.tempRender.scene = Data.data.vg.project.scene;
            this.tempRender.quality = Data.data.vg.project.quality;
            this.tempRender.transparentGlass = Data.data.vg.project.transparentGlass = false;
            this.tempRender.data = { items:[], itemsCatalog:{} };

            for(let i=0; i<Data.data.vg.project.items.length; i++){
                this.tempRender.data.items.push(Data.data.vg.project.items[i].itemID);
            }

            let items:any =this. getVGItemsFromCurrentProject();
            for(let i=0; i<items.length; i++){
                if(this.tempRender.data.itemsCatalog[items[i].id]) continue;

                this.tempRender.data.itemsCatalog[items[i].id] = { glassDrawing:items[i].glassDrawing, baseProduct:items[i].baseProduct };
                if(items[i].colorID) this.tempRender.data.itemsCatalog[items[i].id].colorID = items[i].colorID;
                if(items[i].capsuleType) this.tempRender.data.itemsCatalog[items[i].id].capsuleType = items[i].capsuleType;
            }

            this.renderDialog = true;
        }
        private openRenders(e:any, project:any){
            this.project = project;
            this.status = true;
        }
        private closeRenders(){
            this.project = null;
            this.status = false;
        }
        private changeDescription(render:any){
            if(this.timer) clearInterval(this.timer);

            this.timer = setInterval(this.updateDescription.bind(this, render), 1000);
        }
        private updateDescription(render:any){
            if(this.timer) clearInterval(this.timer);

            this.fileLoader = new Loader(this.saveRendersComplete.bind(this), this.saveRendersError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "update-render-description/" + render.id, method:"POST", contentType:"application/json", data:JSON.stringify({ description:render.description }), headers:Data.data.headers });
            this.fileLoader.start();
        }
        private saveRendersError(){
            //NO NEED TO SHOW ERROR MESSAGE

            // $(window).trigger("HIDE_OVERLAY");
            // $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveRendersComplete(){
            //NO NEED TO SHOW ERROR MESSAGE

            // $(window).trigger("HIDE_OVERLAY");

            // let file:any = this.fileLoader.get("request");
            // let response:any = this.fileLoader.getResponse("request");
            // if(!file || response.status != 200){
            //     $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
            //     return;
            // }
        }
        private isQualityEnabled(quality:string){
            if(Data.data.platform.id == "MYVERALLIA" && quality == "4k") return false;
            else if (Data.data.platform.id == "MYVERALLIA" && quality == "hd" && !Data.data.MYVeralliaCanAccessVG) return false;

            return true;
        }
        private prepareRender(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgRenderToQueue);

            this.tempRender.creationDate = new Date().toISOString();
            Data.data.vg.project.scene = this.tempRender.scene ;
            Data.data.vg.project.quality = this.tempRender.quality;
            Data.data.vg.project.transparentGlass = this.tempRender.transparentGlass;

            this.renderDialog = false;

            $(window).trigger("PREPARE_RENDER");
        }
        private addRender(){
            this.fileLoader = new Loader(this.renderSaveComplete.bind(this), this.renderSaveError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + Data.data.vg.project.id + "/add-render/" + this.tempRender.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempRender), headers: Data.data.headers });
            this.fileLoader.start();
        }
        private renderSaveError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);

            this.renderDialog = false;
        }
        private renderSaveComplete(){
            //$(window).trigger("DIALOG_CLOSE");
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");

            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            this.renderDialog = false;
            $(window).trigger("DIALOG_CLOSE");
            //$(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgRenderInQueue);
            this.addRenderData();

            Data.data.vg.project.renders.unshift(this.tempRender);


            Data.data.vg.item = null;
            Data.data.vg.itemData = null;
            Data.data.vg.catalogItem = null;
            Data.data.vg.project = null;
            Data.data.section = "home";

            let loader:Loader = new Loader();
            loader.add({ id: "run", url: Data.data.platform.rendersEngineURL + "renders/run", method:"GET", headers: Data.data.headers });
            loader.start();
        }
        private addRenderData(){
            if(!this.rendersCheck[this.tempRender.projectID]) this.rendersCheck[this.tempRender.projectID] = {};
            this.rendersCheck[this.tempRender.projectID][this.tempRender.id] = new RenderCheck(this.tempRender, this.updateRenderStatus.bind(this));

            let options:any = {
                id:this.tempRender.id + "Queue",
                html:Utils.replace(Data.data.loc["vg-renderQueue"], "{{PROJECT_NAME}}", this.getProjectById(this.tempRender.projectID).name),
                closeOnClick:true
            };

            //@ts-ignore
            this.$snotify.success("", options);
        }
        private checkRenders(){
            let a:number = Data.data.vg.projects.length;
            for(let i=0; i<a; i++){
                let project:any = Data.data.vg.projects[i];

                if(!this.rendersCheck[project.id]) this.rendersCheck[project.id] = {};

                let b:number = project.renders.length;
                for(let j=0; j<b; j++){
                    let render:any = project.renders[j];
                    if(render.status == "queue" || render.status == "rendering" || render.status == "ready"){
                        if(!this.rendersCheck[project.id][render.id]) this.rendersCheck[project.id][render.id] = new RenderCheck(render, this.updateRenderStatus.bind(this));
                    }
                }
            }
        }
        private updateRenderStatus(render:any){
            //console.log("updateRenderStatus");
            //console.log(render.id + " : " + render.status);

            this.notifyRender(render);
        }
        private downloadRender(render:any){
            window.open(Data.data.platform.digitalEngineFileURL + "virtualglass/" + Data.data.user.id + '/' + render.projectID + '/' + render.id + '.png', "_blank");
        }
        private deleteRender(render:any){
            this.tempRender = render;

            let data:any = Utils.copy(Data.data.config.dialogs.vgConfirmDeleteRender);
            data.buttons.yes.callback = this.confirmDeleteRender.bind(this);

            $(window).trigger("DIALOG_OPEN", data);
        }
        private confirmDeleteRender(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.renderDeleteComplete.bind(this), this.renderDeleteError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + this.project.id + "/delete-render/" + this.tempRender.id, method:"DELETE", contentType:"application/json", data:JSON.stringify(this.tempRender), headers: Data.data.headers });
            this.fileLoader.start();
        }
        private renderDeleteError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private renderDeleteComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let index:number = 0;
            for(let i=0; i<this.project.renders.length; i++){
                if(this.project.renders[i].id == this.tempRender.id){
                    index = i;
                    break;
                }
            }

            this.project.renders.splice(index, 1);
            if(this.project.renders.length == 0) this.closeRenders();
            $(window).trigger("CHECK_RENDERS_QUOTA");
        }
        private notifyRender(render:any){
            let content:string;

            if(render.status == "rendering") content = Data.data.loc["vg-renderRendering"];
            else if(render.status == "complete") content = Data.data.loc["vg-renderComplete"];
            else if(render.status == "error") content = Data.data.loc["vg-renderError"];

            let options:any = {
                id:render.id,
                render:render,
                html:Utils.replace(content, "{{PROJECT_NAME}}", this.getProjectById(render.projectID).name),
                closeOnClick:true
            };

            options.html = options.html;

            //@ts-ignore
            if(render.status == "complete"){
                //@ts-ignore
                this.$snotify.remove(render.id + "Queue", true);
                //@ts-ignore
                this.$snotify.remove(render.id + "Rendering", true);


                options.id = options.id + "Complete";
                options.closeOnClick = false;
                options.timeout = 12000,
                options.buttons = [
                    { text: 'Open', action: this.clickOK.bind(this), bold: true },
                    { text: 'Close', action: this.clickClose.bind(this), bold: false }
                ]

                options.html = Utils.replace(options.html, "{{IMAGE_SOURCE}}", Data.data.platform.digitalEngineFileURL + 'virtualglass/' + Data.data.user.id + '/' + render.projectID + '/' + render.id + '.png');
                //@ts-ignore
                this.$snotify.success("", options);
            }else if(render.status == "rendering"){
                //@ts-ignore
                this.$snotify.remove(render.id + "Queue", true);


                options.id = options.id + "Rendering";
                //@ts-ignore
                this.$snotify.info("", options);
            }else if(render.status == "error"){
                //@ts-ignore
                this.$snotify.remove(render.id + "Queue", true);
                //@ts-ignore
                this.$snotify.remove(render.id + "Rendering", true);


                options.id = options.id + "Error";
                //@ts-ignore
                this.$snotify.error("", options);
            }
        }
        private clickOK(snotify:any){
            this.downloadRender(snotify.config.render);
            //@ts-ignore
            this.$snotify.remove(snotify.config.render.id + "Complete", true);
        }
        private clickClose(snotify:any){
            //@ts-ignore
            this.$snotify.remove(snotify.config.render.id + "Complete", true);
        }
        private getProjectById(id:string){
            for(let i=0; i<Data.data.vg.projects.length; i++){
                if(Data.data.vg.projects[i].id == id) return Data.data.vg.projects[i];
            }
        }
        private getProductData(item:any){
            let str:string;
            for(str in Data.data.products){
                let product:any = Data.data.products[str];
                if (product.glassDrawingVG.indexOf(item.glassDrawing) != -1 && product.baseProducts.indexOf(item.baseProduct) != -1){
                    return product;
                }
            }

            return null;
        }
        private openItem(itemData:any, bp:any){
            this.tempItem = itemData;
            this.tempBP = bp;

            let data: any = Utils.copy(Data.data.config.dialogs.vgAOpenProductPage);
            data.buttons.yes.callback = this.openProductPage.bind(this);

            $(window).trigger("DIALOG_OPEN", data);
        }
        private openProductPage(){
            this.tempItem.bpIndex = this.tempItem.baseProducts.indexOf(this.tempBP);

            Data.data.VGMode = false;
            Data.data.app = "catalog";
            Data.data.section = "home";
            Data.data.view = "products";
            $(window).trigger("OPEN_PRODUCT", this.tempItem);
        }
    }
