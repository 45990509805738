
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";
    import vuescroll from "vuescroll";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import VGObject from "@/ts/player/modules/VGObject";
import Loader from "@/ts/application/Loader";
import { renderableTextureFormatToIndex } from "babylonjs/Engines/WebGPU/webgpuTextureHelper";

    @Component({
        components: {
            vuescroll
        },
        props:{
            section:String
        }
    })
    export default class Labels extends Generic {
        private fileInput:any;
        private fileLoader:Loader;
        private tempImage:any;
        private modelData:any = null;
        private userAction:boolean = false;
        private capsulesEnabled:boolean = false;
        private minPos:number = 0;
        private maxHeight:number = 0;


        private mounted() {
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            if(model) this.modelData = model.getProductData();

            let meta:any = this.getMetaFromBaseProduct(Data.data.vg.itemData.baseProduct);

            //console.log(this.modelData);

            if (meta.labelMaxHeight && meta.labelMaxHeight != 0 && meta.labelMaxHeight < this.modelData.labelHeight){
                this.minPos = this.modelData.labelHeight - meta.labelMaxHeight;
                this.maxHeight = meta.labelMaxHeight;
            }else{
                this.minPos = 0;
                this.maxHeight = this.modelData[this.section + "MaxHeight"];
            }
        }
        private destroyed(){

        }
        private isSectionDisabled(){
            if(this.section != 'capsuleBottle' && this.section != 'capsuleJar'){
                this.capsulesEnabled = true;
                return false;
            }


            if(Data.data.vg.itemData){
                if(Data.data.vg.capsules[Data.data.vg.itemData.glassDrawing] && Data.data.vg.capsules[Data.data.vg.itemData.glassDrawing].length > 0){
                    this.capsulesEnabled = true;
                    return false;

                }else if(Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing].capsules && Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing].capsules.length > 0){
                    this.capsulesEnabled = true;
                    return false;
                }
            }

            this.capsulesEnabled = false;
            return true;
        }
        private close(){
            this.$emit("close");
        }
        private selectImage(element:any ){
            if(this.section == "label" || this.section == "backLabel" || this.section == "decor"){
                Data.data.vg.itemData[this.section + "ID"] = element.id;
                //Data.data.vg.itemData[this.section].image = image.image;

                //console.log(Data.data.config.VGAssetsPath + "images/labels/" + this.section + "/" + image.image);

                if (this.section == "label" || this.section == "backLabel"){
                    if (this.section == "label") window.PLAYER.focusOnModel(Data.data.vg.item.id, 1, "front");
                    else if (this.section == "backLabel") window.PLAYER.focusOnModel(Data.data.vg.item.id, 1, "back");
                }

                this.userAction = true;

                let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
                model.setLabelImage(this.section, Data.data.platform.digitalEngineFileURL + "virtualglass/assets/labels/" + this.section + "/" + element.user + "/" + element.image, Data.data.vg.itemData[this.section + "Position"], Data.data.vg.itemData[this.section + "Height"], this.labelLoaded.bind(this), this.labelError.bind(this));
                //this.close();
            }else if(this.section == "capsuleBottle" || this.section == "capsuleJar"){
                Data.data.vg.itemData.capsuleID = element.id;
                let model: VGObject = window.PLAYER.getModel(Data.data.vg.item.id);

                if (!Data.data.vg.itemData.capsuleType){
                    if (Data.data.vg.capsules[Data.data.vg.itemData.glassDrawing] && Data.data.vg.capsules[Data.data.vg.itemData.glassDrawing].length > 0){
                        Data.data.vg.itemData.capsuleType = Data.data.vg.capsules[Data.data.vg.itemData.glassDrawing][0];
                    }

                    if (!Data.data.vg.itemData.capsuleType && Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing] && Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing].capsules){
                        if (Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing].capsules.length > 0){
                            Data.data.vg.itemData.capsuleType = Data.data.vg.models.catalog[Data.data.vg.itemData.glassDrawing].capsules[0];
                        }
                    }

                    if (Data.data.vg.itemData.capsuleType){
                        this.setCapsuleType(null, Data.data.vg.itemData.capsuleType);

                        return;
                    }
                }


                model.setCapsule(element.id, Data.data.platform.digitalEngineFileURL + 'virtualglass/assets/labels/' + this.section + '/' +  element.user + '/textures/', Data.data.vg.itemData.capsuleShininess, Data.data.vg.itemData.capsuleType);
            }

            setTimeout(this.saveScreenshot.bind(this), 1000);
        }
        private labelLoaded(){
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            // console.log("");
            // console.log("label height");
            // console.log(model.getLabelHeight(this.section));
            Data.data.vg.itemData[this.section + "Height"] = model.getLabelHeight(this.section);

            let meta: any = this.getMetaFromBaseProduct(Data.data.vg.itemData.baseProduct);
            if (meta.labelMaxHeight && meta.labelMaxHeight != 0 && meta.labelMaxHeight < this.modelData.labelHeight) {

            } else {
                this.maxHeight = this.modelData[this.section + "MaxHeight"];
            }

            this.userAction = false;
        }
        private labelError(){

        }
        private setCapsuleShininess(){
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setCapsuleShininess(Data.data.vg.itemData.capsuleShininess);
        }
        private setCapsuleType(type:string, id:string){
            Data.data.vg.itemData.capsuleType = id;

            if(!Data.data.vg.itemData.capsuleID){
                let str:string;
                for(str in Data.data.vg.labels[this.section]){
                    Data.data.vg.itemData.capsuleID = str;
                    break;
                }
            }

            if(!Data.data.vg.itemData.capsuleID) return;

            let capsuleData:any = Data.data.vg.labels[this.section][Data.data.vg.itemData.capsuleID];

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setCapsule(Data.data.vg.itemData.capsuleID, Data.data.platform.digitalEngineFileURL + 'virtualglass/assets/labels/' + this.section + '/' +  capsuleData.user + '/textures/', Data.data.vg.itemData.capsuleShininess, id);
            setTimeout(this.saveScreenshot.bind(this), 1000);
        }
        private addImage(){
            this.fileInput = $("<input id='labelFile' name='file' type='file' accept='image/jpg, image/jpeg, image/png'>");
            $(this.fileInput).trigger("click");
            $(this.fileInput).on("change", this.fileSelected.bind(this));
        }
        private fileSelected(e:any){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            let file:any = $(this.fileInput).prop('files')[0];
            let extension:string = Utils.getFileExtension(file.name);

            this.tempImage = Utils.copy(Data.data.vg.VGImageModel);
            this.tempImage.id = Utils.generateUID();
            this.tempImage.user = Data.data.user.id;
            this.tempImage.type = this.section;
            this.tempImage.image = this.tempImage.id + "." + extension;
            if(this.section == "capsuleBottle" || this.section == "capsuleJar") this.tempImage.texture = this.tempImage.image;

            this.fileLoader = new Loader(this.saveImageComplete.bind(this), this.saveImageError.bind(this));
            //this.fileLoader.add({ id: "saveImage", url: Data.data.platform.digitalEngineVGURL + "add-label/" + this.tempImage.id, method: "POST", data:JSON.stringify(this.tempImage), headers: Data.data.headers });

            let formData:FormData = new FormData();
            formData.append("file", file, this.tempImage.image);
            if(this.section == "capsuleBottle" || this.section == "capsuleJar") formData.append("textureFile", file, this.tempImage.texture);
            formData.append("data", JSON.stringify(this.tempImage));

            this.fileLoader.add({ id: "saveImage", url: Data.data.platform.digitalEngineVGURL + "add-label/" + this.tempImage.id, method: "POST", contentType:false, data:formData, headers: Data.data.headers });

            this.fileLoader.start();
        }
        private saveImageError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveImageComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.$set(Data.data.vg.labels[this.section], this.tempImage.id, this.tempImage);
        }
        private deleteImage(image:any){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.tempImage = image;

            this.fileLoader = new Loader(this.deleteImageComplete.bind(this), this.saveImageError.bind(this));
            this.fileLoader.add({ id: "deleteImage", url: Data.data.platform.digitalEngineVGURL + "delete-label/" + image.id, method: "DELETE", headers: Data.data.headers });
            this.fileLoader.start();
        }
        private deleteImageComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");


            let removeLabel:boolean = false;
            if(this.section == "capsuleBottle" || this.section == "capsuleJar"){
                if(Data.data.vg.itemData.capsuleID == this.tempImage.id) removeLabel = true;
            }else{
                if(Data.data.vg.itemData[this.section + 'ID'] == this.tempImage.id) removeLabel = true;
            }

            if(removeLabel){
                this.removeLabel();
                this.saveScreenshot();
            }

            this.$delete(Data.data.vg.labels[this.section], this.tempImage.id);
        }


        private removeCapsule(){
            Data.data.vg.itemData.capsuleID = null;
            Data.data.vg.itemData.capsuleShininess = 0.5;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.removeCapsule();

            this.saveScreenshot();
        }
        private removeLabel(){
            if(this.section == "label" || this.section == "backLabel" || this.section == "decor"){
                Data.data.vg.itemData[this.section + "ID"] = null;
                Data.data.vg.itemData[this.section + "Position"] = 0;
                Data.data.vg.itemData[this.section + "Height"] = 0;
                //if(key == "decor") Data.data.vg.itemData[this.section + "Rotation"] = 0;

                let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
                model.removeLabel(this.section);
            }else if(this.section == "capsuleBottle" || this.section == "capsuleJar"){
                this.removeCapsule();
            }

            this.saveScreenshot();
        }


        private isRemoveButtonVisible(){
            if(this.section == "label" || this.section == "backLabel" || this.section == "decor"){
                if(Data.data.vg.itemData[this.section + 'ID']) return true;
            }else if(this.section == "capsuleBottle" || this.section == "capsuleJar"){
                if(Data.data.vg.itemData.capsuleID) return true;
            }

            return false;
        }
        private setLabelPosition(key:string){
            if(this.userAction) return;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setLabelPosition(key, Data.data.vg.itemData[key + "Position"]);

            let height:number = model.getLabelHeight(key);
            Data.data.vg.itemData[key + "Height"] = height;

            this.delaySaveScreenShot();
        }
        private setLabelHeight(key:string){
            if(this.userAction) return;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setLabelHeight(key, Data.data.vg.itemData[key + "Height"]);

            let position:number = model.getLabelPosition(key);
            Data.data.vg.itemData[key + "Position"] = position;

            this.delaySaveScreenShot();
        }
        private delaySaveScreenShot(){
            setTimeout(this.saveScreenshot.bind(this), 200);
        }
        private saveScreenshot(){
            //$(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);
        }
        private areLabelsVisible(){
            if(this.section == "capsuleBottle" || this.section == "capsuleJar"){
                if(Data.data.vg.itemData.capsuleType){
                    let capsuleData:any = Data.data.vg.models.capsules[Data.data.vg.itemData.capsuleType];

                    if(capsuleData && capsuleData.data && capsuleData.data.meshes){
                        let hasCustom:boolean = false;
                        let str:string;
                        for(str in capsuleData.data.meshes){
                            if(capsuleData.data.meshes[str] == "custom_image") hasCustom = true;
                        }

                        return hasCustom;
                    }
                }
            }

            return true;
        }
    }
