import Utils from "../utils/Utils";
import BabylonManager from "../managers/BabylonManager";
import PixiManager from "../managers/PixiManager";
import Player from "../Player";
import $ from "jquery";
import * as BABYLON from "babylonjs";


export default class Application{
    public player:Player;
    public babylon: BabylonManager;
    public pixi: PixiManager;
    public positionPlane: any;
    public positionPlaneMaterial: any;
    public positionPlaneMaterialRed: any;
    public sceneBounds: any;


    constructor(player:Player){
        $(document).on("keypress", function (e:any) {
            if(e.keyCode == 32){
                try{
                    console.clear();
                }catch(e){

                }
            }
        });

        this.player = player;

        this.init();
    }
    init(){
        this.babylon = new BabylonManager(this);
        this.pixi = new PixiManager(this);


        this.positionPlane = BABYLON.MeshBuilder.CreatePlane("positionPlane", { height:150, width:150, sideOrientation:BABYLON.Mesh.DOUBLESIDE});


        this.positionPlaneMaterial = new BABYLON.StandardMaterial("positionPlaneMaterial", this.babylon.scene);
        this.positionPlaneMaterial.diffuseTexture = new BABYLON.Texture(this.player.config.assetsPath + "images/positionHelper.png", this.babylon.scene);
        this.positionPlaneMaterial.diffuseTexture.hasAlpha = true;

        this.positionPlaneMaterialRed = new BABYLON.StandardMaterial("positionPlaneMaterialrED", this.babylon.scene);
        this.positionPlaneMaterialRed.diffuseTexture = new BABYLON.Texture(this.player.config.assetsPath + "images/positionHelperRed.png", this.babylon.scene);
        this.positionPlaneMaterialRed.diffuseTexture.hasAlpha = true;



        this.positionPlane.rotation.x = Utils.degreesToRadians(90);
        this.positionPlane.rotation.z = Utils.degreesToRadians(90);
        this.positionPlane.visibility = 0;
        this.positionPlane.material = this.positionPlaneMaterial;

        var points = [
            new BABYLON.Vector3(-this.player.config.sceneBounds.width / 2, this.player.config.sceneBounds.depth, 0),
            new BABYLON.Vector3(this.player.config.sceneBounds.width / 2, this.player.config.sceneBounds.depth, 0),
            new BABYLON.Vector3(this.player.config.sceneBounds.width / 2, 0, 0),
            new BABYLON.Vector3(-this.player.config.sceneBounds.width / 2, 0, 0),
            new BABYLON.Vector3(-this.player.config.sceneBounds.width / 2, this.player.config.sceneBounds.depth, 0)
        ];

        //@ts-ignore
        this.sceneBounds = BABYLON.MeshBuilder.CreateDashedLines("sceneBounds", { points:points, color:BABYLON.Color3.FromHexString("#009B9B") });
        this.sceneBounds.material = new BABYLON.StandardMaterial("sceneBoundsMaterial", this.babylon.scene);
        this.sceneBounds.material.diffuseColor = BABYLON.Color3.FromHexString("#009B9B");
        this.sceneBounds.visibility = 0;
        this.sceneBounds.rotation.x = Utils.degreesToRadians(90);
    }
}