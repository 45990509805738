
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import { Component } from 'vue-property-decorator';
    import vuescroll from 'vuescroll';
    import Footer from './Footer.vue';
    import $ from 'jquery';
    import Utils from "@/ts/utils/Utils";


    @Component({
        components: {
            vuescroll,
            Footer
        },
        props:{

        }
    })
    export default class Products extends Generic {
        private numProducts:number = 0;
        private fakeCards:number = 5;

        private mounted(){

        }
        private destroyed(){

        }
        private getProducts(){
            let products:any[] = [];

            if(!Data.data.filteredProducts) return;

            let a:number = Data.data.filteredProducts.length;
            for(let i=0; i<a; i++){
                products.push(Data.data.filteredProducts[i]);

                if(i >= Data.data.config.productsByPage * Data.data.catalogPage) break;
            }

            this.numProducts = products.length;
            // this.fakeCards = 0;

            // if(this.numProducts > 0){
            //     let cardHeight:number = $($(".Product")[0]).height();
            // }

            return products;
        }
        private openProduct(product:any, bpIndex:any=null){
            product.infoDisplay = false;

            if(bpIndex != null) this.$set(product, "bpIndex", bpIndex);

            if (Data.data.platform.useOldVirtualGlass && Data.data.VGMode == true){
                $(window).trigger("OPEN_OLD_VIRTUALGLASS", product);
                return;
            }

            if(Data.data.VGMode == true){
                this.selectForVirtualGlass(product);
                return;
            }

            //Data.data.userPath.push({ product:product, section:"product" });
            //Data.data.section = "product";
            $(window).trigger("OPEN_PRODUCT", product);
            //Data.data.product = product;
        }
        public switchProdPanel(id:string) {
            let currentCard:string = '#card-' + id;
            $(currentCard).toggleClass('active');
            $(currentCard + ' i').toggleClass('down');
            $('#' + id).toggleClass('minified');
        }
        private selectForVirtualGlass(product:any){
            //$(window).trigger("ITEM_SELECTED_FROM_CATALOG", product);
            if (Data.data.app == "catalog") $(window).trigger("CREATE_PROJECT_FROM_ITEM_SELECTED_FROM_CATALOG", product);
            else $(window).trigger("ITEM_SELECTED_FROM_CATALOG", product);

        }
        private sortProducts(data:any) {
            if (data.ascendant == true){
                return function (a, b) {
                    //a[fields[0]] - b[fields[0]] || a[fields[1]] - b[fields[1]]
                    return a[data.fields[0]] > b[data.fields[0]];
                }
            }
            return function (a, b) {
                //b[fields[0]] - a[fields[0]] || b[fields[1]] - a[fields[1]]
                return a[data.fields[0]] < b[data.fields[0]];
            }
        }
        private toggleComparator(product:any){
            if(!Data.data.comparator[product.glassDrawing]){
                this.$set(Data.data.comparator, product.glassDrawing, { baseProduct:product.baseProducts[product.bpIndex], index:Object.keys(Data.data.comparator).length });
            }else{
                this.$delete(Data.data.comparator, product.glassDrawing);
            }
        }
        private openInVirtualGlass(product:any){
            if(this.maintenanceMode()){
                return;
            }

            if(Data.data.platform.useOldVirtualGlass){
                $(window).trigger("OPEN_OLD_VIRTUALGLASS", product);
                return;
            }


            //ICI ajouter dans le nouveau VG
            //$(window).trigger("ITEM_SELECTED_FROM_CATALOG", product);
            if (Data.data.app == "catalog") $(window).trigger("CREATE_PROJECT_FROM_ITEM_SELECTED_FROM_CATALOG", product);
            else $(window).trigger("ITEM_SELECTED_FROM_CATALOG", product);
        }
        private getPLSPicture(product:any){
            for(let i=0; i<product.baseProducts.length; i++){
                let bp: any = Data.data.baseProducts[product.baseProducts[i]];

                if (!bp.pls) continue;

                return bp.images[1];
            }
            //return Data.data.baseProducts[product.baseProducts[product.colorIndex]].images[1];
        }
    }
