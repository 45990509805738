
    import Generic from "../../Generic.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import Loader from "@/ts/application/Loader";

    @Component({
        components: {

        }
    })
    export default class Home extends Generic {
        private fileLoader:Loader;
        private tempProject:any;

        private mounted() {

        }
        private createProject(){
            $(window).trigger("SHOW_OVERLAY");

            this.tempProject = Utils.copy(Data.data.vg.VGProjectModel);
            this.tempProject.id = Utils.generateUID();
            this.tempProject.name = Utils.generateUID();

            this.fileLoader = new Loader(this.saveProjectComplete.bind(this), this.saveProjectError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "add-project/" + Data.data.userID + "/" + this.tempProject.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempProject), headers:Data.data.headers });
            this.fileLoader.start();
        }
        private saveProjectError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveProjectComplete(){
            $(window).trigger("HIDE_OVERLAY");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");
            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            Data.data.vg.projects.unshift(this.tempProject);
        }
    }
