import Data from "../application/Data";

export default class Utils{
    constructor(){

    }
    static degreesToRadians(degrees:number){
        var pi = Math.PI;
        return degrees * (pi/180);
    }
    static radiansToDegrees(radians: number){
        var pi = Math.PI;
        return radians * (180/pi);
    }
    static fitRectIntoBounds(rect: any, bounds: any):number{
        /*var ratio = Math.min(bounds.width / rect.width, bounds.height / rect.height);

        return { width:rect.width * ratio, height: rect.height * ratio };*/


        let scale:number = 1;
        let outputWidth;
        let outputHeight;
        let outputX;
        let outputY;
        if (rect.width == 0 && rect.height == 0) {
            // scale = Infinity;
            outputWidth = 0;
            outputHeight = 0;
            outputX = bounds.width / 2;
            outputY = bounds.height / 2;
        } else if (bounds.width * rect.height > bounds.height * rect.width) {
            scale = bounds.height / rect.height;
            outputWidth = rect.width * bounds.height / rect.height;
            outputHeight = bounds.height;
            outputX = (bounds.width - outputWidth) / 2;
            outputY = 0;
        } else {
            scale = bounds.width / rect.width;
            outputWidth = bounds.width;
            outputHeight = rect.height * bounds.width / rect.width;
            outputX = 0;
            outputY = (bounds.height - outputHeight) / 2;
        }

        return scale;
    }
    static copy(data:any):any{
        return JSON.parse(JSON.stringify(data));
    }
    static generateUID(lengh:number=10){
        let uid:string = "";
        let chars:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < lengh; i++) {
            uid += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return uid;
    }
}