
    import $ from "jquery";
    import Generic from "../../Generic.vue";
    import Data from "@/ts/application/Data";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import Loader from "@/ts/application/Loader";

    @Component({
        components: {

        },
        props:{
            value:String
        }
    })
    export default class VGFolders extends Generic {
        private folder:string = null;
        private folderDialog:boolean = false;
        private folderData:any = null;
        private folderMode:string = null;
        private fileLoader:Loader = null;

        private mounted(){
            //@ts-ignore
            this.folder = this.value;
        }

        private setFolder(id:string = null){
            this.$set(this, "folder", id);

            this.$emit("select", id);
        }
        private addFolder(){
            this.folderData = { folderID:Utils.generateUID(), name:"" };

            this.folderMode = "add";

            this.folderDialog = true;
        }
        private saveFolder(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.saveFolderComplete.bind(this), this.saveFolderError.bind(this));
            if(this.folderMode == "add") this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "add-folder/" + Data.data.userID + "/" + this.folderData.folderID, method:"POST", contentType:"application/json", data:JSON.stringify(this.folderData), headers:Data.data.headers });
            else if(this.folderMode == "edit") this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "update-folder/" + Data.data.userID + "/" + this.folderData.folderID, method:"PUT", contentType:"application/json", data:JSON.stringify(this.folderData), headers:Data.data.headers });
            this.fileLoader.start();
        }
        private saveFolderError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.folderDialog = false;

            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveFolderComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");
            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            if(this.folderMode == "add") this.$set(Data.data.vg.projectsFolders, this.folderData.folderID, this.folderData.name);
            else if(this.folderMode == "edit") this.$set(Data.data.vg.projectsFolders, this.folderData.folderID, this.folderData.name);

            //this.$forceUpdate();

            this.folderDialog = false;
        }
        private editFolder(id:string){
            this.folderData = { folderID:id, name:Data.data.vg.projectsFolders[id] };

            this.folderMode = "edit";

            this.folderDialog = true;
        }
        private deleteFolder(id:string){
            this.folderData = { folderID:id, name:Data.data.vg.projectsFolders[id] };

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.deleteFolderComplete.bind(this), this.deleteFolderError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "delete-folder/" + Data.data.userID + "/" + this.folderData.folderID, method:"DELETE", contentType:"application/json", headers:Data.data.headers });
            this.fileLoader.start();
        }
        private deleteFolderError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.folderDialog = false;

            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private deleteFolderComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");
            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            this.$delete(Data.data.vg.projectsFolders, this.folderData.folderID);

            //this.$forceUpdate();

            this.folderDialog = false;
        }
    }
