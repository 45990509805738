import * as PIXI from "pixi.js";
import Data from "../application/Data";
import PixiManager from '../managers/PixiManager';
import Utils from '../utils/Utils';
import VGObject from './VGObject';



export default class Model{
    public uid:string;
    public VGObject:VGObject;
    public pixiManager:PixiManager;

    public color:string = "white";
    public mesh:any;
    public meshInterior:any;
    public material:any;
    public materialInterior:any;

    public box:any;
    public texture:any;

    public pixiContainer:any;
    public pixiTopTSprite:any;
    public pixiBottomSprite:any;
    public pixiContentSprite:any;

    public plane:any;


    constructor(VGObject: VGObject){
        this.VGObject = VGObject;
        this.pixiManager = this.VGObject.babylon.application.pixi;
        this.uid = Utils.generateUID();
    }
    public init(){
        this.material = new BABYLON.PBRMaterial(this.VGObject.mdata.id + "_" + this.uid + "_MATERIAL", this.VGObject.babylon.scene);
        //this.externalMaterial.reflectionTexture = probe.cubeTexture;
        this.material.alpha = 0.8;
        this.material.directIntensity = 0.0;
        this.material.environmentIntensity = 1;
        //this.material.microSurface = 1;
        this.material.subSurface.isRefractionEnabled = true;
        this.material.subSurface.indexOfRefraction = 0;
        //this.material.usePhysicalLightFalloff = false;
        //this.material.linkRefractionWithTransparency = false;
        this.material.subSurface.isTranslucencyEnabled = true;
        this.material.forceDepthWrite = true;



        this.materialInterior = new BABYLON.PBRMaterial(this.VGObject.mdata.id + "_" + this.uid + "_MATERIAL_INTERIOR", this.VGObject.babylon.scene);
        //this.externalMaterial.reflectionTexture = probe.cubeTexture;
        this.materialInterior.alpha = 0.8;
        this.materialInterior.directIntensity = 0.0;
        this.materialInterior.environmentIntensity = 1;
        //this.materialInterior.microSurface = 1;
        this.materialInterior.subSurface.isRefractionEnabled = true;
        this.materialInterior.subSurface.indexOfRefraction = 0;
        //this.materialInterior.usePhysicalLightFalloff = false;
        //this.materialInterior.linkRefractionWithTransparency = false;
        this.materialInterior.subSurface.isTranslucencyEnabled = true;
        this.materialInterior.forceDepthWrite = false;

        //this.material.subSurface.isScatteringEnabled = true;


        this.mesh = this.VGObject.modelMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_MODEL");
        this.mesh.parent = this.VGObject.container;
        this.mesh.material = this.material;
        this.mesh.sideOrientation = BABYLON.Mesh.FRONTSIDE;
        this.mesh.alphaIndex = 3;
        this.mesh.scaling.x = 1;
        this.mesh.scaling.z = -1;
        this.mesh.alwaysSelectAsActiveMesh = true;

        this.meshInterior = this.VGObject.modelMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_MODEL_INTERIOR");
        this.meshInterior.parent = this.VGObject.container;
        this.meshInterior.material = this.materialInterior;
        this.meshInterior.sideOrientation = BABYLON.Mesh.BACKSIDE;
        this.meshInterior.alphaIndex = 1;
        this.meshInterior.scaling.x = 1;
        this.meshInterior.scaling.z = -1;
        this.meshInterior.alwaysSelectAsActiveMesh = true;


        this.box = this.VGObject.box = this.mesh.getBoundingInfo();
        // console.log("PRODUCT BOX");
        // console.log(this.box);

        //console.log(this.box.boundingBox.maximum);

        let height: number = this.box.boundingBox.maximum.z;
        if (height < this.box.boundingBox.maximum.y){
            height = this.box.boundingBox.maximum.y;
            //this.mesh.rotattion.x =
            //this.meshInterior.scaling.x = 1;
            // let h:number = this.box.boundingBox.maximum.y;
            // this.box.boundingBox.maximum.y = height;
            // this.box.boundingBox.maximum.z = h;
        }

        this.VGObject.modelData.labelHeight = (height * this.VGObject.babylon.objectsScale) - this.VGObject.modelData.labelBottomPosition;
        this.VGObject.modelData.backLabelHeight = (height * this.VGObject.babylon.objectsScale) - this.VGObject.modelData.labelBottomPosition;

        this.VGObject.modelData.diameter = (this.box.boundingBox.maximum.x * 2) * this.VGObject.babylon.objectsScale;
        this.VGObject.modelData.radius = this.box.boundingBox.maximum.x * this.VGObject.babylon.objectsScale;
        this.VGObject.modelData.height = height * this.VGObject.babylon.objectsScale;
        this.VGObject.modelData.circumference = Math.PI * this.VGObject.modelData.diameter;

        this.generateTexture();
    }
    public sceneChange(){
        //this.internalMaterial.envMap = this.VGObject.threeManager.sceneData.cudeTexture;
       // this.internal2Material.envMap = this.VGObject.threeManager.sceneData.cudeTexture;
    }
    public enable(){
        this.mesh.visibility = 1;
        //this.materialInterior.visibility = 1;
    }
    public disable(){
        this.mesh.visibility = false;
        //this.materialInterior.y = 0;
    }
    public generateTexture(){
        let colorData:any = Data.config.colors[this.color];

        if (!colorData){
            //console.log("Color : " + this.color + " not found in config, setting default color");
            colorData = { hexa: "ffffff", glassOpacity: 0.1, contentOpacity:1 };
        }

        this.mesh.alpha = colorData.glassOpacity;
        this.meshInterior.alpha = colorData.glassOpacity;

        this.material.subSurface.tintColor = BABYLON.Color3.FromHexString("#" + colorData.hexa);
        this.material.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.reflectivityColor = BABYLON.Color3.FromHexString("#" + colorData.hexa);
        this.material.alpha = colorData.glassOpacity;

        this.materialInterior.subSurface.tintColor = BABYLON.Color3.FromHexString("#" + colorData.hexa);
        this.materialInterior.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        this.materialInterior.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        this.materialInterior.reflectivityColor = BABYLON.Color3.FromHexString("#" + colorData.hexa);
        this.materialInterior.alpha = colorData.glassOpacity / 2;

        if(colorData.hexa == "000000"){
            this.material.subSurface.indexOfRefraction = 1.3;
            this.materialInterior.subSurface.indexOfRefraction = 1.3;

            this.material.roughness = this.material.metallic = 0;
            this.materialInterior.roughness = this.materialInterior.metallic = 0;
        }else{
            this.material.subSurface.indexOfRefraction = 0;
            this.materialInterior.subSurface.indexOfRefraction = 0;

            this.material.roughness = this.material.metallic = null;
            this.materialInterior.roughness = this.materialInterior.metallic = null;
        }

        let contentData:any = null;
        if(this.VGObject.content.content){
            contentData = Data.config.contents.bottle[this.VGObject.content.content];
            if (!contentData) contentData = Data.config.contents.jar[this.VGObject.content.content];
        }

        if (contentData) this.material.forceDepthWrite = false;
        else this.material.forceDepthWrite = true;

        if (contentData && contentData.secondaryType == "liquid"){
            if(!this.pixiContainer){
                this.pixiContainer = new PIXI.Container();
                this.pixiManager.stage.addChild(this.pixiContainer);

                this.pixiBottomSprite = new PIXI.Graphics();
                this.pixiContainer.addChild(this.pixiBottomSprite);
                this.pixiBottomSprite.beginFill(0xffffff, 1);
                this.pixiBottomSprite.drawRect(0, 0, 64, 64);
                this.pixiBottomSprite.endFill();

                this.pixiContentSprite = new PIXI.Graphics();
                this.pixiContainer.addChild(this.pixiContentSprite);
                this.pixiContentSprite.beginFill(0xffffff, 1);
                this.pixiContentSprite.drawRect(0, 0, 64, 64);
                this.pixiContentSprite.endFill();
            }

            this.pixiBottomSprite.visible = true;
            this.pixiContentSprite.visible = true;

            var h = 64;
            this.pixiBottomSprite.tint = "0x" + colorData.hexa;
            this.pixiBottomSprite.alpha = colorData.glassOpacity;

            //var contentData = Data.config.contents.bottle[this.VGObject.content.content]; //BOTTLE || JAR

            // console.log("-------------------------");
            // console.log("-------------------------");
            // console.log("-------------------------");
            // console.log(contentData);

            this.pixiContentSprite.tint = "0x" + contentData.data.color;
            this.pixiContentSprite.alpha = Number(contentData.data.opacity);

            this.pixiContentSprite.filters = [];
            if(colorData.contentOpacity < 1){
                var filter = new PIXI.filters.ColorMatrixFilter();
                filter.brightness(colorData.contentOpacity, true);
                this.pixiContentSprite.filters = [filter];
            }

            var imageBase64 =  this.pixiManager.renderer.renderer.plugins.extract.image(this.pixiContainer);
            var t = this;
            imageBase64.onload = function() {
                var canvas = document.createElement('canvas');
                canvas.width = imageBase64.width;
                canvas.height = imageBase64.height;

                var context:any = canvas.getContext('2d');
                context.drawImage(imageBase64, 0, 0);

                var imageData = context.getImageData(0, 0, canvas.width, canvas.height);

                // Now you can access pixel data from imageData.data.
                // It's a one-dimensional array of RGBA values.
                // Here's an example of how to get a pixel's color at (x,y)
                var index = (1 * imageData.width + 1) * 4;
                var red = imageData.data[index];
                var green = imageData.data[index + 1];
                var blue = imageData.data[index + 2];
                var alpha = imageData.data[index + 3];


                /*trace(red);
                trace(green);
                trace(blue);
                trace(alpha);*/
                var hexa = t.rgba2hex("rgba(" + red + "," + green + "," + blue + "," + alpha + ")");

                t.VGObject.content.setLiquidContent(hexa);
            }
        }else if (contentData && contentData.secondaryType == "texture") {

        } else {
            this.VGObject.content.removeContent();
        }
    }
    public rgba2hex(rgb:string){
        let rgbResult:any = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
        return (rgbResult && rgbResult.length === 4) ? "#" +
        ("0" + parseInt(rgbResult[1],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgbResult[2],10).toString(16)).slice(-2) +
        ("0" + parseInt(rgbResult[3],10).toString(16)).slice(-2) : '';
    }
    public frontClicked(){
        //$(window).trigger("MODEL_CLICKED", this.VGObject.mdata.id);
    }
    public setColor(hexa:string, glassOpacity:number){
        this.mesh.alpha = glassOpacity;
        this.meshInterior.alpha = glassOpacity;

        this.material.subSurface.tintColor = BABYLON.Color3.FromHexString("#" + hexa);
        this.material.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.reflectivityColor = BABYLON.Color3.FromHexString("#" + hexa);
        this.material.alpha = glassOpacity;

        this.materialInterior.subSurface.tintColor = BABYLON.Color3.FromHexString("#" + hexa);
        this.materialInterior.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        this.materialInterior.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        this.materialInterior.reflectivityColor = BABYLON.Color3.FromHexString("#" + hexa);
        this.materialInterior.alpha = glassOpacity / 2;

        if (hexa == "000000") {
            this.material.subSurface.indexOfRefraction = 1.3;
            this.materialInterior.subSurface.indexOfRefraction = 1.3;

            this.material.roughness = this.material.metallic = 0;
            this.materialInterior.roughness = this.materialInterior.metallic = 0;
        } else {
            this.material.subSurface.indexOfRefraction = 0;
            this.materialInterior.subSurface.indexOfRefraction = 0;

            this.material.roughness = this.material.metallic = null;
            this.materialInterior.roughness = this.materialInterior.metallic = null;
        }
    }
}