import { render, staticRenderFns } from "./Labels.vue?vue&type=template&id=660ba7f8&"
import script from "./Labels.vue?vue&type=script&lang=ts&"
export * from "./Labels.vue?vue&type=script&lang=ts&"
import style0 from "./Labels.vue?vue&type=style&index=0&id=660ba7f8&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports