import Data from './application/Data';
import InitApp from './application/InitApp';
import InitData from './application/InitData';
import Application from './application/Application';
import Utils from './utils/Utils';
import * as BABYLON from "babylonjs";
import $ from "jquery";


export default class Player{
    public application:any;
    public config:any;

    constructor(config:any){
        $(window).on("APP_DISPOSE", this.dispose.bind(this));

        Data.init(config);

        this.application;
        this.config = config;

        this.init();
    }
    private init(){
        new InitApp(this.initData.bind(this));
    }
    private initData(){
        new InitData(this.start.bind(this));
    }
    private start(){
        this.application = new Application(this);
    }
    private dispose(){
        $(window).off("APP_DISPOSE");
    }





    // SCENE
    public startRender(){
        this.application.babylon.rendering = true;
    }
    public stopRender(){
        this.application.babylon.rendering = false;
    }
    public setScene(id:string){
        this.application.babylon.setScene(id);
    }
    public isModeloaded(id:string){
        if(Data.models[id]) return true;

        return false;
    }

    // MODELS
    public loadObjectModel(data: any, onComplete: any, onError: any){
        this.application.babylon.loadObjectModel(data, onComplete, onError);
    }
    public loadCapsule(data: any, onComplete: any, onError: any) {
        this.application.babylon.loadCapsule(data, onComplete, onError);
    }
    public loadModel(data:any, onComplete:any, onError:any){
        this.application.babylon.loadModel(data, onComplete, onError);
    }
    public getModel(id:string){
        return this.application.babylon.models[id];
    }
    public getModelWithSamePID(pid:string, id:string){
        var tab = [];
        for(var str in this.application.babylon.models){
            var model = this.application.babylon.models[str];

            if(model.mdata.itemID == pid){
                if(id){
                    if (model.mdata.id == id){

                    }else{
                        tab.push(model);
                    }
                }else{
                    tab.push(model);
                }
            }
        }

        return tab;
    }
    public unloadModel(id:string){
        this.application.babylon.unloadModel(id);
    }
    public hideAllModels(){
        for(var str in this.application.babylon.models){
            var model = this.application.babylon.models[str];
            model.hide();
        }
    }
    public focusOnModel(id:string, duration:number=1, direction:string="front"){
        var model = this.getModel(id);

        if(model){
            let z: number = 450;

            if (model.model && model.model.box){
                let height: number = model.model.box.boundingBox.maximum.y;
                if (height < model.model.box.boundingBox.maximum.z) height = model.model.box.boundingBox.maximum.z;

                let h:number = 0.33;
                let maxh:number = 0.75;

                if (height > h){
                    let pr: number = height / maxh;
                    z += pr * 600;
                    //console.log(pr + " : " + z);
                }

                if (direction == "back") z *= -1;

                this.animateCameraToPosition(new BABYLON.Vector3(model.container.position.x, (height / 2) * 1000, model.container.position.z - z));
                this.animateCameraTargetToPosition(new BABYLON.Vector3(model.container.position.x, (height / 2) * 1000, model.container.position.z));

                return;
            }
            this.animateCameraToPosition(new BABYLON.Vector3(model.container.position.x, 100, model.container.position.z - z));
            this.animateCameraTargetToPosition(new BABYLON.Vector3(model.container.position.x, 100, model.container.position.z));


            //TweenMax.to(this.application.babylon.camera.position, duration, { x:model.container.position.x, y:100, z:-300, onUpdate:this.updateCameraPosition.bind(this) });
            //TweenMax.to(this.application.babylon.camera.target, duration, { x:model.container.position.x, y:100, z:0 });
        }
    }
    public focusOnScene(duration:number=1){
        this.animateCameraToPosition(new BABYLON.Vector3(0, 100, -400));
        this.animateCameraTargetToPosition(new BABYLON.Vector3(0, 100, 0));

        //TweenMax.to(this.application.babylon.camera.position, duration, { x:0, y:100, z:-500, onUpdate:this.updateCameraPosition.bind(this) });
        //TweenMax.to(this.application.babylon.camera.target, duration, { x:0, y:100, z:0 });
    }
    public focusOnZero(duration: number = 1){
        this.animateCameraToPosition(new BABYLON.Vector3(0, 0, -150));
        this.animateCameraTargetToPosition(new BABYLON.Vector3(0, 0, 0));
    }
    public updateCameraPosition(){
        var pos:any = this.application.babylon.camera.position;
        this.application.babylon.camera.setPosition(new BABYLON.Vector3(pos.x, pos.y, pos.z));
    }
    public focusOnPositions(duration:number=1){
        this.animateCameraToPosition(new BABYLON.Vector3(0, 600, -300));
        this.animateCameraTargetToPosition(new BABYLON.Vector3(0, 0, 200));

        //TweenMax.to(this.application.babylon.camera.position, duration, { x:0, y:100, z:-500, onUpdate:this.updateCameraPosition.bind(this) });
        //TweenMax.to(this.application.babylon.camera.target, duration, { x:0, y:100, z:0 });
    }





    public takeScreenShot(resolution:number, callback:any, id:string){
        this.application.babylon.takeScreenShot(resolution, callback, id);
    }
    public animateCameraTargetToPosition(newPos:any) {
        var ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);

        var aable1: any = BABYLON.Animation.CreateAndStartAnimation('at5', this.application.babylon.camera, 'target', 60, 60, this.application.babylon.camera.target, newPos, 0, ease);
        aable1.disposeOnEnd = true;
    }
    public animateCameraToPosition(newPos:any) {
        var ease = new BABYLON.CubicEase();
        ease.setEasingMode(BABYLON.EasingFunction.EASINGMODE_EASEOUT);
        var aable2: any = BABYLON.Animation.CreateAndStartAnimation('at4', this.application.babylon.camera, 'position', 60, 60, this.application.babylon.camera.position, newPos, 0, ease);
        aable2.disposeOnEnd = true;
    }
    public showPositionHelper(){
        this.application.positionPlane.visibility = 1;
    }
    public hidePositionHelper(){
        this.application.positionPlane.visibility = 0;
    }
    public setPositionHelperColorToGreen(){
        this.application.positionPlane.material = this.application.positionPlaneMaterial;
        this.application.positionPlane.scaling.x = 1;
        this.application.positionPlane.scaling.y = 1;
    }
    public setPositionHelperColorToRed(){
        this.application.positionPlane.material = this.application.positionPlaneMaterialRed;
        this.application.positionPlane.scaling.x = 0.6;
        this.application.positionPlane.scaling.y = 0.6;
    }
    public showSceneBounds(){
        this.application.sceneBounds.visibility = 1;
    }
    public hideSceneBounds(){
        this.application.sceneBounds.visibility = 0;
    }
}