import { convolutionPixelShader } from "babylonjs/Shaders/convolution.fragment";
import Vue from "vue";
import Data from "../application/Data";
import DataUtils from "./DataUtils";
import Utils from "./Utils";

export default class FiltersUtils {
    static createFilters(view:string){
        let data:any = {};

        //FILTERS
        if (Data.data.config.views[view].filters){
            Data.data.config.views[view].filters.filter((filter: string) => {
                let filterData: any;

                if(Data.data.oldDataMode == true){
                    if (filter == "markets" || filter == "internationalMarkets") {
                        filterData = Utils.copy(Data.data.markets.international);

                        if (filter == "markets") {
                            filterData = Utils.copy(Data.data.markets[Data.data.country]);
                        }
                    } else {
                        filterData = Utils.copy(Data.data[filter]);
                    }
                }else{
                    filterData = Utils.copy(Data.data[filter]);
                }

                data[filter] = filterData;
            })
        }

        //APPLIED FILTERS
        if (Data.data.config.views[view].appliedFilters) {
            let filter:string;
            for (filter in Data.data.config.views[view].appliedFilters){
                let filterData: any;

                if (Data.data.oldDataMode == true) {
                    if (filter == "markets" || filter == "internationalMarkets") {
                        filterData = Utils.copy(Data.data.markets.international);

                        if (filter == "markets") {
                            filterData = Utils.copy(Data.data.markets[Data.data.country]);
                        }
                    } else {
                        filterData = Utils.copy(Data.data[filter]);
                    }
                }else{
                    filterData = Utils.copy(Data.data[filter]);
                }


                let filterConfig: any = Data.data.config.filters[filter];
                if (!filterConfig) {
                    // console.log("");
                    // console.log("---------------------------------");
                    // console.log("/!\\ Could not find config data for filter " + filter);
                    // console.log("---------------------------------");
                    // console.log("");
                } else {
                    let appliedFilterData: any = Data.data.config.views[view].appliedFilters[filter];

                    //TREE VALUES
                    if (filterConfig.type == "treeValues") {
                        let appliedFilterValues: any = appliedFilterData.values;

                        if (filter == "markets" && Data.data.oldDataMode == true){
                            appliedFilterValues = appliedFilterData["values-" + Data.data.country];
                        }


                        let a: number = appliedFilterValues.length;
                        for(let i=0; i<a; i++){
                            let b = filterData.length;
                            for(var j=0; j<b; j++){
                                if (appliedFilterValues[i].id == filterData[j].id){
                                    filterData[j].selected = true;
                                }
                            }

                            if (appliedFilterValues[i].sub){
                                let b = appliedFilterValues[i].sub.length;
                                for (var j = 0; j < b; j++) {
                                    let c:number = filterData.length;
                                    for (var k = 0; k < c; k++) {
                                        if (filterData[k].sub){
                                            let d: number = filterData[k].sub.length;
                                            for(let l=0; l<d; l++){
                                                if (appliedFilterValues[i].sub[j].id == filterData[k].sub[l].id){
                                                    filterData[k].sub[l].selected = true;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    } else if (filterConfig.type == "array"){
                        let appliedFilterValues: any = appliedFilterData.values;

                        let a: number = filterData.length;
                        for(let i=0; i<a; i++){
                            if (appliedFilterValues.indexOf(filterData[i].id) != -1) filterData[i].selected = true;
                        }
                    } else if (filterConfig.type == "value"){
                        filterData = appliedFilterData.value;
                    } else if (filterConfig.type == "multipleValues") {
                        filterData.selected = [];
                    } else if (filterConfig.type == "numberRange") {

                    } else if (filterConfig.type == "datesRange") {

                    }
                }

                data[filter] = filterData;
            }
        }


        return data;
    }
    static setFilters(filter:string=null){
        Data.data.filteredProducts = null;
        Data.data.catalogPage = 1;

        //SEARCH
        if (Data.data.catalogSearch && Data.data.catalogSearch.length >= 3 && !Data.data.catalogSearchWithFilters){
            Data.data.filteredProducts = FiltersUtils.getSearchProducts(Data.data.catalogSearch);
            return;
        }


        //FILTERS
        let view: any = Data.data.config.views[Data.data.view];
        if (!view) return;

        if (view.views) {
            return;
        }

        if (!view.filters && !view.appliedFilters) return;


        Data.data.filters = Data.data.savedFilters[Data.data.platform.id + "-" + Data.data.view];
        if (!Data.data.filters) {
            Data.data.filters = Data.data.savedFilters[Data.data.platform.id + "-" + Data.data.view] = this.createFilters(Data.data.view);
        }

        let resetNumberVlues:boolean = false;
        if(filter){
            let filterConfig: any = Data.data.config.filters[filter];
            if (filterConfig && filterConfig.master) {
                resetNumberVlues = true;
            }
        }

        Data.data.masterFilteredProducts = FiltersUtils.getFilteredProducts(Data.data.products, Data.data.filters, true);
        this.updateSlaveFilters(Data.data.filters, Data.data.masterFilteredProducts, resetNumberVlues);
        Data.data.filteredProducts = FiltersUtils.getFilteredProducts(Data.data.masterFilteredProducts, Data.data.filters);

        //SEARCH WITH FILTERS
        if (Data.data.catalogSearch && Data.data.catalogSearch.length >= 3 && Data.data.catalogSearchWithFilters) {
            Data.data.filteredProducts = FiltersUtils.getSearchProducts(Data.data.catalogSearch, Data.data.filteredProducts);
            return;
        }
    }
    static getSearchProducts(search:string, products?:any){
        let results: any = [];

        let field: string = String(search).toLowerCase();

        if (!products) products = Data.data.products;

        let str: string;
        for (str in products) {
            let product: any = products[str];
            product.colorIndex = 0;
            product.infoDisplay = false;


            // if (label.toLowerCase().indexOf(field) != -1) {
            //     if (Data.data.VGMode) {
            //         if (product.virtualGlass) results.push(product);
            //     } else {
            //         results.push(product);
            //     }
            // }

            //NAME
            let label: string = DataUtils.getProductName(product);
            if (label.toLowerCase().indexOf(field) != -1) {
                if(Data.data.VGMode){
                    if (product.virtualGlass) results.push(product);
                }else{
                    results.push(product);
                }
            } else if (product.glassDrawing.toLowerCase().indexOf(field) != -1) {
                if (Data.data.VGMode) {
                    if (product.virtualGlass) results.push(product);
                } else {
                    results.push(product);
                }
            } else {
                for (let i = 0; i < product.baseProducts.length; i++) {
                    if (product.baseProducts[i].indexOf(field) != -1) {
                        if (Data.data.VGMode) {
                            if (product.virtualGlass){
                                results.push(product);
                                product.colorIndex = i;
                                break;
                            }
                        } else {
                            results.push(product);
                            product.colorIndex = i;
                            break;
                        }

                    }
                }
            }
        }

        this.sortProducts(results);

        return results;
    }
    static getFilteredProducts(products:any, filters: any, onlyMasterFilters:boolean=false): any {
        let results: any = [];
        if (onlyMasterFilters) results = {};
        let pool: any = {};

        // console.log("");
        // console.log("---------------------------------");
        // console.log("FILTERS");
        // console.log("---------------------------------");
        // console.log("");


        let numFilters: number = 0;
        let usedFilters: any = {};

        // if (!onlyMasterFilters){
        //     console.log("");
        //     console.log("MASTER FILTERS --------------------------");
        // }


        let str: string;
        for (str in filters) {
            let filterData: any = filters[str];
            let filterConfig: any = Data.data.config.filters[str];

            if (onlyMasterFilters && !filterConfig.master) continue;
            else if (!onlyMasterFilters && filterConfig.master) continue;

            pool[str] = [];

            //if (!onlyMasterFilters) console.log(str);

            if (filterConfig.type == "value") {
                if (filterData == true && !usedFilters[str]) {
                    usedFilters[str] = filterData;
                    numFilters ++;
                    //break;
                }
            }else if(filterConfig.type == "multipleValues") {
                if (filterData.selected.length > 0 && !usedFilters[str]) {
                    usedFilters[str] = filterData.selected;
                    numFilters++;
                    //break;
                }
            }else if(filterConfig.type == "array") {
                let a: number = filterData.length;
                for (let i = 0; i < a; i++) {
                    if (filterData[i].selected == true && !usedFilters[str]) {
                        usedFilters[str] = filterData;
                        numFilters ++;
                        //break;
                    }
                }
            } else if (filterConfig.type == "numberRange") {
                if (filterData.min != filterData.range[0] || filterData.max != filterData.range[1]){
                    usedFilters[str] = filterData;
                    numFilters++;
                }
            }else if (filterConfig.type == "treeValues"){
                let a: number = filterData.length;
                for (let i = 0; i < a; i++) {
                    if (filterData[i].selected == true && !usedFilters[str]) {
                        usedFilters[str] = filterData;
                        numFilters ++;
                        break;
                    }/*else{
                        if (filterData[i].sub){
                            let b: number = filterData[i].sub.length;
                            for (let j = 0; j < b; j++) {
                                if (filterData[i].sub[j].selected == true) {
                                    usedFilters[str] = filterData;
                                    numFilters ++;
                                    break;
                                }
                            }
                        }
                    }*/
                }
            } else if (filterConfig.type == "datesRange") {
                if (filterData.startDate != filterData.dates[0] || filterData.endDate < filterData.dates[filterData.dates.length - 1]){
                    usedFilters[str] = filterData;
                    numFilters++;
                }
            }
        }

        //if (onlyMasterFilters)  console.log(usedFilters);
        for (str in usedFilters) {
            //if(!onlyMasterFilters) console.log(str);
        }



        //console.log(numFilters);

        //IF NO FILTERS SELECTED THEN ALL THE PRODUCTS ARE DISPLAYED
        if (numFilters == 0){
            for (str in products) {
                if (!onlyMasterFilters) results.push(products[str]);
                else results[str] = products[str];
            }

            if (!onlyMasterFilters) {
                this.sortProducts(results);
            }

            return results;
        }



        //GETTING FILTERED PRODUCTS
        let str2: string;

        for (str2 in products) {
            let product: any = products[str2];
            product.colorIndex = 0;
            product.infoDisplay = false;

            let filtersPassed: number = 0;

            for (str in usedFilters) {
                let filterData: any = usedFilters[str];
                let filterConfig: any = Data.data.config.filters[str];
                let field: any = product[filterConfig.field];


                //CHECK FIELD ON BASEPRODUCT
                if (filterConfig.field.indexOf("baseProduct") == 0) {
                    let bpField: string = filterConfig.field.split(".")[1];

                    let a: number = product.baseProducts.length;
                    for(let i=0; i<a; i++){
                        let bp:any = Data.data.baseProducts[product.baseProducts[i]];

                        if(!bp) continue;

                        field = bp[bpField];

                        if (bpField == "plsProduct"){
                            let plsField: string = filterConfig.field.split(".")[2];
                            let pls: any = Data.data.plsProducts[product.baseProducts[i]];

                            if (!pls) continue;

                            for (let j = 0; j < pls.length; j++){
                                field = pls[j][plsField];

                                if (field == null || field == undefined) {
                                    //console.log("/!\\ Could not find field : " + filterConfig.field + " in product : " + product.id + " / " + product.baseProducts[i]);
                                    continue;
                                }

                                //VALUE
                                if (filterConfig.type == "value") {
                                    //console.log(field + " : " + filterConfig.value);
                                    if (this.filterOnValue(field, filterData, filterConfig.value, product, pool[str]) == true) filtersPassed++;

                                    //MULTIPLE VALUES
                                } else if (filterConfig.type == "multipleValues") {
                                    if (this.filterOnMultipleValues(field, filterData, product, pool[str]) == true) filtersPassed++;

                                    //ARRAY
                                } else if (filterConfig.type == "array") {
                                    if (this.filterOnArray(field, filterData, product, pool[str]) == true) filtersPassed++;

                                    //TREE VALUES
                                } else if (filterConfig.type == "treeValues") {
                                    let bpindex: number = -1;
                                    if (filterConfig.field == "baseProduct.colors") bpindex = i;
                                    if (this.filterOnTreeValues(field, filterData, product, pool[str], bpindex) == true) filtersPassed++;

                                    //NUMBER RANGE
                                } else if (filterConfig.type == "numberRange") {
                                    if (this.filterOnNumberRange(str, field, filterData, product, pool[str]) == true) filtersPassed++;

                                    //DATES RANGE
                                } else if (filterConfig.type == "datesRange") {
                                    if (this.filterOnDatesRange(field, filterData, product, pool[str]) == true) filtersPassed++;
                                }
                            }
                        }else{
                            if (field == null || field == undefined) {
                                //console.log("/!\\ Could not find field : " + filterConfig.field + " in product : " + product.id + " / " + product.baseProducts[i]);
                                continue;
                            }

                            //VALUE
                            if (filterConfig.type == "value") {
                                //console.log(field + " : " + filterConfig.value);
                                if (this.filterOnValue(field, filterData, filterConfig.value, product, pool[str]) == true) filtersPassed++;

                                //MULTIPLE VALUES
                            } else if (filterConfig.type == "multipleValues") {
                                if (this.filterOnMultipleValues(field, filterData, product, pool[str]) == true) filtersPassed++;

                                //ARRAY
                            } else if (filterConfig.type == "array") {
                                if (this.filterOnArray(field, filterData, product, pool[str]) == true) filtersPassed++;

                                //TREE VALUES
                            } else if (filterConfig.type == "treeValues") {
                                let bpindex: number = -1;
                                if (filterConfig.field == "baseProduct.colors") bpindex = i;
                                if (this.filterOnTreeValues(field, filterData, product, pool[str], bpindex) == true) filtersPassed++;

                                //NUMBER RANGE
                            } else if (filterConfig.type == "numberRange") {
                                if (this.filterOnNumberRange(str, field, filterData, product, pool[str]) == true) filtersPassed++;

                                //DATES RANGE
                            } else if (filterConfig.type == "datesRange") {
                                if (this.filterOnDatesRange(field, filterData, product, pool[str]) == true) filtersPassed++;
                            }
                        }
                    }

                //CHECK FIELD ON PRODUCT
                }else{
                    if (field == null || field == undefined) {
                        //console.log("/!\\ Could not find field : " + filterConfig.field + " in product : " + product.id);
                        continue;
                    }

                    //VALUE
                    if (filterConfig.type == "value") {
                        if (this.filterOnValue(field, filterData, filterConfig.value, product, pool[str]) == true) filtersPassed ++;

                    //ARRAY
                    } else if (filterConfig.type == "array") {
                        if (this.filterOnArray(field, filterData, product, pool[str]) == true) filtersPassed ++;

                    //MULTIPLE VALUES
                    } else if (filterConfig.type == "multipleValues") {
                        if (this.filterOnMultipleValues(field, filterData, product, pool[str]) == true) filtersPassed++;

                    //TREE VALUES
                    } else if (filterConfig.type == "treeValues") {
                        if (this.filterOnTreeValues(field, filterData, product, pool[str], -1) == true) filtersPassed ++;

                    //NUMBER RANGE
                    }else if (filterConfig.type == "numberRange") {
                        if (this.filterOnNumberRange(str, field, filterData, product, pool[str]) == true) filtersPassed++;

                    //DATES RANGE
                    } else if (filterConfig.type == "datesRange") {
                        if (this.filterOnDatesRange(field, filterData, product, pool[str]) == true) filtersPassed++;
                    }
                }

                //console.log(str);
                //console.log(filterData);
                //console.log(filterConfig);
            }

            if (!onlyMasterFilters && filtersPassed == numFilters){
                results.push(product);
            }else if (onlyMasterFilters && filtersPassed == numFilters) {
                results[str2] = product;
            }
        }

        //console.log(pool);



        if (!onlyMasterFilters){
            this.sortProducts(results);
        }

        return results;
    }
    static updateSlaveFilters(filters: any, products: any, resetNumberVlues:boolean=false){
        let str: string;
        for (str in filters) {
            let filterData: any = filters[str];
            let filterConfig: any = Data.data.config.filters[str];

            if (!filterConfig.slave) continue;

            if (filterConfig.type == "value") {

            } else if (filterConfig.type == "multipleValues") {

            } else if (filterConfig.type == "array") {

            } else if (filterConfig.type == "numberRange") {
               this.updateNumberRange(filters, str, filterConfig, products, resetNumberVlues);
            } else if (filterConfig.type == "treeValues") {
                let a: number = filterData.length;
                for (let i = 0; i < a; i++) {
                    filterData[i].enabled = false;
                    let exists:boolean = false;

                    filterData[i].enabled = this.existsInProductsTreeValues(filterData[i].id, filterConfig, products);

                    let b: number = filterData[i].sub.length;
                    for (let j = 0; j < b; j++) {
                        filterData[i].sub[j].enabled = this.existsInProductsTreeValues(filterData[i].sub[j].id, filterConfig, products);
                        if (!exists && filterData[i].sub[j].enabled) exists = true;
                    }

                    if (exists) filterData[i].enabled = true;
                }
            } else if (filterConfig.type == "datesRange") {

            }
        }
    }
    private static updateNumberRange(filters: any, filter: string, filterConfig: any, products: any, resetNumberVlues:boolean=false){
        let min:number = 1000000;
        let max:number = 0;

        let str: string;
        for (str in products) {
            let product: any = products[str];

            let field: any = this.getProductField(filterConfig.field, product);

            if (!field) continue;

            if (max < field) max = field;
            if (min > field) min = field;
        }

        filters[filter].min = min;
        filters[filter].max = max;

        if (!resetNumberVlues){
            if (filters[filter].range[0] < min) Vue.set(filters[filter].range, 0, min);
            if (filters[filter].range[1] > max) Vue.set(filters[filter].range, 1, max);
        }else{
            Vue.set(filters[filter].range, 0, min);
            Vue.set(filters[filter].range, 1, max);
        }
    }
    private static existsInProductsTreeValues(id: string, filterConfig: any, products: any){
        let str: string;
        for (str in products) {
            let product: any = products[str];

            let field: any = this.getProductField(filterConfig.field, product);

            if (!field) continue;

            let a:number = field.length;
            for(let i=0; i<a; i++){
                let b: number = field[i].sub.length;

                for (let j = 0; j < b; j++) {
                    if (field[i].sub[j].id == id) return true;
                }

                if (field[i].id == id) return true;
            }
        }

        return false;
    }
    private static getProductField(field:string, product:any){
        let fieldData:any = product[field];

        //CHECK FIELD ON BASEPRODUCT
        if (field.indexOf("baseProduct") == 0) {
            let bpField: string = field.split(".")[1];

            let a: number = product.baseProducts.length;
            for (let i = 0; i < a; i++) {
                let bp: any = Data.data.baseProducts[product.baseProducts[i]];

                if (!bp) continue;

                fieldData = bp[bpField];

                if (bpField == "plsProduct") {
                    let plsField: string = field.split(".")[2];
                    let pls: any = Data.data.plsProducts[product.baseProducts[i]];

                    if (!pls) continue;

                    fieldData = pls[plsField];
                }

            }
        }

        return fieldData;
    }
    private static sortProducts(results:any){
        let orderData: any = Data.data.config.productsOrder[Data.data.productsOrder];
        if (orderData.field) {
            results.sort((a, b) => {
                let type: any = typeof a[orderData.field];

                if(orderData.field == "name"){
                    a.name = DataUtils.getProductName(a);
                    b.name = DataUtils.getProductName(b);
                }

                if (type == "string") {
                    if (orderData.ascendant) return a[orderData.field].localeCompare(b[orderData.field]);
                    else return b[orderData.field].localeCompare(a[orderData.field]);
                } else {
                    if (orderData.ascendant) return a[orderData.field] - b[orderData.field];
                    else return b[orderData.field] - a[orderData.field];
                }
            });
        }
    }
    private static filterOnValue(field:any, filterData:any, filterValue:any, product:any, pool:any) {
        if(field == filterValue && pool.indexOf(product.id) == -1){
            pool.push(product.id);

            return true;
        }

        return false;
    }
    private static filterOnMultipleValues(field: any, filterData: any, product: any, pool: any){
        let a: number = filterData.length;
        for (let i = 0; i < a; i++) {
            if (field.toLowerCase().indexOf(filterData[i].toLowerCase()) != -1 && pool.indexOf(product.id) == -1) {
                pool.push(product.id);

                return true;
            }
        }

        return false;
    }
    private static filterOnArray(field: any, filterData: any, product: any, pool: any){
        let a: number = filterData.length;
        for (let i = 0; i < a; i++) {
            if (filterData[i].selected == true && field.indexOf(filterData[i].id) != -1 && pool.indexOf(product.id) == -1) {
                pool.push(product.id);

                return true;
            }
        }

        return false;
    }
    private static filterOnTreeValues(field: any, filterData: any, product: any, pool: any, bpIndex:number=-1) {
        let a: number = filterData.length;
        let isSubSelected: boolean = false;

        for (let i = 0; i < a; i++) {
            if (filterData[i].selected == true) {
                if (filterData[i].sub) {
                    let b: number = filterData[i].sub.length;
                    for (let j = 0; j < b; j++) {
                        if (filterData[i].sub[j].selected == true) {
                            isSubSelected = true;
                            break;
                        }
                    }
                }
            }
        }

        if (isSubSelected == true) {
            let a: number = filterData.length;
            for (let i = 0; i < a; i++) {
                if (filterData[i].selected == true) {
                    let b: number = field.length;
                    for (let j = 0; j < b; j++) {
                        if (field[j].id == filterData[i].id) {
                            if (field[j].sub && filterData[i].sub) {
                                let c: number = field[j].sub.length;
                                for (let k = 0; k < c; k++) {
                                    let d: number = filterData[i].sub.length;
                                    for (let l = 0; l < d; l++) {
                                        if (filterData[i].sub[l].selected == true) {
                                            if (field[j].sub[k].id == filterData[i].sub[l].id && pool.indexOf(product.id) == -1) {
                                                pool.push(product.id);
                                                if (bpIndex != -1) product.colorIndex = bpIndex;

                                                return true;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            let a: number = filterData.length;
            for (let i = 0; i < a; i++) {
                if (filterData[i].selected == true) {
                    let b: number = field.length;
                    for (let j = 0; j < b; j++) {
                        if (field[j].id == filterData[i].id && pool.indexOf(product.id) == -1) {
                            pool.push(product.id);
                            if (bpIndex != -1) product.colorIndex = bpIndex;

                            return true;
                        }
                    }
                }
            }
        }

        return false;
    }
    private static filterOnNumberRange(filter:string, field: any, filterData: any, product: any, pool: any){
        if (!field) field = 10;

        if (field >= filterData.range[0] && field <= filterData.range[1]) return true;

        return false;
    }
    private static filterOnDatesRange(field: any, filterData: any, product: any, pool: any){
        let index: number = filterData.dates.indexOf(field);
        let startIndex: number = filterData.dates.indexOf(filterData.startDate);
        let endIndex: number = filterData.dates.indexOf(filterData.endDate);

        if (index >= startIndex && index <= endIndex) return true;

        return false;
    }
    public static updateNumberRanges(){
        if (!Data.data.filteredProducts) return;

        let heightRanges:any = Utils.copy(Data.data.height.range);
        let weightRanges:any = Utils.copy(Data.data.weight.range);
        let diameterRanges: any = Utils.copy(Data.data.diameter.range);
        let volumeRanges: any = Utils.copy(Data.data.volume.range);

        Data.data.height = { min:1000000, max:0, range:[0, 0] };
        Data.data.weight = { min: 1000000, max: 0, range: [0, 0] };
        Data.data.diameter = { min: 1000000, max: 0, range: [0, 0] };
        Data.data.volume = { min: 1000000, max: 0, range: [0, 0] };

        let a: number = Data.data.filteredProducts.length;
        for(let i=0; i<a; i++){
            let product: any = Data.data.filteredProducts[i];

            if (Data.data.height.max < product.height) Data.data.height.max = Data.data.height.range[1] = product.height;
            if (Data.data.height.min > product.height) Data.data.height.min = Data.data.height.range[0] = product.height;

            if (Data.data.weight.max < product.weight) Data.data.weight.max = Data.data.weight.range[1] = product.weight;
            if (Data.data.weight.min > product.weight) Data.data.weight.min = Data.data.weight.range[0] = product.weight;

            if (Data.data.diameter.max < product.diameter) Data.data.diameter.max = Data.data.diameter.range[1] = product.diameter;
            if (Data.data.diameter.min > product.diameter) Data.data.diameter.min = Data.data.diameter.range[0] = product.diameter;

            if (Data.data.volume.max < product.volume) Data.data.volume.max = Data.data.volume.range[1] = product.volume;
            if (Data.data.volume.min > product.volume) Data.data.volume.min = Data.data.volume.range[0] = product.volume;
        }

    }
}