
    import Dialog from './Dialog.vue';
    import Catalog from './catalog/Catalog.vue';
    import VirtualGlass from './virtualglass/VirtualGlass.vue';
    import Overlay from './Overlay.vue';
    import Header from './Header.vue';
    import VGManager from './VGManager.vue';
    import Generic from './Generic.vue';
    import $ from 'jquery';


    import {
        Component,
        Vue
    } from 'vue-property-decorator';
    import Data from '@/ts/application/Data';
import URLManager from '@/ts/utils/URLManager';
import OLDVirtualGlassUtils from '@/ts/utils/OLDVirtualGlassUtils';
import ProductDataCheck from '@/ts/application/ProductDataCheck';



    @Component({
        components: {
            Dialog,
            Overlay,
            Header,
            Catalog,
            VirtualGlass,
            VGManager
        },
    })
    export default class App extends Generic {
        public drawer: boolean;
        public statusGG: boolean = false;
        public ggtext: string = null;
        public ggtexts: any = ["It's time to kick ass and chew bubblegum.<br/>And I'm all out of gum...", "Miss ME?", "Ouh, that's gonna leave a mark!"];
        public ggtimer: number = 10;
        public ggscore: number = 0;


        constructor() {
            super();

            this.drawer = false;
        }
        private mounted(){
            $(window).on("APP_DISPOSE", this.disposeApp.bind(this));
            $(window).on("OPEN_PRODUCT", this.openProduct.bind(this));
            $(window).on("CLOSE_PRODUCT", this.closeProduct.bind(this));
            $(window).on("OPEN_OLD_VIRTUALGLASS", this.openOldVirtualGlass.bind(this));
            //$(window).on("CALL_GG", this.callGG.bind(this));

            $(window).on("resize", this.handleResize.bind(this));
            this.handleResize();

            //$(window).trigger("CALL_GG");


            if (Data.data.language == "ua_ua" || Data.data.language == "ru") $("#app").css("font-family", "Roboto");
        }
        private destroyed(){
            $(window).off("APP_DISPOSE");
            $(window).off("OPEN_PRODUCT");
            $(window).off("CLOSE_PRODUCT");
            $(window).off("OPEN_OLD_VIRTUALGLASS");
            $(window).off("resize");
        }
        private disposeApp(){
            this.$destroy();
        }
        private callGG(e:any, rnd:number=0.05){
            //console.log("callGG");
            let GGUsers: any = ["XXXXXX", "B1147402", "S6677133", "J1690579", "P5463832", "E9164162", "L5464145", "C2141735"];
            //console.log(Data.data.userID);
            if (Data.data.platform.id == "INTERNAL" && GGUsers.indexOf(Data.data.userID) != -1) {
                let random: number = Math.random();
                //random = 0.99;
                //console.log(rnd);
                if (random >= 1 - 0.03) {
                    Vue.set(this, "ggtimer", 10);
                    this.ggtext = this.ggtexts[Math.floor(Math.random() * 3)];
                    this.ggscore++;
                    if (this.ggscore >= 2) return;

                    this.statusGG = true;
                    setTimeout(this.updateGGTimer.bind(this), 1000);
                }
            }
        }
        private updateGGTimer(){
            Vue.set(this, "ggtimer", this.ggtimer - 1);

            //console.log(this.ggtimer);

            if (this.ggtimer <= 0){
                setTimeout(this.closeGG.bind(this), 1000);
                return;
            }

            setTimeout(this.updateGGTimer.bind(this), 1000);
        }
        private closeGG(){
            this.statusGG = false;
        }
        private openProduct(e:any, product:any, updateURL:boolean=true){
            console.log("openProduct : " + product.glassDrawing + " - " + product.baseProducts[product.bpIndex], product);
            this.$set(Data.data, "product", product);
            if(updateURL) URLManager.setURLFromData();

            if(!Data.productsData[product.glassDrawing]){
                Data.productsData[product.glassDrawing] = new ProductDataCheck(product);
            }else{
                Data.productsData[product.glassDrawing].checkZoomPicture();
                Data.productsData[product.glassDrawing].getFinishedProductsData();
            }

            $(window).trigger("UPDATE_PRODUCT");
        }
        private closeProduct(e:any, updateURL:boolean=true){
            this.$delete(Data.data, "product");

            if(updateURL) URLManager.setURLFromData();
        }
        private openOldVirtualGlass(e:any, product:any){
            OLDVirtualGlassUtils.openVirtualGlass(product);
        }
        private handleResize(){
            Data.data.windowWidth = $(window).width();
            Data.data.windowHeight = $(window).height();

            if(Data.data.windowWidth < Data.data.config.mobileBreakPoint) Data.data.isMobile = true;
            else Data.data.isMobile = false;
        }
        private isHeaderVisible(){
            if(Data.data.platform.id == 'INTERNAL' || Data.data.platform.id == 'SELECTIVELINE'){
                if(Data.data.platform.id == 'INTERNAL' && Data.data.oldVGMode) return false;
                else if (Data.data.platform.id == 'SELECTIVELINE' && window.location.href.indexOf("selective") == -1) return false;
                return true;
            }

            return false;
        }
    }
