
    import Generic from "./Generic.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';


    @Component
    export default class Dialog extends Generic {
        private status: boolean = false;
        private dialogData: any = null;

        private mounted() {
            $(window).on("DIALOG_OPEN", this.onOpenDialog.bind(this));
            $(window).on("DIALOG_CLOSE", this.onCloseDialog.bind(this));
        }
        private onOpenDialog(e: any, data: any) {
            this.dialogData = data;
            this.dialogData.content = this.loc(this.dialogData.content);
            if(this.dialogData.addtitionalContent) this.dialogData.content += "<br/><br/><br/>" + this.dialogData.addtitionalContent;

            this.status = true;
        }
        private onCloseDialog() {
            this.status = false;
            this.dialogData = null;
        }
        private clickButton(id:string){
            let button:any = this.dialogData.buttons[id];

            if(button.callback){
                button.callback();
            }

            this.status = false;
            this.dialogData = null;
        }
    }
