
    import $ from "jquery";
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import Projects from "./Projects.vue";
    import VGFolders from "./components/VGFolders.vue";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
import Loader from "@/ts/application/Loader";

    @Component({
        components: {
            Projects,
            VGFolders
        }
    })
    export default class Home extends Generic {
        private projectsLength:number = 0;
        private search:string = null;
        private folder:string = null;
        private folderDialog:boolean = false;
        private folderData:any = null;
        private folderMode:string = null;
        private tempProject:any = null;
        private fileLoader:Loader = null;
        private oldRendersDialog:Boolean = false;
        private rendersList:any = [];
        private oldRenders:any = [];
        private renderIndex:number = 0;

        private mounted() {
            $(window).on("CREATE_PROJECT", this.createProject.bind(this));
            $(window).on("CHECK_RENDERS_QUOTA", this.checkRendersQuota.bind(this));
            this.checkRendersQuota();
        }
        private destroyed(){
            $(window).off("CREATE_PROJECT");
            $(window).off("CHECK_RENDERS_QUOTA");
        }
        private getRenderQuota(){
            return Math.round(Data.data.config.VGSizeQuota[Data.data.vg.env] / Data.data.vg.renderSize);
        }
        private setFolder(folder:string){
            this.$set(this, "folder", folder);
        }
        private checkRendersQuota(){
            Data.data.vg.renderSize = 0;
            if(Data.data.vg.projects){
                let a:number = Data.data.vg.projects.length;
                for(let i=0; i<a; i++){
                    let b:number = Data.data.vg.projects[i].renders.length;
                    for(let j=0; j<b; j++){
                        let render:any = Data.data.vg.projects[i].renders[j];
                        if(render.filesize) Data.data.vg.renderSize += render.filesize;
                    }
                }
            }
        }
        private clearSearch(){
            this.search = null;
        }
        private getProjects(number:number){
            this.projectsLength = number;
        }
        private createProject(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.tempProject = Utils.copy(Data.data.vg.VGProjectModel);
            this.tempProject.id = Utils.generateUID();
            this.tempProject.creationDate = new Date().toISOString();
            this.tempProject.editionDate = new Date().toISOString();

            let date:Date = new Date();
            this.tempProject.name = this.loc("vg-project") + " " + this.getDate();

            this.fileLoader = new Loader(this.saveProjectComplete.bind(this), this.saveProjectError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "add-project/" + Data.data.userID + "/" + this.tempProject.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempProject), headers:Data.data.headers });
            this.fileLoader.start();
        }
        private saveProjectError(){
            $(window).trigger("ADD_NOTIFICATION", { code:"e-0001", app:"virtualglass" });

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private saveProjectComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");
            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            Data.data.vg.projects.unshift(this.tempProject);
            Data.data.vg.project = this.tempProject;
            Data.data.section = "project";
        }
        private openCatalog(){
            this.$set(Data.data, "VGMode", false);
            this.$set(Data.data, "app", "catalog");
            this.$set(Data.data, "section", "view");
            if (Data.data.platform.id == "INTERNAL") this.$set(Data.data, "view", "products");
            else if (Data.data.platform.id == "MYVERALLIA") this.$set(Data.data, "view", "general");
            $(window).trigger("CLOSE_PRODUCT");
        }
        private seeOldRenders(){
            if (!Data.data.vg.oldVirtualGlassData){
                $(window).trigger("SHOW_OVERLAY");
                $(window).trigger("SHOW_LOADING");

                let oldID:string = Data.data.user.id;



                if (Data.data.platform.id == "INTERNAL"){
                    oldID = Data.data.user.email;
                    oldID = Utils.makeURLString(Utils.replace(oldID, "@", "ABC"));
                    oldID = Utils.replace(oldID, "ABC", "_");
                }

                Data.data.vg.oldUserID = oldID;

                this.fileLoader = new Loader(this.oldDataComplete.bind(this), this.oldDataError.bind(this));

                if(Data.data.platform.id == "MYVERALLIA"){
                    // let headers: any = { authorization: "Bearer " + Data.data.salesforceToken };
                    // this.fileLoader.add({ id: "oldData", url: Data.data.platform.oldVirtualGlassData + Data.data.vg.oldUserID, method: "GET", meteor: true, headers: headers, type:"application/json" });

                    let req:any = {};


                    req.beforeSend = function (xhr:any) {
                        console.log("Adding authorization bearer " + Data.data.salesforceToken);
                        xhr.setRequestHeader("Authorization", "Bearer " + Data.data.salesforceToken);
                    };

                    req.url = Data.data.platform.oldVirtualGlassData + Data.data.vg.oldUserID;
                    req.method = "GET";
                    // req.contentType = false;
                    // req.cache = false;
                    // req.processData = false;
                    // req.data = null;
                    $.ajax(req).done(this.oldDataComplete.bind(this)).fail(this.oldDataError.bind(this));
                }else{
                    this.fileLoader = new Loader(this.oldDataComplete.bind(this), this.oldDataError.bind(this));
                    this.fileLoader.add({ id: "oldData", url: Data.data.platform.oldVirtualGlassData + Data.data.vg.oldUserID, method: "GET", contentType: "application/json" });
                    this.fileLoader.start();
                }


                return;
            }

            this.openOldRenders();
        }
        private oldDataError(){
            console.log("oldDataError");
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgNoOldData);
        }
        private oldDataComplete(data:any){
            console.log("oldDataComplete");

            if (data){
                Data.data.vg.oldVirtualGlassData = data.data;
            }else if (Data.data.platform.id == "MYVERALLIA" && !data) {
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgNoOldData);
                return;
            }else{
                let file: any = this.fileLoader.get("oldData");
                let response: any = this.fileLoader.getResponse("oldData");
                if (!file || response.status != 200) {
                    $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgNoOldData);
                    return;
                }

                Data.data.vg.oldVirtualGlassData = file.data;
            }


            try{
                let nb:number = 0;
                for (let str in Data.data.vg.oldVirtualGlassData.renders.renders){
                    if (Data.data.vg.oldVirtualGlassData.projects[str]){
                        console.log(str);
                        nb ++;
                        Data.data.vg.oldVirtualGlassData.renders.renders[str].renders[0].projectName = Data.data.vg.oldVirtualGlassData.projects[str].name;
                        Data.data.vg.oldVirtualGlassData.renders.renders[str].renders[0].projectID = str;
                        this.rendersList.push(Data.data.vg.oldVirtualGlassData.renders.renders[str].renders[0]);
                        continue;
                    }
                }

                if(nb == 0){
                    $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgNoOldData);
                    return;
                }
            }catch(e:any){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgNoOldData);
                return;
            }

            this.checkNextRender();
        }
        private checkNextRender(){
            if(this.renderIndex >= this.rendersList.length){
                $(window).trigger("HIDE_OVERLAY");
                $(window).trigger("HIDE_LOADING");

                this.openOldRenders();
            }else{
                let uid: string = this.rendersList[this.renderIndex].uid;
                let projectID: string = this.rendersList[this.renderIndex].projectID;
                let url: string = Data.data.platform.oldVirtualGlassRendersURL + Data.data.vg.oldUserID + '/projects/' + projectID + '/images/render_' + uid + '.png?access_token=' + Data.data.salesforceToken;
                $.ajax({ url: url, type: "HEAD", method: "HEAD" }).done(this.renderCheckDone.bind(this)).fail(this.renderCheckError.bind(this));
            }
        }
        private renderCheckDone(data:any, m:any, response:any) {
            if (response.status == 200){
                this.oldRenders.push(this.rendersList[this.renderIndex]);
            }

            this.renderIndex++;
            this.checkNextRender();
        }
        private renderCheckError(data:any){
            this.renderIndex ++;
            this.checkNextRender();
        }
        private openOldRenders(){
            this.oldRendersDialog = true;
        }
        private closeOldRenders() {
            this.oldRendersDialog = false;
        }
        private downloadRender(url:string){
            window.open(url, "_blank");
        }
    }
