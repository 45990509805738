export default class InitData{
    private callback:any;

    constructor(callback:any){
        this.callback = callback;

        this.init();
        this.callback();
    }
    private init(){

    }
}