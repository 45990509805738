
    import Generic from "../../Generic.vue";
    import Utils from "../../../ts/utils/Utils";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";

    @Component({
        components: {

        }
    })

    export default class BuyFinishedProducts extends Generic {
        private headers:any = [];

        private mounted() {

            this.headers = [
                // { text: 'Ref', align: 'start', value: 'id', width:1 },
                // { text: 'Hauteur', value: 'palletHeight', width:1 },
                // { text: 'Nb cols', value: 'nbRows', width:1 },
                // { text: 'Nb lits', value: 'nbLayer', width:1 },
                // { text: 'Nb cols/lits', value: 'nbUnitLayer', width:1 },
                // { text: 'Poids brut', value: 'grossWeight', width:1 },
                // { text: 'Gestion Palette', value: 'tierSheetMgmt', width:2 },
                // { text: 'Emballage', value: 'palletCode', width:2 },
                // //{ text: 'Plan', value: 'palletType', width:1 },
                // { text: 'Layer disposition', value: 'layerDisposition', width:1 }

                { text: 'Ref', align: 'start', value: 'id', width:1 },
                { text: 'Hauteur', value: 'palletHeight', width:1 },
                { text: 'Nb cols', value: 'nbRows', width:1 },
                { text: 'Nb lits', value: 'nbLayer', width:1 },
                { text: 'Nb cols/lits', value: 'nbUnitLayer', width:1 },
                { text: 'Poids brut', value: 'grossWeight', width:1 },
                { text: 'Gestion Palette', value: 'palletCode', width:2 },
                { text: 'Emballage', value: 'palletType', width:2 }
            ]
        }
        private destroyed(){

        }
        private checkQuantity(fp:any){
            if(fp.quantity < 1) fp.quantity = 1;
            else if(fp.quantity > 999) fp.quantity = 999;

            this.$forceUpdate();
            //NOT UPDATING
            // console.log(Data.data.finishedProducts[baseProduct][index].quantity);
            // if(Data.data.finishedProducts[baseProduct][index].quantity < 1) this.$set(Data.data.finishedProducts[baseProduct][index], "quantity", 1);
            // else if(Data.data.finishedProducts[baseProduct][index].quantity > 99) this.$set(Data.data.finishedProducts[baseProduct][index], "quantity", 99);
        }
        private removeQuantity(fp:any){
            fp.quantity --;
            this.checkQuantity(fp);
        }
        private addQuantity(fp:any){
            fp.quantity ++;
            this.checkQuantity(fp);
        }
        private buy(fp:any){
            let bpID:string = Data.data.product.baseProducts[Data.data.product.bpIndex];
            let fpID:string = fp.id;
            console.log("Adding to cart : " + bpID + " / " + fp.channel + fpID + " / " + fp.quantity);

            try{
                //@ts-ignore
                window.ct_cart_product_id = bpID;
                //@ts-ignore
                window.ct_cart_finished_product_id = fp.channel + fpID;
                //@ts-ignore
                window.ct_cart_quantity = fp.quantity;
                //@ts-ignore
                window.AddToCart(Data.data.view);
            }catch(e:any){
                console.log("Could not add to cart : " + bpID + " / " + fp.channel + fpID + " / " + fp.quantity, e);
            }
        }
    }
