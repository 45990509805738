import $ from "jquery";
import Player from "../player/Player";
import Utils from "../utils/Utils";
import Data from "./Data";
import Loader from "./Loader";
import DataUtils from "../utils/DataUtils";

export default class VGDataLoader {
    private onComplete:any;
    private onError:any;
    private fileLoader:Loader;


    constructor(onComplete: any = null, onError:any = null) {
        this.onComplete = onComplete;
        this.onError = onError;

        this.init();
    }
    private init(){
        this.fileLoader = new Loader(this.dataComplete.bind(this), this.dataError.bind(this));
        this.fileLoader.add({ id: "userData", url: Data.data.platform.digitalEngineVGURL + "user-data/" + Data.data.userID, method: "GET", headers: Data.data.headers });
        this.fileLoader.add({ id: "VGColors", url: Data.data.platform.digitalEngineVGURL + "colors", method: "GET", headers: Data.data.headers });
        this.fileLoader.add({ id: "VGContents", url: Data.data.platform.digitalEngineVGURL + "contents", method: "GET", headers: Data.data.headers });
        this.fileLoader.add({ id: "VGContentsCategories", url: Data.data.platform.digitalEngineVGURL + "content-categories", method: "GET", headers: Data.data.headers });
        this.fileLoader.add({ id: "VGLabels", url: Data.data.platform.digitalEngineVGURL + "labels", method: "GET", headers: Data.data.headers });
        this.fileLoader.start();
    }
    private dataError() {
        if (this.onError) this.onError();
    }
    private dataComplete(){
        let userData: any = this.fileLoader.get("userData")[Data.data.user.id];
        Data.data.vg.userData = userData.userData;
        if (!Data.data.vg.userData.data) Data.data.vg.userData.data = Utils.copy(Data.data.vg.VGUserDataModel);

        Data.data.vg.projects = userData.projects;
        Data.data.vg.projectsFolders = userData.projectsFolders;

        try{
            if (Data.data.vg.projectsFolders && Data.data.vg.projectsFolders.length == 0) Data.data.vg.projectsFolders = {};
        }catch(e:any){

        }

        Data.data.vg.items = userData.items;
        Data.data.vg.colors = this.fileLoader.get("VGColors");
        Data.data.vg.labels = this.fileLoader.get("VGLabels");
        Data.data.vg.contents = this.fileLoader.get("VGContents");
        Data.data.vg.contentCategories = this.fileLoader.get("VGContentsCategories");

        for (let i = 0; i < Data.data.vg.projects.length; i++){
            //Data.data.vg.projects[i].picIndex = 0;
        }

       DataUtils.mapOldLanguagesForVG();

        Data.data.vg.sceneBounds = { width: 800, depth: 400 };

        let config: any = { id: "VGPlayer", container: "#VGPlayerContainer", assetsPath: Data.data.config.VGAssetsPath, dataFilesPath: Data.data.platform.digitalEngineFileURL };
        config.colors = Data.data.vg.colors;
        config.contents = Data.data.vg.contents;
        config.contents = Data.data.vg.contents;
        config.capsules = Data.data.vg.capsules;
        config.labels = Data.data.vg.labels;
        config.sceneBounds = Data.data.vg.sceneBounds;
        config.models = Data.data.vg.models;

        $("#VGPlayerContainer").show();

        window.PLAYER = new Player(config);
        window.PLAYER.application.babylon.rendering = false;
        window.DATA.playerContainer = $(window.PLAYER.config.container);
        $(window.DATA.playerContainer).remove();

        Data.data.vg.dataReady = true;

        if (this.onComplete) this.onComplete();
    }
}