
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import vuescroll from "vuescroll";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
import VGObject from "@/ts/player/modules/VGObject";

    @Component({
        components: {
            vuescroll
        }
    })
    export default class Contents extends Generic {
        private mounted() {

        }
        private destroyed(){

        }
        private close(){
            this.$emit("close");
        }
        private selectContent(content:any){
            Data.data.vg.itemData.contentID = content.id;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setContent(content.id);
            //this.close();
            setTimeout(this.saveScreenshot.bind(this), 1000);
        }
        private removeContent(){
            Data.data.vg.itemData.contentID = null;
            Data.data.vg.itemData.contentFilling = 1;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.removeContent();

            this.saveScreenshot();
        }
        private saveScreenshot(){
            //$(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);
        }
        private getContentName(content:any){
            if(content.translations[Data.data.language]) return content.translations[Data.data.language];
            else if(content.translations.en_us) return content.translations.en_us;

            return content.id;
        }
    }
