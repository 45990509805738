import $ from "jquery";
import Utils from "../utils/Utils";
import Data from "./Data";
import Loader from "./Loader";

export default class Notifications {
    constructor(){
        $(window).on("ADD_NOTIFICATION", this.addNotification.bind(this));
    }
    private addNotification(e:any, data:any){
        let postData: any = { id: Utils.generateUID(), code: data.code, app:data.app, env:Data.data.vg.env, data:{ user:Data.data.user.id } };

        let loader:Loader = new Loader();
        loader.add({ id: "notification", url: Data.data.platform.digitalEngineVGURL + "add-notification/" + postData.id, contentType: "application/json", data: JSON.stringify(postData), method: "POST", headers: Data.data.headers });
        loader.start();
    }
}