import Data from "../application/Data";
import Utils from "../utils/Utils";
import VGObject from "./VGObject";

export default class Capsule{
    public uid:string;
    public VGObject:VGObject;

    public type:string;
    public meshes:any;
    public texture:any;
    public material:any;
    public isActive:boolean = false;
    private scale:number = 1.005;


    constructor(VGObject:VGObject){
        this.VGObject = VGObject;
        this.uid = Utils.generateUID();
    }
    public init(){
        //this.material = new BABYLON.StandardMaterial(this.VGObject.mdata.id + "_" + this.uid + "CapsuleMaterial", this.VGObject.babylon.scene);
        this.material = new BABYLON.PBRMetallicRoughnessMaterial(this.VGObject.mdata.id + "_" + this.uid + "CapsuleMaterial", this.VGObject.babylon.scene);
        this.material.metallic = 0;
        //this.material.diffuseTexture = new BABYLON.Texture("cap.png", this.VGObject.babylon.scene);
    }
    public sceneChange(){
        return;
    }
    public enable(){
        if (!this.meshes) return;

        let str: string;
        for (str in this.meshes){
            this.meshes[str].setEnabled(true);
            if (this.isActive == true) this.meshes[str].visibility = 1;
            else this.meshes[str].visibility = 0;
        }
    }
    public disable(){
        if (!this.meshes) return;

        let str: string;
        for (str in this.meshes) {
            this.meshes[str].visibility = 0;
        }
    }
    private setMeshes(){
        if (this.meshes){
            let str: string;
            for (str in this.meshes) {
                this.meshes[str].dispose();
            }
        }

        this.meshes = null;

        if (!this.type){
            let str: string;
            for (str in this.VGObject.capsulesMesh) {
                if (!this.type) this.type = str;
            }
        }

        if(!this.type) return;


        //SPEDCIAL CAPSULES
        if (Data.config.models.capsules[this.type]
            && Data.config.models.catalog[this.VGObject.mdata.glassDrawing].capsules
            && Data.config.models.catalog[this.VGObject.mdata.glassDrawing].capsules.indexOf(this.type) != -1){

            this.meshes = {};

            let model:any = Data.models[this.type];

            let meshes: any = Data.config.models.capsules[this.type].data.meshes;
            let str:string;
            for(str in meshes){
                if(meshes[str]){
                    let a:number = model.meshes.length;
                    for(let i=0; i<a; i++){
                        if (model.meshes[i].name == str){
                            let mesh: any = model.meshes[i].clone("CAPSULE_MESH_" + this.VGObject.uid + "_" + str);

                            mesh.parent = this.VGObject.container;
                            if (meshes[str] == "custom_image") mesh.material = this.material;
                            else mesh.material = this.VGObject.babylon.materials.capsules[meshes[str]];
                            mesh.sideOrientation = BABYLON.Mesh.DOUBLESIDE;
                            mesh.alphaIndex = 0;
                            mesh.visibility = 0;
                            mesh.scaling.x = this.scale;
                            mesh.scaling.z = this.scale;
                            mesh.scaling.y = this.scale;

                            this.meshes[str] = mesh;
                            this.meshes[str].position.y = this.VGObject.model.box.boundingBox.maximum.y;
                        }
                    }
                }
            }

        //CLASSIC CAPSULES
        } else if (this.VGObject.capsulesMesh && this.VGObject.capsulesMesh[this.type]){
            this.meshes = {};

            let mesh: any = this.VGObject.capsulesMesh[this.type].clone(this.VGObject.mdata.id + "_" + this.uid + "_CAPSULE_MESH_" + this.type);

            mesh.parent = this.VGObject.container;
            mesh.material = this.material;
            mesh.sideOrientattion = BABYLON.Mesh.DOUBLESIDE;
            mesh.alphaIndex = 0;
            mesh.visibility = 0;
            mesh.scaling.x = mesh.scaling.y = mesh.scaling.z = this.scale;

            this.meshes[this.type] = mesh;
        }

        this.type = null;
    }
    public setShininess(value:number){
        this.material.roughness = 1 - value;
    }
    public display(url:string, shininess:number, type:string){
        this.type = type;

        this.setMeshes();

        //this.material.diffuseTexture = new BABYLON.Texture(url, this.VGObject.babylon.scene);
        //this.material.diffuseTexture.uScale = -1;
        //this.material.diffuseTexture.vScale = -1;
        this.material.baseTexture = new BABYLON.Texture(url, this.VGObject.babylon.scene);
        this.material.baseTexture.uScale = -1;
        this.material.baseTexture.vScale = -1;
        this.material.roughness = 1 - shininess;

        if (!this.meshes) return;



        let str: string;
        for (str in this.meshes) {
            this.meshes[str].visibility = 1;
        }

        this.isActive = true;
    }
    public hide(){
        if (!this.meshes) return;

        let str: string;
        for (str in this.meshes) {
            this.meshes[str].visibility = 0;
        }

        this.isActive = false;
    }
}