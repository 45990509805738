
    import Data from "../ts/application/Data";
    //import $ from "jquery";

    import {
        Component,
        Vue
    } from 'vue-property-decorator';
    import DataUtils from "@/ts/utils/DataUtils";

    @Component
    export default class Generic extends Vue {
        data:any = Data.data;
        vg:any = Data.data.vg;

        public loc(id:string){
            if(Data.data.mode == "dev") return Data.data.loc[id] || id + " NOT FOUND...";
            else if(Data.data.mode == "prod") return Data.data.loc[id] || Data.data.locs.en_us[id];
        }
        public openDialog(data:any){
            $(document).trigger("DIALOG_OPEN", data);
        }
        public closeDialog(){
            $(document).trigger("DIALOG_CLOSE");
        }
        public setDefaultProductImage(e:any){
            //console.log(e);
            e.target.src = Data.data.config.assetsPath + "images/defaultBottle.jpg";
        }
        public getMetaFromBaseProduct(bp:string){
            for(let str in Data.data.products){
                let product: any = Data.data.products[str];

                if (product.baseProducts && product.baseProducts.indexOf(bp) != -1) return product;
            }

            return;
        }
        public getProductName(product:any){
            return DataUtils.getProductName(product);
        }
        public getColorFromBaseproduct(bp:string){
            let baseProduct:any = Data.data.baseProducts[bp];

            if(baseProduct){
                let sapCode:string = baseProduct.sapColor;

                let a:number = Data.data.colors.length;
                for(let i=0; i<a; i++){
                    let b:number = Data.data.colors[i].sub.length;

                    for(let j=0; j<b;j++){
                        if(Data.data.colors[i].sub[j].id == sapCode) return Data.data.colors[i].sub[j];
                    }
                }
            }

            return { hexa:"000000", name:"-" };
        }
        public getColorFamilyName(baseProduct:any){
            let sapCode:string = baseProduct.sapColor;

            let a:number = Data.data.colors.length;
            for(let i=0; i<a; i++){
                let b:number = Data.data.colors[i].sub.length;

                for(let j=0; j<b;j++){
                    if(Data.data.colors[i].sub[j].id == sapCode) return Data.data.colors[i]["name-" + Data.data.language];
                }
            }

            return null;
        }
        public getColorName(baseProduct:any){
            let sapCode:string = baseProduct.sapColor;

            let a:number = Data.data.colors.length;
            for(let i=0; i<a; i++){
                let b:number = Data.data.colors[i].sub.length;

                for(let j=0; j<b;j++){
                    if(Data.data.colors[i].sub[j].id == sapCode) return Data.data.colors[i].sub[j]["name-" + Data.data.language];
                }
            }

            return null;
        }
        public getColorNameFromBaseProduct(bp:string){
            let name:string = "-";

            let color:any = this.getColorFromBaseproduct(bp);

            name = color["name-" + Data.data.language];
            if(!name ||name == "") name = color["name-en"];
            if(!name || name == "") name = "-";

            return name;
        }
        public getFinishFamilyName(product:any){
            let name:string = "-";

            let finishID:string = product.finishFamily;

            if(finishID){
                for(let i=0; i<Data.data.finish.length; i++){
                    if(Data.data.finish[i].id == finishID){
                        name = Data.data.finish[i]["name-" + Data.data.language];
                        if(!name ||name == "") name = Data.data.finish[i]["name-en_us"];

                        return name;
                    }
                }
            }

            return null;
        }
        public getFinishName(product:any){
            let name:string = "-";

            let finishID:string = product.finishSubFamily;

            if(finishID){
                let a:number = Data.data.finish.length;
                for(let i=0; i<a; i++){
                    let b:number = Data.data.finish[i].sub.length;

                    for(let j=0; j<b;j++){
                        if(Data.data.finish[i].sub[j].id == finishID){
                            name = Data.data.finish[i].sub[j]["name-" + Data.data.language];
                            if(!name ||name == "") name = Data.data.finish[i].sub[j]["name-en_us"];
                        }
                    }
                }
            }

            return null;
        }
        public getMarketsNames(product:any){
            let markets = "";

            for(let i=0; i<product.markets.length; i++){
                for(let j=0; j<Data.data.markets.length; j++){
                    if(product.markets[i].id == Data.data.markets[j].id){
                        markets += Data.data.markets[j]["name-" + Data.data.language] + " / ";

                        if(product.markets[i].sub.length != 0 && Data.data.markets[j].sub.length != 0){
                            for(let k=0; k<product.markets[i].sub.length; k++){
                                for(let l=0; l<Data.data.markets[j].sub.length; l++){
                                    if(product.markets[i].sub[k].id == Data.data.markets[j].sub[l].id){
                                        markets += Data.data.markets[j].sub[l]["name-" + Data.data.language] + " / ";

                                        //return markets;
                                    }
                                }
                            }
                        }

                        //return markets;
                    }
                }
            }

            return markets.slice(0, -2);
        }
        public getPackageNameFromCode(code:string){
            if(Data.packageJson && code){
                for(let i=0; i<Data.packageJson.length; i++){
                    if(Data.packageJson[i].id && Data.packageJson[i].id.toLowerCase() == code.toLowerCase() && Data.packageJson[i]["name-" + Data.data.language]){
                        return Data.packageJson[i]["name-" + Data.data.language];
                    }
                }
            }

            if(code) return code;
            else return "-";
        }
        public getProductShape(code:string){
            if(Data.shapeJson && code){
                for(let i=0; i<Data.shapeJson.length; i++){
                    if(Data.shapeJson[i].id && Data.shapeJson[i].id.toLowerCase() == code.toLowerCase() && Data.shapeJson[i]["name-" + Data.data.language]){
                        return Data.shapeJson[i]["name-" + Data.data.language];
                    }
                }
            }

            if(code) return code;
            else return "-";
        }
        public getPalletNameFromCode(code:string){
            if(Data.palletJson && code){
                for(let i=0; i<Data.palletJson.length; i++){
                    if(Data.palletJson[i].id && Data.palletJson[i].id.toLowerCase() == code.toLowerCase() && Data.palletJson[i]["name-" + Data.data.language]){
                        return Data.palletJson[i]["name-" + Data.data.language];
                    }
                }
            }

            if(code) return code;
            else return "-";
        }
        public getVGProject(id:string):any{
            let a:number = Data.data.vg.projects.length;
            for(let i=0; i<a; i++){
                if(Data.data.vg.projects[i].id == id) return Data.data.vg.projects[i];
            }
        }
        public getVGItem(id:string):any{
            let a:number = Data.data.vg.items.length;
            for(let i=0; i<a; i++){
                if(Data.data.vg.items[i].id == id) return Data.data.vg.items[i];
            }
        }
        public getVGItemFromCurrentProject(id:string):any{
            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                if(Data.data.vg.project.items[i].id == id) return Data.data.vg.project.items[i];
            }
        }
        public getVGItemsFromCurrentProject():any{
            let items:any = [];
            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                items.push(this.getVGItem(Data.data.vg.project.items[i].itemID));
            }

            return items;
        }
        public getVGItemCatalog(glassDrawing:string):any{
            let str:string = Data.data.vg.items.length;
            for(str in Data.data.products){
                if(Data.data.products[str].glassDrawingVG == glassDrawing) return Data.data.products[str];
            }
        }
        public formatDate(value:string, includeTime:boolean=false){
            let date:Date = new Date(value);

            if(Data.data.language == "fr"){
                if(includeTime) return this.addLeadingZero(date.getDate()) + "/" + this.addLeadingZero(date.getMonth() + 1) + "/" + date.getFullYear() + " " + this.addLeadingZero(date.getHours()) + ":" + this.addLeadingZero(date.getMinutes());
                return this.addLeadingZero(date.getDate()) + "/" + this.addLeadingZero(date.getMonth() + 1) + "/" + date.getFullYear();
            }else{
                if(includeTime) return this.addLeadingZero(date.getMonth() + 1) + "/" + this.addLeadingZero(date.getDate()) + "/" + date.getFullYear() + " " + this.addLeadingZero(date.getHours()) + ":" + this.addLeadingZero(date.getMinutes());
                return this.addLeadingZero(date.getMonth() + 1) + "/" + this.addLeadingZero(date.getDate()) + "/" + date.getFullYear();
            }
        }
        public addLeadingZero(value:any){
            if(value < 10) value = "0" + value;
            return value;
        }
        public getDate(){
            let date:Date = new Date();

            return this.addLeadingZero(date.getDate()) + "/" + this.addLeadingZero(date.getMonth() + 1) + "/" + date.getFullYear() + " " + this.addLeadingZero(date.getHours()) + ":" + this.addLeadingZero(date.getMinutes());

        }
        public getProjectFilesize(project:any){
            let filesize:number = 0;
            let b:number = project.renders.length;
            for(let j=0; j<b; j++){
                let render:any = project.renders[j];
                if(render.filesize) filesize += render.filesize;
            }

            return filesize.toFixed(1);
        }
        public maintenanceMode(){
            if (Data.data.vg.serverData && Data.data.vg.serverData.maintenance && Data.data.vg.serverData.maintenance.enabled){
                return true;
            }

            return false;
        }
    }
