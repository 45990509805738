
    import Generic from "../../Generic.vue";
    import Utils from "../../../ts/utils/Utils";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";
import DataUtils from "@/ts/utils/DataUtils";

    @Component({
        components: {

        }
    })

    export default class FinishedProducts extends Generic {
        private headers:any = [];

        private mounted() {
            this.headers = [
                { text: 'Ref', align: 'start', value: 'id', width:1 },
                { text: 'Statut', value: 'status', width:1 },
                { text: 'Hauteur', value: 'palletHeight', width:1 },
                { text: 'Nb cols', value: 'nbRows', width:1 },
                { text: 'Nb lits', value: 'nbLayer', width:1 },
                { text: 'Nb cols/lits', value: 'nbUnitLayer', width:1 },
                { text: 'Poids brut', value: 'grossWeight', width:1 },
                //{ text: 'Gestion Palette', value: 'tierSheetMgmt', width:2 },
                { text: 'Gestion Palette', value: 'palletCode', width:2 },
                { text: 'Emballage', value: 'palletType', width:2 },
                { text: 'Plan', value: 'plan', width:1 }
            ]

            if(Data.data.platform.id == "MYVERALLIA"){
                this.headers = [
                    { text: 'Ref', align: 'start', value: 'id', width:1 },
                    { text: 'Hauteur', value: 'palletHeight', width:1 },
                    { text: 'Nb cols', value: 'nbRows', width:1 },
                    { text: 'Nb lits', value: 'nbLayer', width:1 },
                    { text: 'Nb cols/lits', value: 'nbUnitLayer', width:1 },
                    { text: 'Poids brut', value: 'grossWeight', width:1 },
                    //{ text: 'Gestion Palette', value: 'tierSheetMgmt', width:2 },
                    { text: 'Gestion Palette', value: 'palletCode', width:2 },
                    { text: 'Emballage', value: 'palletType', width:2 },
                    { text: 'Plan', value: 'plan', width:2 }
                ]
            }
        }
        private destroyed(){

        }
        private openPlan(fp:any){
            let url:string = null;

            if(Data.data.platform.id == "MYVERALLIA"){
                let pathname: any = window.location.pathname.split("/");
                let serverUrl = window.location.origin + "/" + pathname[1];
                if (window.location.href.indexOf("verallia.com") != -1) serverUrl = "";
                //serverUrl = serverUrl.replace("Verallia_Catalog", "");
                url = serverUrl + "/Web_ViewDocumentFile?distribChannel=" + fp.channel + "&objType=PALLET&objNum=" + fp.id + "&salesorg=" + fp.so;
            }else if(Data.data.platform.id == "INTERNAL"){
                let baseProduct:any = Data.data.baseProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]];
                let channel:string = DataUtils.getChannelCodeFromCountry(baseProduct.productOwner[0]);
                if(baseProduct.productOwner[0] == "ib") channel = DataUtils.getChannelCodeFromCountry("sp");

                let salesOrg:string = null;
                for(let i=0; i<fp.salesData.length; i++){
                    if(fp.salesData[i].channel == channel){
                        salesOrg = fp.salesData[i].salesOrg;
                        break;
                    }
                }
                if(!salesOrg && baseProduct.productOwner[0] == "ib"){
                    channel = DataUtils.getChannelCodeFromCountry("pt");
                    for(let i=0; i<fp.salesData.length; i++){
                        if(fp.salesData[i].channel == channel){
                            salesOrg = fp.salesData[i].salesOrg;
                            break;
                        }
                    }
                }


                url = "https://digital-api.corp.inpkg.net/api/sap-documents/palletization-forms/" + fp.id + "?channel=" + channel + "&salesOrg=" + salesOrg;
            }

            console.log(url);
            window.open(url, "_blank");
        }
    }
