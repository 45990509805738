
    import Generic from "../Generic.vue";
    import VGFolders from "./components/VGFolders.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import VGObject from "@/ts/player/modules/VGObject";
    import Loader from "@/ts/application/Loader";

    @Component({
        components: {
            VGFolders
        }
    })
    export default class Project extends Generic {
        private fileLoader:Loader;
        private project:any;
        private modelIndex:number;
        private messages:any;


        private saveCallback:any;
        private fileScreenShotLoader:Loader;

        private mounted() {
            $("#VGProject").append(window.DATA.playerContainer);
            window.PLAYER.startRender();
            window.PLAYER.hideAllModels();
            window.PLAYER.setScene(Data.data.vg.project.scene);
            window.PLAYER.focusOnScene();

            $(window).on("PREPARE_RENDER", this.prepareRender.bind(this));
            $(window).on("MODELS_LOADED", this.modelsLoaded.bind(this));
            this.loadModels();

            $(window).on("LOAD_MODELS", this.loadModels.bind(this));
            $(window).on("resize", this.resize.bind(this));
            this.resize();
            setTimeout(this.resize.bind(this), 500);

            //$(window).trigger("CALL_GG");
        }
        private destroyed(){
            $(window).off("PREPARE_RENDER");
            $(window).off("MODELS_LOADED");
            $(window).off("LOAD_MODELS");
            $(window).off("resize", this.resize.bind(this));

            window.PLAYER.hideAllModels();
            window.PLAYER.stopRender();
        }
        private setFolder(folder:string){
            Data.data.vg.project.folder = folder;
        }
        private openCatalog(){
            $(window).trigger("ADD_ITEM", "catalog");
        }
        private openLibrary(){
            $(window).trigger("ADD_ITEM", "library");
        }
        private resize(){
            var width = $("#VGProject").width();
            var height = $("#VGProject").height() - 60;
            //if(width < 840) height = $("#project").height();
            window.PLAYER.application.babylon.resize(width, height);
        }
        private loadModels(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.modelIndex = 0;
            this.messages = {};

            if(this.checkModelsComplete()) return;

            this.loadModelsData();
            //this.loadNextModel();
        }
        private loadModelsData(){
            let gd:any = [];
            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                let itemInfo:any = Data.data.vg.project.items[i];
                let item:any = this.getVGItem(itemInfo.itemID);

                if(!item) continue;

                if(!Data.data.vg.models.catalog[item.glassDrawing]) gd.push(item.glassDrawing);
            }

            if(gd.length != 0){
                let models:string = gd.join(",");
                this.fileLoader = new Loader(this.modelsDataComplete.bind(this), this.modelsDataError.bind(this));
                this.fileLoader.add({ id: "VGModels", url: Data.data.platform.digitalEngineVGURL + "model/" + models, method: "GET", headers:Data.data.headers });
                this.fileLoader.start();
                return;
            }

            this.loadNextModel();
        }
        private modelsDataError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private modelsDataComplete(){
            let response:any = this.fileLoader.getResponse("VGModels");
            let data:any = this.fileLoader.get("VGModels");

            if(!data || response.status != 200){
                this.modelsDataError();
                return;
            }

            let str:string;
            if(data.catalog){
                for(str in data.catalog){
                    Data.data.vg.models.catalog[str] = data.catalog[str];
                }
            }
            if(data.capsules){
                for(str in data.capsules){
                    Data.data.vg.models.capsules[str] = data.capsules[str];
                }
            }

            this.loadNextModel();
        }
        private loadNextModel(){
            //let itemInfo = Data.data.vg.project.items[this.modelIndex];
            let itemInfo:any = Data.data.vg.project.items[this.modelIndex];
            let item:any = this.getVGItem(itemInfo.itemID);
            let model:VGObject = window.PLAYER.getModel(itemInfo.id);
            let meta: any = this.getMetaFromBaseProduct(item.baseProduct);

            //console.log("loading model " + this.modelIndex + " id: " + itemInfo.id);

            if(!model){
                if(window.PLAYER.isModeloaded(item.glassDrawing) == false){
                    let data: any = Utils.copy(Data.data.config.dialogs.vgModelLoading);
                    $(window).trigger("DIALOG_OPEN", data);
                }

                if(Data.data.vg.models.catalog[item.glassDrawing]){
                    let modelName:string = Data.data.vg.models.catalog[item.glassDrawing].id;
                    window.PLAYER.loadModel({ id: itemInfo.id, itemID: item.id, glassDrawing: item.glassDrawing, labelMaxHeight: meta.labelMaxHeight, labelBottomOffset:meta.labelBottomOffset, path:Data.data.platform.digitalEngineFileURL + "virtualglass/assets/models/" + item.glassDrawing + "/", filename:modelName + ".gltf" }, this.modelReady.bind(this), this.modelError.bind(this));
                }else{
                    console.log("modèle introuvable, gérer ce cas. Supprimer la bouteille par exemple");
                }

                //window.PLAYER.loadModel({ id:itemInfo.id, itemID:item.id, glassDrawing:"77141", path:"appAssets/player/obj/", filename:"77141.obj" }, this.modelReady.bind(this), this.modelError.bind(this));
            }else{
                //console.log("model from gd : " + item.glassDrawing + " already created");
                this.modelReady();
            }
        }
        private modelReady(){
            let itemInfo:any = Data.data.vg.project.items[this.modelIndex];
            let item:any = this.getVGItem(itemInfo.itemID);
            let model:VGObject = window.PLAYER.getModel(itemInfo.id);

            if(model){
                model.designFromData(item);
                model.setRotation(itemInfo.rotation.y);
                model.hide();
            }

            this.checkItemReferences(item);

            this.modelIndex ++;

            if(this.checkModelsComplete()) return;

            this.loadNextModel();
        }
        private modelsLoaded(){
            $(window).off("MODELS_LOADED");

            if(Data.data.vg.project.items.length > 0){
                let itemInfo:any = Data.data.vg.project.items[0];
                let model:VGObject = window.PLAYER.getModel(itemInfo.id);

                Data.data.vg.item = this.getVGItemFromCurrentProject(itemInfo.id);
                Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
                Data.data.vg.catalogItem = this.getVGItemCatalog(Data.data.vg.itemData.glassDrawing);

                model.setRotation(Data.data.vg.item.rotation.x);

                window.PLAYER.focusOnModel(Data.data.vg.item.id);
                model.show();
            }
        }
        private checkModelsComplete(){
            if(this.modelIndex == Data.data.vg.project.items.length){
                $(window).trigger("POSITION_MODELS");
                $(window).trigger("DIALOG_CLOSE");
                $(window).trigger("HIDE_OVERLAY");
                $(window).trigger("HIDE_LOADING");
                $(window).trigger("MODELS_LOADED");

                if(Object.keys(this.messages).length == 0) this.messages = null;
                if(this.messages) this.openProjectMessages();

                return true;
            }

            return false;
        }
        private modelError(){

        }
        private checkItemReferences(item:any){
            this.messages[item.id] = { name:item.name };

            if(item.colorID && !Data.data.vg.colors[item.colorID]){
                item.colorID = "white";
                this.messages[item.id].color = true;
            }
            if(item.labelID && !Data.data.vg.labels.label[item.labelID]){
                item.labelID = null;
                this.messages[item.id].label = true;
            }
            if(item.backLabelID && !Data.data.vg.labels.backLabel[item.backLabelID]){
                item.backLabelID = null;
                this.messages[item.id].backLabel = true;
            }
            if(item.decorID && !Data.data.vg.labels.decor[item.decorID]){
                item.decorID = null;
                this.messages[item.id].decor = true;
            }
            if(item.capsuleID){
                if(!Data.data.vg.labels.capsuleBottle[item.capsuleID] && !Data.data.vg.labels.capsuleJar[item.capsuleID]){
                    item.capsuleID = null;
                    this.messages[item.id].capsule = true;
                }
            }
            if(!Data.data.vg.capsules[item.glassDrawing]) Data.data.vg.capsules[item.glassDrawing] = [];
            //if(Data.data.vg.models.catalog[item.glassDrawing] && Data.data.vg.models.catalog[item.glassDrawing].capsules.length > 0) item.capsuleType = Data.data.vg.models.catalog[item.glassDrawing].capsules[0];
            let capsuleTypeFound:boolean = false;
            let capsuleEmpty:boolean = false;
            if(item.capsuleType){
                //CHECKING CAPSULE EXISTENCE
                if(Data.data.vg.models.catalog[item.glassDrawing]
                && Data.data.vg.models.catalog[item.glassDrawing].capsule
                && Data.data.vg.models.catalog[item.glassDrawing].capsules.length > 0
                && Data.data.vg.models.catalog[item.glassDrawing].capsules.indexOf(item.capsuleType) != -1){
                    if(Data.data.vg.models.capsules[item.capsuleType]) capsuleTypeFound = true;
                }
                if(!capsuleTypeFound && Data.data.vg.capsules[item.glassDrawing]){
                    if(Data.data.vg.capsules[item.glassDrawing].indexOf(item.capsuleType) != -1) capsuleTypeFound = true;
                }
            }else{
                capsuleEmpty = true;
            }

            //SET DEFAULT CAPSULE TYPE
            // if(!capsuleTypeFound){
            //     item.capsuleType = null;
            //     //if(!capsuleEmpty) this.messages[item.id].capsuleType = true;

            //     if(Data.data.vg.capsules[item.glassDrawing] && Data.data.vg.capsules[item.glassDrawing].length > 0){
            //         item.capsuleType = Data.data.vg.capsules[item.glassDrawing][0];
            //     }
            //     if(!item.capsuleType){
            //         if(Data.data.vg.models.catalog[item.glassDrawing]
            //         && Data.data.vg.models.catalog[item.glassDrawing].capsules
            //         && Data.data.vg.models.catalog[item.glassDrawing].capsules.length > 0){
            //             let a:number = Data.data.vg.models.catalog[item.glassDrawing].capsules.length;
            //             for(let i=0; i<a; i++){
            //                 let cap:string = Data.data.vg.models.catalog[item.glassDrawing].capsules[i];
            //                 if(!item.capsuleType){
            //                     if(Data.data.vg.models.capsules[cap]){
            //                         item.capsuleType = cap;
            //                         break;
            //                     }
            //                 }
            //             }
            //         }
            //     }
            //     //if(!capsuleEmpty) this.messages[item.id].capsuleTypeNew = item.capsuleType;
            // }

            if(this.messages[item.id] && Object.keys(this.messages[item.id]).length <= 1) delete this.messages[item.id];
            //this.messages = null;
        }
        private openProjectMessages(){
            console.log(this.messages);

            let txt:string = "<br/><br/>";
            let str:string;
            for(str in this.messages){
                let item:any = this.messages[str];

                //let itemData:any = this.getVGItem(item.id);
               // console.log(itemData);



                txt += "<span class='ColorBlue mb-3'>" + item.name + "</span><br/>";
                if(item.label) txt += this.loc("dialog-vgProjectsReferencesInfo-label") + "<br/>";
                if(item.backLabel) txt += this.loc("dialog-vgProjectsReferencesInfo-backLabel") + "<br/>";
                if(item.decor) txt += this.loc("dialog-vgProjectsReferencesInfo-decor") + "<br/>";
                if(item.capsule) txt += this.loc("dialog-vgProjectsReferencesInfo-capsule") + "<br/>";
                if(item.capsuleType){
                    if(item.capsuleTypeNew) txt += this.loc("dialog-vgProjectsReferencesInfo-capsuleType") + item.capsuleTypeNew + "<br/>";
                    else txt += this.loc("dialog-vgProjectsReferencesInfo-capsuleType") + " " + this.loc("none") + "<br/>";
                }
            }


            let data:any = Utils.copy(Data.data.config.dialogs.vgProjectsReferencesInfo);
            data.addtitionalContent = txt;

            $(window).trigger("DIALOG_OPEN", data);
        }






        private save(callback:any = null){
            this.saveCallback = callback;

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            if(Data.data.vg.item) $(window).trigger("SAVE_ITEM_SCREENSHOT", Data.data.vg.item.itemID);


            let project:any = Utils.copy(Data.data.vg.project);
            project.itemsSettings = project.items;
            delete project.items;
            project.items = [];

            let items:string[] = [];
            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                let settingsID:string = Data.data.vg.project.items[i].id;
                let itemID:string = Data.data.vg.project.items[i].itemID;
                if(items.indexOf(itemID) == -1){
                    items.push(itemID);
                    project.items.push(Utils.copy(this.getVGItem(itemID)));
                    //this.fileLoader.add({ id: "requestItem" + itemID, url: Data.data.platform.digitalEngineVGURL + Data.data.userID + "/update-item-catalog/" + itemID, method:"PUT", contentType:"application/json", data:JSON.stringify(this.getVGItem(itemID)) });
                }

                //this.fileLoader.add({ id: "requestSetting" + settingsID, url: Data.data.platform.digitalEngineVGURL + Data.data.userID + "/project/" + Data.data.vg.project.id + "/update-item-settings/" + settingsID, method:"PUT", contentType:"application/json", data:JSON.stringify(Data.data.vg.project.items[i]) });
            }

            this.fileLoader = new Loader(this.projectSaveComplete.bind(this), this.projectSaveError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "save-project/" + Data.data.user.id + "/" + Data.data.vg.project.id, method:"PUT", contentType:"application/json", data:JSON.stringify(project), headers: Data.data.headers });

            this.fileLoader.start();
        }
        private projectSaveError(){
            $(window).trigger("ADD_NOTIFICATION", { code:"e-0002", app:"virtualglass", data:{ projectID:Data.data.vg.project.id } });

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private projectSaveComplete(){
            if(this.saveCallback){
                let callback = this.saveCallback;
                this.saveCallback = null;
                callback();
                return;
            }

            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            window.PLAYER.hideSceneBounds();
            window.PLAYER.hidePositionHelper();
            window.PLAYER.setPositionHelperColorToGreen();
            window.PLAYER.focusOnScene();

            if(Data.data.vg.item){
                let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
                model.hidePositionHelper();
            }

            Data.data.vg.project.editionDate = new Date().toISOString();

            Data.data.vg.item = null;
            Data.data.vg.itemData = null;
            Data.data.vg.catalogItem = null;
            Data.data.vg.project = null;

            Data.data.section = "home";
        }



        private saveScreenShot(e:any, id:string, base64:string){
            let canvas:any = document.createElement("canvas");
            canvas.width = 700;
            canvas.height = 700;
            let ctx:any = canvas.getContext("2d");
            let t:any = this;
            let image:any = new Image();
            image.onload = function() {
                ctx.drawImage(image, 0, 0);

                //canvas.width = $(image).width();
                //canvas.height = $(image).height();

                let newCanvas:any = Utils.removeTransparentPixelsFromBase64(canvas);
                t.saveScreenShotImage(id, newCanvas.toDataURL());
            };
            image.src = base64;
        }
        private saveScreenShotImage(id:string, base64:string){
             let file:any = Utils.createFile(Utils.convertDataURIToBinary(base64), id + ".png");

            this.fileScreenShotLoader = new Loader(this.screenShotSaved.bind(this), this.screenShotError.bind(this));

            let formData:FormData = new FormData();
            formData.append("file", file, id + ".png");

            this.fileScreenShotLoader.add({ id: "uploadPNG", url: Data.data.platform.digitalEngineFileURL + "upload/virtualglass/" + Data.data.user.id + "/itemsScreenShots/" + id + ".png", method:"POST", contentType:false, data:formData, headers: Data.data.headers });
            this.fileScreenShotLoader.start();
        }
        private screenShotSaved(){
            //console.log("screenShotSaved");
        }
        private screenShotError(){
            //console.log("screenShotError");
        }




        private prepareRender(){
            this.save(this.saveLabels.bind(this));
        }
        private saveLabels(){
            this.fileLoader = new Loader(this.labelsSaveComplete.bind(this), this.labelsSaveError.bind(this));

            let itemsID:any = {};
            let a:number = Data.data.vg.project.items.length;
            for(let i=0; i<a; i++){
                let item:any = Data.data.vg.project.items[i];
                let model:VGObject = window.PLAYER.getModel(item.id);

                if(!model) continue;

                let base64:string = model.labels.base64;
                if(base64 && !itemsID[item.itemID]){
                    itemsID[item.itemID] = true;

                    let file:any = Utils.createFile(Utils.convertDataURIToBinary(base64), "label" + item.itemID + ".png");

                    let formData:FormData = new FormData();
                    formData.append("file", file, item.id + ".png");

                    this.fileLoader.add({ id: "request" + item.id, url: Data.data.platform.digitalEngineURL + "file/upload/virtualglass/" + Data.data.user.id + "/" + Data.data.vg.project.id + "/TEXTURE_" + item.itemID + ".png", method:"POST", contentType:false, data:formData, headers: Data.data.headers });
                }
            }

            this.fileLoader.start();
        }
        private labelsSaveError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private labelsSaveComplete(){
            $(window).trigger("ADD_RENDER");
        }

        private seeRenders(){
            $(window).trigger("OPEN_RENDERS", Data.data.vg.project);
        }
        private isRenderEnabled(){
            if(Data.data.vg.project.items.length == 0) return true;

            if(Data.data.platform.id == "MYVERALLIA"){
                if(!Data.data.MYVeralliaCanAccessVG){
                    let currentDateMS:number = new Date().getTime();
                    let renders:any = 0;

                    let a:number = Data.data.vg.projects.length;
                    for(let i=0; i<a; i++){
                        let project:any = Data.data.vg.projects[i];
                        let b:number = project.renders.length;

                        for(let j=0; j<b; j++){
                            let render:any = project.renders[j];

                            let dateMS:number = new Date(render.creationDate).getTime();
                            console.log(dateMS);
                            if(currentDateMS - dateMS <= 2629800000){
                                renders ++;
                            }
                        }
                    }

                    if(renders >= 10){
                        return true;
                    }
                }
            }

            return false;
        }
        private isRendering(){
            let a:number = Data.data.vg.project.renders.length;
            for(let i=0; i<a; i++){
                let render:any = Data.data.vg.project.renders[i];
                if(render.status == "queue" || render.status == "rendering"){
                    return false;
                }
            }

            return true;
        }
        private createRender(){
            $(window).trigger("NEW_RENDER");
        }
        private setModelRotation(){
            if(Data.data.vg.item){
                Data.data.vg.item.rotation.x = Math.round(Data.data.vg.item.rotation.x);
                let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
                model.setRotation(Data.data.vg.item.rotation.x);

                window.PLAYER.focusOnModel(Data.data.vg.item.id);
            }
        }
    }
