
    import Generic from "./Generic.vue";
    import Utils from "../ts/utils/Utils";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import DataUtils from "@/ts/utils/DataUtils";
    import URLManager from "@/ts/utils/URLManager";
    import VGDataLoader from "@/ts/application/VGDataLoader";



    @Component
    export default class Header extends Generic {
        private menu:boolean = false;

        private openHome() {
            if (Data.data.section == "home") return;

            let data: any = Utils.copy(Data.data.config.dialogs.confirmGoHome);
            data.buttons.yes.callback = this.goHome.bind(this);

            $(window).trigger("DIALOG_OPEN", data);
        }
        private goHome() {
            Data.data.userPath = [];
            Data.data.section = "home";
        }
        private openSection(section:string){
            this.menu = false;

            if(section == "virtualglass"){
                if(!Data.data.vg.dataReady){
                    $(window).trigger("SHOW_OVERLAY");
                    $(window).trigger("SHOW_LOADING");
                    $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgDataLoading);

                    new VGDataLoader(this.VGDataComplete.bind(this), this.openVGError.bind(this));
                    return;
                }

                this.openVG();

            }else if(section == "catalog"){
                this.$set(Data.data, "VGMode", false);
                this.$set(Data.data, "app", "catalog");
                this.$set(Data.data, "section", "home");
                if(Data.data.platform.id == "INTERNAL") this.$set(Data.data, "view", "products");
                else if(Data.data.platform.id == "MYVERALLIA") this.$set(Data.data, "view", "general");
                $(window).trigger("CLOSE_PRODUCT");
            }
        }
        private VGDataComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");

            this.openVG();
        }
        private openVG(){
            this.$set(Data.data, "VGMode", true);
            this.$set(Data.data, "app", "virtualglass");
            this.$set(Data.data, "section", "home");
            $(window).trigger("CLOSE_PRODUCT");
        }
        private openVGError(){
            this.$set(Data.data, "VGMode", false);
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");
            console.log("Error loading VG Data");
        }
        private focusOnfield(){
            $("#SearchField").focus();
        }
        private toggleProductsMode(){
            let updateURL:boolean = false;

            if(Data.data.view == "general"){
                this.$set(Data.data, "products", Data.myProductsJson);
                this.$set(Data.data, "baseProducts", Data.myProductsBaseProductsJson);
                this.$set(Data.data, "view", "myproducts");
            }else{
                this.$set(Data.data, "products", Data.productsJson);
                this.$set(Data.data, "baseProducts", Data.baseProductsJson);
                this.$set(Data.data, "view", "general");
            }

            Data.data.product = null;
            this.$set(Data.data, "section", "view");

            $(window).trigger("UPDATE_VIEW");
            URLManager.setURLFromData();
        }
        private openPDF(url:string){
            window.open(url, "_blank");
        }
        private openDisclaimer(){
            this.menu = false;
            Data.data.section = "disclaimer";
        }
        private setLanguage(l:string){
            Data.data.language = l;
            Data.data.loc = Data.data.locs[l];

            try {
                localStorage.setItem("catalogLanguage", l);
            } catch (e) {

            }

            URLManager.setURLFromData();

            if(l == "uk" || l == "ru") $("#app").css("font-family", "Roboto");
            else $("#app").css("font-family", "DMSans");
        }
        private toggleCatalog() {
            this.$delete(Data.data, "product");

            if (Data.data.view == "pls") {
                this.$set(Data.data, "view", "general");
            } else {
                this.$set(Data.data, "view", "pls");
            }

            $(window).trigger("UPDATE_VIEW");
            URLManager.setURLFromData();
        }
    }
