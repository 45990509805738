import $ from 'jquery';
import Data from './Data';

export default class SSO {
    private onComplete: any;

    constructor(onComplete: any = null) {
        this.onComplete = onComplete;

        //@ts-ignore
        window.SSOcallback = this.SSOCallback.bind(this);
        $("#app").hide();
        $("body").append("<iframe id='SSO' width='100%' height='100%' frameBorder='0' src='sso/' style='position:absolute;'>");
    }
    private SSOCallback(user: any) {
        $("#app").show();
        $("#SSO").remove();

        if (this.onComplete) this.onComplete(user);
        else console.log("Could not callback function after SSO callback...");
    }
}