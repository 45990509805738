import $ from "jquery";
import Data from "./Data";

export default class RenderCheck {
    public render:any;
    public callback:any;
    public timer:any;
    public attemps:number = 0;

    constructor(render:any, callback:any) {
        this.render = render;
        this.callback = callback;

        if (this.render.status == "ready"){
            this.updateRenderStatusToComplete();
            return;
        }

        console.log("Start checking render status for : " + render.id);

        this.checkStatus();
        this.timer = setInterval(this.checkStatus.bind(this), Data.data.config.VGRendersCheckDelay * 1000);
    }
    private checkStatus(){
        $.ajax({ url: Data.data.platform.digitalEngineVGURL + "render-data/" + this.render.id, method: "GET", crossDomain: true }).done(this.checkComplete.bind(this)).fail(this.checkError.bind(this));
    }
    private checkComplete(response: any, e: any, r: any){
        if(r.status != 200 || !response) return;


        if (response.status != this.render.status){
            if (response.status == "ready" || response.status == "error") clearInterval(this.timer);

            if (response.status == "ready"){
                this.render.filesize = response.filesize;
                $(window).trigger("CHECK_RENDERS_QUOTA");
                $(window).trigger("UPDATE_RENDERS_IMAGES");
                this.updateRenderStatusToComplete();
                return;
            }

            this.render.status = response.status;
            if (response.status == "ready" || response.status == "complete" || response.status == "error"){
                let date: Date = new Date();
                this.render.completeDate = date.toISOString();
            }
            this.callback(this.render);
        }
    }
    private updateRenderStatusToComplete(){
        $.ajax({
            url: Data.data.platform.digitalEngineVGURL + "update-render-status/" + this.render.id,
            method: "POST",
            crossDomain: true,
            data: JSON.stringify({ status: "complete" }),
            cache: false,
            contentType: "application/json",
            processData: false
        }).done(this.statusUpdateComplete.bind(this)).fail(this.statusUpdateError.bind(this));
    }
    private statusUpdateComplete(response: any, e: any, r: any){
        if (r.status != 200 || !response){
            if (this.attemps >= 3){
                console.log("Cannot update render status to cpomplete : " + this.render.id);
                return;
            }

            this.attemps ++;
            this.updateRenderStatusToComplete();
            return;
        }

        this.render.status = "complete";
        this.callback(this.render);
    }
    private statusUpdateError(){

    }
    private checkError(){

    }
}