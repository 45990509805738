import Data from "../application/Data";
import * as BABYLON from 'babylonjs';
import VGObject from "./VGObject";
import Utils from "../utils/Utils";


export default class Content{
    public uid: string;
    public VGObject: VGObject;

    public content: string = null;
    public front: any;
    public internal: any;
    public material: any;
    public materialTexture: any;
    public texture: any;
    public box: any;
    public isActive: boolean = false;



    constructor(VGObject: VGObject){
        this.VGObject = VGObject;
        this.uid = Utils.generateUID();
    }
    public init(){
        this.material = new BABYLON.PBRMaterial(this.VGObject.mdata.id + "_" + this.uid + "ContentMaterial", this.VGObject.babylon.scene);
        //this.material.reflectionTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("appAssets/player/environments/classic.dds", this.VGObject.babylon.scene);
        this.material.directIntensity = 1;
        this.material.environmentIntensity = 1;
        this.material.backFaceCulling = true;
        this.material.forceDepthWrite = false;
        //this.material.microSurface = 1;
        this.material.subSurface.isRefractionEnabled = true;
        this.material.subSurface.indexOfRefraction = 0.01;
        //this.material.matallic = 0.9;
        //this.material.roughness = 0.8;


        this.materialTexture = new BABYLON.StandardMaterial(this.VGObject.mdata.id + "_" + this.uid + "ContentSolidMaterial", this.VGObject.babylon.scene);
        this.materialTexture.backFaceCulling = false;


        this.front = this.VGObject.contentMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_ContentFront");
        this.front.parent = this.VGObject.container;
        this.front.material = this.material;
        this.front.sideOrientation = BABYLON.Mesh.FRONTSIDE;
        this.front.alphaIndex = 4;
        this.front.visibility = 0;

        this.internal = this.VGObject.internalContentMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_IntrenalContentFront");
        this.internal.parent = this.VGObject.container;
        this.internal.material = this.material;
        this.internal.sideOrientation = BABYLON.Mesh.FRONTSIDE;
        this.internal.alphaIndex = 4;
        this.internal.visibility = 0;

        this.box = this.front.getBoundingInfo();

        this.internal.position.x = 0.15;
    }
    public enable(){
        this.front.setEnabled(true);
        if(this.isActive == true){
            this.front.visibility = 1;
        }else{
            this.front.visibility = 0;
        }
    }
    public disable(){
        // this.front.setEnabled(false);
        this.front.visibility = 0;
    }
    public setLiquidContent(hexa:string){
        this.isActive = true;
        /*if(this.texture) this.texture.dispose();

        this.texture = BABYLON.Texture.CreateFromBase64String(base64, this.uid + "Tetxure", this.VGObject.babylon.scene, false, true, BABYLON.Texture.LINEAR_LINEAR_MIPLINEAR);
        this.material.diffuseTexture = this.texture;
        this.material.diffuseTexture.hasAlpha = true;

        */
        //trace(hexa);
        let contentData:any = Data.config.contents.bottle[this.content]; //BOTTLE || JAR
        if (!contentData) contentData = Data.config.contents.jar[this.content];

        //console.log(contentData);
        this.material.diffuseTexture = null;
        this.material.subSurface.tintColor = BABYLON.Color3.FromHexString(hexa);
        this.material.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        this.material.reflectivityColor = BABYLON.Color3.FromHexString(hexa);
        this.material.alpha = Number(contentData.data.opacity);
        this.front.material = this.material;

        this.front.visibility = 1;
        this.front.alphaIndex = 4;
        this.front.scaling.x = 1;
        this.front.scaling.y = 1;
        this.front.scaling.z = -1;
        this.front.position.y = 0;
    }
    public setTexture(){
        this.isActive = true;

        let contentData: any = Data.config.contents.bottle[this.content]; //BOTTLE || JAR
        if (!contentData) contentData = Data.config.contents.jar[this.content];


        this.texture = new BABYLON.Texture(Data.config.dataFilesPath + "virtualglass/assets/contents/jar/textures/" + contentData.id + ".png?" + Math.random(), this.VGObject.babylon.scene);
        this.materialTexture.diffuseTexture = this.texture;
        this.materialTexture.diffuseTexture.hasAlpha = true;
        this.materialTexture.diffuseTexture.uScale = -1;
        this.materialTexture.diffuseTexture.vScale = -1;
        this.front.material = this.materialTexture;

        this.front.visibility = 1;
        this.front.alphaIndex = 3;
        this.front.scaling.x = 0.985;
        this.front.scaling.y = 0.985;
        this.front.scaling.z = -0.985;
        this.front.position.y += 0.0002;
    }
    public removeContent(){
        if(this.front){
            this.front.visibility = 0;
        }

        this.isActive = false;
    }
    public setContentFromBase64(imageBase64:string){
        //this.texture = BABYLON.Texture.CreateFromBase64String(imageBase64, this.frameData.name, this.scene, false, true, BABYLON.Texture.LINEAR_LINEAR_MIPLINEAR);
        //this.material.diffuseTexture = this.texture;
    }



    public showExternalContent() {
        this.texture = new BABYLON.Texture(Data.config.dataFilesPath + "virtualglass/assets/contents/jar/textures/contentDemo.png", this.VGObject.babylon.scene);
        this.materialTexture.diffuseTexture = this.texture;
        this.materialTexture.diffuseTexture.hasAlpha = true;
        this.materialTexture.diffuseTexture.uScale = -1;
        this.materialTexture.diffuseTexture.vScale = -1;
        this.front.material = this.materialTexture;

        this.front.visibility = 1;

        this.front.alphaIndex = 3;

        this.front.scaling.x = 0.985;
        this.front.scaling.y = 0.985;
        this.front.scaling.z = -0.985;
    }
    public hideExternalContent() {
        this.front.visibility = 0;
        this.internal.visibility = 0;
    }
    public showContents(){
        this.texture = new BABYLON.Texture(Data.config.dataFilesPath + "virtualglass/assets/contents/jar/textures/contentDemo.png", this.VGObject.babylon.scene);
        this.materialTexture.diffuseTexture = this.texture;
        this.materialTexture.diffuseTexture.hasAlpha = true;
        this.materialTexture.diffuseTexture.uScale = -1;
        this.materialTexture.diffuseTexture.vScale = -1;
        this.front.material = this.materialTexture;
        this.internal.material = this.materialTexture;

        this.front.visibility = 1;
        this.internal.visibility = 1;

        this.front.alphaIndex = 3;
        this.internal.alphaIndex = 3;

        this.front.scaling.x = this.internal.scaling.x = 0.985;
        this.front.scaling.y = this.internal.scaling.y =  0.985;
        this.front.scaling.z = this.internal.scaling.z = -0.985;
    }
    public hideContents() {
        this.front.visibility = 0;
        this.internal.visibility = 0;
    }
}