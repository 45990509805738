import $ from "jquery";
import Utils from "../utils/Utils";

export default class Loader {
    public files: any[] = [];
    public data: any = {};
    public response: any = {};
    public index: number = 0;
    public onComplete: any;
    public onError: any;

    constructor(onComplete: any = null, onError: any = null) {
        this.onComplete = onComplete;
        this.onError = onError;
    }
    public add(file: any) {
        file.extension = file.url.split('.').pop();

        this.files.push(file);
    }
    public get(id: string) {
        return this.data[id];
    }
    public getResponse(id: string) {
        return this.response[id];
    }
    public start() {
        this.loadNextFile();
    }
    private loadNextFile() {
        if (this.index >= this.files.length || this.files.length == 0) {
            this.filesComplete();
        } else {
            const file: any = this.files[this.index];
            file.method = file.method.toUpperCase();

            let settings: any = {
                url: file.url,
                method: file.method,
                crossDomain: true,
                cache: file.cache || false,
                headers: file.headers
            }

            if (file.method == "POST" || file.method == "PUT") {
                settings.data = file.data;
                settings.contentType = file.contentType;
                settings.processData = false;
            }

            if(file.meteor){
                settings.contentType = false;
                settings.processData = false;
                settings.type = file.type;
            }


            // window.fetch(file.url, settings).then(function (response) {
            //         console.log(response);
            //     }).catch(function (response){
            //         console.log(response);
            //     })

            $.ajax(settings).done(this.fileComplete.bind(this)).fail(this.fileError.bind(this));
        }
    }
    private addHeaders(headers: any) {
        console.log(headers);
    }
    private fileComplete(response: any, e: any, r: any) {
        let file: any = this.files[this.index];

        this.response[file.id] = r;

        //try{
        this.index++;
        let t: Loader = this;

        this.data[file.id] = response;
        this.loadNextFile();
        /*}catch(e){
            this.fileError(null);
        }*/
    }
    private fileError(response: any, e: any, r: any) {
        let file: any = this.files[this.index];
        this.response[file.id] = response;

        if (this.onError) {
            this.onError();
            return;
        }

        this.index++;
        this.loadNextFile();
    }
    private filesComplete() {
        if (this.onComplete) this.onComplete();
    }
}