
    import Generic from "../../Generic.vue";
    import Data from "@/ts/application/Data";
    import Settings from "./project/Settings.vue";
    import ProductsSettings from "./project/ProductsSettings.vue";
    import Colors from "./project/Colors.vue";
    import Contents from "./project/Contents.vue";
    import Labels from "./project/Labels.vue";
    import Item from "./project/Item.vue";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
    import VGObject from "@/ts/player/modules/VGObject";
    import Loader from "@/ts/application/Loader";

    @Component({
        components: {
            Settings,
            ProductsSettings,
            Colors,
            Contents,
            Labels,
            Item
        }
    })
    export default class Project extends Generic {
        private section:string = "settings";
        private fileLoader:Loader;

        private sectionChange(section:string){
            this.section = section;
        }

        private mounted() {
            if(Data.data.vg.project.items.length == 1){
                let item:any = Data.data.vg.project.items[0];

                Data.data.vg.item = this.getVGItemFromCurrentProject(item.id);
                if(!Data.data.vg.item) return;

                //Data.data.vg.itemData = this.getVGItem(Data.data.vg.item.itemID);
                //console.log(Data.data.vg.itemData);
                //Data.data.vg.catalogItem = Data.data.products[Data.data.vg.itemData.glassDrawing];
                //console.log(Data.data.vg.catalogItem);

                //this.editModel();
            }
        }
    }
