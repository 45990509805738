
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';


    import {
        Component,
    } from 'vue-property-decorator';
    import ProductsView from "@/components/catalog/ProductsView.vue";

    @Component({
        components: {
            vuescroll,
            ProductsView
        }
    })
    export default class Settings extends Generic {
        private section:string = "settings";

        private mounted() {

        }
        private destroyed(){

        }
        private openSection(section:string){
            this.section = section;
            this.$emit("sectionChange", section);
        }
        private isLabelSectionVisible(labelSection:string){
            if(!Data.data.vg.catalogItem) return false;

            if(labelSection == "capsuleBottle"){
                if(Data.data.vg.catalogItem.productType == "bottle") return true;
                else return false;
            }
            if(labelSection == "capsuleJar"){
                if(Data.data.vg.catalogItem.productType == "jar") return true;
                else return false;
            }

            return true;
        }
    }
