import * as PIXI from "pixi.js";
import Application from '../application/Application';
import Data from "../application/Data";


export default class PixiManager{
    public application:Application;
    public canvas:any;
    public stage:any;
    public renderer:any;
    public stats:any;

    constructor(application:Application){
        this.application = application;

        this.init();
    }
    private init(){
        //@ts-ignore
        this.renderer = new PIXI.Application({ width: 1024, height: 1024, backgroundAlpha :0, antialias:true, forceFXAA:true, roundPixels:false });
        this.renderer.start();
        this.renderer.ticker.add(this.render.bind(this));

        this.canvas = this.renderer.view;
        this.canvas.id = "PIXIStage";
        this.canvas.style = "position:absolute;";
        //$(Data.config.container).prepend(this.canvas);

        this.stage = this.renderer.stage;


        /*if(this.params.stats == true){
            this.stats = new Stats();
            $("body").append(this.stats.domElement);
            this.stats.domElement.id = "Debug";
        }*/
    }
    private render(){
        if(this.stats) this.stats.update();
    }
}