import Data from "../application/Data";
import Utils from "./Utils";
import Vue from "vue";

export default class DataUtils {
    static parseData(){
        Data.data.markets = Data.marketsJson;
        Data.data.colors = Data.colorsJson;
        Data.data.finish = Data.finishJson;

        this.setMissingTranslationsForMappings();

        //PRODUCTS
        this.parseProducts("general", Data.productsJson, Data.baseProductsJson);
        if (Data.data.platform.id == "MYVERALLIA") this.parseProducts("myproducts", Data.myProductsJson, Data.myProductsBaseProductsJson);

        Data.data.products = Data.productsJson;
        Data.data.baseProducts = Data.baseProductsJson;


        //COUNTRIES
        if (Data.data.platform.id == "INTERNAL"){
            let countries: string[] = [];
            let productOwner: string[] = [];
            let productionCountries: string[] = [];

            let str: string;
            for (str in Data.data.products) {
                if (Data.data.products[str].countries) {
                    for (let i = 0; i < Data.data.products[str].countries.length; i++) {
                        if (!Data.data.products[str].countries[i]) continue;
                        let country: string = Data.data.products[str].countries[i].toLowerCase();
                        if (Data.data.config.authorizedCountries.indexOf(country) == -1) continue;

                        if (countries.indexOf(country) == -1) countries.push(country);
                    }
                }
            }

            for (str in Data.data.baseProducts) {
                if (Data.data.baseProducts[str].productOwner) {
                    for (let i = 0; i < Data.data.baseProducts[str].productOwner.length; i++) {
                        if (!Data.data.baseProducts[str].productOwner[i]) continue;
                        let country: string = Data.data.baseProducts[str].productOwner[i].toLowerCase();
                        if (Data.data.config.authorizedCountries.indexOf(country) == -1) continue;

                        if (productOwner.indexOf(country) == -1) productOwner.push(country);
                    }
                }
                if (Data.data.baseProducts[str].productionCountries) {
                    for (let i = 0; i < Data.data.baseProducts[str].productionCountries.length; i++) {
                        if (!Data.data.baseProducts[str].productionCountries[i]) continue;
                        let country: string = Data.data.baseProducts[str].productionCountries[i].toLowerCase();
                        if (Data.data.config.authorizedCountries.indexOf(country) == -1) continue;

                        if (productionCountries.indexOf(country) == -1) productionCountries.push(country);
                    }
                }
            }

            countries.sort();
            productOwner.sort();
            productionCountries.sort();

            Data.data.config.countries = countries;

            let a: number = countries.length;
            for (let i = 0; i < a; i++) {
                if (countries[i] != "us") Data.data.countries.push({ id: countries[i], selected: false });
            }

            a = productOwner.length;
            for (let i = 0; i < a; i++) {
                if (productOwner[i] != "us") Data.data.productOwner.push({ id: productOwner[i], selected: false });
            }

            a = productionCountries.length;
            for (let i = 0; i < a; i++) {
                if (productionCountries[i] != "us") Data.data.productionCountries.push({ id: productionCountries[i], selected: false });
            }
        }
    }
    static parseProducts(mode:string, productsJson:any, baseProductsJson:any){
        //PRODUCTS
        let str: string;
        for (str in productsJson) {
            let product: any = productsJson[str];

            if (!product.parsed) {
                product.parsed = true;

                product.colorIndex = 0;
                product.bpIndex = 0;
                product.virtualGlass = false;
                product.productType = "bottle";
                product.glassDrawingVG = this.getGDWithoutVersion(product);
                product.glassDrawingLink = null;
                product.dilatationCurvesLink = null;
                product.infoDisplay = false;
                product.finish = this.setProductFinish(product);

                // if (!product.height) product.height = 10;
                // if (!product.weight) product.weight = 10;
                // if (!product.diameter) product.diameter = 10;
                // if (!product.fd) product.fd = 10;
                // if (!product.cm) product.cm = 10;
                // if (!product.overflowCapacity) product.overflowCapacity = 10;
                // if (!product.fillingHeight) product.fillingHeight = 10;
                // if (!product.finishID){
                //     // if (!product.volume) product.volume = 0.01;
                //     // product.volume *= 1000;
                // }

                if (product.height) product.height = Math.round(product.height);
                if (product.weight) product.weight = Math.round(product.weight);
                if (product.diameter) product.diameter = Math.round(product.diameter);
                if (product.fd) product.fd = Math.round(product.fd);
                if (product.overflowCapacity) product.overflowCapacity = Math.round(product.overflowCapacity) / 10;
                if (product.fillingHeight) product.fillingHeight = Math.round(product.fillingHeight);
                if (product.volume) product.volume = Math.round(product.volume * 100);
                if (product.cm) product.cm = Math.round(product.cm);

                product.owners = [];
                let a: number = product.baseProducts.length;
                for (let i = 0; i < a; i++) {
                    let bp: any = baseProductsJson[product.baseProducts[i]];
                    if (bp && bp.materialGroup.toLowerCase().indexOf("naa") == -1) {
                        product.productType = "jar";
                    }

                    if (product.owners.indexOf(bp.productOwner) == -1) product.owners.push(bp.productOwner);
                    if (product.countries.indexOf(bp.productOwner) == -1) product.countries.push(bp.productOwner);
                }

                this.extractBrandFromProduct(product);
                //if (Data.data.platform.id == "INTERNAL") this.extractBrandFromProduct(product);
            }


            if (product.height && Data.data.height.max < product.height) Data.data.height.max = Data.data.height.range[1] = product.height;
            if (product.height && Data.data.height.min > product.height) Data.data.height.min = Data.data.height.range[0] = product.height;

            if (product.weight && Data.data.weight.max < product.weight) Data.data.weight.max = Data.data.weight.range[1] = product.weight;
            if (product.weight && Data.data.weight.min > product.weight) Data.data.weight.min = Data.data.weight.range[0] = product.weight;

            if (product.diameter && Data.data.diameter.max < product.diameter) Data.data.diameter.max = Data.data.diameter.range[1] = product.diameter;
            if (product.diameter && Data.data.diameter.min > product.diameter) Data.data.diameter.min = Data.data.diameter.range[0] = product.diameter;

            if (product.fd && Data.data.finishDiameter.max < product.fd) Data.data.finishDiameter.max = Data.data.finishDiameter.range[1] = product.fd;
            if (product.fd && Data.data.finishDiameter.min > product.fd) Data.data.finishDiameter.min = Data.data.finishDiameter.range[0] = product.fd;

            if (product.cm && Data.data.carbonationMax.max < product.cm) Data.data.carbonationMax.max = Data.data.carbonationMax.range[1] = product.cm;
            if (product.cm && Data.data.carbonationMax.min > product.cm) Data.data.carbonationMax.min = Data.data.carbonationMax.range[0] = product.cm;

            if (product.volume && Data.data.volume.max < product.volume) Data.data.volume.max = Data.data.volume.range[1] = product.volume;
            if (product.volume && Data.data.volume.min > product.volume) Data.data.volume.min = Data.data.volume.range[0] = product.volume;
        }

        //BASE PRODUCTS
        for (str in baseProductsJson) {
            let baseProduct: any = baseProductsJson[str];

            if (!baseProduct.parsed){
                baseProduct.parsed = true;
                baseProduct.colors = this.setBaseProductColors(baseProduct);

                //if (baseProduct.sapColor == "01") console.log(baseProduct.colors);

                baseProduct.zoomPicture = false;
                baseProduct.pls = false;
                baseProduct.nbImages = baseProduct.images || 1;
                baseProduct.images = [Data.data.platform.productsImages + 'large/' + str + ".jpg"];



                for (let i = 1; i <baseProduct.nbImages; i++){
                    baseProduct.images.push(Data.data.platform.productsImages + 'large/' + str + "_" + i + ".jpg");
                }

                baseProduct.productOwner = [baseProduct.productOwner];
                baseProduct.productionCountries = [];
                let str2: string;
                for (str2 in baseProduct.pc){
                    baseProduct.productionCountries.push(str2);
                }

                if (Data.data.platform.id == "MYVERALLIA") this.extractFinishedProductsFromBaseProduct(mode, baseProduct, str);
            }
        }
    }
    static parseFilters(){
        let a: number = Data.data.markets.length;
        for (let i = 0; i < a; i++) {
            Data.data.markets[i].selected = false;
            Data.data.markets[i].enabled = true;

            if(Data.data.platform.id == "MYVERALLIA"){
                Data.data.markets[i].selected = Data.data.markets[i].default;
            }

            for(let k=0; k<Data.data.config.languages.length; k++){
                let l: string = Data.data.config.languages[k];

                if (!Data.data.markets[i]["name-" + l]) Data.data.markets[i]["name-" + l] = Data.data.markets[i]["name-en_us"];
            }

            let b: number = Data.data.markets[i].sub.length;
            for (let j = 0; j < b; j++) {
                Data.data.markets[i].sub[j].selected = false;
                Data.data.markets[i].sub[j].enabled = true;

                //PAS DE DEFAULT DANS LES SOUS MARCH2S
                // if (Data.data.platform.id == "MYVERALLIA") {
                //     Data.data.markets[i].sub[j].selected = Data.data.markets[i].sub[j].default;
                // }

                for (let k = 0; k < Data.data.config.languages.length; k++) {
                    let l: string = Data.data.config.languages[k];

                    if (!Data.data.markets[i].sub[j]["name-" + l]) Data.data.markets[i].sub[j]["name-" + l] = Data.data.markets[i].sub[j]["name-en_us"];
                }
            }
        }

        a = Data.data.colors.length;
        for (let i = 0; i < a; i++) {
            Data.data.colors[i].selected = false;
            Data.data.colors[i].enabled = true;

            let b: number = Data.data.colors[i].sub.length;
            for(let j=0; j<b; j++){
                Data.data.colors[i].sub[j].selected = false;
                Data.data.colors[i].sub[j].enabled = true;
            }
        }

        a = Data.data.finish.length;
        for (let i = 0; i < a; i++) {
            Data.data.finish[i].selected = false;
            Data.data.finish[i].enabled = true;

            let b: number = Data.data.finish[i].sub.length;
            for (let j = 0; j < b; j++) {
                Data.data.finish[i].sub[j].selected = false;
                Data.data.finish[i].sub[j].enabled = true;
            }
        }

        // if (Data.data.productsLines){
        //     a = Data.data.productsLines.length;
        //     for (let i = 0; i < a; i++) {
        //         Data.data.productsLines[i].selected = false;
        //         Data.data.productsLines[i].enabled = true;
        //         Data.data.productsLines[i].sub = [];
        //     }
        // }
    }
    static parseOldData() {
        Data.catalogJson.catalog.international.ra.splice(0, 1);
        Data.catalogJson.catalog.international.ra.pop();

        let catalogJson:any = Data.catalogJson.catalog;
        let str:string;
        let str2:string;
        let a:number;
        let b:number;
        let c:number;
        let d:number;


        //COLORS
        a = Data.data.colorsMapping.colors.length;
        for(let i=0; i<a; i++){
            let colorData: any = Data.data.colorsMapping.colors[i];

            let color: any = { id: colorData.id, hexa: colorData.cc, sub:[], selected:false };

            Data.data.config.languages.filter((l: string) => {
                color["name-" + l] = colorData["name-" + l];
            })

            Data.data.colors.push(color);

            let colorsUsed:any = [];

            for (str in colorData.mapping){
                for (str2 in colorData.mapping[str]){
                    if (colorsUsed.indexOf(str2) == -1) {
                        colorsUsed.push(str2);

                        let colorSub: any = { id: str2, hexa: colorData.cc, selected:false };

                        color.sub.push(colorSub);
                    }
                }
            }
        }


        //FINISH
        a = Data.data.finishMapping.finish.length;
        for (let i = 0; i < a; i++) {
            let finishData: any = Data.data.finishMapping.finish[i];

            let finish: any = { id: finishData.id, sub: [], selected: false };

            Data.data.config.languages.filter((l: string) => {
                finish["name-" + l] = finishData["name-" + l];
            })

            Data.data.finish.push(finish);

            let finishUsed: any = [];

            for (str in finishData.mapping) {
                for (let j=0; j<finishData.mapping[str].length; j++) {
                    let fid: string = finishData.mapping[str][j];

                    if (finishUsed.indexOf(fid) == -1) {
                        finishUsed.push(fid);

                        let finishSub: any = { id: fid, selected: false };

                        finish.sub.push(finishSub);
                    }
                }
            }
        }



        //COUNTRIES
        let countries: string[] = JSON.parse(JSON.stringify(Data.data.config.countries));
        a = countries.length;
        for (let i = 0; i < a; i++) {
            if (countries[i] != "us") Data.data.countries.push({ id: countries[i], selected:false });
        }

        countries.push("international");
        a = countries.length;
        for(let i=0; i<a; i++){
            let country: string = countries[i];

            if (country != "international") country = country.toUpperCase();
            let countryData: any = catalogJson[country];

            if (!countryData){
                console.log("Country : " + country + " not found in the catalog json.");
                continue;
            }

            country = country.toLowerCase();

            //MARKETS
            Data.data.markets[country] = [];
            b = countryData.ra.length;
            for (let j = 0; j < b; j++) {
                Data.data.markets[country].push(countryData.ra[j]);

                Data.data.config.languages.filter((l:string) => {
                    countryData.ra[j]["name-" + l] = countryData.ra[j].la;
                })

                countryData.ra[j].sub = countryData.ra[j].sr;
                countryData.ra[j].selected = false;

                if (country != "international") countryData.ra[j].index = parseInt(countryData.ra[j].we);
                else countryData.ra[j].index = j;

                delete countryData.ra[j].la;
                delete countryData.ra[j].sr;
                delete countryData.ra[j].we;
                delete countryData.ra[j].ch;
                delete countryData.ra[j].lan;

                c = countryData.ra[j].sub.length;
                for(let k=0; k<c; k++){

                    Data.data.config.languages.filter((l: string) => {
                        countryData.ra[j].sub[k]["name-" + l] = countryData.ra[j].sub[k].la;
                    })

                    countryData.ra[j].sub[k].selected = false;
                    if (country != "international") countryData.ra[j].sub[k].index = parseInt(countryData.ra[j].sub[k].we);
                    else countryData.ra[j].sub[k].index = k;

                    delete countryData.ra[j].sub[k].la;
                    delete countryData.ra[j].sub[k].we;
                    delete countryData.ra[j].sub[k].ch;
                    delete countryData.ra[j].sub[k].lan;
                    delete countryData.ra[j].sub[k].pl;
                    delete countryData.ra[j].sub[k].pi;
                }
            }


            if (country == "international") continue;

            //PRODUCTS
            b = countryData.mps.mp.length;
            for(let j=0; j<b; j++){
                let jsonProduct: any = countryData.mps.mp[j];

                let product: any = Data.data.products[jsonProduct.gd];

                if (!Data.data.products[jsonProduct.gd]){
                    Data.data.products[jsonProduct.gd] = { labels:{ } };

                    product = Data.data.products[jsonProduct.gd];

                    product.id = jsonProduct.gd;
                    product.salesforceId = jsonProduct.id;
                    product.name = jsonProduct.la;
                    product.glassDrawing = jsonProduct.gd;
                    product.glassDrawingSimplified = jsonProduct.gds;
                    product.height = parseInt(jsonProduct.he) || 10;
                    product.fillingHeight = parseInt(jsonProduct.fh) || 10;
                    product.diameter = parseInt(jsonProduct.di) || 10;
                    product.weight = parseInt(jsonProduct.we) || 10;
                    product.volume = parseInt(jsonProduct.vo) || 10;
                    product.overflowCapacity = parseInt(jsonProduct.oc) || 10;
                    product.finishID = jsonProduct.fi;
                    product.finishCode = jsonProduct.fis;
                    product.country = country;
                    product.countries = [];
                    product.baseProducts = [];
                    product.virtualGlass = false;
                    product.labelMaxHeight = 100;
                    product.labelBottomOffset = 10;
                    product.productType = "Bottle";
                    if (jsonProduct.vg == "true") product.virtualGlass = true;
                    //if (Math.random() < 0.5) product.virtualGlass = true; //REMOVE AFTER TEST
                    product.productsLines = [];
                    product.markets = [];
                    product.internationalMarkets = [];

                    product.searchIndex = 0;
                    product.bpIndex = 0;


                    if (Data.data.height.max < product.height) Data.data.height.max = Data.data.height.range[1] = product.height;
                    if (Data.data.height.min > product.height) Data.data.height.min = Data.data.height.range[0] = product.height;

                    if (Data.data.weight.max < product.weight) Data.data.weight.max = Data.data.weight.range[1] = product.weight;
                    if (Data.data.weight.min > product.weight) Data.data.weight.min = Data.data.weight.range[0] = product.weight;

                    if (Data.data.diameter.max < product.diameter) Data.data.diameter.max = Data.data.diameter.range[1] = product.diameter;
                    if (Data.data.diameter.min > product.diameter) Data.data.diameter.min = Data.data.diameter.range[0] = product.diameter;

                    if (Data.data.volume.max < product.volume) Data.data.volume.max = Data.data.volume.range[1] = product.volume;
                    if (Data.data.volume.min > product.volume) Data.data.volume.min = Data.data.volume.range[0] = product.volume;
                }

                product.countries.push(country);

                product.labels[country] = jsonProduct.la;

                //PRODUCTS LINES
                // if (!jsonProduct.pl) jsonProduct.pl = [];
                // c = jsonProduct.pl.length;
                // for (let k = 0; k < c; k++) {
                //     let exists: boolean = false;
                //     d = product.productsLines.length;
                //     for (let l = 0; l < d; l++) {
                //         if (jsonProduct.pl[k].id == product.productsLines[l].id) exists = true;
                //     }

                //     if (exists == false) {
                //         //console.log(product);
                //         product.productsLines.push({ id: jsonProduct.pl[k].id, sub: [] });
                //     }


                // }

                //MARKETS
                c = jsonProduct.ga.length;
                for (let k = 0; k < c; k++) {
                    let exists:boolean = false;
                    d = product.markets.length;
                    for(let l=0; l<d; l++){
                        if (jsonProduct.ga[k].id == product.markets[l].id) exists = true;
                    }

                    if(exists == false){
                        product.markets.push({ id:jsonProduct.ga[k].id, sub: [] });
                    }
                }
                c = jsonProduct.fo.length;
                for (let k = 0; k < c; k++) {
                    let exists: boolean = false;
                    d = product.markets.length;
                    for (let l = 0; l < d; l++) {
                        if (jsonProduct.fo[k].pi == product.markets[l].id){

                            let exists = false;
                            let e: number = product.markets[l].sub.length;
                            for(let m=0; m<e; m++){
                                if (jsonProduct.fo[k].id == product.markets[l].sub[m].id) exists = true;
                            }

                            if (exists == false) product.markets[l].sub.push({ id: jsonProduct.fo[k].id });
                        }
                    }
                }


                //INTERNATIONAL MARKETS
                c = jsonProduct.gaI.length;
                for (let k = 0; k < c; k++) {
                    let exists: boolean = false;
                    d = product.internationalMarkets.length;
                    for (let l = 0; l < d; l++) {
                        if (jsonProduct.gaI[k].id == product.internationalMarkets[l].id) exists = true;
                    }

                    if (exists == false) {
                        product.internationalMarkets.push({ id: jsonProduct.gaI[k].id, sub: [] });
                    }
                }
                c = jsonProduct.foI.length;
                for (let k = 0; k < c; k++) {
                    let exists: boolean = false;
                    d = product.internationalMarkets.length;
                    for (let l = 0; l < d; l++) {
                        if (jsonProduct.foI[k].pi == product.internationalMarkets[l].id) {

                            let exists = false;
                            let e: number = product.internationalMarkets[l].sub.length;
                            for (let m = 0; m < e; m++) {
                                if (jsonProduct.foI[k].id == product.internationalMarkets[l].sub[m].id) exists = true;
                            }

                            if (exists == false) product.internationalMarkets[l].sub.push({ id: jsonProduct.foI[k].id });
                        }
                    }
                }


                //FINISH
                let finish: any = this.getFinishWithID(product.finishID);
                if (finish) {
                    product.finish = [finish.finishData];
                }


                //BASEPRODUCTS
                c = jsonProduct.pr.length;
                for(let k=0; k<c; k++){
                    let jsonBaseProduct: any = jsonProduct.pr[k];
                    jsonBaseProduct.id = jsonBaseProduct.cb.substring(2, jsonBaseProduct.cb.length);

                    if (product.baseProducts.indexOf(jsonBaseProduct.id) == -1){
                        product.baseProducts.push(jsonBaseProduct.id);
                    }

                    let baseProduct: any = Data.data.baseProducts[jsonBaseProduct.id];
                    let color: any;

                    if (!Data.data.baseProducts[jsonBaseProduct.id]) {
                        Data.data.baseProducts[jsonBaseProduct.id] = {};

                        baseProduct = Data.data.baseProducts[jsonBaseProduct.id];

                        baseProduct.sapColor = jsonBaseProduct.cs;
                        baseProduct.materialGroup = jsonBaseProduct.mg.toLowerCase();
                        if (baseProduct.materialGroup.indexOf("naa") == -1) product.productType = "Jar";
                        baseProduct.productManagement = jsonBaseProduct.pme.toLowerCase();
                        baseProduct.productOwner = jsonBaseProduct.po;
                        baseProduct.countries = [product.country];
                        baseProduct.pls = false;

                        //COLORS
                        color = this.getColorWithID(baseProduct.sapColor);
                        if (color){
                            if (!color.color["name-" + product.country]) color.color["name-" + product.country] = jsonBaseProduct.cl;
                            color.color.hexa = jsonBaseProduct.cc;
                            baseProduct.colors = [color.colorData];
                        }
                    }

                    if (baseProduct.productOwner = product.country){
                        baseProduct.sapColor = jsonBaseProduct.cs;
                        baseProduct.materialGroup = jsonBaseProduct.mg.toLowerCase();
                        baseProduct.productManagement = jsonBaseProduct.pme.toLowerCase();
                        baseProduct.productOwner = jsonBaseProduct.po;

                        //COLORS
                        color = this.getColorWithID(baseProduct.sapColor);
                        if (color){
                            if (!color.color["name-" + product.country]) color.color["name-" + product.country] = jsonBaseProduct.cl;
                            color.color.hexa = jsonBaseProduct.cc;
                            baseProduct.colors = [color.colorData];
                        }
                    }
                }
            }
        }
    }
    static parsePLS() {
        try{
            let pls: any = Data.plsJson.data;

            let a:number = pls.length;
            for(let i=0; i<a; i++){
                if (Data.data.baseProducts[pls[i].baseProduct]){
                    Data.data.baseProducts[pls[i].baseProduct].pls = true;
                    if (!Data.data.plsProducts[pls[i].baseProduct]) Data.data.plsProducts[pls[i].baseProduct] = [];

                    Data.data.plsProducts[pls[i].baseProduct].push(pls[i]);

                    if (pls[i].images && pls[i].images.length > 0){
                        //for (let j = 0; j < pls[i].images.length; j++){
                        for (let j = 0; j < 1; j++){
                            //Data.data.baseProducts[pls[i].baseProduct].images.push(pls[i].images[j].urlL);
                            Data.data.baseProducts[pls[i].baseProduct].images.push(pls[i].images[j].urlL.replace("-xlarge", "-whr"));
                        }
                    }

                    pls[i].commercial = pls[i].salesGroupLastName + " " + pls[i].salesGroupFirstName;


                    // PLS SALES OFFICE
                    if (pls[i].salesOfficeDescription && Data.data.plsSalesOffice.data.indexOf(pls[i].salesOfficeDescription) == -1){
                        Data.data.plsSalesOffice.data.push(pls[i].salesOfficeDescription);
                    }

                    // PLS FILLING
                    if (pls[i].filling && Data.data.plsFilling.data.indexOf(pls[i].filling) == -1) {
                        Data.data.plsFilling.data.push(pls[i].filling);
                    }

                    // PLS CLIENTS
                    if (pls[i].customerName && Data.data.plsClients.data.indexOf(pls[i].customerName) == -1) {
                        Data.data.plsClients.data.push(pls[i].customerName);
                    }

                    // PLS COMMERCIAL
                    if (pls[i].commercial && Data.data.plsCommercial.data.indexOf(pls[i].commercial) == -1) {
                        Data.data.plsCommercial.data.push(pls[i].commercial);
                    }

                    // PLS DECORATORS
                    if (pls[i].decoration && Data.data.plsDecorators.data.indexOf(pls[i].decoration) == -1) {
                        Data.data.plsDecorators.data.push(pls[i].decoration);
                    }

                    // PLS COMMUNICATION
                    if (pls[i].communication && Data.data.plsCommunication.data.indexOf(pls[i].communication) == -1) {
                        Data.data.plsCommunication.data.push(pls[i].communication);
                    }

                    // PLS CFEATION DATE
                    if (pls[i].creationDate){
                        pls[i].creationDate = pls[i].creationDate.split("T")[0];

                        if (Data.data.plsCreationDate.dates.indexOf(pls[i].creationDate) == -1) {
                            Data.data.plsCreationDate.dates.push(pls[i].creationDate);
                        }

                        Data.data.plsCreationDate.dates.sort(function (a, b){
                            return a.localeCompare(b)
                        });

                        Data.data.plsCreationDate.startDate = Data.data.plsCreationDate.dates[0];
                        Data.data.plsCreationDate.endDate = Data.data.plsCreationDate.dates[Data.data.plsCreationDate.dates.length - 1];
                    }

                    // PLS LAUNCH DATE
                    if (pls[i].launchDate) {
                        pls[i].launchDate = pls[i].launchDate.split("T")[0];

                        if (Data.data.plsLaunchDate.dates.indexOf(pls[i].launchDate) == -1) {
                            Data.data.plsLaunchDate.dates.push(pls[i].launchDate);
                        }

                        Data.data.plsLaunchDate.dates.sort(function (a, b) {
                            return a.localeCompare(b)
                        });

                        Data.data.plsLaunchDate.startDate = Data.data.plsLaunchDate.dates[0];
                        Data.data.plsLaunchDate.endDate = Data.data.plsLaunchDate.dates[Data.data.plsLaunchDate.dates.length - 1];
                    }
                }
            }
        }catch(e){
            console.log("ERROR IN PLS FILE !!!");
            console.log(e);
        }
    }
    static getColorWithID(id:string):any{
        for(let i=0; i<Data.data.colors.length; i++){
            let color: any = Data.data.colors[i];

            for(let j=0; j<color.sub.length; j++){
                if (String(color.sub[j].id) === String(id)){
                    let colorData: any = { id: color.sub[j].id };
                    return { color: color.sub[j], colorData: { id: color.id, sub: [colorData] } };
                }
            }
        }

        return null;
    }
    static getFinishWithID(id: string): any {
        for (let i = 0; i < Data.data.finish.length; i++) {
            let finish: any = Data.data.finish[i];

            for (let j = 0; j < finish.sub.length; j++) {
                if (finish.sub[j].id == id) {
                    let finishData: any = { id: finish.sub[j].id };
                    return { finish: finish.sub[j], finishData: { id: finish.id, sub: [finishData] } };
                }
            }
        }

        return null;
    }
    static setProductFinish(product:any){
        if (!product.finishID){
            if (product.finishFamily && !product.finishSubFamily) return [{ id: product.finishFamily, sub: [] }];
            else return [{ id: product.finishFamily, sub: [{ id: product.finishSubFamily }] }];
        } else {
            for (let i = 0; i < Data.data.finish.length; i++) {
                let finish: any = Data.data.finish[i];

                if (finish.id == product.finishID) {
                    return [{ id: finish.id, sub: [] }];
                }

                for (let j = 0; j < finish.sub.length; j++) {
                    if (finish.sub[j].id == product.finishID) {
                        return [{ id: finish.id, sub: [{ id: finish.sub[j].id }] }];
                    }
                }
            }
        }

        return null;
    }
    static setBaseProductColors(baseProduct: any) {
        for (let i = 0; i < Data.data.colors.length; i++) {
            let color: any = Data.data.colors[i];

            // if (color.id == baseProduct.sapColor) {
            //     return [{ id: color.id, sub: [] }];
            // }

            for (let j = 0; j < color.sub.length; j++) {
                //

                if (String(color.sub[j].id) === String(baseProduct.sapColor)) {
                    return [{ id: color.id, sub: [{ id: color.sub[j].id }] }];
                }
            }
        }

        return null;
    }
    static parseVirtualGlassModels(){
        if (!Data.data.vg.modelsList) return;

        let str:string;
        for (str in Data.data.products){
            let product:any = Data.data.products[str];
            let pgd: string = product.glassDrawingVG;
            if (Data.data.platform.useOldVirtualGlass) pgd = pgd.toLowerCase();


            if (Data.data.vg.modelsList.indexOf(pgd) != -1 || Data.data.vg.modelsList.indexOf(product.glassDrawing) != -1){
                Vue.set(product, "virtualGlass", true);
            }else{
                for (let i = 0; i < Data.data.vg.modelsList.length; i++){
                    let gd: string = Data.data.vg.modelsList[i];
                    if (pgd.indexOf(gd) != -1 || product.glassDrawing.indexOf(gd) != -1 || gd.indexOf(pgd) != -1 || gd.indexOf(product.glassDrawing) != -1){
                        Vue.set(product, "glassDrawingVG", gd);
                        Vue.set(product, "virtualGlass", true);
                        break;
                    }
                }
            }

            //if (!product.virtualGlass) console.log(product.glassDrawing + " : " + product.glassDrawingVG);
        }

        if (Data.data.platform.id == "MYVERALLIA"){
            let str: string;
            for (str in Data.myProductsJson) {
                let product: any = Data.myProductsJson[str];
                let pgd: string = product.glassDrawingVG;
                if (Data.data.platform.useOldVirtualGlass) pgd = pgd.toLowerCase();


                if (Data.data.vg.modelsList.indexOf(pgd) != -1 || Data.data.vg.modelsList.indexOf(product.glassDrawing) != -1) {
                    Vue.set(product, "virtualGlass", true);
                } else {
                    for (let i = 0; i < Data.data.vg.modelsList.length; i++) {
                        let gd: string = Data.data.vg.modelsList[i];
                        if (pgd.indexOf(gd) != -1 || product.glassDrawing.indexOf(gd) != -1 || gd.indexOf(pgd) != -1 || gd.indexOf(product.glassDrawing) != -1) {
                            Vue.set(product, "glassDrawingVG", gd);
                            Vue.set(product, "virtualGlass", true);
                            break;
                        }
                    }
                }
            }
        }
    }
    static extractFinishedProductsFromBaseProduct(mode:string, baseProduct:any, id:string){
        if (!Data.data.finishedProducts[id]) Data.data.finishedProducts[id] = [];

        if (!baseProduct.fip || baseProduct.fip.length == 0) return;


        let finishedProducts: any = baseProduct.fip;
        baseProduct.selected_line = 0;

        let a: number = finishedProducts.length;
        for (let i = 0; i < a; i++) {

            finishedProducts[i].id = finishedProducts[i].cf.slice(2);
            finishedProducts[i].channel = finishedProducts[i].cf.substring(0, 2);
            delete finishedProducts[i].cf;


            if (finishedProducts[i].mg) finishedProducts[i].materialGroup = finishedProducts[i].mg.toLowerCase();
            delete finishedProducts[i].mg;

            finishedProducts[i].quantity = 1;
            finishedProducts[i].display = true;

            if (finishedProducts[i].materialGroup) {
                if (finishedProducts[i].materialGroup.toLowerCase() == "naac" || finishedProducts[i].materialGroup.toLowerCase() == "naaa" || finishedProducts[i].materialGroup.toLowerCase() == "naba" || finishedProducts[i].materialGroup.toLowerCase() == "nabc") finishedProducts[i].display = true;
                else finishedProducts[i].display = false;
            }

            if (!finishedProducts[i].materialGroup) finishedProducts[i].display = false;
            if (finishedProducts[i].stsp) finishedProducts[i].standard = finishedProducts[i].stsp.toLowerCase();
            delete finishedProducts[i].stsp;


            finishedProducts[i].palletHeight = finishedProducts[i].hp;
            delete finishedProducts[i].hp;

            finishedProducts[i].nbRows = finishedProducts[i].nip;
            delete finishedProducts[i].nip;

            finishedProducts[i].nbLayer = finishedProducts[i].nl;
            delete finishedProducts[i].nl;

            finishedProducts[i].nbUnitLayer = finishedProducts[i].ni;
            delete finishedProducts[i].ni;

            finishedProducts[i].grossWeight = finishedProducts[i].wg;
            delete finishedProducts[i].wg;

            finishedProducts[i].palletCode = finishedProducts[i].pm;
            delete finishedProducts[i].pm;

            finishedProducts[i].palletType = finishedProducts[i].fc;
            delete finishedProducts[i].fc;

            finishedProducts[i].layerDisposition = finishedProducts[i].pa;
            delete finishedProducts[i].pa;



            let status: any = ["st", "sr"];
            //if (finishedProducts[i].standard && status.indexOf(finishedProducts[i].standard.toLowerCase()) == -1) finishedProducts[i].display = false;
            //if (!finishedProducts[i].standard) finishedProducts[i].display = false;

            if (mode == "myproducts" && finishedProducts[i].display) {
                if (finishedProducts[i].fp == true) {
                    baseProduct.favorite = true;
                }

                finishedProducts[i].favorite = finishedProducts[i].fp;
            }


            if (finishedProducts[i].display){
                let FPFound:any = false;
                for (let j = 0; j < Data.data.finishedProducts[id].length; j++){
                    if (Data.data.finishedProducts[id][j].id == finishedProducts[i].id){
                        FPFound = Data.data.finishedProducts[id][j];

                        if (mode == "myproducts"){
                            Data.data.finishedProducts[id][j].favorite = finishedProducts[i].favorite;
                            //if (finishedProducts[i].id == "9073952") console.log("found", Data.data.finishedProducts[id][j]);
                        }

                        break;
                    }
                }

                if (!FPFound) Data.data.finishedProducts[id].push(finishedProducts[i]);
            }
        }
    }
    static extractBrandFromProduct(product:any){
        product.brands = [{ id: product.br, sub: [] }];

        for(let i=0; i<Data.data.brands.length; i++){
            if (Data.data.brands[i].id == product.br) return;
        }

        let brand: any = { id: product.br, sub: [], enabled:true, selected:false };
        for (let i = 0; i < Data.data.config.languages.length; i++) {
            let l: string = Data.data.config.languages[i];

            brand["name-" + l] = product.br;
        }

        Data.data.brands.push(brand);
    }




    static getGDWithoutVersion(metaProduct:any) {
        if (!metaProduct.glassDrawing) metaProduct.glassDrawing = "";


        var gd_without_version = metaProduct.glassDrawing;
        if (Data.data.useOldVirtualGlass) gd_without_version = gd_without_version.toLowerCase();

        if (gd_without_version.toLowerCase().indexOf("ps_") != -1) return gd_without_version.substring(0, gd_without_version.length - 2);

        var de_plan = false;

        for (var i = 0; i < 10; i++) {
            if (gd_without_version.indexOf(i + "-") == 0) {
                de_plan = true;
                break;
            }
        }

        if (gd_without_version.indexOf("77") == 0 || gd_without_version.indexOf("50") == 0 || gd_without_version.indexOf("76") == 0 || gd_without_version.indexOf("11") == 0) {
            var tab = [];
            tab = gd_without_version.split("_");
            if (tab.length > 2) {
                gd_without_version = tab[0] + "_" + tab[1];
                return gd_without_version;
            }

            if (gd_without_version.length > 5 && gd_without_version.indexOf("-") == -1) {
                gd_without_version = gd_without_version.slice(0, -1);
                return gd_without_version;
            }
        } else if (metaProduct.countries.indexOf("fr") != -1 && de_plan == false) {
            var tab = [];
            tab = gd_without_version.split("_");

            if (tab.length > 2) {
                gd_without_version = tab[0] + "_" + tab[1];
            } else {
                //if (metaProduct.ac == "Z4" || metaProduct.ac == "Z0") {
                    if (gd_without_version.indexOf("77") == 0 || gd_without_version.indexOf("50") == 0 || gd_without_version.indexOf("76") == 0 || gd_without_version.indexOf("11") == 0) {
                        if (gd_without_version.length > 5) {
                            gd_without_version = gd_without_version.slice(0, -1);
                            return gd_without_version;
                        }
                    }
                //}
            }
        } else if (metaProduct.countries.indexOf("sp") != -1 || metaProduct.countries.indexOf("ua") != -1 || metaProduct.countries.indexOf("pt") != -1 || metaProduct.countries.indexOf("ib") != -1) {
            if (gd_without_version.indexOf("z-") != -1) {
                var tab = [];
                tab = gd_without_version.split("-");
                if (tab.length == 3) {
                    gd_without_version = tab[0] + "-" + tab[1];
                }
            } else {
                if (metaProduct.countries.indexOf("sp") != -1 || metaProduct.countries.indexOf("pt") != -1 || metaProduct.countries.indexOf("ib") != -1) {
                    //if(gd_without_version.length == 6 && gd_without_version.indexOf("_") == -1 && gd_without_version.charAt(5) == "W"){
                    if (gd_without_version.length == 6 && gd_without_version.charAt(4) == "w") {
                        gd_without_version = gd_without_version.substring(0, gd_without_version.length - 1);
                    }
                }
            }
        } else if (metaProduct.countries.indexOf("de") != -1 || de_plan == true) {
            var tab = [];
            tab = gd_without_version.split("-");
            if (tab.length == 3) {
                gd_without_version = tab[0] + "-" + tab[1];
            }
        } else if (metaProduct.countries.indexOf("ar") != -1) {
            if (gd_without_version.length == 10) gd_without_version = gd_without_version.substring(0, gd_without_version.length - 1);
        } else if (metaProduct.countries.indexOf("cl") != -1) {
            var tab = [];
            tab = gd_without_version.split("_");
            if (tab.length == 4) {
                gd_without_version = tab[0] + "_" + tab[1] + "_" + tab[2];
            }
        }

        return gd_without_version;
    }
    static getChannelCodeFromCountry(country: string) {
        let str: string;
        for (str in Data.data.config.companyCode) {
            if (Data.data.config.companyCode[str].country == country) return str;
        }
    }
    static getCompanyCodeFromCountry(country:string){
        let str:string;
        for (str in Data.data.config.companyCode){
            if (Data.data.config.companyCode[str].country == country) return Data.data.config.companyCode[str].code;
        }
    }
    static getProductName(product: any) {
        if(Data.data.platform.id == "INTERNAL"){
            if (product.name) return product.name;

            return product.id;
        }


        let language: string = Data.data.language;
        let label: string = product.labels[language];

        if(!label){
            let str: string;
            for (str in product.labels) {
                label = product.labels[str];
                break;
            }
        }

        if (!label) label = product.name;


        return label;


        let country:string = Utils.getCountryFromLanguage(language);
        country = null;
        if (!country) country = Data.data.baseProducts[product.baseProducts[product.bpIndex]].productOwner[0];
        //let label: string = product.labels[country + "_" + language];

        country = Data.data.baseProducts[product.baseProducts[product.bpIndex]].productOwner[0];

        if (!label) label = product.labels[country + "_" + language];


        if (!label){
            let languages: string = Data.data.config.countriesLanguages[country];
            language = "en_us";
            if (languages) language = languages[0];

            label = product.labels[country + "_" + language];
        }


        if (!label && Data.data.language != "en_us") label = product.labels[country + "_en_us"];
        //label = product.labels[owner + "_" + language];

        if (!label) {
            let str: string;
            for (str in product.labels) {
                label = product.labels[str];
                break;
            }
        }

        if (!label) label = product.name;

        return label;
    }
    static getVGColorFromProduct(product:any, bpCode:string){
        let baseProduct: any = Data.data.baseProducts[bpCode];

        for(let str in Data.data.vg.colors){
            let color: any = Data.data.vg.colors[str];

            if (color.sapColor == baseProduct.sapColor){
                for (let i = 0; i < baseProduct.countries.length; i++){
                    let bpCountry: string = baseProduct.countries[i];

                    if (color.countries.indexOf(bpCountry) != -1){
                        console.log("found color", str);
                        return str;
                    }
                }
            }
        }

        return null;
    }
    static mapOldLanguagesForVG() {
        console.log("mapping old languages for colors and contents");

        let str: string;
        for (str in Data.data.vg.colors) {
            Data.data.vg.colors[str].translations;

            let str2: string
            for (str2 in Data.data.config.oldLanguagesMappingVG) {
                if (Data.data.vg.colors[str].translations[str2]) {
                    let newL: string = Data.data.config.oldLanguagesMappingVG[str2];

                    Data.data.vg.colors[str].translations[newL] = Data.data.vg.colors[str].translations[str2];
                    delete Data.data.vg.colors[str].translations[str2];
                }
            }
        }


        let str3: string;
        for (str3 in Data.data.vg.contents) {
            for (str in Data.data.vg.contents[str3]) {
                Data.data.vg.contents[str3][str].translations;

                let str2: string
                for (str2 in Data.data.config.oldLanguagesMappingVG) {
                    if (Data.data.vg.contents[str3][str].translations[str2]) {
                        let newL: string = Data.data.config.oldLanguagesMappingVG[str2];

                        Data.data.vg.contents[str3][str].translations[newL] = Data.data.vg.contents[str3][str].translations[str2];
                        delete Data.data.vg.contents[str3][str].translations[str2];
                    }
                }
            }
        }
    }
    private static setMissingTranslationsForMappings(){
        //to do if necessary to have all keys for every languages
    }
}