
    import Generic from "../../Generic.vue";
    import Utils from "../../../ts/utils/Utils";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";

    @Component({
        components: {

        },
        props: {

        }
    })

    export default class SpecsData extends Generic {
        private models:any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
        private headers:any = null;


        private mounted() {
            $(window).on("UPDATE_PRODUCT", this.updateData.bind(this));
            this.updateData();
        }
        private destroyed(){
            $(window).off("UPDATE_PRODUCT");
        }
        private updateData(){
            console.log("updateData");
            this.headers = {};
            let baseProduct: any = Data.data.baseProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]];
            let value: string;

            if (Data.data.platform.id == "INTERNAL") {
                this.headers.productOwnership = { fields: [] };
                this.headers.productOwnership.fields.push({ id: "productOwner", value: this.loc("country-" + baseProduct.productOwner[0]) });
            }

            this.headers.markets = { fields: [] };
            this.headers.markets.fields.push({ id: "markets", value: this.getMarketsNames(Data.data.product) });


            this.headers.dimensionShape = { fields: [] };
            if (Data.data.product.weight) this.headers.dimensionShape.fields.push({ id: "weight", value: Data.data.product.weight + " " + this.loc("product-weightUnits") });
            else this.headers.dimensionShape.fields.push({ id: "weight", value: "-" });
            if (Data.data.product.height) this.headers.dimensionShape.fields.push({ id: "height", value: Data.data.product.height + " " + this.loc("product-heightUnits") });
            else this.headers.dimensionShape.fields.push({ id: "height", value: "-" });
            if (Data.data.product.diameter) this.headers.dimensionShape.fields.push({ id: "diameter", value: Data.data.product.diameter + " " + this.loc("product-diameterUnits") });
            else this.headers.dimensionShape.fields.push({ id: "diameter", value: "-" });
            if (Data.data.product.md) this.headers.dimensionShape.fields.push({ id: "maxDiameter", value: Data.data.product.md + " " + this.loc("product-diameterUnits") });
            else this.headers.dimensionShape.fields.push({ id: "maxDiameter", value: "-" });
            if (Data.data.product.labelMaxHeight) this.headers.dimensionShape.fields.push({ id: "labelMaxHeight", value: Data.data.product.labelMaxHeight + " " + this.loc("product-heightUnits") });
            else this.headers.dimensionShape.fields.push({ id: "labelMaxHeight", value: "-" });
            if (Data.data.product.sh) this.headers.dimensionShape.fields.push({ id: "shape", value: this.getProductShape(Data.data.product.sh) });
            else this.headers.dimensionShape.fields.push({ id: "shape", value: "-" });

            this.headers.capacity = { fields: [] };
            if (Data.data.product.volume) this.headers.capacity.fields.push({ id: "volume", value: Data.data.product.volume + " " + this.loc("product-volumeUnits") });
            else this.headers.capacity.fields.push({ id: "volume", value: "-" });
            if (Data.data.product.overflowCapacity) this.headers.capacity.fields.push({ id: "overflowCapacity", value: Data.data.product.overflowCapacity + " " + this.loc("product-overflowCapacityUnits") });
            else this.headers.capacity.fields.push({ id: "overflowCapacity", value: "-" });
            if (Data.data.product.fillingHeight) this.headers.capacity.fields.push({ id: "fillingHeight", value: Data.data.product.fillingHeight + " " + this.loc("product-heightUnits") });
            else this.headers.capacity.fields.push({ id: "fillingHeight", value: "-" });

            this.headers.colors = { fields: [] };
            this.headers.colors.fields.push({ id: "colorFamily", value: this.getColorFamilyName(baseProduct) });
            this.headers.colors.fields.push({ id: "color", value: this.getColorName(baseProduct) });

            this.headers.finish = { fields: [] };
            this.headers.finish.fields.push({ id: "finishFamily", value: this.getFinishFamilyName(Data.data.product) });
            this.headers.finish.fields.push({ id: "finishSubFamily", value: this.getFinishName(Data.data.product) });
            this.headers.finish.fields.push({ id: "finishType", value: Data.data.product.finishTypeCode });

            value = this.loc("yes");
            if (!Data.data.product.vi) value = this.loc("no");
            this.headers.finish.fields.push({ id: "vinolok", value: value });
            // this.headers.finish.fields.push({ id:"closureOptions", field:"closureOptions" });
            if (Data.data.product.fd) this.headers.finish.fields.push({ id: "finishDiameter", value: Data.data.product.fd + " " + this.loc("product-diameterUnits") });
            else this.headers.finish.fields.push({ id: "finishDiameter", value: "-" });

            this.headers.brandProductLine = { fields: [] };
            this.headers.brandProductLine.fields.push({ id: "brand", value: Data.data.product.br });
            this.headers.brandProductLine.fields.push({ id: "productLine", value: Data.data.product.pl });

            value = this.loc("yes");
            if (!Data.data.product.ec) value = this.loc("no");
            this.headers.brandProductLine.fields.push({ id: "ecova", value: value });

            this.headers.technical = { fields: [] };
            if (Data.data.product.cm) this.headers.technical.fields.push({ id: "compressionMax", value: Data.data.product.cm + " " + this.loc("product-compressionMaxUnits") });
            else this.headers.technical.fields.push({ id: "compressionMax", value: "-" });
            if (Data.data.product.pm) this.headers.technical.fields.push({ id: "pressureMax", value: Data.data.product.pm });
            else this.headers.technical.fields.push({ id: "pressureMax", value: "-" });
            if (Data.data.product.tm) this.headers.technical.fields.push({ id: "maxThermicChoc", value: Data.data.product.tm });
            else this.headers.technical.fields.push({ id: "maxThermicChoc", value: "-" });

            value = this.loc("yes");
            if (!Data.data.product.pa) value = this.loc("no");
            this.headers.technical.fields.push({ id: "pasteurisation", value: value });

            value = this.loc("yes");
            if (!Data.data.product.st) value = this.loc("no");
            this.headers.technical.fields.push({ id: "stacking", value: value });

            value = this.loc("yes");
            if (!Data.data.product.re) value = this.loc("no");
            this.headers.technical.fields.push({ id: "reuse", value: value });

            if (Data.data.platform.id == "INTERNAL") {
                this.headers.plm = { fields: [] };
                this.headers.plm.fields.push({ id: "status", value: baseProduct.status });

                let d: string = baseProduct.rd;
                if (d && d != "") {
                    d = this.formatDate(d, false);
                }
                this.headers.plm.fields.push({ id: "launchDate", value: d });

                d = baseProduct.bd;
                if (d && d != "") {
                    d = this.formatDate(d, false);
                }
                this.headers.plm.fields.push({ id: "blockingDate", value: d });

                this.headers.supply = { fields: [] };
                this.headers.supply.fields.push({ id: "leaderPlant", value: baseProduct.lp });

                value = "";
                let str: string;
                for (str in baseProduct.pc) {
                    value += this.loc("country-" + str) + ", ";
                }
                value = value.slice(0, -2);

                this.headers.supply.fields.push({ id: "productionCountry", value: value });
                this.headers.supply.fields.push({ id: "onOrder", value: Data.data.product.oo });
                this.headers.supply.fields.push({ id: "stockFlag", value: baseProduct.sf });
                this.headers.supply.fields.push({ id: "supplyInfo", value: baseProduct.labels });
            }
        }
    }
