
    import Generic from "./Generic.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';


    @Component
    export default class Overlay extends Generic {
        private status: boolean = true;
        private loading: boolean = false;

        private mounted() {
            $(window).on("SHOW_OVERLAY", this.showOverlay.bind(this));
            $(window).on("HIDE_OVERLAY", this.hideOverlay.bind(this));

            $(window).on("SHOW_LOADING", this.showLoading.bind(this));
            $(window).on("HIDE_LOADING", this.hideLoading.bind(this));
        }
        private showOverlay(){
            this.status = true;
        }
        private hideOverlay(){
            this.status = false;
        }
        private showLoading(){
            this.loading = true;
        }
        private hideLoading(){
            this.loading = false;
        }
    }
