import * as BABYLON from 'babylonjs';
import 'babylonjs-loaders';
import BabylonManager from '../../managers/BabylonManager';

export default class SceneClassic{
    public babylon:BabylonManager;
    public lights:any = {};
    public cudeTexture:any;
    public id:string = "classic";

    public light0:any;
    public light1:any;
    public light2:any;
    public tableMaterial:any;
    public table:any;



    constructor(babylon: BabylonManager){
        this.babylon = babylon;

        this.init();
    }
    public init(){
        this.initLights();
        this.initTextures();
    }
    public destroy(){

    }
    public initLights(){
        //this.babylon.scene.environmentTexture = BABYLON.CubeTexture.CreateFromPrefilteredData("appAssets/player/environments/classic.dds", this.babylon.scene);

        //let hdrTexture: any = BABYLON.CubeTexture.CreateFromPrefilteredData("appAssets/player/environments/environmentSpecular.env", this.babylon.scene);
        let hdrTexture: any = new BABYLON.HDRCubeTexture(this.babylon.application.player.config.assetsPath + "environments/AreaLight360.hdr", this.babylon.scene, 128, false, true, false, true);
        this.babylon.scene.environmentTexture = hdrTexture;
        //hdrTexture.level = 0.5;


        // let hdrRotation:number = -60; // in degrees
        // hdrTexture.setReflectionTextureMatrix(
        //     BABYLON.Matrix.RotationY(
        //         BABYLON.Tools.ToRadians(hdrRotation)
        //     )
        // );


        //this.babylon.scene.createDefaultSkybox(this.babylon.scene.environmentTexture, true, 10000, );


        //this.babylon.scene.fogMode = BABYLON.Scene.FOGMODE_EXP;
        //this.babylon.scene.fogColor = new BABYLON.Color3(0.9, 0.9, 0.85);
        //this.babylon.scene.fogDensity = 0.5;

        //ground.material.gridOffset = new BABYLON.Vector3(0.5, 0, 0);




        this.light0 = new BABYLON.HemisphericLight("sceneClassicLight0", new BABYLON.Vector3(0, 1, -1), this.babylon.scene);
        this.light0.intensity = 1;

        this.light1 = new BABYLON.HemisphericLight("sceneClassicLight1", new BABYLON.Vector3(0, 1, 1), this.babylon.scene);
        this.light1.intensity = 1;

        this.light2 = new BABYLON.HemisphericLight("sceneClassicLight2", new BABYLON.Vector3(0, -1, 0), this.babylon.scene);
        this.light2.intensity = 0.5;

        // this.light1 = new BABYLON.PointLight("sceneClassicPointLight0", new BABYLON.Vector3(0, 50, 50), this.babylon.scene);
        // this.light1.intensity = 1;
        // this.light1.diffuse = new BABYLON.Color3(1, 1, 1);
        // this.light1.specular = new BABYLON.Color3(1, 1, 1);
        // this.light1.intensityMode = BABYLON.Light.INTENSITYMODE_AUTOMATIC;

        //this.createTable();
    }
    async createTable(){
        await BABYLON.SceneLoader.AppendAsync("https://patrickryanms.github.io/BabylonJStextures/Demos/sodaBottle/assets/gltf/table.gltf");
        this.table = this.babylon.scene.getMeshByName("table_low");
    }
    public update(){
        //trace(this.babylon.camera.rotation.y);
        if(this.babylon.camera.rotation.y > 0.4) this.babylon.camera.rotation.y = 0.4;
        if(this.babylon.camera.rotation.y < -0.4) this.babylon.camera.rotation.y = -0.4;
    }
    public initTextures(){

    }
    public cubeTextureLoaded(cudeTexture:any){


        $(window).trigger("SCENE_CHANGE");
    }
}