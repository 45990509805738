
import Generic from "../../Generic.vue";
import Utils from "../../../ts/utils/Utils";
import $ from "jquery";

import {
    Component,
} from 'vue-property-decorator';
import Data from "@/ts/application/Data";

@Component({
    components: {

    },
    props: {

    }
})

export default class PLSSpecsData extends Generic {
    private models: any = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16];
    private headers: any = null;
    private currentImage: number = 0;


    private mounted() {
        $(window).on("UPDATE_PRODUCT", this.updateData.bind(this));
        this.updateData(null, 0);
    }
    private destroyed() {
        $(window).off("UPDATE_PRODUCT");
    }
    private updateData(e:any, index:number) {
        if(!index) index = 0;
        this.currentImage = index;

        let baseProduct: any = Data.data.baseProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]];
        let plsProduct: any = Data.data.plsProducts[Data.data.product.baseProducts[Data.data.product.bpIndex]][this.currentImage];
        let value: string;
        this.headers = {};


        this.headers.description = { fields: [] };
        if (plsProduct.description) this.headers.description.fields.push({ id: "description", value: plsProduct.description });
        else this.headers.description.fields.push({ id: "description", value: "-" });

        this.headers.salesData = { fields: [] };
        if (plsProduct.baseProductName) this.headers.salesData.fields.push({ id: "baseProductName", value: plsProduct.baseProductName });
        else this.headers.salesData.fields.push({ id: "baseProductName", value: "-" });

        if (plsProduct.filling) this.headers.salesData.fields.push({ id: "filling", value: plsProduct.filling });
        else this.headers.salesData.fields.push({ id: "filling", value: "-" });

        if (plsProduct.customerName) this.headers.salesData.fields.push({ id: "customerName", value: plsProduct.customerName });
        else this.headers.salesData.fields.push({ id: "customerName", value: "-" });

        if (plsProduct.salesGroupFirstName) this.headers.salesData.fields.push({ id: "commercial", value: plsProduct.salesGroupFirstName + " " + plsProduct.salesGroupLastName });
        else this.headers.salesData.fields.push({ id: "commercial", value: "-" });

        if (plsProduct.communication) this.headers.salesData.fields.push({ id: "communication", value: plsProduct.communication });
        else this.headers.salesData.fields.push({ id: "communication", value: "-" });

        if (plsProduct.plantName) this.headers.salesData.fields.push({ id: "plantName", value: plsProduct.plantName  });
        else this.headers.salesData.fields.push({ id: "plantName", value: "-" });

        if (plsProduct.publicSalesPrice) this.headers.salesData.fields.push({ id: "price", value: plsProduct.publicSalesPrice + " " + plsProduct.publicSalesPriceUnit });
        else this.headers.salesData.fields.push({ id: "price", value: "-" });

        if (plsProduct.yearlyQuant) this.headers.salesData.fields.push({ id: "salesQuantity", value: plsProduct.yearlyQuant + " " + plsProduct.yearlyQuantUnit });
        else this.headers.salesData.fields.push({ id: "salesQuantity", value: "-" });

        if (plsProduct.creationDate) this.headers.salesData.fields.push({ id: "creationDate", value: this.formatDate(plsProduct.creationDate) });
        else this.headers.salesData.fields.push({ id: "creationDate", value: "-" });

        if (plsProduct.launchDate) this.headers.salesData.fields.push({ id: "launchDate", value: this.formatDate(plsProduct.launchDate) });
        else this.headers.salesData.fields.push({ id: "launchDate", value: "-" });


    }
}
