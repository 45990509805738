
    import Generic from "../../../Generic.vue";
    import Data from "@/ts/application/Data";
    import Colors from "./Colors.vue";
    import Contents from "./Contents.vue";
    import Labels from "./Labels.vue";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
import VGObject from "@/ts/player/modules/VGObject";

    @Component({
        components: {
            Colors,
            Contents,
            Labels
        }
    })
    export default class Item extends Generic {
        private section:string = "item";
        private labelsSections:any = ["label", "backLabel", "decor", "capsuleBottle", "capsuleJar"];
        private modelData:any = null;

        private mounted() {
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            if(model) this.modelData = model.getProductData();
            // window.PLAYER.focusOnModel(Data.data.vg.item.id);

            // for(let i=0; i<Data.data.vg.project.items.length; i++){
            //     if(Data.data.vg.project.items[i].id != Data.data.vg.item.id){
            //         let model:VGObject = window.PLAYER.getModel(Data.data.vg.project.items[i].id);
            //         model.hide();
            //     }
            // }
        }
        private destroyed(){
            // if(!Data.data.vg.project) return;

            // for(let i=0; i<Data.data.vg.project.items.length; i++){
            //     let model:VGObject = window.PLAYER.getModel(Data.data.vg.project.items[i].id);
            //     model.show();
            // }
        }
        private save(){
            this.$emit("close");
        }
        private removeContent(){
            Data.data.vg.itemData.contentID = null;
            Data.data.vg.itemData.contentFilling = 1;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.removeContent();
        }
        private removeCapsule(){
            Data.data.vg.itemData.capsuleID = null;
            //Data.data.vg.itemData.capsule.type = null;
            Data.data.vg.itemData.capsuleShininess = 0.5;

            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.removeCapsule();
        }
        private setCapsuleShininess(){
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setCapsuleShininess(Data.data.vg.itemData.capsuleShininess);
        }
        private removeLabel(key:string){
            if(key == "label" || key == "backLabel" || key == "decor"){
                Data.data.vg.itemData[key + "ID"] = null;
                //Data.data.vg.itemData[key].image = null;
                Data.data.vg.itemData[key + "Position"] = 0;
                Data.data.vg.itemData[key + "Height"] = 0;
                if(key == "decor") Data.data.vg.itemData[key + "Rotation"] = 0;

                let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
                model.removeLabel(key);
            }else if(key == "capsuleBottle" || key == "capsuleJar"){
                this.removeCapsule();
            }
        }
        private setLabelPosition(key:string){
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setLabelPosition(key, Data.data.vg.itemData[key + "Position"]);

            let height:number = model.getLabelHeight(key);
            Data.data.vg.itemData[key + "Height"] = height;
        }
        private setLabelHeight(key:string){
            let model:VGObject = window.PLAYER.getModel(Data.data.vg.item.id);
            model.setLabelHeight(key, Data.data.vg.itemData[key + "Height"]);

            let position:number = model.getLabelPosition(key);
            Data.data.vg.itemData[key + "Position"] = position;
        }
        private isLabelSectionVisible(labelSection:string){
            if(!Data.data.vg.catalogItem) return false;

            if(labelSection == "capsuleBottle"){
                if(Data.data.vg.catalogItem.productType == "bottle") return true;
                else return false;
            }
            if(labelSection == "capsuleJar"){
                if(Data.data.vg.catalogItem.productType == "jar") return true;
                else return false;
            }

            return true;
        }
    }
