
    import Generic from "./Generic.vue";
    import Projects from "./virtualglass/Projects.vue";
    import vuescroll from 'vuescroll';
    import $ from 'jquery';

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";
import Utils from "@/ts/player/utils/Utils";
import VGDataLoader from "@/ts/application/VGDataLoader";
import Loader from "@/ts/application/Loader";
import DataUtils from "@/ts/utils/DataUtils";

    @Component({
        components: {
            Projects,
            vuescroll
        }
    })
    export default class VGManager extends Generic {
        private fileLoader:Loader = null;
        private projectsDialog:boolean = false;
        private tempProject:any;
        private tempItem:any;
        private tempItemData:any;
        private tempIndex:number;
        private product:any;

        private mounted(){
            $(window).on("CREATE_PROJECT_FROM_ITEM_SELECTED_FROM_CATALOG", this.productSelectedFromCatalog.bind(this));
            $(window).on("CREATE_TEMP_PROJECT", this.addToNewProject.bind(this));
            //$(window).on("VGPROJECT_SELECTED", this.projectSelected.bind(this));
        }
        private destroyed(){
            // $(window).off("CREATE_PROJECT_FROM_ITEM_SELECTED_FROM_CATALOG");
            // $(window).off("CREATE_TEMP_PROJECT");
            //$(window).off("VGPROJECT_SELECTED");
        }
        private productSelectedFromCatalog(e:any, product:any){
            this.product = product;
            this.product.bpIndex = this.product.colorIndex;
            //console.log("productSelectedFromCatalog");

            if(!Data.data.vg.dataReady){
                $(window).trigger("SHOW_OVERLAY");
                $(window).trigger("SHOW_LOADING");
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgDataLoading);

                new VGDataLoader(this.VGDataComplete.bind(this), this.openVGError.bind(this));
                return;
            } else if (!Data.data.vg.userData.data.disclaimer) {
                Data.data.vg.projectCreationWaiting = true;
                Data.data.app = "virtualglass";
                Data.data.section = "disclaimer";

                return;
            }

            this.addToNewProject();

            //if(Data.data.vg.projects.length == 0){
            //    this.addToNewProject();
            // }else{
            //     let data: any = Utils.copy(Data.data.config.dialogs.vgAddToProjectFromCatalog);
            //     data.buttons.new.callback = this.addToNewProject.bind(this);
            //     data.buttons.existing.callback = this.addToExistingProject.bind(this);
            //     $(window).trigger("DIALOG_OPEN", data);
            // }
        }
        private VGDataComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");

            if (!Data.data.vg.userData.data.disclaimer){
                Data.data.vg.projectCreationWaiting = true;
                Data.data.app = "virtualglass";
                Data.data.section = "disclaimer";

                return;
            }

            this.addToNewProject();
        }
        private openVGError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");
            console.log("Error loading VG Data");
        }
        private addToNewProject(e:any=null){
            if (Data.data.vg.projectCreationWaiting) return;

            Data.data.vg.projectCreationWaiting = false;

            //console.log("addToNewProject");
            //$(window).off("CREATE_TEMP_PROJECT");

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgProjectCreation);

            this.tempProject = Utils.copy(Data.data.vg.VGProjectModel);
            this.tempProject.id = Utils.generateUID();
            this.tempProject.name = this.product.name;
            this.tempProject.creationDate = new Date().toISOString();
            this.tempProject.editionDate = new Date().toISOString();

            this.tempItem = Utils.copy(Data.data.vg.VGItemModel);
            this.tempItemData = Utils.copy(Data.data.vg.VGItemDataModel);

            this.tempItemData.id = Utils.generateUID();
            this.tempItemData.glassDrawing = this.product.glassDrawingVG;
            this.tempItemData.baseProduct = this.product.baseProducts[this.product.bpIndex];
            this.tempItemData.name = this.product.name;

            this.tempItem.id = Utils.generateUID();
            this.tempItem.itemID = this.tempItemData.id;



            this.fileLoader = new Loader(this.saveProjectComplete.bind(this), this.saveProjectError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "add-project/" + Data.data.userID + "/" + this.tempProject.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempProject), headers:Data.data.headers });
            this.fileLoader.add({ id: "request1", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + this.tempProject.id + "/add-item-settings/" + this.tempItem.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempItem), headers: Data.data.headers });
            this.fileLoader.add({ id: "request2", url: Data.data.platform.digitalEngineVGURL + "add-item-catalog/" + Data.data.userID + "/" + this.tempItemData.id, method:"POST", contentType:"application/json", data:JSON.stringify(this.tempItemData), headers: Data.data.headers });

            this.fileLoader.start();
        }
        // private addToExistingProject(){
        //     this.projectsDialog = true;
        // }
        private saveProjectComplete(){
            let file1:any = this.fileLoader.get("request");
            let response1:any = this.fileLoader.getResponse("request");
            let file2:any = this.fileLoader.get("request1");
            let response2:any = this.fileLoader.getResponse("request1");
            let file3:any = this.fileLoader.get("request2");
            let response3:any = this.fileLoader.getResponse("request2");

            if(!file1 || response1.status != 200 || !file2 || response2.status != 200 || !file3 || response3.status != 200){
                $(window).trigger("HIDE_OVERLAY");
                $(window).trigger("HIDE_LOADING");
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            this.tempItemData.colorID = DataUtils.getVGColorFromProduct(this.product, this.tempItemData.baseProduct);

            Data.data.vg.projects.unshift(this.tempProject);
            this.tempProject.items.push(this.tempItem);
            Data.data.vg.items.push(this.tempItemData);

            Data.data.vg.project = this.tempProject;
            Data.data.VGMode = true;
            Data.data.app = "virtualglass";
            Data.data.section = "project";
        }
        private saveProjectError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
    }
