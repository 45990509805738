import Data from "../application/Data";
import DataUtils from "./DataUtils";
import Utils from "./Utils";

export default class OLDVirtualGlassUtils {
    public static openVirtualGlass(product:any){
        if (Data.data.oldVGMode) {
            this.selectProduct(product);
        } else {
            this.openVGWindow(product);
        }
    }
    private static openVGWindow(product: any){
        let index:number = product.bpIndex;
        let bp:any = product.baseProducts[index];
        let baseProduct: any = Data.data.baseProducts[bp];

        var p :any= {};
        p.id = product.id;
        p.name = product.name;
        p.is_project = false;
        p.country = product.country;
        p.co = product.country;
        p.area = DataUtils.getCompanyCodeFromCountry(product.country);
        p.gd = product.glassDrawingVG.toLowerCase();
        p.original_gd = product.glassDrawing.toLowerCase();
        p.gds = product.glassDrawingSimplified;
        p.fh = product.fillingHeight;
        p.he = product.height;
        if (!p.he || p.he == "" || p.he == 0) p.he = 350;
        p.lmh = product.labelMaxHeight;
        p.we = product.weight;
        p.vo = product.volume;
        p.di = product.diameter;
        p.account = p.area;
        p.ac = p.area;
        //p.color_ref = baseproduct.pr[index].cl_en.toLowerCase();
        p.colors = {};
        p.colors_tab = [];
        //p.fi = product.fi;
        p.vg = true;
        p.pr = {};

        let sapCode:string = baseProduct.sapColor;
        p.color_ref = Data.data.config.sapToColor[sapCode];
        if (p.color_ref && p.color_ref.length > 0) p.color_ref = p.color_ref[0];

        p.pr.cb = bp;
        p.pr.cs = sapCode;
        p.pr.cc = "ffffff";
        p.pr.cl = p.color_ref;
        p.pr.cl_en = p.color_ref;
        p.pr.br = "Verallia";


        if (baseProduct.materialGroup.toLowerCase().indexOf("naa") != -1) {
            p.is_bottle = true;
        } else {
            p.is_bottle = false;
        }

        var a = product.baseProducts.length;
        for (var i = 0; i < a; i++) {
            let baseProduct: any = Data.data.baseProducts[product.baseProducts[i]];
            let sapCode: string = baseProduct.sapColor;
            let color_ref:any = Data.data.config.sapToColor[sapCode];
            if (color_ref && color_ref.length > 0){
                color_ref = color_ref[0];

                p.colors_tab.push({ cc: "ffffff", la: color_ref.toLowerCase(), cs: sapCode });
                p.colors[color_ref.toLowerCase()] = { cc: "ffffff", la: color_ref.toLowerCase(), cs: sapCode };
            }
        }

        p.filling_height = parseInt(product.fillingHeight);
        p.height = parseInt(product.height);
        p.diameter = parseInt(product.diameter);
        p.label_max_height = parseInt(product.labelMaxHeight);

        console.log(p);

        if(window.location.host.indexOf("apps.corp.inpkg.net") != -1){
            window.open(Data.data.platform.oldVirtualGlass + "?mode=render&platform=catalog#/" + JSON.stringify(p), "_blank");
            return;
        }

        try {
            var vg_menu = $('.menu-container a[href*="Web_VirtualGlass"]').parent().attr("style");
            var vg_access = false;
            if (vg_menu == "display:block") vg_access = true;

            //@ts-ignore
            if (window.mv_can_access_virtualglass == true || window.mv_can_access_virtualglass == "true" || vg_access == true) {
                Data.data.platform.oldVirtualGlass = "https://" + location.host + location.pathname.toLowerCase().replace("/web_catalogvg", "/Web_VirtualGlass");

                if (window.location.href.toLowerCase().indexOf("/catalog") != -1) {
                    Data.data.platform.oldVirtualGlass = parent.document.location.href.replace("/catalog", "/virtual-glass");
                }

                window.open(Data.data.platform.oldVirtualGlass + "?mode=render&platform=catalog#/" + JSON.stringify(p), "_blank");
                return;
            }
        } catch (e) {

        }

        Data.data.platform.oldVirtualGlass = "https://" + location.host + location.pathname.toLowerCase().replace("/catalog", "/virtual-glass");

        if (window.location.href.toLowerCase().indexOf("/catalog") != -1) {
            Data.data.platform.oldVirtualGlass = parent.document.location.href.replace("/catalog", "/virtual-glass");
        }



        window.open(Data.data.platform.oldVirtualGlass + "?mode=render&platform=catalog#/" + JSON.stringify(p), "_blank");
    }
    private static selectProduct(product: any){
        let index: number = product.bpIndex;
        let bp: any = product.baseProducts[index];
        let baseProduct: any = Data.data.baseProducts[bp];

        var p: any = {};
        p.id = product.id;
        p.name = product.name;
        p.is_project = false;
        p.country = product.country;
        p.co = product.country;
        p.area = DataUtils.getCompanyCodeFromCountry(product.country);
        p.gd = product.glassDrawingVG.toLowerCase();
        p.original_gd = product.glassDrawing.toLowerCase();
        p.gds = product.glassDrawingSimplified;
        p.fh = product.fillingHeight;
        p.he = product.height;
        if (!p.he || p.he == "" || p.he == 0) p.he = 350;
        p.lmh = product.labelMaxHeight;
        p.we = product.weight;
        p.vo = product.volume;
        p.di = product.diameter;
        p.account = p.area;
        p.ac = p.area;
        //p.color_ref = baseproduct.pr[index].cl_en.toLowerCase();
        p.colors = [];
        //p.fi = product.fi;
        p.vg = true;
        p.pr = {};

        let sapCode: string = baseProduct.sapColor;
        p.color_ref = Data.data.config.sapToColor[sapCode];
        if (p.color_ref && p.color_ref.length > 0) p.color_ref = p.color_ref[0];

        p.pr.cb = bp;
        p.pr.cs = sapCode;
        p.pr.cc = "ffffff";
        p.pr.cl = p.color_ref;
        p.pr.cl_en = p.color_ref;
        p.pr.br = "Verallia";


        if (baseProduct.materialGroup.toLowerCase().indexOf("naa") != -1) {
            p.is_bottle = true;
        } else {
            p.is_bottle = false;
        }

        var a = product.baseProducts.length;
        for (var i = 0; i < a; i++) {
            let baseProduct: any = Data.data.baseProducts[product.baseProducts[i]];
            let sapCode: string = baseProduct.sapColor;
            let color_ref: any = Data.data.config.sapToColor[sapCode];
            if (color_ref && color_ref.length > 0) {
                color_ref = color_ref[0];

                p.colors.push({ cc: "ffffff", la: color_ref.toLowerCase(), cs: sapCode });
            }
        }

        p.filling_height = parseInt(product.fillingHeight);
        p.height = parseInt(product.height);
        p.diameter = parseInt(product.diameter);
        p.label_max_height = parseInt(product.labelMaxHeight);



        try{
            console.log("Adding product to VirtualGlass :", p);
            //@ts-ignore
            window.ONBottleSelect(p);
        }catch(e){
            console.log("Could not call window.ONBottleSelect()...", e);
        }



        // var index = baseproduct.selected_index;
        // var p = {};
        // p.id = baseproduct.id;
        // p.co = baseproduct.country;
        // p.area = baseproduct.pr[index].ai;
        // p.la = baseproduct.la;
        // p.fh = baseproduct.fh;
        // p.he = baseproduct.he;
        // if (!p.he || p.he == "" || p.he == 0) p.he = 350;
        // p.lmh = baseproduct.lmh;
        // p.we = baseproduct.we;
        // p.vo = baseproduct.vo;
        // p.di = baseproduct.di;
        // p.gd = baseproduct.gd;
        // p.original_gd = baseproduct.gd;
        // p.gd_without_version = app.Utils.getGDWithoutVersion(baseproduct);
        // p.gds = baseproduct.gds;
        // p.ac = baseproduct.ac;
        // p.fi = baseproduct.fi;
        // p.vg = baseproduct.vg;

        // p.pr = {};
        // p.pr.cb = baseproduct.pr[index].cb;
        // p.pr.cs = baseproduct.pr[index].cs;
        // p.pr.cc = baseproduct.pr[index].cc;
        // p.pr.cl = baseproduct.pr[index].cl;
        // p.pr.cl_en = baseproduct.pr[index].cl_en;
        // p.pr.br = baseproduct.pr[index].br;

        // if (p.pr.cl == "half flint") p.pr.cl = "half-flint";
        // if (p.pr.cl_en == "half flint") p.pr.cl_en = "half-flint";

        // if (baseproduct.pr[index].mg.toLowerCase().indexOf("naa") != -1) {
        //     p.is_bottle = true;
        // } else {
        //     p.is_bottle = false;
        // }

        // p.colors = [];
        // var c = baseproduct.pr.length;
        // for (var k = 0; k < c; k++) {
        //     p.colors.push({ la: baseproduct.pr[k].cl_en.toLowerCase(), cs: baseproduct.pr[k].cs, cc: baseproduct.pr[k].cc });
        // }

        // try {
        //     window.ONBottleSelect(p);
        // } catch (e) {
        //     trace("catalog functions not found, trying to send message to parent window (for VirtualGlass V3)");

        //     var post_data = JSON.stringify({ type: "verallia", data: { message: "productSelected", product: p } });
        //     window.parent.postMessage(post_data, "*");
        // }
    }
}