import * as BABYLON from "babylonjs";
import VGObject from "../modules/VGObject";
import Data from "../application/Data";
import Application from "../application/Application";
import SceneClassic from '../modules/scenes/SceneClassic';
import $ from "jquery";
import VGAccessoryObject from "../modules/VGAccessoryObject";
import VGCapsule from "../modules/VGCapsule";


export default class BabylonManager {
    public application: Application;
    public rendering: boolean = true;
    public canvas: any;
    public engine: any;
    public scene: any;
    public camera: BABYLON.ArcRotateCamera;
    public renderer: any;
    public debug: any;
    public stats: any;
    public controls: any;
    public sceneData: any;
    public models: any = {};
    public materials: any = { contents: {}, capsules: {} };
    public objectsScale: number = 1000;


    constructor(application: Application) {
        this.application = application;

        this.init();
    }
    private init() {
        this.initScene();
        this.initContentsMaterials();
        this.initCapsulesMaterials();
        this.render();
    }
    private initScene() {
        $("body").append($("<canvas id='" + this.application.player.config.id + "'>"));
        this.canvas = document.getElementById(this.application.player.config.id);
        $(this.application.player.config.container).append(this.canvas);

        this.engine = new BABYLON.Engine(this.canvas, true, { preserveDrawingBuffer: true, stencil: true, disableWebGL2Support: false });
        this.scene = new BABYLON.Scene(this.engine);
        //this.scene.useRightHandedSystem = true;


        //@ts-ignore
        this.camera = new BABYLON.ArcRotateCamera("camera" + this.application.player.config.id, 0, 0, 0, BABYLON.Vector3(0, 0, 0), this.scene);
        this.camera.setPosition(new BABYLON.Vector3(0, 0, -500));
        this.camera.setTarget(new BABYLON.Vector3(0, 0, 0));
        //@ts-ignore
        this.camera.inputs.attached['pointers'].onContextMenu = (e: any) => {
            //console.log(e);
        }


        this.camera.attachControl(this.canvas, true);

        this.scene.clearColor = new BABYLON.Color4(0, 0, 0, 0);
        //this.scene.clearColor = BABYLON.Color3.FromHexString("#e0e0e0");

        let t: any = this;

        this.scene.onPointerObservable.add((evt: any) => {
            if (evt.type === BABYLON.PointerEventTypes.POINTERDOWN) {
                let pickInfo: any = t.scene.pick(t.scene.pointerX, t.scene.pointerY, null, null, t.camera);
                //console.log(pickInfo);
                if (pickInfo.pickedMesh) {
                    let name: string = pickInfo.pickedMesh.name;
                    //console.log( pickInfo.pickedMesh);
                    if (name.indexOf("_") != -1) {
                        let tab: string[] = name.split("_");

                        $(window).trigger("MODEL_CLICKED", tab[0]);
                    }
                }
            }
        })


        this.sceneData = new SceneClassic(this);
    }
    public takeScreenShot(resolution: number = 300, callback: any, id:string) {
        let base64: string = this.canvas.toDataURL("png");
        callback(id, base64);
        //BABYLON.Tools.CreateScreenshotUsingRenderTarget(this.engine, this.camera, resolution, callback);
        //BABYLON.Tools.CreateScreenshotUsingRenderTargetAsync(this.engine, this.camera, resolution).then(callback);
    }
    private initContentsMaterials() {
        //FRAISE
        let material: any = new BABYLON.PBRMetallicRoughnessMaterial("fraise_Material", this.scene);
        this.materials.contents.fraise = material;
        material.baseColor = new BABYLON.Color3(0.21, 0.21, 0.21);
        material.metallic = 0.5;
        material.roughness = 0.2;
    }
    private initCapsulesMaterials() {
        //CUSTOM IMAGE
        let material: any = new BABYLON.StandardMaterial("custom_image_material", this.scene);
        this.materials.capsules.custom_image = material;
        material.diffuseTexture = new BABYLON.Texture(this.application.player.config.assetsPath + "images/capsule.png", this.scene);
        material.metallic = 0.5;
        material.roughness = 0.2;
        material.diffuseTexture.uScale = -1;
        material.diffuseTexture.vScale = -1;

        //IRON THREAD
        material = new BABYLON.PBRMetallicRoughnessMaterial("iron_thread_Material", this.scene);
        this.materials.capsules.iron_thread = material;
        material.baseColor = new BABYLON.Color3(0.21, 0.21, 0.21);
        material.metallic = 0.5;
        material.roughness = 0.2;

        //ALUMINIUM
        material = new BABYLON.PBRMetallicRoughnessMaterial("aluminium_Material", this.scene);
        this.materials.capsules.aluminium = material;
        material.baseColor = new BABYLON.Color3(0.3, 0.3, 0.3);
        material.metallic = 0.7;
        material.roughness = 0.3;

        //BLACK ALUMINIUM
        material = new BABYLON.PBRMetallicRoughnessMaterial("black_aluminium_Material", this.scene);
        this.materials.capsules.black_aluminium = material;
        material.baseColor = new BABYLON.Color3(0.05, 0.05, 0.05);
        material.metallic = 0.7;
        material.roughness = 0.3;

        //WHITE PLASTIC
        material = new BABYLON.StandardMaterial("white_plastic_material", this.scene);
        this.materials.capsules.white_plastic = material;
        material.diffuseColor = new BABYLON.Color3(0.77, 0.77, 0.77);
        material.metallic = 0;
        material.roughness = 0.8;

        //RED PLASTIC
        material = new BABYLON.StandardMaterial("red_plastic_material", this.scene);
        this.materials.capsules.red_plastic = material;
        material.diffuseColor = new BABYLON.Color3(1, 0, 0);
        material.metallic = 0;
        material.roughness = 0.8;

        //BLACK PLASTIC
        material = new BABYLON.StandardMaterial("black_plastic_material", this.scene);
        this.materials.capsules.black_plastic = material;
        material.diffuseColor = new BABYLON.Color3(0, 0, 0);
        material.metallic = 0;
        material.roughness = 0.2;

        //WHITE SILICON
        material = new BABYLON.StandardMaterial("white_silicon_material", this.scene);
        this.materials.capsules.white_silicon = material;
        material.diffuseColor = new BABYLON.Color3(0.9, 0.9, 0.9);
        material.metallic = 0;
        material.roughness = 0.9;
        material.roughness = 0.9;

        //WHITE GLASS
        material = new BABYLON.PBRMaterial("white_glass_material", this.scene);
        this.materials.capsules.white_glass = material;
        material.alpha = 0.6;
        material.directIntensity = 0.0;
        material.environmentIntensity = 1;
        material.subSurface.isRefractionEnabled = true;
        material.subSurface.indexOfRefraction = 0;
        material.subSurface.isTranslucencyEnabled = true;
        material.subSurface.tintColor = BABYLON.Color3.FromHexString("#ffffff");
        material.diffuseColor = BABYLON.Color3.FromHexString("#ffffff");
        material.ambientColor = BABYLON.Color3.FromHexString("#ffffff");
        material.reflectivityColor = BABYLON.Color3.FromHexString("#ffffff");
        material.alphaIndex = 2;

        //BLUE GLASS
        material = new BABYLON.PBRMaterial("blue_glass_material", this.scene);
        this.materials.capsules.blue_glass = material;
        material.alpha = 0.6;
        material.directIntensity = 0.0;
        material.environmentIntensity = 1;
        material.subSurface.isRefractionEnabled = true;
        material.subSurface.indexOfRefraction = 0;
        material.subSurface.isTranslucencyEnabled = true;
        material.subSurface.tintColor = BABYLON.Color3.FromHexString("#12dfff");
        material.diffuseColor = BABYLON.Color3.FromHexString("#12dfff");
        material.ambientColor = BABYLON.Color3.FromHexString("#12dfff");
        material.reflectivityColor = BABYLON.Color3.FromHexString("#12dfff");




        //LIEGE
        material = new BABYLON.StandardMaterial("liege_material", this.scene);
        this.materials.capsules.liege = material;
        material.diffuseTexture = new BABYLON.Texture(this.application.player.config.assetsPath + "images/materials/liege.png", this.scene);
        //material.diffuseTexture.uScale = 30;
        //material.diffuseTexture.vScale = 30;
        material.specularPower = 30;
        material.specularColor = new BABYLON.Color3(0.23, 0.23, 0.23);

        material.detailMap.texture = new BABYLON.Texture(this.application.player.config.assetsPath + "images/materials/detailmap2.png", this.scene);
        material.detailMap.isEnabled = true;
        material.detailMap.diffuseBlendLevel = 0.1;
        material.detailMap.bumpLevel = 1;
        material.detailMap.roughnessBlendLevel = 0.25;
        material.detailMap.texture.uScale = 2;
        material.detailMap.texture.vScale = 1;
        material.uScale = 1;
        material.vScale = 1;
    }
























    public setScene(id: string) {
        if (this.sceneData.id == id) return;

        this.sceneData.destroy();

        if (id == "classic") this.sceneData = new SceneClassic(this);
        //else if(id == "bridge") this.sceneData = new SceneBridge(this);
    }
    public loadObjectModel(data: any, onComplete: any, onError: any) {
        if (!this.models[data.id] || data.forceLoading) {
            this.models[data.id] = new VGAccessoryObject(data, this, onComplete, onError);
            this.models[data.id].init();
            //return this.models[data.id];
        } else {
            //console.log("The object model " + data.id + " already exists. Unload it with unloadModel() to create a new model with this id");
            if (onComplete) onComplete();
        }
    }
    public loadCapsule(data: any, onComplete: any, onError: any) {
        if (!Data.models[data.glassDrawing] || data.forceLoading) {
            this.models[data.glassDrawing] = new VGCapsule(data, this, onComplete, onError);
            this.models[data.glassDrawing].init();
            //return this.models[data.id];
        } else {
            //console.log("The capsule " + data.glassDrawing + " already exists. Unload it with unloadModel() to create a new model with this id");
            if (onComplete) onComplete();
        }
    }
    public loadModel(data: any, onComplete: any, onError: any) {
        if (!this.models[data.id] || data.forceLoading) {
            this.models[data.id] = new VGObject(data, this, onComplete, onError);
            this.models[data.id].init();
            //return this.models[data.id];
        } else {
            //console.log("The model " + data.id + " already exists. Unload it with unloadModel() to create a new model with this id");
            if (onComplete) onComplete();
        }
    }
    public unloadModel(id: string) {
        if (this.models[id]) {
            //console.log("unloadModel " + id);
            //this.models[id].destroy();
            //this.scene.remove(this.models[id]);

            delete this.models[id];

            //return this.models[data.id];
        }
    }
    public focueOnModel(id: string) {
        if (this.models[id]) {

        } else {
            console.log("Could not focus on the model " + id + " because it does not exist");
        }
    }
    public render() {
        requestAnimationFrame(this.render.bind(this));

        if (this.rendering == false) return;

        this.scene.render();
    }
    public resize(width: number, height: number) {
        try {
            $(this.canvas).width(width);
            $(this.canvas).height(height);

            this.engine.resize();
        } catch (e) {

        }
    }
}