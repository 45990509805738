import Data from "../application/Data";

export default class Utils {
    static copy(data:any){
        return JSON.parse(JSON.stringify(data));
    }
    static getDialogModel(id:string){
        return this.copy(Data.data.config.dialogs[id]);
    }
    static generateUID(l:number=15):string{
        //if(length) l = length;
        let uid:string = "";
        let chars:string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

        for (let i = 0; i < l; i++) {
            uid += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        return uid;
    }
    static makeURLString(str:string){
        return str.replace(/[^a-zA-Z0-9]/g, '');
    }
    static replace(text, expression, replace_expression) {
        return text.replace(new RegExp(expression, "g"), replace_expression);
    }
    static getCountryFromLanguage(language:string){
        for (let str in Data.data.config.countriesLanguages){
            if (Data.data.config.countriesLanguages[str].indexOf(language) != -1) return str;
        }

        return null;
    }
    static createFile(data: any, filename: string): File{
        let extension: string = this.getFileExtension(filename);
        let type:string;

        if (extension == "txt") type = "text/plain";
        else if (extension == "json") type = "application/json";
        else if (extension == "jpg" || extension == "jpeg")
        type = "image/jpg"; else if (extension == "gif")
        type = "image/gif"; else if (extension == "png")
        type = "image/png";

        return new File([data], filename, { lastModified: new Date().getTime(), type: type });
    }
    static getFileExtension(filename:string){
        return filename.split('.').pop();
    }
    static convertDataURIToBinary(dataURI:string){
        let BASE64_MARKER:string = ';base64,';
        let base64Index:number = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
        let base64:string = dataURI.substring(base64Index);
        let raw:any = window.atob(base64);
        let rawLength:number = raw.length;
        let array:any = new Uint8Array(new ArrayBuffer(rawLength));

        for (let i = 0; i < rawLength; i++) {
            array[i] = raw.charCodeAt(i);
        }

        return array;
    }
    static removeTransparentPixelsFromBase64(c:any) {
        var ctx = c.getContext('2d');
        var copy = document.createElement('canvas').getContext('2d'),
            pixels = ctx.getImageData(0, 0, c.width, c.height),
            l = pixels.data.length,
            i,
            bound = {
                top: null,
                left: null,
                right: null,
                bottom: null
            },
            x, y;



        // Iterate over every pixel to find the highest
        // and where it ends on every axis ()
        for (i = 0; i < l; i += 4) {
            if (pixels.data[i + 3] !== 0) {
                x = (i / 4) % c.width;
                y = ~~((i / 4) / c.width);

                if (bound.top === null) {
                    bound.top = y;
                }

                if (bound.left === null) {
                    bound.left = x;
                } else if (x < bound.left) {
                    bound.left = x;
                }

                if (bound.right === null) {
                    bound.right = x;
                } else if (bound.right < x) {
                    bound.right = x;
                }

                if (bound.bottom === null) {
                    bound.bottom = y;
                } else if (bound.bottom < y) {
                    bound.bottom = y;
                }
            }
        }

        // Calculate the height and width of the content
        var trimHeight = bound.bottom - bound.top,
            trimWidth = bound.right - bound.left,
            trimmed = ctx.getImageData(bound.left, bound.top, trimWidth, trimHeight);

        copy.canvas.width = trimWidth;
        copy.canvas.height = trimHeight;
        copy.putImageData(trimmed, 0, 0);

        // Return trimmed canvas
        return copy.canvas;
    }
}