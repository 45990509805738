
    import Generic from "../Generic.vue";

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";

    @Component({
        components: {

        }
    })
    export default class UserPath extends Generic {
        private openPath(path:any, index:number){
            if(index == Data.data.userPath.length - 1) return;

            if(path.section == "home"){
                Data.data.section = "home";
                Data.data.view = Data.data.platform.view;
            }else if(path.section == "view"){
                Data.data.section = "view";
                Data.data.view = path.id;
            }

            if(index != 0) Data.data.userPath.splice(index + 1, Data.data.userPath.length - index);
            else Data.data.userPath = [];
        }
    }
