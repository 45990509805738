
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import $ from "jquery";
    import vuescroll from 'vuescroll';

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";
import Loader from "@/ts/application/Loader";

    @Component({
        components: {
            vuescroll
        },
        props:{
            catalogMode:Boolean,
            search:String,
            folder:String
        }
    })
    export default class Projects extends Generic {
        private fileLoader:Loader;
        private tempProject:any = null;
        private tempIndex:number;
        private fakeCards:number = 5;
        private projectFolder:string = null;
        private folderDialog:boolean = false;
        private itemsRemoval:any = null;

        private mounted() {
            $(window).on("UPDATE_RENDERS_IMAGES", this.updateRendersImages.bind(this));
        }
        private destroyed() {
            $(window).off("UPDATE_RENDERS_IMAGES");
        }
        private createProject(){
            $(window).trigger("CREATE_PROJECT");
        }
        private updateRendersImages(){
            let pictures:any = $(".RenderPicture");
            console.log(pictures);
        }
        private getProjects(){
            let projects:any = [];

            if(this.search || this.folder){
                for(let i=0; i<Data.data.vg.projects.length; i++){
                    let project:any = Data.data.vg.projects[i];

                    if(this.folder){
                        if(project.folder == this.folder){
                            projects.push(project);
                            continue;
                        }
                    }

                    if(this.search){
                        if(project.name && project.name.toLowerCase().indexOf(this.search.toLowerCase()) != -1){
                            projects.push(project);
                            continue;
                        }
                    }
                }
            }else{
                this.$emit("getProjects", Data.data.vg.projects.length);
                return Data.data.vg.projects;
            }

            this.$emit("getProjects", projects.length);
            return projects;
        }
        private editProject(project:any){
            this.tempProject = project;

            this.itemsRemoval = [];
            for(let i=0; i<project.items.length; i++){
                let item:any = this.getVGItem(project.items[i].itemID);

                if(!item){
                    this.itemsRemoval.push({ id:project.items[i].id, index:i });
                }
            }

            if(this.itemsRemoval.length > 0){
                let data:any = Utils.copy(Data.data.config.dialogs.vgItemsMissing);
                data.buttons.ok.callback = this.startDeleteItemsSettings.bind(this);

                $(window).trigger("DIALOG_OPEN", data);
                return;
            }

            this.openProject();
        }
        private startDeleteItemsSettings(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.deleteItemsSettingsComplete.bind(this), this.deleteItemsSettingsComplete.bind(this));

            let a:number = this.itemsRemoval.length;
            for(let i=0; i<a; i++){
                this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + this.tempProject.id + "/delete-item-settings/" + this.itemsRemoval[i].id, method:"DELETE", contentType:"application/json", headers: Data.data.headers });
            }
            this.fileLoader.start();
        }
        private deleteItemsSettingsError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            //$(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);

        }
        private deleteItemsSettingsComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            for(let i=0; i<this.itemsRemoval.length; i++){
                this.tempProject.items.splice(this.itemsRemoval[i].index, 1);
            }

            this.openProject();
        }
        private openProject(){
            Data.data.vg.project = this.tempProject;
            Data.data.section = "project";
        }
        private deleteProject(project:any, index:number){
            this.tempProject = project;
            this.tempIndex = index;

            let data:any = Utils.copy(Data.data.config.dialogs.vgProjectDeleteConfirmation);
            data.buttons.yes.callback = this.deleteProjectConfirm.bind(this);

            $(window).trigger("DIALOG_OPEN", data);
        }
        private deleteProjectConfirm(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.fileLoader = new Loader(this.deleteProjectComplete.bind(this), this.deleteProjectError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "delete-project/" + Data.data.user.id + "/" + this.tempProject.id, method:"DELETE", headers:Data.data.headers });
            // let a = this.tempProject.items.length;
            // for(let i=0; i<a; i++){
            //     let itemID:string = this.tempProject.items[i].id;
            //     this.fileLoader.add({ id: "requestItem" + i, url: Data.data.platform.digitalEngineVGURL + "project/" + Data.data.userID + "/" + this.tempProject.id + "/delete-item-settings/" + itemID, method:"DELETE", contentType:"application/json", headers:Data.data.headers });
            // }

            this.fileLoader.start();
        }
        private deleteProjectError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private deleteProjectComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            let file:any = this.fileLoader.get("request");
            let response:any = this.fileLoader.getResponse("request");
            if(!file || response.status != 200){
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
                return;
            }

            Data.data.vg.projects.splice(this.tempIndex, 1);
            $(window).trigger("CHECK_RENDERS_QUOTA");
        }

        private selectProject(project:any){
            $(window).trigger("VGPROJECT_SELECTED", project);
        }
        private getCompleteRender(index:number){
            let project:any = Data.data.vg.projects[index];

            for(let i=0; i<project.renders.length; i++){
                let render:any = project.renders[i];

                if(render.status == "complete") return render;
            }
        }
        private downloadRender(project:any){
            let url:string = Data.data.platform.digitalEngineFileURL + 'virtualglass/' + Data.data.user.id + '/' + project.id + '/' + project.renders[project.picIndex].id + '.png';
            window.open(url, "_blank");
        }
        private openRenders(project:any){
            if(project.renders.length > 0){
                $(window).trigger("OPEN_RENDERS", project);
            }
        }
        private openFolders(project:any, index:number){
            this.tempProject = project;
            this.projectFolder = this.tempProject.folder;
            this.folderDialog = true;
        }
        private closeFolders(){
            this.folderDialog = false;
        }
        private saveFolder(){
            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            this.closeFolders();

            let project:any = Utils.copy(this.tempProject);
            project.folder = this.projectFolder;
            project.itemsSettings = project.items;
            delete project.items;
            project.items = [];

            let items:string[] = [];
            let a = project.items.length;
            for(let i=0; i<a; i++){
                let settingsID:string = project.items[i].id;
                let itemID:string = project.items[i].itemID;
                if(items.indexOf(itemID) == -1){
                    items.push(itemID);
                    project.items.push(Utils.copy(this.getVGItem(itemID)));
                }
            }

            this.fileLoader = new Loader(this.folderSaveComplete.bind(this), this.folderSaveError.bind(this));
            this.fileLoader.add({ id: "request", url: Data.data.platform.digitalEngineVGURL + "save-project/" + Data.data.user.id + "/" + this.tempProject.id, method:"PUT", contentType:"application/json", data:JSON.stringify(project), headers: Data.data.headers });

            this.fileLoader.start();
        }
        private folderSaveError(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private folderSaveComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.tempProject.editionDate = new Date().toISOString();

            this.$set(this.tempProject, "folder", this.projectFolder);
        }
    }
