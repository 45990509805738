
    import vuescroll from 'vuescroll';
    import Generic from "../Generic.vue";
    import Utils from "../../ts/utils/Utils";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';


    @Component({
        components: {
            vuescroll
        }
    })
    export default class Comparator extends Generic {
        private headers = [
            { id:"name" },
            { id:"reference" },
            { id:"volume" },
            { id:"height" },
            { id:"weight" },
            { id:"diameter" },
            { id:"fillingHeight" },
            { id:"overflowCapacity" },
            { id:"finishFamily" }
        ];

        private mounted(){

        }
        private destroyed(){

        }
        private clearComparator(){
            Data.data.comparator = {};
            Data.data.comparatorStatus = false;
        }
        private removeFromComparator(key:string){
            this.$delete(Data.data.comparator, key);

            if(Object.keys(Data.data.comparator).length == 0) Data.data.comparatorStatus = false;
        }
    }
