
    import Main from "../../ts/Main";
    import Generic from "../Generic.vue";
    import Utils from "../../ts/utils/Utils";
    import Data from "@/ts/application/Data";
    import FiltersUtils from "@/ts/utils/FiltersUtils";
    import UserPath from "./UserPath.vue";
    import Products from "./Products.vue";
    import Filters from "./Filters.vue";
    import Footer from "./Footer.vue";
    import Comparator from "./Comparator.vue";
    import $ from "jquery";
    import vuescroll from 'vuescroll';
    import VGDataLoader from "../../ts/application/VGDataLoader";
    import Vue from "vue";

    import {
        Component,
    } from 'vue-property-decorator';
import URLManager from "@/ts/utils/URLManager";


    @Component({
        components: {
            UserPath,
            Filters,
            Products,
            Comparator,
            Footer,
            vuescroll
        },
        props:{

        },
        watch:{
            "data.catalogPage":function(oldValue, newValue){
                if(Data.data.catalogPage == 1) this.scrollTop();
            }
        }
    })
    export default class ProductsView extends Generic {
        private mounted(){
            $(window).on("UPDATE_VIEW", this.updateView.bind(this));

            this.updateView();
        }
        private destroyed(){
            $(window).off("UPDATE_VIEW");
        }
        private handleComplete(e:any){
            if(!Data.data.products) return;

            let position:number = e.scrollTop;
            let areaHeight:number = $("#ProductsView .__view").height();
            let productsHeight:number = $(".ViewProducts").height();

            //console.log("");
            // console.log(position, areaHeight, productsHeight);
            // console.log(productsHeight - position);

            let areaOffset:any = [areaHeight - 50, areaHeight + 100];
            let offset:number = Math.round(productsHeight - position);

            //console.log(offset + "  -  " + areaHeight);

            if(offset > areaOffset[0] && offset < areaOffset[1]){
                if(Data.data.catalogPage * Data.data.config.productsByPage < Object.keys(Data.data.products).length) Data.data.catalogPage ++;
            }
            // console.log(areaHeight);
            // console.log(productsHeight);

            //$(window).trigger("ON_SCROLL", e);
        }
        private scrollTop(){
            //@ts-ignore
            this.$refs["vs"].scrollTo({ y: 0 }, 0);
        }
        private getLanguages(){
            if(Data.data.platform.id == "INTERNAL"){
                return Data.data.config.languages;
            }
        }
        private setLanguage(l:string){
            Data.data.language = l;
            Data.data.loc = Data.data.locs[l];

            try{
                localStorage.setItem("catalogLanguage", l);
            }catch(e){

            }

            URLManager.setURLFromData();

            if(l == "uk" || l == "ru") $("#app").css("font-family", "Roboto");
            else $("#app").css("font-family", "DMSans");
        }
        private openComparator(){
            if(Object.keys(Data.data.comparator).length == 0) return;

            Data.data.comparatorStatus = true
        }
        private updateFilters(){
            $(window).trigger("UPDATE_FILTERS");
        }
        private updateView(e:any=null, filter:string=null){
            FiltersUtils.setFilters(filter);

            //this.$forceUpdate();
        }
        private openView(view:string){
            Data.data.view = view;
            //if(Data.data.section == "home") Data.data.userPath.push({ section:"home" });
            //Data.data.userPath.push({ id:view, section:"view" });
            this.updateView();
            Data.data.section = "view";
        }
        getLarge(index:number, length:number){
            if(index == 0) return true;
            else if(index == length - 1 && index % 2 == 1) return true;
            return false;
        }
        getFull(index:number, length:number){
            if(index == length- 1 && index % 3 == 1) return true;

            return false;
        }
        setProductsOrder(order:string){
            Data.data.productsOrder = order;

            this.updateView();
        }
        private checkSearchField(){
            $(window).trigger("UPDATE_VIEW");
        }
        private clearSearch(){
            Data.data.catalogSearch = null;
        }
        private toggleProductsMode(){
            let updateURL:boolean = false;

            if(Data.data.view == "general"){
                this.$set(Data.data, "products", Data.myProductsJson);
                this.$set(Data.data, "baseProducts", Data.myProductsBaseProductsJson);
                this.$set(Data.data, "view", "myproducts");
            }else{
                this.$set(Data.data, "products", Data.productsJson);
                this.$set(Data.data, "baseProducts", Data.baseProductsJson);
                this.$set(Data.data, "view", "general");
            }

            this.$delete(Data.data, "product");
            this.$set(Data.data, "section", "view");

            $(window).trigger("UPDATE_VIEW");
            URLManager.setURLFromData();
        }
        private toggleCatalog(){
            this.$delete(Data.data, "product");

            if (Data.data.view == "pls"){
                this.$set(Data.data, "view", "general");
            }else{
                this.$set(Data.data, "view", "pls");
            }

            $(window).trigger("UPDATE_VIEW");
            URLManager.setURLFromData();
        }
        private openVirtualGlass(){
            if(!Data.data.vg.dataReady){
                $(window).trigger("SHOW_OVERLAY");
                $(window).trigger("SHOW_LOADING");
                $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.vgDataLoading);

                new VGDataLoader(this.VGDataComplete.bind(this), this.openVGError.bind(this));
                return;
            }

            this.openVG();
        }
        private VGDataComplete(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");

            this.openVG();
        }
        private openVG(){
            this.$set(Data.data, "VGMode", true);
            this.$set(Data.data, "app", "virtualglass");
            this.$set(Data.data, "section", "home");
            $(window).trigger("CLOSE_PRODUCT");
        }
        private openVGError(){
            this.$set(Data.data, "VGMode", false);
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");
            $(window).trigger("DIALOG_CLOSE");
            console.log("Error loading VG Data");
        }
        private openCatalogCountry(country:string){
            let hash:string = window.location.hash;
            let url:string = window.location.protocol + "//" + location.host + location.pathname + "?country=" + country.toUpperCase() + hash;
            window.open(url, "_self");
        }
    }
