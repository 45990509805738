export default class InitApp{
    private callback:any;

    constructor(callback:any){
        this.callback = callback;

        this.initApp();
    }
    private initApp(){
        this.callback();
    }
}