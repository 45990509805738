
    import ProductsView from "./ProductsView.vue";
    import Footer from "./Footer.vue";
    import Generic from "../Generic.vue";
    import Utils from "../../ts/utils/Utils";
    import Data from "@/ts/application/Data";
    import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';
import URLManager from "@/ts/utils/URLManager";

    @Component({
        components: {
            ProductsView,
            Footer
        }
    })
    export default class Home extends Generic {
        private mounted() {
            if(Data.data.platform.id == "MYVERALLIA"){
                if(Data.data.oldVGMode){
                    this.openView("general");
                }
                //setTimeout(this.openView.bind(this, "general"), 100);
            }else if(Data.data.platform.id == "PUBLIC"){
                this.openView("publicproducts");
            }else if(Data.data.platform.id == "INTERNAL"){
                if(Data.data.oldVGMode){
                    this.openView("virtualglass");
                }else{
                    this.openView("products");
                }
            }else if(Data.data.platform.id == "SELECTIVELINE"){
                this.openView("slproducts");
            }

            if(Data.data.firstDisplay){
                Data.data.firstDisplay = false;
                setTimeout(URLManager.setDataFromURL.bind(URLManager), 100);
            }
        }
        private openView(view:string){
            //Data.data.userPath.push({ section:"home" });
            //Data.data.userPath.push({ id:view, section:"view" });
            this.$set(Data.data, "view", view);
            this.$set(Data.data, "section", "view");
        }
    }
