
import $ from "jquery";
import Generic from "../Generic.vue";
import Data from "@/ts/application/Data";
import Loader from "@/ts/application/Loader";

import {
    Component,
} from 'vue-property-decorator';
import Utils from "@/ts/utils/Utils";

@Component({
    components: {

    }
})
export default class Maintenance extends Generic {

    private mounted() {

    }
    private destroyed() {

    }
}
