
    import Generic from "../Generic.vue";
    import Utils from "../../ts/utils/Utils";
    import Data from "@/ts/application/Data";
    //import $ from "jquery";

    import {
        Component,
    } from 'vue-property-decorator';


    @Component
    export default class Header extends Generic {

    }
