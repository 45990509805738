import $ from "jquery";
import Data from "./Data";
import Vue from "vue";

export default class ProductDataCheck {
    private product:any;

    constructor(product:any) {
        this.product = product;

        this.checkGlassDrawingPDF();
        this.checkDilatationCurvesPDF();
        this.checkZoomPicture();
        this.getFinishedProductsData();
    }
    private beforeSendToken(xhr: any) {
        //@ts-ignore
        if (Data.data.platform.id == "MYVERALLIA") xhr.setRequestHeader("Authorization", "Bearer " + window.sfdc_ws_token);
    }



    //GLASSDRAWING PDF
    private checkGlassDrawingPDF(){
        if (Data.data.platform.id != "INTERNAL" && Data.data.platform.id != "MYVERALLIA") return;

        $.ajax({ method: "GET", url: Data.data.platform.sharePointFilesURL + "GlassDrawings/" + this.product.glassDrawing + ".pdf", beforeSend: this.beforeSendToken.bind(this) }).done(this.checkGlassDrawingCall.bind(this));
    }
    private checkGlassDrawingCall(response:any){
        if (response && response.status == "success") {
            Vue.set(this.product, "glassDrawingLink", response.data["@microsoft.graph.downloadUrl"]);

            if (Data.data.platform.id == "MYVERALLIA"){
                if (Data.data.view == "general" && Data.myProductsJson[this.product.id]){
                    Vue.set(Data.myProductsJson[this.product.id], "glassDrawingLink", response.data["@microsoft.graph.downloadUrl"]);
                } else if (Data.data.view == "myproducts" && Data.productsJson[this.product.id]) {
                    Vue.set(Data.productsJson[this.product.id], "glassDrawingLink", response.data["@microsoft.graph.downloadUrl"]);
                }
            }
        }
    }


    //DILATATION CURVES PDF
    private checkDilatationCurvesPDF() {
        if (Data.data.platform.id != "INTERNAL" && Data.data.platform.id != "MYVERALLIA") return;

        $.ajax({ method: "GET", url: Data.data.platform.sharePointFilesURL + "DilatationCurves/C_" + this.product.glassDrawingSimplified + ".pdf", beforeSend: this.beforeSendToken.bind(this) }).done(this.checkDilatationCurvesCall.bind(this));
    }
    private checkDilatationCurvesCall(response: any){
        if (response && response.status == "success") {
            Vue.set(this.product, "dilatationCurvesLink", response.data["@microsoft.graph.downloadUrl"]);

            if (Data.data.platform.id == "MYVERALLIA") {
                if (Data.data.view == "general" && Data.myProductsJson[this.product.id]) {
                    Vue.set(Data.myProductsJson[this.product.id], "dilatationCurvesLink", response.data["@microsoft.graph.downloadUrl"]);
                } else if (Data.data.view == "myproducts" && Data.productsJson[this.product.id]) {
                    Vue.set(Data.productsJson[this.product.id], "dilatationCurvesLink", response.data["@microsoft.graph.downloadUrl"]);
                }
            }
        }
    }


    //ZOOM PICTURE
    public checkZoomPicture(){
        if (Data.data.platform.id == "SELECTIVELINE") return;
        if (Data.data.platform.id == "MYVERALLIA" && !Data.data.MYVeralliaUserCanAccessHDPictures) return;

        $.ajax({ method: "HEAD", url: Data.data.platform.productsImages + "zoom/" + this.product.baseProducts[this.product.bpIndex] + ".jpg", success: this.checkZoomPictureCall.bind(this) }).done(this.checkZoomPictureCall.bind(this));
    }
    private checkZoomPictureCall(response:any, text:any, request:any){
        if (request && request.status == 200) {
            Vue.set(Data.data.baseProducts[this.product.baseProducts[this.product.bpIndex]], "zoomPicture", true);

            if (Data.data.platform.id == "MYVERALLIA") {
                if (Data.data.view == "general" && Data.data.myProductsBaseProducts[this.product.baseProducts[this.product.bpIndex]]) {
                    Vue.set(Data.data.myProductsBaseProducts[this.product.baseProducts[this.product.bpIndex]], "zoomPicture", true);
                } else if (Data.data.view == "myproducts" && Data.data.baseProducts[this.product.baseProducts[this.product.bpIndex]]) {
                    Vue.set(Data.data.baseProducts[this.product.baseProducts[this.product.bpIndex]], "zoomPicture", true);
                }
            }
        }
    }




    public getFinishedProductsData(){
        if (Data.data.platform.id != "INTERNAL") return;

        this.getFinishedProducts(this.product.baseProducts[this.product.bpIndex]);
    }
    private getFinishedProducts(baseProductID:string){
        let baseProduct: any = Data.data.baseProducts[baseProductID];
        if (Data.data.finishedProducts[baseProductID]) return;

        $.ajax({ method: "GET", url: Data.data.platform.finishedProductsURL + this.product.baseProducts[this.product.bpIndex] }).done(this.setFinishedProducts.bind(this, baseProductID));
    }
    private setFinishedProducts(baseProductID:string, response:any){
        let baseProduct: any = Data.data.baseProducts[baseProductID];
        if (!Data.data.finishedProducts[baseProductID]) Data.data.finishedProducts[baseProductID] = [];

        let finishedProducts:any = null;
        try {
            if (response.status == "success" && response.data) finishedProducts = response.data;
        } catch (e) {
            return;
        }


        //if (finishedProducts && finishedProducts.length != 0 && finishedProducts[0].baseProduct == baseProductID) {
        if (finishedProducts && finishedProducts.length != 0) {
            baseProduct.selected_line = 0;

            let a: number = finishedProducts.length;
            for (let i=0; i<a; i++) {

                finishedProducts[i].id = finishedProducts[i]._id;
                delete finishedProducts[i]._id;

                finishedProducts[i].quantity = 1;
                finishedProducts[i].display = true;

                if (finishedProducts[i].materialGroup) {
                    if (finishedProducts[i].materialGroup.toLowerCase() == "naac" || finishedProducts[i].materialGroup.toLowerCase() == "naaa" || finishedProducts[i].materialGroup.toLowerCase() == "naba" || finishedProducts[i].materialGroup.toLowerCase() == "nabc") finishedProducts[i].display = true;
                    else finishedProducts[i].display = false;
                }

                if (!finishedProducts[i].materialGroup) finishedProducts[i].display = false;
                //if(finishedProducts[i].status && finishedProducts[i].status.toLowerCase() == "z3") finishedProducts[i].display = false;
                if (finishedProducts[i].status && finishedProducts[i].status.toLowerCase() == "z1") finishedProducts[i].display = false;

                let status:any = ["st", "sr"];
                if (finishedProducts[i].standard && status.indexOf(finishedProducts[i].standard.toLowerCase()) == -1) finishedProducts[i].display = false;
                if (!finishedProducts[i].standard) finishedProducts[i].display = false;

                if (finishedProducts[i].display) Data.data.finishedProducts[baseProductID].push(finishedProducts[i]);
            }
        }

        if (Data.data.finishedProducts[baseProductID].length > 0) this.getFinishedProductStock(baseProductID);
    }



    private getFinishedProductStock(baseProductID: string) {
        if (Data.data.platform.id != "INTERNAL") return;

        let url: string = Data.data.platform.finishedProductsStockURL;
        let str:string;
        for (str in Data.data.finishedProducts[baseProductID]){
            url += Data.data.finishedProducts[baseProductID][str].id + ",";
        }

        //url += "&lang=" + Data.data.language;
        url += "&lang=" + Data.data.config.oldLanguagesMapping[Data.data.language];

        $.ajax({ method: "GET", url: url }).done(this.setFinishedProductStock.bind(this, baseProductID));
    }
    private setFinishedProductStock(baseProductID: string, response: any){
        let xml: any;

        try{
            xml = $(response)[2];
        }catch(e:any){
            console.log("error getting dtock data, trying another method", e);
        }

        if(!xml){
            try {
                xml = $(response)[0];
            } catch (e: any) {
                console.log("error getting dtock data", e);
            }
        }

        if (!xml) return;

        let stockData: any = {};

        let str:string;
        for (str in Data.data.finishedProducts[baseProductID]){
            let finishedProduct: any = Data.data.finishedProducts[baseProductID][str];

            if (!finishedProduct.materialGroup) continue;
            if (finishedProduct.materialGroup && finishedProduct.materialGroup.toLowerCase() == "naad") continue;

            if (!stockData[finishedProduct.id]) stockData[finishedProduct.id] = [];

            $(xml).find("EItemFp").find("item").each(function (index) {
                let code:string = String(Number($(this).find('ProductCode').text()));

                let company:string = $(this).find('Company').text();
                if (company && company != "") {
                    if (company == "S09" || company == "S08") return;
                }

                let plantcode:string = $(this).find('PlantCode').text();
                if (plantcode && plantcode != "") {
                    if (plantcode == "D830" || plantcode == "D500" || plantcode == "D700") return;
                }


                if (code.indexOf(finishedProduct.id) == -1) return;


                let stock:any = {};

                stock.Code = code;
                stock.BaseUnit = $(this).find('BaseUnit').text();
                stock.ProductCode = $(this).find('ProductCode').text();
                stock.ProductDescription = $(this).find('ProductDescription').text();
                stock.Country = $(this).find('Country').text();
                if (stock.Country) stock.Country = stock.Country.toLowerCase();
                stock.PlantCode = $(this).find('PlantCode').text();
                stock.PlantText = $(this).find('PlantText').text();
                stock.FreeStock = $(this).find('FreeStock').text();
                stock.BlockedStock = $(this).find('BlockedStock').text();
                stock.PartsVendorStock = $(this).find('PartsVendorStock').text();
                stock.OrdersOnhandStock = $(this).find('OrdersOnhandStock').text();
                stock.SalesReqQty = $(this).find('SalesReqQty').text();
                stock.ProdDateFrom = $(this).find('ProdDateFrom').text();
                if (stock.ProdDateFrom == "0000-00-00") stock.ProdDateFrom = "";
                stock.ProdDateTo = $(this).find('ProdDateTo').text();
                stock.ProdTotalQty = $(this).find('ProdTotalQty').text();
                stock.ProdRemainQty = $(this).find('ProdRemainQty').text();
                stock.StckInTransit = $(this).find('StckInTransit').text();
                stock.StckInTransitUsed = 0;

                stock.StckInTransitUb = $(this).find('StckInTransitUb').text();
                stock.StckInTransitEb = $(this).find('StckInTransitEb').text();

                stock.quantity = Number(stock.FreeStock) - Number(stock.SalesReqQty);

                if (stock.StckInTransitUb < 0) {
                    stock.quantity += Number(stock.StckInTransitUb);
                }
                if (stock.StckInTransitEb < 0) {
                    stock.quantity += Number(stock.StckInTransitEb);
                }

                if (stock.StckInTransitUb > 0) {
                    stock.StckInTransitUsed += Number(stock.StckInTransitUb);
                }
                if (stock.StckInTransitEb > 0) {
                    stock.StckInTransitUsed += Number(stock.StckInTransitEb);
                }

                stock.quantity += Number(stock.StckInTransitUsed);

                if (stock.quantity != 0 || Number(stock.ProdRemainQty) > 0) {
                    stockData[finishedProduct.id].push(stock);
                }

            })

            if (stockData[finishedProduct.id].length == 0) delete stockData[finishedProduct.id];
        }


        //@ts-ignore
        Vue.set(Data.data.finishedProductsStocks, baseProductID, stockData);

        // var a = t.baseproduct.fip.length;
        // for (var i = 0; i < a; i++) {
        //     var fp = t.baseproduct.fip[i];


        //     var status = true;
        //     if (fp.materialGroup.toLowerCase() != "naad" && status == true) {
        //         fp.stock = {};

        //         $(data[2]).find("EItemFp").find("item").each(function (index) {
        //             var code = $(this).find('ProductCode').text();

        //             var company_good = true;
        //             var company = $(this).find('Company').text();
        //             if (company && company != "") {
        //                 if (company == "S09" || company == "S08") company_good = false;
        //             }

        //             var plantcode = $(this).find('PlantCode').text();
        //             if (plantcode && plantcode != "") {
        //                 if (plantcode == "D830" || plantcode == "D500" || plantcode == "D700") company_good = false;
        //             }

        //             if (code.indexOf(t.baseproduct.fip[i].id) != -1 && company_good == true) {
        //                 var stock = {};

        //                 stock.BaseUnit = $(this).find('BaseUnit').text();
        //                 stock.ProductCode = $(this).find('ProductCode').text();
        //                 stock.ProductDescription = $(this).find('ProductDescription').text();
        //                 stock.Country = $(this).find('Country').text();
        //                 stock.PlantCode = $(this).find('PlantCode').text();
        //                 stock.PlantText = $(this).find('PlantText').text();
        //                 stock.FreeStock = $(this).find('FreeStock').text();
        //                 //fp.stock.FreeStock = Math.round(Math.random() * 200000);
        //                 stock.BlockedStock = $(this).find('BlockedStock').text();
        //                 stock.PartsVendorStock = $(this).find('PartsVendorStock').text();
        //                 stock.OrdersOnhandStock = $(this).find('OrdersOnhandStock').text();
        //                 stock.SalesReqQty = $(this).find('SalesReqQty').text();
        //                 //fp.stock.SalesReqQty = Math.round(Math.random() * 50000);
        //                 stock.ProdDateFrom = $(this).find('ProdDateFrom').text();
        //                 if (stock.ProdDateFrom == "0000-00-00") stock.ProdDateFrom = "";
        //                 stock.ProdDateTo = $(this).find('ProdDateTo').text();
        //                 stock.ProdTotalQty = $(this).find('ProdTotalQty').text();
        //                 stock.ProdRemainQty = $(this).find('ProdRemainQty').text();
        //                 stock.StckInTransit = $(this).find('StckInTransit').text();
        //                 stock.StckInTransitUsed = 0;

        //                 stock.StckInTransitUb = $(this).find('StckInTransitUb').text();
        //                 stock.StckInTransitEb = $(this).find('StckInTransitEb').text();

        //                 stock.quantity = Number(stock.FreeStock) - Number(stock.SalesReqQty);

        //                 if (stock.StckInTransitUb < 0) {
        //                     stock.quantity += Number(stock.StckInTransitUb);
        //                 }
        //                 if (stock.StckInTransitEb < 0) {
        //                     stock.quantity += Number(stock.StckInTransitEb);
        //                 }

        //                 if (stock.StckInTransitUb > 0) {
        //                     stock.StckInTransitUsed += Number(stock.StckInTransitUb);
        //                 }
        //                 if (stock.StckInTransitEb > 0) {
        //                     stock.StckInTransitUsed += Number(stock.StckInTransitEb);
        //                 }

        //                 stock.quantity += Number(stock.StckInTransitUsed);

        //                 if (stock.quantity != 0 || Number(stock.ProdRemainQty) > 0) {
        //                     fp.stock[code + stock.PlantCode] = stock;

        //                     if (!t.stock_data[t.baseproduct.fip[i].id]) t.stock_data[t.baseproduct.fip[i].id] = { fip: {}, quantity: 0 };
        //                     t.stock_data[t.baseproduct.fip[i].id].fip[code + stock.PlantCode] = stock;

        //                     if (t.countries.indexOf(stock.Country.toLowerCase()) == -1) {
        //                         t.countries.push(stock.Country.toLowerCase());
        //                     }
        //                 }
        //             }
        //         })
        //     }
        //}
    }
}