import config from "../../assets/config.json";
import colorsMapping from "../../assets/colorsMapping.json";
import finishMapping from "../../assets/finishMapping.json";
import VGProjectModel from "../../assets/VGProjectModel.json";
import VGItemModel from "../../assets/VGItemModel.json";
import VGItemDataModel from "../../assets/VGItemDataModel.json";
import VGRenderModel from "../../assets/VGRenderModel.json";
import VGImageModel from "../../assets/VGImageModel.json";
import VGUserDataModel from "../../assets/VGUserDataModel.json";
import Player from "../player/Player";
import Utils from "../utils/Utils";
import URLManager from "../utils/URLManager";

declare global {
    interface Window { DATA: any, VG: any, PLAYER: Player, playerContainer:any }
    //interface Window { DATA: any, VG:any, PLAYER:any }
}

export default class Data{
    static forcePlatform: any = null;
    static originLanguage: string = null;

    static environments: any = null;
    static productsData: any = { };
    static data: any = { };
    static catalogJson: any ; //unused (for old data model)
    static myProductsJson: any ;
    static productsJson: any ;
    static baseProductsJson: any ;
    static myProductsBaseProductsJson: any ;
    static plsJson: any ;
    static marketsJson: any ;
    static colorsJson: any ;
    static finishJson: any ;
    static packageJson: any ;
    static palletJson: any ;
    static shapeJson: any ;
    static productsLinesJson: any ;

    static init(){
        this.data.platform = null;
        this.data.mode = "PROD";
        this.data.oldDataMode = false;

        try {
            //@ts-ignore
            if (window.OLD_DATA) this.oldDataMode = true;
        } catch (e: any) {

        }


        this.data.adminUsers = ["XXXXXX", "B1147402", "S6677133"];
        this.data.oldVGMode = false;
        this.data.firstDisplay = true;
        this.data.salesforceToken = null;
        this.data.token = null;
        this.data.headers = null;
        this.data.userID = "XXXXXX";
        this.data.user = { id: "XXXXXX", email: "XXX.XXX@verallia.com", country: "fr", company: "sge", lastConnection: "2021-11-22 10:09:32", firstConnection:"2021-11-22 10:09:32" };

        //MYVERALLIA DATA
        this.data.MyVeralliaUserID = null;
        this.data.MYVeralliaCanAccessVG = null;
        this.data.MyVeralliaUserCanOrder = true;
        this.data.MYVeralliaUserCanAccessHDPictures = true;




        this.data.windowWidth = 0;
        this.data.windowHeight = 0;
        this.data.isMobile = false;
        this.data.isLocalhost = true;
        this.data.app = "catalog";
        this.data.section = null;
        this.data.config = config;
        this.data.colorsMapping = colorsMapping;
        this.data.finishMapping = finishMapping;

        this.data.country = config.defaultCountry;
        this.data.language = config.defaultLanguage;
        this.data.view = null;

        this.data.VGMode = false;
        this.data.filtersStatus = false;
        this.data.productSection = null;
        this.data.productSections = ["commercialData", "specs", "buy", "finishedProducts", "stocks"];
        this.data.comparatorStatus = false;
        this.data.comparator = {};
        this.data.menu = false;
        this.data.catalogSearch = null;
        this.data.catalogSearchWithFilters = true;
        this.data.catalogPage = 1;
        this.data.userParams = false;
        this.data.locs = {};
        this.data.loc = {};
        this.data.masterFilteredProducts = null;
        this.data.filteredProducts = null;
        this.data.products = {};
        this.data.baseProducts = {};
        this.data.finishedProducts = {};
        this.data.finishedProductsStocks = {};
        this.data.plsProducts = {};
        this.data.mvCountries = null;

        // Models for filters
        this.data.productOwner = [];
        this.data.productionCountries = [];
        this.data.countries = [];
        this.data.productsLines = [];
        this.data.markets = {};
        this.data.colors = [];
        this.data.finish = [];
        this.data.brands = [];
        this.data.height = { min:1000000, max:0, range:[0, 0] };
        this.data.weight = { min: 1000000, max: 0, range: [0, 0] };
        this.data.diameter = { min: 1000000, max: 0, range: [0, 0] };
        this.data.finishDiameter = { min: 1000000, max: 0, range: [0, 0] };
        this.data.carbonationMax = { min: 1000000, max: 0, range: [0, 0] };
        this.data.volume = { min: 1000000, max: 0, range: [0, 0] };
        this.data.virtualGlass = false;
        this.data.selectiveLine = false;
        this.data.developReady = false;
        this.data.new = false;
        this.data.ecova = false;
        this.data.reuse = false;
        this.data.vinolok = false;
        this.data.pls = false;
        this.data.plsDecoration = false;
        this.data.plsYearbook = false;
        this.data.plsSalesOffice = { selected:[], data:[] };
        this.data.plsFilling = { selected:[], data:[] };
        this.data.plsClients = { selected:[], data:[] };
        this.data.plsCommercial = { selected:[], data:[] };
        this.data.plsDecorators = { selected:[], data:[] };
        this.data.plsCommunication = { selected:[], data:[] };
        this.data.plsCreationDate = { startDate: null, endDate: null, dates: [] };
        this.data.plsLaunchDate = { startDate: null, endDate: null, dates:[] };

        this.data.productsOrder = "nameASC";
        this.data.filters = null;
        this.data.savedFilters = {};
        this.data.userPath = [];


        this.data.vg = { dataReady:false, env:"localhost", serverData:null };
        this.data.vg.oldData = null;
        this.data.vg.oldUserID = null;
        this.data.vg.isAddingFromCatalog = false;
        this.data.vg.renderSize = 5;
        this.data.vg.capsules = {}; // used to store capsules types from glassDrawings (single mesh)
        this.data.vg.modelsList = null;
        this.data.vg.models = { catalog:{}, capsules:{} }; //used to store models data and capsules compatibility
        this.data.vg.colors = null;
        this.data.vg.labels = null;
        this.data.vg.contents = null;
        this.data.vg.contentCategories = null;
        this.data.vg.project = null;
        this.data.vg.projectsFolders = {};
        this.data.vg.item = null;
        this.data.vg.itemIndex = -1;
        this.data.vg.userData = null;
        this.data.vg.projects = [];
        this.data.vg.items = [];
        this.data.vg.VGProjectModel = VGProjectModel;
        this.data.vg.VGItemModel = VGItemModel;
        this.data.vg.VGItemDataModel = VGItemDataModel;
        this.data.vg.VGRenderModel = VGRenderModel;
        this.data.vg.VGImageModel = VGImageModel;
        this.data.vg.VGUserDataModel = VGUserDataModel;
        this.data.vg.itemData = null;
        this.data.vg.catalogItem = null;
        this.data.vg.projectCreationWaiting = false;


        this.data.scrollOptions = {
            vuescroll: {
                wheelScrollDuration: 350,
                mode: 'native',
                sizeStrategy: 'percent',
                detectResize: true,
                locking: true
            },
            scrollPanel: {
                initialScrollY: false,
                initialScrollX: false,
                scrollingX: false,
                scrollingY: true,
                speed: 500,
                easing: "easeOutQuad",
                verticalNativeBarPos: 'right'
            },
            rail: {
                background: '#01a99a',
                opacity: 0,
                size: '6px',
                specifyBorderRadius: false,
                gutterOfEnds: null,
                gutterOfSide: '2px',
                keepShow: false
            },
            bar: {
                showDelay: 500,
                onlyShowBarOnScroll: false,
                keepShow: false,
                background: '#c1c1c1',
                opacity: 1,
                hoverStyle: false,
                specifyBorderRadius: false,
                minSize: 0,
                size: '6px',
                disable: false
            }
        }

        this.data.scrollOptionsBlack = Utils.copy(this.data.scrollOptions);
        this.data.scrollOptionsBlack.rail.background = "#252525";
        this.data.scrollOptionsBlack.bar.background = "#252525";

        this.data.scrollOptionsAllDirections = Utils.copy(this.data.scrollOptions);
        this.data.scrollOptionsAllDirections.scrollPanel.scrollingX = true;

        window.DATA = this.data;
        window.VG = this.data.vg;
    }
}