
    import $ from "jquery";
    import Generic from "../Generic.vue";
    import Data from "@/ts/application/Data";
    import Loader from "@/ts/application/Loader";

    import {
        Component,
    } from 'vue-property-decorator';
    import Utils from "@/ts/utils/Utils";

    @Component({
        components: {

        }
    })
    export default class Disclaimer extends Generic {
        private accepted:Boolean = false;
        private fileLoader:Loader;


        private mounted() {

        }
        private destroyed(){
            Data.data.vg.projectCreationWaiting = false;
        }
        private decline(){
            Data.data.vg.projectCreationWaiting = false;

            this.$set(Data.data, "VGMode", false);
            this.$set(Data.data, "app", "catalog");
            this.$set(Data.data, "section", "view");
            this.$set(Data.data, "view", "products");
            $(window).trigger("CLOSE_PRODUCT");
        }
        private declineAndSave(){
            this.accept(false);
        }
        private accept(accepted:boolean=true){
            this.accepted = accepted;

            $(window).trigger("SHOW_OVERLAY");
            $(window).trigger("SHOW_LOADING");

            let userData: any = { data:Utils.copy(Data.data.vg.userData.data) };
            userData.data.disclaimer = accepted;
            if(accepted) userData.data.disclaimerDate = new Date().toISOString();
            else userData.data.disclaimerDate = null;

            this.fileLoader = new Loader(this.completed.bind(this), this.error.bind(this));
            this.fileLoader.add({ id: "updateUserData", url: Data.data.platform.digitalEngineVGURL + "update-user-data/" + Data.data.user.id, method: "PUT", contentType: "application/json", data: JSON.stringify(userData), headers: Data.data.headers });
            this.fileLoader.start();
        }
        private completed(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            this.$set(Data.data.vg.userData.data, "disclaimer", this.accepted);

            if(this.accepted){
                this.$set(Data.data.vg.userData.data, "disclaimerDate", new Date());
                this.$set(Data.data, "section", "home");

                if (Data.data.vg.projectCreationWaiting) {
                    Data.data.vg.projectCreationWaiting = false;
                    $(window).trigger("CREATE_TEMP_PROJECT");
                }
            }else{
                Data.data.vg.projectCreationWaiting = false;

                this.$set(Data.data.vg.userData.data, "disclaimerDate", null);
                this.decline();
            }
        }
        private error(){
            $(window).trigger("HIDE_OVERLAY");
            $(window).trigger("HIDE_LOADING");

            $(window).trigger("DIALOG_OPEN", Data.data.config.dialogs.errorDigitalEngine);
        }
        private getCGUURL(){
            return Data.data.platform.virtualGlassCGUUrl.replace("{{LANG}}", Data.data.language);
        }
        private getAcceptedText(){
            let text:string = this.loc("vg-disclaimerTextAccepted");
            let dateText:string = this.formatDate(Data.data.vg.userData.data.disclaimerDate, true);
            return text.replace("{{DATE}}", dateText);
        }
        private close(){
            Data.data.vg.projectCreationWaiting = false;

            this.$set(Data.data, "section", "home");
        }
    }
