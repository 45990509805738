import * as PIXI from "pixi.js";
import PixiManager from '../managers/PixiManager';
import Utils from '../utils/Utils';
import VGObject from './VGObject';


export default class Label {
    public uid: string;
    public VGObject: VGObject;
    public pixiManager: PixiManager;
    public isActive: boolean = false;
    public base64: string;
    public base64_HD: string;

    public data: any = {};
    public currentTarget: any;
    public textureFront: any;
    public textureFrontTemp: any;
    public frontMaterial: any;
    public backMaterial: any;
    public front: any;
    public back: any;

    public labelLoader: any;
    public texture: any;

    public pixiContainer: any;
    public pixiContainerSprites: any;
    public pixiMask: any;
    public pixiLabel: any;
    public pixiLabelBG: any;
    public pixiLabelArea: any;
    public pixiDecorArea: any;

    public pixiLabelSprite: any;
    public textureScale: number = 3;
    public labelArea: any = { width: 0, height: 0 };
    public decorArea: any = { width: 0, height: 0 };
    public labelData: any = { width: 0, height: 0 };



    constructor(VGObject: VGObject) {
        this.VGObject = VGObject;
        this.pixiManager = this.VGObject.babylon.application.pixi;
        this.uid = Utils.generateUID();
    }
    public init() {
        this.labelArea.width = (this.VGObject.modelData.circumference / 2) * this.textureScale;
        this.labelArea.height = this.VGObject.modelData.labelHeight * this.textureScale;
        this.decorArea.width = this.VGObject.modelData.circumference * this.textureScale;
        this.decorArea.height = (this.VGObject.modelData.labelHeight + this.VGObject.modelData.labelBottomPosition) * this.textureScale;

        this.front = this.VGObject.decorMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_DECOR_FRONT");
        this.front.parent = this.VGObject.container;
        this.front.sideOrientation = BABYLON.Mesh.FRONTSIDE;
        this.front.alphaIndex = 7;
        this.front.visibility = 0;
        //this.front.renderingGroupId = 5;
        this.front.scaling.x = 1.005;
        this.front.scaling.y = 1.005;
        this.front.scaling.z = -1.005;
        //this.front.position.x = -0.2;
        //this.front.rotation.y = Utils.degreesToRadians(90);
        //this.front.rotate(BABYLON.Axis.Y, Utils.degreesToRadians(90), BABYLON.Space.WORLD);


        this.back = this.VGObject.decorMesh.clone(this.VGObject.mdata.id + "_" + this.uid + "_DECOR_BACK");
        this.back.parent = this.VGObject.container;
        this.back.sideOrientation = BABYLON.Mesh.BACKSIDE;
        this.back.alphaIndex = 0;
        this.back.visibility = 0;
        this.back.scaling.x = 1.005;
        this.back.scaling.y = 1.005;
        this.back.scaling.z = -1.005;
        //this.back.position.x = 0.2;
        //this.back.rotation.y = Utils.degreesToRadians(90);
        //this.back.rotate(BABYLON.Axis.Y, Utils.degreesToRadians(90), BABYLON.Space.WORLD);


        this.frontMaterial = new BABYLON.StandardMaterial(this.VGObject.mdata.id + "_" + this.uid + "_LabelFrontMaterial", this.VGObject.babylon.scene);
        //this.frontMaterial.diffuseTexture = new BABYLON.Texture("label.png", this.VGObject.babylon.scene);
        //this.frontMaterial.diffuseTexture.hasAlpha = true;
        this.frontMaterial.useAlphaFromDiffuseTexture = true;
        this.frontMaterial.roughness = 0.8;
        //this.frontMaterial.backFaceCulling = false;
        //this.frontMaterial.separateCullingPass = true;
        this.front.material = this.frontMaterial;
        //this.front.rotate(BABYLON.Axis.Y, Utils.degreesToRadians(90), BABYLON.Space.WORLD)


        this.backMaterial = new BABYLON.StandardMaterial(this.VGObject.mdata.id + "_" + this.uid + "_LabelBackMaterial", this.VGObject.babylon.scene);
        //this.backMaterial.diffuseTexture = new BABYLON.Texture("labelBack.png", this.VGObject.babylon.scene);
        //this.backMaterial.diffuseTexture.hasAlpha = true;
        this.backMaterial.useAlphaFromDiffuseTexture = true;
        this.backMaterial.backFaceCulling = false;
        this.backMaterial.roughness = 0.8;
        //this.backMaterial.separateCullingPass = true;
        this.back.material = this.backMaterial;
    }
    public sceneChange() {
        return;

        //this.frontMaterial.envMap = this.VGObject.threeManager.sceneData.cudeTexture;
        //this.backMaterial.envMap = this.VGObject.threeManager.sceneData.cudeTexture;
    }
    public enable() {
        //this.front.setEnabled(true);
        //this.back.setEnabled(true);

        if (this.isActive == true) {
            this.front.visibility = 1;
            this.back.visibility = 1;
        } else {
            this.front.visibility = 0;
            this.back.visibility = 0;
        }
    }
    public disable() {
        //this.front.setEnabled(false);
        this.front.visibility = 0;
        this.back.visibility = 0;
        //this.back.setEnabled(true);
    }






    public setLabelImage(target: any, url: string, position: any, height: number, onComplete: any = null, onError: any = null) {
        this.VGObject.modelData[target + "MaxHeight"] = null;
        this.isActive = true;

        this.currentTarget = target;

        if (!this.data[this.currentTarget]) {
            this.data[this.currentTarget] = {};
        }

        this.data[this.currentTarget].rect = { width: 0, height: 0 };
        this.data[this.currentTarget].position = position;
        this.data[this.currentTarget].height = height;
        this.data[this.currentTarget].maxHeight = height;
        this.data[this.currentTarget].url = url;
        this.data[this.currentTarget].onComplete = onComplete;
        this.data[this.currentTarget].onError = onError;
        this.data[this.currentTarget].loader = new PIXI.Loader();
        this.data[this.currentTarget].loader.add(this.currentTarget, url, { crossOrigin: 'anonymous' });
        this.data[this.currentTarget].loader.onComplete.add(this.labelImageComplete.bind(this));
        this.data[this.currentTarget].loader.onError.add(this.labelImageError.bind(this));
        this.data[this.currentTarget].loader.load();

        //console.log(this.data[this.currentTarget]);
    }
    public labelImageError() {
        if (this.data[this.currentTarget].onError) this.data[this.currentTarget].onError();
    }
    public labelImageComplete(loader: any, ressources: any) {
        this.data[this.currentTarget].loader = null;
        delete this.data[this.currentTarget].loader;

        this.data[this.currentTarget].texture = ressources[this.currentTarget].texture;

        this.data[this.currentTarget].rect.width = this.data[this.currentTarget].texture.baseTexture.width;
        this.data[this.currentTarget].rect.height = this.data[this.currentTarget].texture.baseTexture.height;

        let scale: number = Utils.fitRectIntoBounds(this.data[this.currentTarget].rect, this.labelArea);
        this.data[this.currentTarget].maxHeight = (this.data[this.currentTarget].rect.height * scale) / this.textureScale;
        this.VGObject.modelData[this.currentTarget + "MaxHeight"] = this.data[this.currentTarget].maxHeight;

        if (!this.data[this.currentTarget].height) {
            this.data[this.currentTarget].height = this.data[this.currentTarget].maxHeight / 2;
            this.data[this.currentTarget].position = (this.VGObject.modelData.labelHeight - this.data[this.currentTarget].height) / 2;

            //this.data[this.currentTarget].height = this.data[this.currentTarget].maxHeight;
        }

        if (this.data[this.currentTarget].onComplete) this.data[this.currentTarget].onComplete();

        this.generateTexture();
    }
    public setLabelPosition(target: any, position: any) {
        if (!this.data[target]) return;

        this.data[target].position = position;

        if (this.data[target].position + this.data[target].height > this.VGObject.modelData.labelHeight) {
            this.data[target].height = Math.round(this.VGObject.modelData.labelHeight - this.data[target].position);
        }

        this.generateTexture();
    }
    public getLabelPosition(target: any) {
        return this.data[target].position;
    }
    public setLabelHeight(target: any, height: number) {
        if (!this.data[target]) return;

        this.data[target].height = height;

        if (this.data[target].position + this.data[target].height > this.VGObject.modelData.labelHeight) {
            //this.data[target].position = this.data[target].maxHeight - this.data[target].height;
            //console.log(this.VGObject.modelData.labelHeight + " / " + this.data[target].height);
            this.data[target].position = this.VGObject.modelData.labelHeight - this.data[target].height;
        }

        this.generateTexture();
    }
    public getLabelHeight(target: any) {
        return this.data[target].height;
    }
    public removeLabel(target: any) {
        //console.log("target " + target);
        if (this.data[target]) {
            if (this.data[target].sprite) {
                if (this.data[target].areaSprite) {
                    this.data[target].areaSprite.removeChild(this.data[target].sprite);
                    this.data[target].sprite = null;
                    delete this.data[target].sprite;

                    if (this.data[target].secondSprite) {
                        this.data[target].areaSprite.removeChild(this.data[target].secondSprite);
                        this.data[target].secondSprite = null;
                        delete this.data[target].secondSprite;
                    }

                    this.pixiContainer.removeChild(this.data[target].areaSprite);
                    this.data[target].areaSprite = null;
                    delete this.data[target].areaSprite;
                }
            }

            delete this.data[target];

            this.generateTexture();
        }

        if (!this.data.label && !this.data.backLabel && !this.data.decor) {
            this.isActive = false;
            this.front.visibility = 0;
            this.back.visibility = 0;
        }
    }



    public generateTexture() {
        if (!this.pixiContainer) {
            this.pixiContainer = new PIXI.Container();
            this.pixiManager.stage.addChild(this.pixiContainer);

            this.pixiContainerSprites = new PIXI.Container();
            this.pixiContainer.addChild(this.pixiContainerSprites);

            this.pixiMask = new PIXI.Graphics();
            this.pixiContainer.addChild(this.pixiMask);
            this.pixiMask.beginFill(0xffffff, 1);
            this.pixiMask.drawRect(0, 0, this.VGObject.modelData.circumference * this.textureScale, this.VGObject.modelData.height * this.textureScale);
            this.pixiMask.endFill();
            this.pixiContainerSprites.mask = this.pixiMask;

            this.pixiLabelBG = new PIXI.Graphics();
            this.pixiContainerSprites.addChild(this.pixiLabelBG);
            this.pixiLabelBG.beginFill(0xffffff, 0);
            this.pixiLabelBG.drawRect(0, 0, this.VGObject.modelData.circumference * this.textureScale, this.VGObject.modelData.height * this.textureScale);
            this.pixiLabelBG.endFill();
        }

        let targets: string[] = ["label", "backLabel", "decor"];
        for (let i = 0; i < targets.length; i++) {
            let target: string = targets[i];

            if (this.data[target]) {
                if (!this.data[target].areaSprite) {
                    this.data[target].areaSprite = new PIXI.Graphics();
                    this.pixiContainerSprites.addChild(this.data[target].areaSprite);
                    this.data[target].areaSprite.lineStyle(1, 0x000000, 0);

                    if (target == "label" || target == "backLabel") {
                        this.data[target].areaSprite.drawRect(0, 0, this.labelArea.width, this.labelArea.height);
                        this.data[target].areaSprite.endFill();
                        //this.data[target].areaSprite.x = this.labelArea.width / 2;
                        this.data[target].areaSprite.y = ((this.VGObject.modelData.height - this.VGObject.modelData.labelBottomPosition) * this.textureScale) - this.labelArea.height;
                    } else if (target == "decor"){
                        this.pixiContainerSprites.setChildIndex(this.data[target].areaSprite, 0);
                        this.data[target].areaSprite.drawRect(0, 0, this.decorArea.width, this.decorArea.height);
                        this.data[target].areaSprite.endFill();
                        //this.data[target].areaSprite.x = this.labelArea.width / 2;
                        this.data[target].areaSprite.y = 0;
                    }
                }

                if (this.data[target].sprite) {
                    this.data[target].areaSprite.removeChild(this.data[target].sprite);
                    this.data[target].sprite = null;

                    // if(this.data[target].secondSprite){
                    //     this.data[target].areaSprite.removeChild(this.data[target].secondSprite);
                    //     this.data[target].secondSprite = null;
                    // }
                }

                this.data[target].sprite = new PIXI.Sprite(this.data[target].texture);
                this.data[target].areaSprite.addChild(this.data[target].sprite);
                this.data[target].rect.width = this.data[target].sprite.width;
                this.data[target].rect.height = this.data[target].sprite.height;

                // if(target == "backLabel"){
                //     if(this.data[target].secondSprite){
                //         this.data[target].areaSprite.removeChild(this.data[target].secondSprite);
                //         this.data[target].secondSprite = null;
                //     }

                //     this.data[target].secondSprite = new PIXI.Sprite(this.data[target].texture);
                //     this.data[target].areaSprite.addChild(this.data[target].secondSprite);
                //     this.data[target].rect.width = this.data[target].secondSprite.width;
                //     this.data[target].rect.height = this.data[target].secondSprite.height;
                // }
            }
        }

        this.positionLabels();


        let imageBase64: any = this.pixiManager.renderer.renderer.plugins.extract.image(this.pixiContainer);
        this.base64 = imageBase64.src;

        //
        //if (this.textureFrontTemp) this.textureFrontTemp.dispose();
        this.textureFrontTemp = BABYLON.Texture.CreateFromBase64String(this.base64, this.uid + "_LabelImage_" + Utils.generateUID(), this.VGObject.babylon.scene, false, true, BABYLON.Texture.LINEAR_LINEAR_MIPLINEAR, setTexture);

        let t: any = this;
        function setTexture() {
            t.frontMaterial.diffuseTexture = t.textureFrontTemp.clone();
            //if (t.textureFront) t.textureFront.dispose();
            t.textureFront = t.frontMaterial.diffuseTexture;

            t.frontMaterial.diffuseTexture.hasAlpha = true;
            //t.frontMaterial.diffuseTexture.uScale = -1;
            t.frontMaterial.diffuseTexture.vScale = -1;

            t.backMaterial.diffuseTexture = t.textureFront;
            t.backMaterial.diffuseTexture.hasAlpha = true;
            // t.backMaterial.diffuseTexture.uScale = -1;
            t.backMaterial.diffuseTexture.vScale = -1;
        }





        this.front.visibility = 1;
        this.back.visibility = 1;

        /*this.texture = new THREE.Texture(imageBase64);
        this.texture.needsUpdate = true;
        this.frontMaterial.map = this.texture;
        this.backMaterial.map =  this.textur*/
    }
    public positionLabels() {
        let targets: string[] = ["label", "backLabel", "decor"];
        for (let i = 0; i < targets.length; i++) {
            let target: string = targets[i];

            if (this.data[target]) {
                if (target == "label" || target == "backLabel") {
                    this.data[target].sprite.width = this.data[target].rect.width * (this.data[target].height / this.data[target].rect.height);
                    this.data[target].sprite.width *= this.textureScale;
                    this.data[target].sprite.height = this.data[target].height;
                    this.data[target].sprite.height *= this.textureScale;
                    this.data[target].sprite.x = (this.labelArea.width - this.data[target].sprite.width) / 2;
                    this.data[target].sprite.y = this.data[target].position * this.textureScale;

                    if (target == "backLabel") {
                        this.data[target].sprite.x = this.labelArea.width + ((this.labelArea.width - this.data[target].sprite.width) / 2);

                        // this.data[target].secondSprite.width = this.data[target].sprite.width;
                        // this.data[target].secondSprite.height = this.data[target].sprite.height;
                        // this.data[target].secondSprite.x = this.labelArea.width + (this.labelArea.width / 2) - (this.data[target].secondSprite.width / 2);
                        // this.data[target].secondSprite.y = this.data[target].sprite.y;
                    }
                } else if (target == "decor"){
                    this.data[target].sprite.width = this.decorArea.width;
                    this.data[target].sprite.height = this.decorArea.height;
                }
            }
        }
    }







    public setLabel() {
        this.labelLoader = new PIXI.Loader();
        this.labelLoader.add("label", "static/assets/images/label.jpg");
        this.labelLoader.onComplete.add(this.labelImageComplete.bind(this));
        this.labelLoader.onError.add(this.labelImageError.bind(this));
        this.labelLoader.load();
    }
    public labelImageCompleted(loader: any, ressources: any) {
        if (!this.pixiContainer) {
            this.pixiContainer = new PIXI.Container();
            this.pixiManager.stage.addChild(this.pixiContainer);

            //this.pixiContainer.x = 512;

            this.pixiLabelBG = new PIXI.Graphics();
            this.pixiContainer.addChild(this.pixiLabelBG);
            this.pixiLabelBG.beginFill(0xffffff, 0.1);
            this.pixiLabelBG.drawRect(0, 0, this.VGObject.modelData.circumference * this.textureScale, this.VGObject.modelData.height * this.textureScale);
            this.pixiLabelBG.endFill();

            this.labelArea.width = (this.VGObject.modelData.circumference / 2) * this.textureScale;
            this.labelArea.height = this.VGObject.modelData.labelHeight * this.textureScale;


            this.pixiLabelArea = new PIXI.Graphics();
            this.pixiContainer.addChild(this.pixiLabelArea);
            this.pixiLabelArea.lineStyle(1, 0x000000, 1);
            this.pixiLabelArea.drawRect(0, 0, this.labelArea.width, this.labelArea.height);
            this.pixiLabelArea.endFill();
            this.pixiLabelArea.x = this.labelArea.width / 2;
            this.pixiLabelArea.y = ((this.VGObject.modelData.height - this.VGObject.modelData.labelBottomPosition) * this.textureScale) - this.labelArea.height;
        }

        if (this.pixiLabelSprite) {
            this.pixiLabelArea.removeChild(this.pixiLabelSprite);
            this.pixiLabelSprite = null;
        }

        this.pixiLabelSprite = new PIXI.Sprite(ressources.label.texture);
        this.pixiLabelArea.addChild(this.pixiLabelSprite);
        this.labelData.width = this.pixiLabelSprite.width;
        this.labelData.height = this.pixiLabelSprite.height;

        this.positionLabels();


        var imageBase64 = this.pixiManager.renderer.renderer.plugins.extract.image(this.pixiContainer);

        //this.texture = new THREE.Texture(imageBase64);
        this.texture.needsUpdate = true;
        this.frontMaterial.map = this.texture;
        this.backMaterial.map = this.texture;

    }
    public positionLabelss() {
        var rectScale = Utils.fitRectIntoBounds(this.labelData, this.labelArea);
        this.pixiLabelSprite.width *= rectScale;
        this.pixiLabelSprite.height *= rectScale;
        this.pixiLabelSprite.x = (this.labelArea.width - this.pixiLabelSprite.width) / 2;
        this.pixiLabelSprite.y = (this.labelArea.height - this.pixiLabelSprite.height) / 2;
    }
}