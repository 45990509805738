
    import Generic from "../Generic.vue";
    import $ from 'jquery';

    import {
        Component,
    } from 'vue-property-decorator';
    import Data from "@/ts/application/Data";
import Utils from "@/ts/utils/Utils";

    @Component({
        components: {

        }
    })
    export default class Menu extends Generic {
        private searchField:string = "";

        private toggleMenu(){
            Data.data.menu = !Data.data.menu;
        }
    }
